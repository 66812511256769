import { CalendarRoute } from "../../../../domains/routes/services/RoutesInterface";
import moment from "moment";
import { FlexContainer } from "../../../../components/containers";
import ReScheduleRoute from "../../../../domains/routes/components/ReScheduleRoute";

export type EventItem = {
  start?: Date;
  end?: Date;
  title: string;
  route: CalendarRoute & {
    state: string;
  };
};

interface CalendarEventProps {
  event: EventItem;
  date: Date;
}

const CalendarEvent = ({ event, date }: CalendarEventProps) => {
  const calendarMonth = moment(event.route.calendarRouteDate.split("T")[0]).month();
  const isRoutePriorToToday = event.route.calendarRouteDate < moment().format("YYYY-MM-DD");
  const isRouteStarted = event.route.state === "INICIADA";
  const isRoutePending = event.route.state === "PENDIENTE";
  const isRouteStartedOrPending =
    (isRouteStarted || isRoutePending) && event.route.periodicity !== "1";
  const isRouteDisabled = isRoutePriorToToday && isRouteStartedOrPending;
  const isRouteFromAnotherMonth = !(calendarMonth === moment(date).month());

  return (
    <FlexContainer
      align="center"
      justify="left"
      style={{
        margin: "8px 4px",
        borderLeft: "2px solid #002F6C",
        maxHeight: "25px",
        opacity: isRouteFromAnotherMonth ? "0.50" : isRouteDisabled ? "0.25" : "1",
      }}
    >
      {
        <ReScheduleRoute
          item={event.route}
          disabled={isRouteDisabled}
          customPopoverTitle={
            isRoutePriorToToday && isRouteStartedOrPending ? "Ruta Realizada" : undefined
          }
        />
      }
      <p style={{ margin: "0", color: "black" }}>{event.route.routeName}</p>
    </FlexContainer>
  );
};

export default CalendarEvent;
