import React from 'react';
import SimpleAutocompleteSelect from '../../../components/autocomplete/SimpleAutocompleteSelect';
import { useLangLabels } from '../../../shared/lang/services/useLangLabels';
import { criticalityColors } from '../models/criticalityInterface';

interface SelectColorProps{
    onChange:(value:any)=>void, 
    defaultValue?:string,
    error?:string,
    complete?:boolean,
    label:string,
    disabled?:boolean

}

const SelectColor:React.FC<SelectColorProps> = ({ onChange, defaultValue, error, disabled}) => {

    const showTitle='label'
    const icon ='state'
    const {lang} = useLangLabels()
    const label = lang.criticalities.form.color
    return (
        <SimpleAutocompleteSelect
        {...{
            icon,
            onChange,
            defaultValue,
            error,
            label,
            showTitle,
            items:criticalityColors,
            disabled
        }}
        />
    );
}

export default SelectColor;






