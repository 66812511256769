import { ReactNode, useMemo } from "react";

import FlexContainer from "../../../../components/containers/FlexContainer";

import { ColumnDef } from "@tanstack/react-table";

type RenderOptionFunction<TData> = (original: TData) => ReactNode;

export const useRenderOptionColumn = <TData,>(
  headerName?: string,
  renderOption?: RenderOptionFunction<TData>,
  size?: number
): ColumnDef<TData, unknown>[] => {
  return useMemo(() => {
    if (renderOption) {
      return [
        {
          id: "renderOptions",
          header: headerName || "",
          cell: ({ row }) => (
            <FlexContainer align={"center"} gap={"0px"}>
              {renderOption(row.original)}
            </FlexContainer>
          ),
          size: size ?? 100,
        },
      ];
    } else {
      return [];
    }
  }, [headerName, renderOption]);
};
