import axios,{ AxiosRequestConfig }  from "axios";
import { URL } from "../config/appConfig";
import { axiosMutateAdapter, axiosQueryAdapter } from "./adapters/http-request-adapter";


export const httpRequest = axios.create({
    baseURL: URL,
    timeout: 600000,
    proxy:false,
});


export const apiCall = <T = any>(config:AxiosRequestConfig) => axiosQueryAdapter<T>(()=>httpRequest(config)) 

export const mutateApiCall = (config:AxiosRequestConfig) => axiosMutateAdapter(()=>httpRequest(config)) 
