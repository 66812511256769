import React from "react";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { CrudFormType } from "../../../../shared/form/types/FormTypes";
import {
  CUEntityFormPropsType,
  UIFormInterface,
} from "../../../../shared/form/types/CRUDTypes";
import DefaultFormLayout from "../../../../components/form/DefaultFormLayout/DefaultFormLayout";
import FieldSelector from "../../../../shared/form/components/FieldSelector";
import {
  CreateCollector,
  UpdateCollector,
} from "../../services/collectorsServices";
import { useCreateUpdate } from "../../../../shared/form/hooks/useCreateUpdate";
import { CollectorInterface } from "../../models/CollectorInterfaces";
import CollectorStatus from "../CollectorStatus";
import { useCollectorsForm } from "../hooks/useCollectorForm";

export type CreateUpdateCollectorsFormProps =
  CUEntityFormPropsType<CollectorInterface> & {
    isDataUpdated?: boolean;
  };

const CreateUpdateCollectorsForm: React.FC<CreateUpdateCollectorsFormProps> = ({
  actionType,
  item,
}) => {
  const {
    action: submitAction,
    resetStatus,
    status,
    error,
    message,
  } = useCreateUpdate(
    {
      create: CreateCollector,
      update: UpdateCollector,
    },
    actionType,
    ["CollectorsByTagFP"]
  );

  const { lang } = useLangLabels();

  const { uiModel, handleSubmit, resetForm } = useCollectorsForm(item);

  const ui = (() => {
    const formName = lang.collectors.singular;

    switch (actionType) {
      case CrudFormType.create:
        const create: UIFormInterface = {
          icon: "add",
          title: `${lang.actionTypes.create} ${formName}`,
          variant: "button",
          mode: "modal",
        };

        return create;

      case CrudFormType.update:
        const update: UIFormInterface = {
          icon: "edit",
          title: `${lang.actionTypes.update} ${formName}`,
          variant: "iconButton",
          mode: "content",
        };

        return update;
    }
  })();

  //Methods
  const submitData = (data: any) => {
    submitAction(data);
  };

  const resetData = () => {
    resetStatus();
    resetForm();
  };

  return (
    <DefaultFormLayout
      componentMode={ui.mode}
      buttonIcon={ui.icon}
      buttonVariant={ui.variant}
      buttonText={ui.title}
      popoverTitle={ui.title}
      buttonAction={handleSubmit(submitData)}
      {...{ queryStatus: status, error, alertStatusMessage: message }}
      onClose={resetData}
      resetForm={resetData}
    >
      {item ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <span>{lang.collectors.collectorStatus}:</span>
          <CollectorStatus collector={item} />
        </div>
      ) : null}
      {uiModel.map((props, index) => (
        <FieldSelector key={index} fieldProps={props} />
      ))}
    </DefaultFormLayout>
  );
};

export default React.memo(CreateUpdateCollectorsForm);
