import axios, { AxiosRequestConfig } from "axios";
import { PBI_URL } from "../../shared/config/appConfig";


const pbiHttp = axios.create({
    baseURL:PBI_URL,
    timeout: 600000
})


export const pbiCall = (config:AxiosRequestConfig) => pbiHttp(config)
