import { Alert } from "@material-ui/lab";
import { Snackbar } from "@mui/material";
import { createContext, useContext, useState } from "react";
import { ClipboardContext, ClipboardState } from "../types/ClipboardTypes";
import { Portal } from "../../../../components/containers";
import { useLangLabels } from "../../../lang/services/useLangLabels";

const initialCLipboard: ClipboardContext = {
  status: false,
  clipboard: null,
  copy: () => {},
  resetClipboard: () => {},
};

const clipboardContext = createContext<ClipboardContext>(initialCLipboard);

export const useClipboardContext = () => {
  return useContext(clipboardContext);
};

export const ClipboardProvider: React.FC<{}> = ({ children }) => {
  const { lang } = useLangLabels();

  const [clipboard, setClipboard] = useState<ClipboardState>(initialCLipboard);

  const copy = (clipboard: any) => {
    setClipboard({
      ...clipboard,
      status: true,
      clipboard,
    });
  };

  const resetClipboard = () => {
    setClipboard({
      ...clipboard,
      status: false,
    });
  };

  return (
    <clipboardContext.Provider value={{ ...clipboard, copy, resetClipboard }}>
      {children}
      <Portal>
        <Snackbar
          style={{ zIndex: 5000 }}
          open={clipboard.status}
          autoHideDuration={500}
          onClose={resetClipboard}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success">{lang.messages.copiedToClipboard}</Alert>
        </Snackbar>
      </Portal>
    </clipboardContext.Provider>
  );
};
