import React from "react";
import CreateUpdateBrandForm from "../../instrument-brands/components/CreateUpdateBrandForm";
import { CrudFormType } from "../../../../shared/form/types/FormTypes";
import { CarrousellActionInterface } from "../../../../shared/react-table/VirtualGrid/types/VirtualGridTypes";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { useGetAllBrands } from "../../instrument-brands/services/brandService";
import { useBrandGridModel } from "../../instrument-brands/lang/useBrandGridModel";
import VirtualGrid from "../../../../shared/react-table/VirtualGrid/VirtualGrid";
import { DeleteBrandForm } from "../../instrument-brands/components/DeleteBrandForm";

export const ManageBrandPage = ({ fixedHeight }: { fixedHeight?: number }) => {
  const { lang } = useLangLabels();
  const { data: brands, isFetching } = useGetAllBrands();

  const carrousellActions: CarrousellActionInterface = [
    {
      icon: "edit",
      popoverTitle: lang.crudActions.update,
      component: (item) => (
        <CreateUpdateBrandForm
          item={item}
          actionType={CrudFormType.update}
          isDataUpdated={!isFetching}
        />
      ),
    },
  ];

  return (
    <VirtualGrid
      gridModel={useBrandGridModel()}
      fixedHeight={fixedHeight}
      title={lang.instruments.brands.title.plural}
      entityName={lang.instruments.brands.title.plural}
      items={brands}
      carrousellComponents={carrousellActions}
      headerActions={() => <CreateUpdateBrandForm actionType={CrudFormType.create} />}
      itemActions={(item) => <DeleteBrandForm item={item} />}
    />
  );
};
