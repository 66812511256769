import useBasicLocalStorage from "../../localStorage/useBasicLocalStorage";
import { LangInterface, LangType } from "./interfaces";

export const useSelectedLanguage = (): [(lang: LangType) => void, LangInterface] => {
  const [selectedLanguage, setSelectedLanguage] = useBasicLocalStorage<{ lang: LangType }>({
    key: "lang",
    initialValue: {
      lang: undefined,
    },
  });

  const setLang = (lang: LangType) => {
    setSelectedLanguage({
      lang,
    });

    window.location.reload();
  };

  return [setLang, selectedLanguage!];
};
