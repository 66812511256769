import React from "react";
import { SVGSyncPanelIconType } from "../InstrumentSyncBoxIcon";
import { imageHrefs } from "./imageHrefs";
import { SVGHoverGroup } from "./SVGHoverGroup";
import { QueryStatus } from "react-query";

interface SVGButtonProps {
  type: SVGSyncPanelIconType;
  handleUpdateSyncClick: () => void;
  handleHoverIn: (e: any, type: SVGSyncPanelIconType) => void;
  handleHoverOut: () => void;
  handleSyncClick: () => void;
  actionStatus: QueryStatus;
  actionUpdateStatus: QueryStatus;
}

export const SVGButton = ({
  type,
  handleUpdateSyncClick,
  actionStatus,
  actionUpdateStatus,
  handleHoverIn,
  handleHoverOut,
  handleSyncClick,
}: SVGButtonProps) => {
  return (
    <>
      {type === "update" && (
        <g>
          <image
            className="cls-2"
            width="50"
            height="50"
            transform="translate(83.52 214)"
            href={imageHrefs.updateType}
          />
          <SVGHoverGroup
            type={type}
            handleClickAction={handleUpdateSyncClick}
            handleHoverIn={handleHoverIn}
            handleHoverOut={handleHoverOut}
            actionStatus={actionUpdateStatus}
          >
            <circle className="cls-3" cx="108.68" cy="239.7" r="14.8" />

            {actionUpdateStatus === "loading" ? (
              <path
                transform="translate(97,227.8)"
                className="cls-5"
                d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
              />
            ) : (
              <path
                transform="translate(97,227.8)"
                className="cls-5"
                d="M11,8v5l4.25,2.52l0.77-1.28l-3.52-2.09V8H11z M21,10V3l-2.64,2.64C16.74,4.01,14.49,3,12,3c-4.97,0-9,4.03-9,9 s4.03,9,9,9s9-4.03,9-9h-2c0,3.86-3.14,7-7,7s-7-3.14-7-7s3.14-7,7-7c1.93,0,3.68,0.79,4.95,2.05L14,10H21z"
              />
            )}
            
            {actionUpdateStatus === "loading" && (
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 108.68 239.7"
                to="360 108.68 239.7"
                dur="2s"
                additive="sum"
                repeatCount="indefinite"
              />
            )}
          </SVGHoverGroup>
        </g>
      )}

      {type === "info2" && (
        <g>
          <image
            className="cls-2"
            width="50"
            height="50"
            transform="translate(312, 135)"
            href={imageHrefs.infoType}
          />

          <SVGHoverGroup
            type={type}
            handleHoverIn={handleHoverIn}
            handleHoverOut={handleHoverOut}
            actionStatus={actionUpdateStatus}
          >
            <circle className="cls-3" cx="337" cy="160.28" r="14.8" />

            <path
              className="cls-5"
              transform="translate(-32, 10)"
              d="M368.64,145.83h1.77v1.78h-1.77Zm0,3.56h1.77v5.33h-1.77Zm.88-8a8.89,8.89,0,1,0,8.89,8.89A8.89,8.89,0,0,0,369.52,141.39Zm0,16a7.11,7.11,0,1,1,7.11-7.1A7.11,7.11,0,0,1,369.52,157.38Z"
            />
          </SVGHoverGroup>
        </g>
      )}

      {type === "syncAction" && (
        <g>
          <image
            className="cls-2"
            width="61"
            height="61"
            transform="translate(126.52)"
            href={imageHrefs.syncActionType}
          />

          <SVGHoverGroup
            type={type}
            handleClickAction={() => {
              actionStatus !== "loading" && handleSyncClick();
            }}
            handleHoverIn={handleHoverIn}
            handleHoverOut={handleHoverOut}
            actionStatus={actionUpdateStatus}
          >
            <circle className="cls-3" cx="157.03" cy="30.3" r="14.8" />

            <path
              className="cls-5"
              d="M157,21V17.57l-4.5,4.5,4.5,4.5V23.2A6.76,6.76,0,0,1,163.78,30,6.54,6.54,0,0,1,163,33.1l1.64,1.64A8.88,8.88,0,0,0,166,30,9,9,0,0,0,157,21Zm0,15.75A6.75,6.75,0,0,1,150.28,30a6.54,6.54,0,0,1,.79-3.15l-1.65-1.64A8.87,8.87,0,0,0,148,30a9,9,0,0,0,9,9v3.38l4.5-4.5-4.5-4.5Z"
            />

            {actionStatus === "loading" && (
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="360 157.03 30.3"
                to="0 157.03 30.3"
                dur="1s"
                additive="sum"
                repeatCount="indefinite"
              />
            )}
          </SVGHoverGroup>
        </g>
      )}
    </>
  );
};
