import { LubricationPointInterface } from "../../lubricationPoints/model/lubricationPointsInterface";
import SidebarSimpleMenuButton from "../../../layout/components/SidebarSimpleMenuButton";
import { useModal } from "../../../components/containers/modal/Modal/hooks/useModal";
import TGDModal from "../../../components/containers/modal/TGDModal/TGDModal";
import VirtualGrid from "../../../shared/react-table/VirtualGrid/VirtualGrid";
import { useLubricationPointsGridModel } from "../../lubricationPoints/lang/useLubricationPointsGridModel";
import { useEquipmentswithCompleteInfo } from "../../lubricationPoints/services/lubricationPointsAdapters";
import LubricationPointSurvey from "./LubricationPointSurvey/LubricationPointSurvey";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

export const OutOfRouteLubricationPointSurvey = () => {
  const { lang } = useLangLabels();
  const modalProps = useModal(false);
  const LubricationPoints = useEquipmentswithCompleteInfo(
    (data) => data.filter((item) => item.state === true) as any
  );
  const gridModel = useLubricationPointsGridModel();

  return (
    <>
      <SidebarSimpleMenuButton
        onClick={modalProps.openModal}
        ownerService={"TGD"}
        allowedUserTypes={["O"]}
        title={lang.surveys.outOfRoute}
        icon="outOfRouteWhite"
      />

      <TGDModal open={modalProps.isOpen} handleClose={modalProps.closeModal}>
        <VirtualGrid
          {...{
            height: 13,
            documentExport: false,
            documentSendEmail: false,
            title: lang.lubricationPoints.allActivePoints,
            items: LubricationPoints,
            gridModel,
            bulkActions: (items: LubricationPointInterface[]) => (
              <LubricationPointSurvey {...{ outOfRoute: true, items }} />
            ),
          }}
        />
      </TGDModal>
    </>
  );
};