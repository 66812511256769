import { useEffect, useMemo, useState } from "react"
import { QueryStatus } from "react-query"
import { apiCall, mutateApiCall } from "../../../shared/axios/axios-config"
import { useDate } from "../../../shared/date/useDate"
import { PrivateQueryData, PrivateQueryDataTagFPInterface } from "../../../shared/globals/utilsGlobalTypes"
import { useStatusProcessor } from "../../../shared/queries/StatusProcessor"
import { useQuery, useMutation, queryClient } from "../../../shared/react-query/react-query-conf"
import { useGetAllAuxByPlantData } from "../../allAux/services/allAuxServices"
import { useDailyLubricationPointsWithInfo, useEquipmentsBySupply } from "../../lubricationPoints/services/lubricationPointsAdapters"
import { EquipmentInterface, LubricationPointInterface } from "../../lubricationPoints/model/lubricationPointsInterface"
import { useSessionContext } from "../../session/store/sessionContext"
import { useToken } from "../../session/store/sessionStore"
import { AssignCancelSupplyInterface, SupplyInterface, SupplyType } from "../model/SupplyInterface"
import { PersonInterface } from "../../person/models/PersonInterface"


export const SupplyCRUDDependencies=[
    'PlantAux',
    'LubricationPointByTagTGD',
    'PlantRoutes',
    'EquipmentsByTagFP',
    'DaoEquipmentsByTagFP',
    'DaoEquipmentsElementsByTagFP',
]

//---------------------------
//Repo
//---------------------------


export const CreateSupply = ({data,token}:PrivateQueryData) => {
    return mutateApiCall({
        method:'POST',
        url:'/CreateSupply',
        headers:{
            Authorization: `Bearer ${token}`
        },
        data
    })
}

export const UpdateSupply = ({data,token}:PrivateQueryData) => {
    return mutateApiCall({
        method:'POST',
        url:'/UpdateSupply',
        headers:{
            Authorization: `Bearer ${token}`
        },
        data
    })
}

export const DeleteSupply = ({data,token}:PrivateQueryData) => {
    return mutateApiCall({
        method:'POST',
        url:'/DeleteSupply',
        headers:{
            Authorization: `Bearer ${token}`
        },
        data
    })
}

export const AssignCancelSupplies = ({data,token}:PrivateQueryData) => {
    return mutateApiCall({
        method:'POST',
        url:'/AssignCancelSupplies',
        headers:{
            Authorization: `Bearer ${token}`
        },
        data
    })
}

export const getDailySupplies = ({data,tagFP,token}:PrivateQueryDataTagFPInterface) => {
    return apiCall({
        method:'POST',
        url:'/SuppliesAndLubricationPoints',
        headers:{
            Authorization: `Bearer ${token}`,
            tagFP
        },
        data
    })
}

export const AssignCanceInfoEquipmentSupplies = ({token,data}:PrivateQueryData) => {
    /**
    * 
    * */
    return mutateApiCall({
        method: "post",
        url:`/AssignCancelInfoEquipmentSupplies`,
        headers:{
            Authorization: `Bearer ${token}`,
        },
        data
    })
}


export const useAllSupplies = () => {
    return  useGetAllAuxByPlantData('supplies')
}

export const useSuppliesByType = (supplyType?:SupplyType) => {
    const query = useAllSupplies() 
    const allSupplies = query.data
    return  {
        ...query,
        data: supplyType ? allSupplies?.filter((supply)=> supply.type === supplyType) : allSupplies
    }
}

export const useDailySupplies = (lubricationPoints?:LubricationPointInterface[],type?:SupplyType) =>{
    const {token,tagFP} = useSessionContext()

    const dailyOperatorLubricationPoints = lubricationPoints?.map(i=>({
        ...i,
        supplies:JSON.stringify(i.supplies),
        info:undefined
    }))

    return useQuery<SupplyInterface[] | []>({
        queryKey:'DailySupplies',
        queryFn:()=>getDailySupplies({data:dailyOperatorLubricationPoints,tagFP,token}),
        enabled:!!tagFP && !!dailyOperatorLubricationPoints && !!token,
        select:(data)=>{
            return type 
            ? data.filter((supply:any)=> (supply.supply.type === type))
            : data
        },
        staleTime:1
    })
}

//----------------------------
//MUTATIONS
//---------------------------------------------

export const useCreateSupply = (queryConfigs?:{
    onSuccess:()=>void
}) => {
    const token = useToken()

    const query = useMutation(CreateSupply,{
        onSuccess:()=>SupplyCRUDDependencies.forEach((dependency)=>{
            queryClient.invalidateQueries(dependency)
            queryConfigs && queryConfigs.onSuccess()
        })
        
    })
    const createSupply = (data:SupplyInterface) => {
        return query.mutate({
            data,
            token
        })
    }

    const status= useStatusProcessor(query)
    
    return {createSupply,...query,...status}
}

export const useUpdateSupply = () =>{
    return useMutation(UpdateSupply,{
        onSuccess:()=>SupplyCRUDDependencies.forEach((dependency)=>{
            queryClient.invalidateQueries(dependency)
        })
    })
}


export const useDeleteSupply = () => {
    return useMutation(DeleteSupply,{
        onSuccess:()=>SupplyCRUDDependencies.forEach((dependency)=>{
            queryClient.invalidateQueries(dependency)
        })
    })
}

export const useAssignCancelSupplies = () => {
    const token = useToken()
    const query = useMutation(AssignCancelSupplies,{
        onSuccess:()=>SupplyCRUDDependencies.forEach((dependency)=>{
            queryClient.invalidateQueries(dependency)
        })
    })

    const status = useStatusProcessor(query)

    const assignMultipleSupplies = (multipleObjects:AssignCancelSupplyInterface[]) => {
        query.mutate({
            data:multipleObjects,
            token
        })
    }

    const cancelMultipleSupplies = (multipleObjects:AssignCancelSupplyInterface[]) => {
        query.mutate({
            data:multipleObjects,
            token
        })
    }

    const assign = (equipment:LubricationPointInterface,supply:string) => {
        query.mutate({
            data:[{
                supply:supply,
                equipment:equipment.tagTGD,
                state:'A',
                tagFP:equipment.tagFP
            }],
            token
        })
    }

    const cancel = (equipment:LubricationPointInterface,supply:string) => {
        query.mutate({
            data:[{
                supply:supply,
                equipment:equipment.tagTGD,
                state:'C',
                tagFP:equipment.tagFP
            }],
            token
        })
    }
    
    return{
        assign,
        cancel,
        assignMultipleSupplies,
        cancelMultipleSupplies,
        ...query
    }
}


interface DeleteSupplyErrors {
    equipments?:SupplyInterface[] | undefined 
}

export const useDeleteSupplyVerification = () => {
    
    const token = useToken()
    const query = useDeleteSupply()
    const {mutate:deleteItem,data} = query
    const [status, setStatus] = useState<QueryStatus>('idle')
    const [validationElement,setValidationElement] = useState<SupplyInterface | {} | undefined >()

    const {data:equipments,status:equipmentStatus,remove:removeEquipmentsBySupply} = useEquipmentsBySupply(validationElement)

    const [errors, setErrors] = useState<DeleteSupplyErrors>({})

    const handleRemoveValidations = ()=>{
        removeEquipmentsBySupply()
    }

    const validate = (item:SupplyInterface) => {
        setStatus('loading')
        setErrors({})
        handleRemoveValidations()
        setValidationElement(item)
    }

    const handleDelete= () =>{
        if((equipmentStatus === 'success')){
            if((equipments.length > 0)){
                setErrors({equipments})
                setStatus('error')
                setValidationElement(undefined)
            }
            else{
                deleteItem({
                    data:validationElement,
                    token
                },{
                    onSuccess:()=>{
                        setStatus('success')
                        setValidationElement(undefined)
                    },
                    onError:(err)=>console.log(err)
                })
            }
        }
        if(equipmentStatus === 'loading'){
            setStatus('loading')
        }
    }


    useEffect(() => {
        validationElement && handleDelete()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[equipmentStatus])


    return  {
        errors,
        status,
        validate,
        query
    }

}

export const useAssignMultipleSuppliesToMultipleEquipments = () => {

    const {assignMultipleSupplies,status,data,reset} = useAssignCancelSupplies()
    const [assignationItems,setAssignationItems] = useState<AssignCancelSupplyInterface[]>([])
  
    const handleAssigCancel = (equipments:EquipmentInterface[],Supplies:SupplyInterface[],state:'A'|'C') => {
        setAssignationItems([])
        equipments.forEach(e => {
            Supplies.forEach(supply => {
                setAssignationItems((assignationItems) =>[
                        ...assignationItems,
                        {
                            supply:supply.supply,
                            equipment:e.tagTGD,
                            state:state,
                            tagFP:e.tagFP
                        }
                ])
            })
        })
    }

    useEffect(() => {
        if(assignationItems.length > 0){
            assignMultipleSupplies(assignationItems)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[assignationItems])
    
    return{
        handleAssigCancel,
        status,
        objectQuery:assignationItems,
        data,
        reset
    }
}

export const useAssignCanceInfoEquipmentSupplies = (lubricationPoint?:LubricationPointInterface) => {

    //types
    const types = {
        assign:'A',
        cancel:'C'
    }

    
    //mutate
    const queryAssign = useMutation(AssignCanceInfoEquipmentSupplies,{
        onSuccess:()=>{
            queryClient.invalidateQueries('EquipmentsInfoByCriticality')
            queryClient.invalidateQueries('DailyOperatorLubricationPoints')
            queryClient.invalidateQueries('AllLubricationPointsAndInfoByTagFP')
            queryClient.invalidateQueries('LubricationPointsWithInfo')
        }   
    })

    const queryCancel = useMutation(AssignCanceInfoEquipmentSupplies,{
        onSuccess:()=>{
            queryClient.invalidateQueries('EquipmentsInfoByCriticality')
            queryClient.invalidateQueries('DailyOperatorLubricationPoints')
            queryClient.invalidateQueries('AllLubricationPointsAndInfoByTagFP')
            queryClient.invalidateQueries('LubricationPointsWithInfo')
            
        }   
    })
    
    //needed data
    const token = useToken()
    const _assignStatus = useStatusProcessor(queryAssign)
    const _cancelStatus = useStatusProcessor(queryCancel)

    const status = useMemo(()=>({
        assignStatus:_assignStatus,
        cancelStatus:_cancelStatus
    }),[_assignStatus, _cancelStatus])

    const tagTGD = lubricationPoint?.tagTGD
    const tagFP = lubricationPoint?.tagFP

    //Methods
    const assignAll = (supplies:string[]) => {
        supplies.forEach(item => {
            console.log({
                token,
                data:item
            })
        })
    }

    const assignSupply = (supply:string) => {
        if(tagFP && tagTGD){
            queryAssign.mutate({
                token,
                data:{
                    tagTGD,
                    tagFP,
                    supply,
                    state:types.assign
                }
            })
        }else{
            console.log('tagFP or TagTGD: undefined')
        }
    }

    const deleteSupply = (supply:string) => {
         if(tagFP && tagTGD){
            queryCancel.mutate({
                token,
                data:{
                    tagTGD,
                    tagFP,
                    supply,
                    state:types.cancel
                }
            })
        }else{
            console.log('tagFP or TagTGD: undefined')
        }
    }

    //status cofigurations


    //Public methods
    return {
        assignSupply,
        deleteSupply,
        assignAll,
        queryAssign,
        queryCancel,
        ...status

    }
}