import React, { useEffect } from "react";
import CustomButton from "../../../components/buttons/CustomButton";
import { useSnackbar } from "../../../components/snackbar/hooks/useSnackbar";
import { useClickPosition } from "../../../shared/globals/hooks/useClickPosition";
import { StockInterface } from "../models/StockInterface";
import { useUpdateStockHiddenState } from "../services/service.stock";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

interface Props {
  item: StockInterface;
}

const HiddenStockForm: React.FC<Props> = ({ item }) => {
  const { lang } = useLangLabels();
  const { archiveStock, status, message, error } = useUpdateStockHiddenState();
  const { position, getClickPosition } = useClickPosition();
  const { setData } = useSnackbar();

  const submit = () => {
    archiveStock({
      ...item,
      hidden: !item?.hidden,
    });
  };

  useEffect(() => {
    if (status === "error") {
      setData({
        modal: true,
        errors: message,
        severity: "warning",
        position: position,
      });
    }

    if (status) {
      setData({
        modal: true,
        errors: message,
        severity: status === "success" ? status : "warning",
        position: position,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, error]);

  return (
    <CustomButton
      status={status}
      action={(e) => getClickPosition(e, submit)}
      icon={item?.hidden ? "power" : "delete"}
      variant="iconButton"
      popoverTitle={item?.hidden ? "recuperar" : lang.lubricationSection.archive}
    />
  );
};

export default HiddenStockForm;
