import { Badge, CircularProgress, Paper } from "@material-ui/core";
import { FlagOutlined } from "@material-ui/icons";
import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import FlexContainer from "../../../components/containers/FlexContainer";
import PointerContainer from "../../../components/containers/PointerContainer";
import TgdPopover from "../../../components/popovers/TgdPopover";
import CheckList from "../../checklist/CheckList";
import {
  colectorHomeSupplies,
  useCheckListController,
} from "../../checklist/controllers/useCheckListController";
import { useSessionContext } from "../../session/store/sessionContext";
import { useLubricatorStore } from "../../users/lubricator/lubricatorStore";
import ColectorRoutesPage from "../pages/ColectorRoutesPage/ColectorRoutesPage";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

const LubricatorPanel = () => {
  const { selectOperator } = useSessionContext();
  const { data, status } = useLubricatorStore();
  const checkListTypes = colectorHomeSupplies();
  const { lang } = useLangLabels();

  const { notification, routes, icons } = useCheckListController();
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.operator) {
      selectOperator(data.operator);
    }
  }, [data?.operator]);

  return (
    <>
      {status === "loading" && (
        <FlexContainer
          justify={"center"}
          align={"center"}
          margin={"16px 0"}
          height="72px"
          width="100%"
        >
          <CircularProgress />
        </FlexContainer>
      )}

      {status === "success" && (
        <FlexContainer
          gap="24px"
          flexWrap="wrap"
          style={{ minHeight: "72px" }}
          margin={"16px 0"}
          align="center"
        >
          {checkListTypes.map((type, index) => (
            <TgdPopover key={index} mode="hover" title={lang.supplies[type].title.plural.toUpperCase()}>
              <PointerContainer key={type}>
                <Badge
                  style={{ display: "block" }}
                  color="primary"
                  badgeContent={notification(type) || undefined}
                >
                  <Paper elevation={3}>
                    <FlexContainer
                      onClick={() => navigate(`${routes[type]}`)}
                      height="40px"
                      width="80px"
                      align={"center"}
                      justify="center"
                      padding="16px"
                    >
                      {icons[type]}
                    </FlexContainer>
                  </Paper>
                </Badge>
              </PointerContainer>
            </TgdPopover>
          ))}

          <TgdPopover mode="hover" title={lang.routes.plural.toUpperCase()}>
            <PointerContainer>
              <Badge
                style={{ display: "block" }}
                color="primary"
                badgeContent={data?.dailyRoutes.length}
              >
                <Paper elevation={3}>
                  <FlexContainer
                    onClick={() => navigate(``)}
                    height="40px"
                    width="80px"
                    align="center"
                    justify="center"
                    padding="16px"
                  >
                    <FlagOutlined />
                  </FlexContainer>
                </Paper>
              </Badge>
            </PointerContainer>
          </TgdPopover>
        </FlexContainer>
      )}

      <Routes>
        {checkListTypes?.map((type) => (
          <Route
            key={type}
            path={`${routes[type]}`}
            element={
              <div key={type}>
                <CheckList checkListType={type} />
              </div>
            }
          />
        ))}
        <Route path={`*`} element={<ColectorRoutesPage />} />
      </Routes>
    </>
  );
};

export default LubricatorPanel;
