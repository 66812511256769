import { GridModelInterface } from "../../../shared/gridModel/gridModelEntity"
import { useLangLabels } from "../../../shared/lang/services/useLangLabels"


export const usePermissionsGridModel = () => {

    const {lang} = useLangLabels()
    const gridModelPermission:GridModelInterface = {
        area:{label:lang.permissions.area}, 
        code:{label:lang.permissions.code}, 
        date:{label:lang.globalentityValues.date}, 
        description:{label:lang.permissions.description}, 
        id:{label:lang.globalentityValues.id}, 
        permissionName:{label:lang.permissions.permissionName}, 
        sector:{label:lang.permissions.sector}, 
        tagFP:{label:lang.globalentityValues.tagFP},
    } 

    return gridModelPermission
}