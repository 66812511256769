import { Chip, InputAdornment, TextField } from "@material-ui/core";
import { Lock, MailOutline } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import CustomButton from "../../../components/buttons/CustomButton";
import FlexContainer from "../../../components/containers/FlexContainer";
import { ModalBackdrop } from "../../../components/containers/modal/Modal/components/ModalBackdrop";
import { ModalDialog } from "../../../components/containers/modal/Modal/components/ModalDialog";
import Portal from "../../../components/containers/Portal";
import Form from "../../../components/form/Form";
import { Logo } from "../../../pages/login/components/LoginFormContainer";
import OnlineOfflineMode from "../../../shared/config/offline Mode/OnlineOfflineMode";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { USER_ROUTES } from "../../../shared/routes/routes";
import { Auth } from "../model/sessionInterface";
import { useSessionContext } from "../store/sessionContext";
import NavBarLogo from "./../../../assets/img/navBarLogo.png";
import { CustomIcon } from "../../../components/buttons/CustomIcon";

const LoginModal: React.FC<{}> = ({ }) => {
  const { lang } = useLangLabels();
  const { status, sessionStale, message, login, enableSession, logout } =
    useSessionContext();

  const actualTextDate = `${new Date().getFullYear() - 1990} ${lang.login.yearText} - ${new Date().getFullYear()}`;

  const [form, setData] = useState<Auth>({
    email: "",
    password: "",
  });

  const handleSubmit = async () => {
    login(form, enableSession);
  };

  const handleInputChange = (e: any) => {
    setData({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Portal>
      <ModalBackdrop isOpen={sessionStale} zIndex={5000}>
        <ModalDialog
          isOpen={sessionStale}
          onClick={(e: any) => e.stopPropagation()}
          height={"auto"}
          width={"auto"}
          style={{ borderRadius: "8px" }}
        >
          <FlexContainer justify={"center"} height={"80px"} padding="12px">
            <Logo src={NavBarLogo} />
          </FlexContainer>

          <Form onSubmit={handleSubmit}>
            <TextField
              color="secondary"
              variant="outlined"
              placeholder={lang.login.form.label.user}
              required
              autoComplete="off"
              type="email"
              name="email"
              value={form.email}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutline color="secondary" />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              color="secondary"
              variant="outlined"
              autoComplete="off"
              placeholder={lang.login.form.label.password}
              required
              type="password"
              name="password"
              value={form.password}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock color="secondary" />
                  </InputAdornment>
                ),
              }}
            />
            <FlexContainer>
              {status !== "loading" && (
                <CustomButton
                  styleProps={{ height: "40px", width: "40px" }}
                  action={logout}
                  color="primary"
                  icon="logout"
                  status={status}
                >
                  {" "}
                </CustomButton>
              )}
              <CustomButton
                styleProps={{ height: "40px", width: "100%" }}
                type="submit"
                color="secondary"
                status={status}
              >
                {" "}
                login{" "}
              </CustomButton>
            </FlexContainer>
          </Form>

          <FlexContainer
            justify={"space-between"}
            padding="0px 16px"
            flexDirection="column"
            align={"center"}
          >
            {status === "error" ? (
              <>
                <FlexContainer gap={"24px"}>
                  <Alert style={{ width: "100%" }} severity="error">
                    {message}
                  </Alert>
                </FlexContainer>
              </>
            ) : null}

            <OnlineOfflineMode warning={false}>
              <Chip
                onDelete={() => true}
                deleteIcon={<CustomIcon icon="next" />}
                size="small"
                label={lang.login.navigation.name}
                clickable
                color="secondary"
                component={Link}
                to={`${USER_ROUTES.demoRequest}`}
              />
            </OnlineOfflineMode>

            <p>TGD S.A © - {actualTextDate}</p>
          </FlexContainer>
        </ModalDialog>
      </ModalBackdrop>
    </Portal>
  );
};

export default LoginModal;
