import { InputComponentTypes } from "../../../shared/form/types/FormTypes";
import { useForm } from "../../../shared/form/useForm";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { AddedReasonInterface } from "../models/AdedReasonTypes";

export const useAddedReasonForm = (
  tagFP: string,
  item?: AddedReasonInterface
) => {
  const { lang } = useLangLabels();
  
  return useForm<AddedReasonInterface>({
    id: {
      label: lang.globalentityValues.id,
      placeholder: lang.globalentityValues.id,
      value: item?.id || 0,
      component: InputComponentTypes.none,
    },
    reason: {
      label: lang.addedReasons.reason,
      placeholder: lang.addedReasons.reason,
      component: InputComponentTypes.text,
      value: item?.reason || "",
      validations: {
        required: { value: true, message: lang.validations.messages.required },
      },
    },
    tagFP: {
      label: lang.globalentityValues.tagFP,
      placeholder: lang.globalentityValues.tagFP,
      value: tagFP,
      component: InputComponentTypes.none,
    },
  });
};
