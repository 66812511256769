import { Typography } from "@material-ui/core";
import { FlexContainer } from "../../../../components/containers";
import { ColumnFilterPanel } from "../../../../components/filters";
import { VirtualGridHeaderProps } from "../types/VirtualGridTypes";
import FieldFilter from "../../../../components/filters/FieldFilter/FieldFilter";

const VirtualGridHeader: React.FC<VirtualGridHeaderProps> = ({
  title,
  showFilters,
  handleToggleColumnVisibility,
  columnLabels,
  hiddenColumns,
  gridModel,
  handleToggleAllColumns,
  period,
  setPeriod,
  dynamicFilters,
  headerActions,
}) => {
  return (
    <FlexContainer align="center" justify="space-between" width={"100%"} flexWrap="wrap">
      <Typography variant={"h6"}>{title}</Typography>
      <FlexContainer padding="0px 0px 8px 0px" gap={"8px"}>
        {showFilters && (
          <>
            <ColumnFilterPanel
              onChange={handleToggleColumnVisibility}
              labels={columnLabels}
              hiddenColumns={hiddenColumns}
              gridModel={gridModel}
              handleActivateDesactivateAllColumns={handleToggleAllColumns}
            />
            <FieldFilter {...dynamicFilters} period={period} setPeriod={setPeriod} />
          </>
        )}
        {headerActions && headerActions()}
      </FlexContainer>
    </FlexContainer>
  );
};

export default VirtualGridHeader;
