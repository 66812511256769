import { AxiosError, AxiosPromise} from "axios"
import { MutateResponse } from "../models/MutateResponse"

const AxiosResponseErrorAdapter = (response:AxiosError['response'])=>{
    const status = response?.status
    let message = ''

    switch(status){
        case undefined:
            message = 'no ha habido una respuesta del servidor'
            break
        case 401: 
            message =  'No autorizado'
            break
        default:
            message = 'Un error inesperado'
            break
    }

    return {
        ...response,
        message
    }
}

export const axiosMutateAdapter =  async (httpRequest:()=>AxiosPromise<MutateResponse>) =>{
    return await httpRequest()
    .then((res)=>res.data)
    .catch(err => {
        throw AxiosResponseErrorAdapter(err.response)
    })
}

export const axiosQueryAdapter =  async <T>(httpRequest:()=>AxiosPromise<T>) =>{
    
    return await httpRequest()
    .then((res)=>res.data)
    .catch(err => {
        throw AxiosResponseErrorAdapter(err.response)
    })
    
}