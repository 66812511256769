import { GridModelInterface } from "../../../shared/gridModel/gridModelEntity"
import { useLangLabels } from "../../../shared/lang/services/useLangLabels"
import { EnumSupplyTypes, SupplyType } from "../model/SupplyInterface"


export const useSuppliesGridModel = (type:SupplyType) => {

    const {lang} = useLangLabels()

    const gridModelSupply:GridModelInterface = {
        id: {label:lang.globalentityValues.id},
        date: {label:lang.globalentityValues.date},
        description: {label:lang.supplies[type].form.label.description},
        supply: {label:lang.supplies[type].form.label.supply},
        type: {label:lang.supplies[type].form.label.type},
        tagFP: {label:lang.globalentityValues.tagFP},
    }

    return gridModelSupply
}


export const useToolsGridModel = ()=> {
    return useSuppliesGridModel(EnumSupplyTypes.tool)
}

export const useConsumablesGridModel = () => {
    return useSuppliesGridModel(EnumSupplyTypes.consumable)
}

export const useProtectionsGridModel = () => {
    return useSuppliesGridModel(EnumSupplyTypes.protection)
}