import { useState } from "react";
import { PageState, UseReactPDFStateResult } from "../types/ReactPDFTypes";

const useReactPDFState = <T,>(): UseReactPDFStateResult<T> => {
  const [lastDataPosition, setLastDataPosition] = useState(0);
  const [pageState, setPageState] = useState<PageState<T>[]>([
    {
      pageIndex: 0,
      pageData: [],
    },
  ]);

  const handleSkipPage = () => {
    setPageState((prevState) => {
      return [...prevState, { pageIndex: pageState.length, pageData: [] }];
    });
  };

  const handleNextDataPosition = () => {
    setLastDataPosition((prevState) => prevState + 1);
  };

  const handlePrevDataPosition = () => {
    setLastDataPosition((prevState) => prevState - 1);
  };

  const handlePageStateDataPush = (pageIndex: number, newPageStateData: T) => {
    setPageState((prevState) => {
      const updatedPageState = [...prevState];
      const foundItem = updatedPageState.find(
        (item) => item.pageIndex === pageIndex
      );
      if (foundItem) {
        foundItem.pageData = [...foundItem.pageData, newPageStateData];
      }
      return updatedPageState;
    });
  };

  const handlePageStateDataPop = (pageIndex: number) => {
    setPageState((prevState) => {
      const updatedPageState = [...prevState];
      const foundItem = updatedPageState.find(
        (item) => item.pageIndex === pageIndex
      );
      if (foundItem && foundItem.pageData.length > 0) {
        foundItem.pageData.pop();
      }
      return updatedPageState;
    });
  };

  return {
    pageState,
    handleSkipPage,
    lastDataPosition,
    handleNextDataPosition,
    handlePrevDataPosition,
    handlePageStateDataPush,
    handlePageStateDataPop,
  };
};

export default useReactPDFState;
