import { useCallback, useState } from "react";
import { ModalHookResult } from "../types/ModalTypes";

export const useModal = (init?: boolean): ModalHookResult => {
  const [isOpen, setIsOpen] = useState(init || false);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return { isOpen, openModal, closeModal, toggleModal };
};
