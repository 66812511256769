import React,{useEffect, useState} from 'react';

//components

//material
import { makeStyles,createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Fab, Paper, useTheme } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FactoryAssigment from '../../factories/FactoryAssigment';
import OnlineOfflineMode from '../../../shared/config/offline Mode/OnlineOfflineMode';
import SidebarContainer from '../../../components/containers/SidebarContainer';
import DuoContainer from '../../../components/containers/DuoContainer';
import FlexContainer from '../../../components/containers/FlexContainer';
import PageContainer from '../../../components/containers/PageContainer';
import AddPersonForm from './AddPersonForm';
import { PersonInterface } from '../models/PersonInterface';
import { useAllAccountManagers } from '../services/personServices';
import EntityList from '../../../components/grids/EntityList';
import UpdatePersonForm from './UpdatePersonForm';
import CustomButton from '../../../components/buttons/CustomButton';
import { useLayoutStore } from '../../../layout/store/layoutStore';
import { SIDEBAR_WIDTH } from '../../../layout/LayoutConstants';
import { usePerson } from '../../session/store/sessionStore';
import { useSessionContext } from '../../session/store/sessionContext';
import { useLangLabels } from '../../../shared/lang/services/useLangLabels';


const useStyles = makeStyles((theme) => 
    createStyles({
        container:{
            width:'100%',
            display:'grid',
            gridTemplateColumns:'1fr 400px',
            gap:'24px',
            height:'-webkit-fill-available',
        },
        fabBack:{
            position: 'fixed',
            left:  (props:any)=>`${270 - props.sidebarWidth }px`,
            bottom: theme.spacing(3),
        },
        asignFactory:{
            display:'grid',
            gap:'24px',
            gridTemplateColumns:'1fr 1fr',
            height:'-webkit-fill-available'
        }

    })
);




  //
function getStepContent(step:number) {
    switch (step) {
      case 0:
        return 1;
      case 1:
        return 2;
      case 2:
        return 3;
      default:
        return 'Unknown step';
    }
  }

const AddAccounManager:React.FC = () => {

    const {layout} = useLayoutStore()

    
    
    function getSteps() {
        return [lang.persons.createstep1, lang.persons.createstep2];
    }
    const {user} = useSessionContext()
    const { lang } = useLangLabels()
    const classes = useStyles({sidebarWidth:layout?.sidebarWidth === 0 ? SIDEBAR_WIDTH : 0})
    const theme = useTheme()

    const {data:accountManagers,status:accountManagersStatus} = useAllAccountManagers()

    //componentState
    const [, setFactory] = useState<any>();
    const [entity, setEntity] = useState<any>();
    const [load,setLoad] =useState({
        loading:true,
        success:true,
        error:null
    })


    //stepperstate
    const [activeStep, setActiveStep] = React.useState<any>(0);
    const [completed, setCompleted] = React.useState<any>({});
    const steps = getSteps();

    const refreshData = () =>{
        let ref = !load.success
        setLoad({...load,success:ref})
    }

    const handleFactory = (object:any) =>{
        setFactory(object)
    }
    const handlePerson = (person:PersonInterface) =>{
        setEntity(person)
        handleNext()
    }
 

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
        isLastStep() && !allStepsCompleted()
            ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
            : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep:any) => prevActiveStep - 1);
    };


    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    const customButtons = (person:PersonInterface) => {
        return(
            <CustomButton
            /* style={{margin:'0 0'}} */ 
            icon='business'
            variant='iconButton' 
            popoverTitle={lang.factories.manageFactories} 
            action={()=>handlePerson(person)} 
            color={'primary'}/> 
        )
    }


    return (
        <OnlineOfflineMode>
                <Stepper nonLinear activeStep={activeStep} >
                    {steps.map((label, index) => (
                    <Step key={label}>
                        <StepButton  completed={completed[index]}>
                            {label}
                        </StepButton>
                    </Step>
                    ))}
                </Stepper>
                <br />
                {allStepsCompleted() ? (
                <div>
                    <Typography >
                        {lang.persons.createFinishStep}
                    </Typography>
                    <Button onClick={handleReset}>Reset</Button>
                </div>
                ) : (
                
                <>
                    {getStepContent(activeStep) === 1 &&<>

                        <SidebarContainer>

                            {/* <ResourceListContainer //display a list of account managers
                                onLoad={<p>Todavía no hay ningún gerente regional asignado</p>}
                                endpoint={'AllAccountManagers'}
                                handleNext={handleNext}
                                handleState={setEntity}
                                searchBarTitle='Gerentes Regionales'
                            /> */}

                             <EntityList
                                title={lang.persons.accountManagers}
                                icon={'person'}
                                status={accountManagersStatus}
                                items={accountManagers}
                                customButtons={customButtons}
                                dropDownChildren={(item)=><PageContainer>
                                    <UpdatePersonForm person={item}/>
                                </PageContainer>}
                            />
                              <Paper>
                                <FlexContainer border={'4px 0px 0px solid red'}/>
                                <PageContainer padding='16px 16px 0px'>
                                    <Typography component={'h6'} >{lang.persons.newAccountManager}</Typography>
                                </PageContainer>
                                <AddPersonForm
                                    role='Administrador de Cuentas'
                                    handlePerson={handlePerson}
                                />
                            </Paper>
                        </SidebarContainer>
            
                    </>}
                    {getStepContent(activeStep) === 2 && <>
                        <DuoContainer >

                            <FactoryAssigment //AsignFactories to an Account Manager
                                color={theme.palette.primary.main} 
                                handleFactory={handleFactory}
                                title={getSteps()[activeStep]} 
                                email={entity.email}
                                action={'assign'}
                                filterByEmail={user?.type === 'SA' ? undefined : ['desarrollo@tgdsa.com']}
                                handleRefresh={refreshData}
                                refresh={load.success}
                                />
                            
                            <FactoryAssigment //Cancel Client accont to an Account Manager
                                color={theme.palette.primary.main} 
                                handleFactory={handleFactory}
                                title={lang.factories.accountManagerFactories} 
                                onLoad={<p>{/* Todavía no hay ninguna planta asignada para este usuario */ lang.factories.noFactories}</p>}
                                email={entity.email}
                                action={'cancel'}
                                refresh={load.success}
                                handleRefresh={refreshData}
                                />
                            
                            </DuoContainer>
                    </> }

                    {getStepContent(activeStep) === 2 &&
                        <Fab className={classes.fabBack} color="primary" onClick={handleBack}>
                            <ArrowBackIcon />
                        </Fab>
                    }
                </>
            )}
        </OnlineOfflineMode>
    );
}

export default AddAccounManager;
