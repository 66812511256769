import React from "react";
import { FlexContainer } from "../../../components/containers";
import VirtualGrid from "../../../shared/react-table/VirtualGrid/VirtualGrid";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { useNewsByTagFP } from "../../../domains/news/services/service.news";
import { usePeriod } from "../../../shared/date";
import { useCriticalitiesGridModel } from "../../../domains/criticalities/lang/useCriticalitiesGridModel";
import CustomCriticalityField from "../../../domains/plant-notifications/news/components/CustomCriticalityField";
import CreateNewForm from "../../../domains/news/components/CreateNewForm";
import NewView from "../../../domains/plant-notifications/news/components/NewView";

const NewNotificationsPage = () => {
  const { lang } = useLangLabels();
  const entityName = lang.news.plural;
  const gridModel = useCriticalitiesGridModel();

  const { period, setPeriod } = usePeriod(7, entityName);
  const { data: news } = useNewsByTagFP(period);

  return (
    <FlexContainer>
      <VirtualGrid
        entityName={entityName}
        gridModel={gridModel}
        items={news || []}
        title={entityName}
        period={period}
        periodChange={setPeriod}
        headerActions={() => <CreateNewForm />}
        renderOptionColumn={{
          headerName: lang.criticalities.title.singular,
          renderOption: (item) => CustomCriticalityField(item.criticality),
        }}
        bulkActions={(items) => <NewView items={items} />}
      />
    </FlexContainer>
  );
};

export default NewNotificationsPage;
