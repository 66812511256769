import { CustomIcon } from "../../../components/buttons/CustomIcon";
import FlexContainer from "../../../components/containers/FlexContainer";
import { useLeakLevel } from "./hooks/leakLevel";

interface Props {
  pointCapacity: number;
  lastlubricantCuantity: number;
}

const LeakLevelComponent: React.FC<Props> = ({ pointCapacity, lastlubricantCuantity }) => {
  const { description, leakQuantity } = useLeakLevel({ lastlubricantCuantity, pointCapacity });

  return (
    <FlexContainer gap={"10px"} align="center">
      <CustomIcon icon="leak" style={{ color: description }} />
      <div style={{ color: description }}>% {leakQuantity.toString().padStart(2, "0")}</div>
    </FlexContainer>
  );
};

export default LeakLevelComponent;
