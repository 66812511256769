import { Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import ModalButton from "../../../components/buttons/ModalButton";
import PageContainer from "../../../components/containers/PageContainer";
import TgdAuxList from "../../../components/grids/TgdAuxList";
import TabPanel from "../../../components/tabs/TabPanel";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import AddPersonForm from "../../person/components/AddPersonForm";
import UpdatePersonForm from "../../person/components/UpdatePersonForm";
import { PersonInterface } from "../../person/models/PersonInterface";
import {
  usePlantClients,
  usePlantOperators,
  usePlantSupervisors,
} from "../../person/services/personServices";
import { Plant } from "../../plants/PlantInterface";
import { useUser } from "../../session/store/sessionStore";

const a11yProps = (index: any) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

const ManageUsersPage: React.FC<{ plant: Plant }> = ({ plant }) => {
  const { data: user } = useUser();
  const { lang } = useLangLabels();
  const [value, setValue] = React.useState(0);
  const { data: operators, status: operatorsStatus } = usePlantOperators(plant.tagFP);
  const { data: supervisors, status: supervisorsStatus } = usePlantSupervisors(plant.tagFP);
  const { data: clients, status: clientsStatus } = usePlantClients(plant.tagFP);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(0);
    localStorage.setItem("currentPlantModification", JSON.stringify(plant));
  }, [plant]);

  const users = {
    supervisors: supervisors,
    operators: operators,
    clients: clients,
  };

  interface PageConfigInterface {
    name: string;
    singularName: string;
    headerAction: React.ReactNode;
    items: any[];
    status: string;
    children: (item: PersonInterface) => React.ReactNode;
  }

  const pageConfig = useMemo<PageConfigInterface[]>(
    () => [
      ...(user?.type !== "S"
        ? [
            {
              name: lang.persons.supervisors,
              singularName: lang.persons.supervisor,
              items: users.supervisors || [],
              status: supervisorsStatus,
              headerAction: (
                <ModalButton
                  icon="add"
                  variant="button"
                  title={lang.persons.newSupervisor}
                  children={<AddPersonForm role="Supervisor" />}
                />
              ),
              children: (item: any) => <UpdatePersonForm tagFP={plant.tagFP} person={item} />,
            },
          ]
        : []),
      {
        name: lang.persons.operators,
        singularName: lang.persons.operator,
        items: users.operators || [],
        status: operatorsStatus,
        headerAction: (
          <ModalButton
            icon="add"
            variant="button"
            title={lang.persons.newOperator}
            children={<AddPersonForm role="Operario" />}
          />
        ),
        children: (item) => <UpdatePersonForm tagFP={plant.tagFP} person={item} />,
      },
    ],
    [operatorsStatus, users, supervisorsStatus, plant, user]
  );

  return (
    <PageContainer width={"100%"}>
      <Tabs
        style={{ marginTop: "0px" }}
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="secondary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {pageConfig.map((item, index: number) => (
          <Tab key={index + 100} label={item.name} {...a11yProps(index)} />
        ))}
      </Tabs>
      {pageConfig.map((item: PageConfigInterface, index: number) => (
        <TabPanel key={index} value={value} index={index}>
          <TgdAuxList
            showLabel="name"
            items={item.items}
            status="success"
            displaySubNameHandler={"email"}
            children={item.children}
            toolbarActions={item.headerAction}
            searchBar={true}
          />
        </TabPanel>
      ))}
    </PageContainer>
  );
};

export default React.memo(ManageUsersPage);
