import { useRef, useState } from "react";
import { Ref, UseReactPDFPageStateProps, useReactPDFPageStateResult } from "../types/ReactPDFTypes";

const useReactPDFPageState = <T,>({
  data,
  pageIndex,
  lastDataPosition,
  handleNextDataPosition,
  handlePrevDataPosition,
  handleSkipPage,
  handlePageStateDataPush,
  handlePageStateDataPop,
}: UseReactPDFPageStateProps<T>): useReactPDFPageStateResult => {
  const [remainingSize, setRemainingSize] = useState<number>();
  const [variableSize, setVariableSize] = useState<number>(0);
  const [triggerOnResizing, setTriggerOnResizing] = useState(0);
  const variableContentRef = useRef<Ref>(null);

  const setInitialContentHeight = () => {
    if (variableContentRef.current) {
      setRemainingSize(variableContentRef.current.clientHeight);
    }
  };

  const handleRemainingSizeChange = () => {
    setRemainingSize((prevState) => {
      return (prevState || 0) - variableSize;
    });
  };

  const handleTriggerOnResizing = () => {
    setTriggerOnResizing((prevState) => prevState + 1);
  };

  const handleVariableSizeChange = (newSize: number) => {
    setVariableSize(newSize);
  };

  const handlePageStateOnRemainingSizeChange = () => {
    if (remainingSize && remainingSize > 0) {
      if (lastDataPosition < data.length) {
        handlePageStateDataPush(pageIndex, data[lastDataPosition]);
        handleNextDataPosition();
      }
    } else {
      if (remainingSize) {
        handlePageStateDataPop(pageIndex);
        handlePrevDataPosition();
        handleSkipPage();
      }
    }
  };

  return {
    variableContentRef,
    remainingSize,
    setInitialContentHeight,
    handleRemainingSizeChange,
    triggerOnResizing,
    handleTriggerOnResizing,
    handleVariableSizeChange,
    handlePageStateOnRemainingSizeChange,
  };
};

export default useReactPDFPageState;
