import React from "react";
import DefaultFormLayout from "../../components/form/DefaultFormLayout/DefaultFormLayout";
import FieldSelector from "../../shared/form/components/FieldSelector";
import { useCreateUpdate } from "../../shared/form/hooks/useCreateUpdate";
import { CUEntityFormPropsType, UIFormInterface } from "../../shared/form/types/CRUDTypes";
import { CrudFormType } from "../../shared/form/types/FormTypes";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";
import { useSessionContext } from "../session/store/sessionContext";

import { ObservationInterface } from "./models/ObservationTypes";
import {
  DeleteObservation,
  ObservationCRUDDependencies,
  UpdateObservation,
  CreateObservation,
} from "./services/observationServices";
import { useObservationForm } from "./services/useObservationForm";

type CUObservationFormProps = CUEntityFormPropsType<ObservationInterface> & {
  observationData?: any;
  isDataUpdated?: boolean;
};

const CUObservationForm: React.FC<CUObservationFormProps> = ({
  item,
  actionType,
  icon,
  variant,
  mode,
  observationData,
  isDataUpdated,
}) => {
  const { tagFP } = useSessionContext();

  //DB
  const { action, resetStatus, status, error, message } = useCreateUpdate(
    {
      create: CreateObservation,
      update: UpdateObservation,
      delete: DeleteObservation,
    },
    actionType,
    ObservationCRUDDependencies
  );

  //UI
  const { lang } = useLangLabels();
  const { uiModel, handleSubmit, resetForm } = useObservationForm(tagFP, item);

  const ui = (() => {
    const formName = lang.observations.plural;
    switch (actionType) {
      case CrudFormType.create:
        const create: UIFormInterface = {
          icon: icon || "add",
          title: `${lang.actionTypes.create} ${formName.toLowerCase()}`,
          variant: variant || "button",
          mode: mode || "modal",
        };

        return create;

      case CrudFormType.update:
        const update: UIFormInterface = {
          icon: icon || "edit",
          title: `${lang.actionTypes.update} ${formName.toLowerCase()}`,
          variant: variant || "iconButton",
          mode: mode || "content",
        };
        return update;
    }
  })();

  //Methods
  const submit = (data: ObservationInterface) => {
    switch (actionType) {
      case CrudFormType.create:
        action([
          {
            area: data.area,
            criticality: data.criticality,
            observation: data.observation,
            tagFP: data.tagFP,
            ...observationData,
          },
        ]);
        break;

      case CrudFormType.update:
        action({
          ...item,
          area: data.area,
          criticality: data.criticality,
          observation: data.observation,
          tagFP: data.tagFP,
        });
        break;
    }
  };

  const handleReset = () => {
    resetStatus();
    resetForm();
  };

  return (
    <DefaultFormLayout
      componentMode={ui.mode}
      buttonIcon={ui.icon}
      buttonVariant={ui.variant}
      buttonText={ui.title}
      popoverTitle={ui.title}
      {...{ queryStatus: status, error, alertStatusMessage: message }}
      buttonAction={handleSubmit(submit)}
      onClose={handleReset}
      resetForm={handleReset}
      isDataUpdated={isDataUpdated}
    >
      {uiModel.map((props, index) => (
        <FieldSelector key={index} fieldProps={props} />
      ))}
    </DefaultFormLayout>
  );
};

export default CUObservationForm;
