import React from "react";
import CustomButton from "../../../../components/buttons/CustomButton";
import { useCarrousell } from "../../../../components/containers/toolbar/hooks/useCarrousell";
import { useLocalStorage } from "../../../../shared/localStorage/useLocalStorage";
import { LubricationPointInterface } from "../../../lubricationPoints/model/lubricationPointsInterface";
import SurveyModal from "./components/SurveyModal/SurveyModal";
import CreateSurveyForm from "./components/CreateSurveyForm/CreateSurveyForm";
import { surveyTasks } from "../../tasks/model/TaskInterface";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

type LubricationPointSurveyProps = {
  items: LubricationPointInterface[];
  outOfRoute: boolean;
};

const LubricationPointSurvey: React.FC<LubricationPointSurveyProps> = ({ items, outOfRoute }) => {
  const { lang } = useLangLabels();

  const { current, handleNext, handlePrev, canNext, canPrev } = useCarrousell(items?.length || 0);
  const currentLubricationPoint = items && items[current];

  const [isModalOpen, setIsModalOpen] = useLocalStorage("surveyModal", false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const isOutOfRoute = currentLubricationPoint?.info?.pending ? outOfRoute : true;

  const popoverTitle = `${
    outOfRoute ? lang.routes.survey.outOfRoute : lang.routes.survey.inRoute
  }`;

  return (
    <>
      <CustomButton
        action={toggleModal}
        color="secondary"
        icon="surveyPlay"
        variant="iconButton"
        popoverTitle={popoverTitle}
      />

      <SurveyModal
        open={!!isModalOpen}
        handleClose={toggleModal}
        canNext={canNext}
        canPrev={canPrev}
        handleNext={handleNext}
        handlePrev={handlePrev}
        currentLubricationPoint={currentLubricationPoint}
        tasks={surveyTasks}
        outOfRoute={isOutOfRoute}
      >
        <CreateSurveyForm
          item={currentLubricationPoint!}
          tasks={surveyTasks}
          outOfRoute={isOutOfRoute}
          onFinish={() => {}}
        />
      </SurveyModal>
    </>
  );
};

export default React.memo(LubricationPointSurvey);
