import React from "react";
import { CircularProgress } from "@material-ui/core";
import { useDeleteRouteVerification } from "./services/service.routes";
import { useEffect } from "react";
import { useSnackbar } from "../../components/snackbar/hooks/useSnackbar";
import CustomButton from "../../components/buttons/CustomButton";
import FlexContainer from "../../components/containers/FlexContainer";
import { useStatusProcessor } from "../../shared/queries/StatusProcessor";
import { useClickPosition } from "../../shared/globals/hooks/useClickPosition";
import { RouteInterface } from "./services/RoutesInterface";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";

interface Props {
  item: RouteInterface;
}
const DeleteRoute: React.FC<Props> = ({ item }) => {
  const { errors, status: deleteStatus, validate, query } = useDeleteRouteVerification();
  const { status, message } = useStatusProcessor(query);

  const { position, getClickPosition } = useClickPosition();
  const { setData } = useSnackbar();

  const { lang } = useLangLabels();

  useEffect(() => {
    if (deleteStatus === "error") {
      const componentsError = errors?.equipments?.length
        ? errors.equipments.length > 1
          ? `${errors.equipments.length} ${lang.STIEquipments.form.label.equipments}`
          : `${errors.equipments.length} ${lang.STIEquipments.form.label.equipment}`
        : "";
      setData({
        modal: true,
        errors: `${lang.routes.routeCannotBeRemoved}: ${componentsError}`,
        severity: "warning",
        position: position,
      });
    }
    if (status === "success") {
      setData({
        modal: true,
        errors: message,
        severity: "success",
        position: position,
      });
    }
  }, [status, deleteStatus, errors]);

  return deleteStatus === "loading" || status === "loading" ? (
    <FlexContainer justify={"center"} align="center">
      <CircularProgress size="24px" />
    </FlexContainer>
  ) : (
    <CustomButton
      status={status}
      icon="delete"
      variant="iconButton"
      action={(e) => getClickPosition(e, () => validate(item))}
      popoverTitle={lang.crudActions.delete + " " + lang.routes.singular}
    />
  );
};

export default React.memo(DeleteRoute);
