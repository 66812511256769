import { useEffect, useMemo, useState } from "react";
import {
  FieldFilterHookProps,
  FieldFilterHookResult,
  FieldOption,
  FieldProps,
  FieldsObject,
  FilterModel,
} from "../types/FieldFilterTypes";
import { getNestedKeys, getNestedValue, getNestedValueByPath } from "../utils/dataFiltering";
import { useTranslateValues } from "./useTranslateValues";
import useBasicLocalStorage from "../../../../shared/localStorage/useBasicLocalStorage";
import _ from "lodash";

const useFieldFilter = <T>({
  items = [],
  gridModel,
  entityName,
  config,
  customOptions,
  filterDependentActions,
  filterDependentActionsArgs,
  defaultValue,
}: FieldFilterHookProps<T>): FieldFilterHookResult<T> => {
  // State and Values --------
  const { translateValue } = useTranslateValues();

  const storeValue = !!entityName;

  const [itemsState, setItemsState] = useState<T[]>([]);

  useEffect(() => {
    if (items && items.length > 0 && !_.isEqual(itemsState, items)) {
      setItemsState(items);
    }
  }, [items]);

  const [searchValue, setSearchValue] = useBasicLocalStorage<string>({
    key: `${entityName?.toLowerCase()}-search-value`,
    initialValue: "",
    storeLocal: storeValue,
  });
  const [selectedFields, setSelectedFields] = useBasicLocalStorage<FieldsObject>({
    key: `${entityName?.toLowerCase()}-row-filters`,
    initialValue: {},
    storeLocal: storeValue,
  });

  const getOptionsByKey = (key: string) => {
    return Array.from(
      new Set(itemsState.map((item: any) => getNestedValueByPath(item, key)?.toString()))
    )
      .filter((value) => value !== undefined)
      .map((value) => ({
        name: value.toString(),
        value: value.toString(),
      }));
  };

  const allFields: FieldsObject = useMemo(() => {
    const groupedOptions: FieldsObject = {};

    for (const key in gridModel) {
      if (gridModel.hasOwnProperty(key)) {
        const nestedKeys = key.split(".");
        const lastKeyIndex = nestedKeys.length - 1;
        const lastKey = nestedKeys[lastKeyIndex];

        const prevOptions =
          gridModel[key]?.options ?? customOptions?.[lastKey] ?? getOptionsByKey(key);

        const label = gridModel[key]?.label || key;
        const options = config?.fields?.[key as keyof T]?.options?.(prevOptions) ?? prevOptions;
        const component = config?.fields?.[key as keyof T]?.component || "autocomplete";
        const hideField = config?.fields?.[key as keyof T]?.hideField || false;

        if (itemsState.length > 0 && options.length > 0 && !hideField) {
          groupedOptions[lastKey] = {
            key: lastKey,
            nestedKey: key,
            label,
            options,
            selectedValue: null,
            component,
          };
        }
      }
    }

    return groupedOptions;
  }, [itemsState, gridModel, config, customOptions]);

  const notSelectedFields: FieldsObject = useMemo(() => {
    const selectedFieldKeys = Object.keys(selectedFields);

    return Object.keys(allFields).reduce((notSelected, key) => {
      if (!selectedFieldKeys.includes(key)) {
        notSelected[key] = allFields[key];
      }
      return notSelected;
    }, {} as FieldsObject);
  }, [allFields, selectedFields]);

  const getSearchFilterResult = (filterResult: T[]) => {
    return filterResult.filter((item: any, index: number) => {
      const keysToFilter = getNestedKeys(filterResult[index] as object);

      let itMatches = false;

      keysToFilter.forEach((key) => {
        if (itMatches === true) return;

        const valueToCompare = getNestedValueByPath(item, key);

        itMatches =
          valueToCompare === undefined
            ? false
            : valueToCompare?.toString().toUpperCase().indexOf(searchValue.toUpperCase()) === -1
            ? false
            : true;
      });

      return itMatches;
    });
  };

  const filteredItems: T[] = useMemo(() => {
    if (!config?.isServerSideFiltering && itemsState.length) {
      const filterResult = itemsState.filter((item: any, index: number) => {
        for (const [fieldKey, fieldValue] of Object.entries(selectedFields)) {
          const itemValue = getNestedValue(item, fieldValue.nestedKey)?.toString().toUpperCase();
          const filterValue = fieldValue.selectedValue;

          if (Array.isArray(filterValue)) {
            const valueToCompare = (filterValue as FieldOption[])?.map((fieldOption) =>
              translateValue(fieldOption.value)?.toString().toUpperCase()
            );
            const existValueToCompare = valueToCompare !== null && valueToCompare !== undefined;

            if (existValueToCompare) {
              if (!valueToCompare.includes(itemValue)) {
                return false;
              }
            }
          } else {
            const valueToCompare = translateValue(
              (filterValue as FieldOption)?.value?.toString().toUpperCase()
            );
            const existValueToCompare = valueToCompare !== null && valueToCompare !== undefined;

            if (existValueToCompare && itemValue !== valueToCompare) {
              return false;
            }
          }
        }

        return true;
      });

      const searchResult = searchValue ? getSearchFilterResult(filterResult) : filterResult;

      return searchResult;
    }

    return itemsState;
  }, [itemsState, selectedFields, searchValue]);

  const notSelectedOptions = useMemo(() => {
    return Object.entries(notSelectedFields || []).map(([key, field]) => field);
  }, [notSelectedFields]);

  const filterModel = useMemo(() => {
    const selectedFieldKeys = Object.entries(selectedFields).map(
      ([fieldKey, fieldValue]) => fieldValue.selectedValue !== null && fieldKey
    );

    return Object.keys(allFields).reduce((notSelected, key) => {
      if (selectedFieldKeys.includes(key)) {
        let filterValue;

        if (Array.isArray(selectedFields[key].selectedValue)) {
          filterValue = (selectedFields[key].selectedValue as FieldOption[])?.map(
            (fieldOption) => fieldOption.value
          );
        } else {
          filterValue = [(selectedFields[key].selectedValue as FieldOption)?.value];
        }

        if (filterValue !== undefined) {
          notSelected[key] = filterValue;
        }
      }
      return notSelected;
    }, {} as FilterModel);
  }, [notSelectedFields]);

  // Methods --------

  const handleSearchValueChange = (value: string) => {
    setSearchValue(value);
    filterDependentActions?.(filterDependentActionsArgs);
  };

  const onSelectedFieldAdd = (field: FieldProps) => {
    const newSelectedFields = {
      ...selectedFields,
      [field.key]: field,
    };

    setSelectedFields(newSelectedFields);
  };

  const onSelectedFieldDelete = (keyToDelete: string) => {
    const { [keyToDelete]: removedField, ...newSelectedFields } = selectedFields;
    setSelectedFields(newSelectedFields);
    selectedFields[keyToDelete].selectedValue &&
      filterDependentActions?.(filterDependentActionsArgs);
  };

  const onSelectedFieldsClear = () => {
    setSelectedFields({});
    filterDependentActions?.(filterDependentActionsArgs);
  };

  const handleFilterValueChange = (key: string, value: any) => {
    if (!selectedFields[key]) {
      console.error(`Field with key "${key}" not found in selectedFields`);
      return;
    }

    setSelectedFields((prevSelectedFields) => ({
      ...prevSelectedFields,
      [key]: {
        ...prevSelectedFields[key],
        selectedValue: Array.isArray(value) ? (value.length ? value : null) : value,
      },
    }));

    filterDependentActions?.(filterDependentActionsArgs);
  };

  useEffect(() => {
    if (defaultValue && selectedFields && itemsState.length > 0) {
      Object.entries(defaultValue).map(([key, value]) => {
        if (!selectedFields[key]) {
          const existOption = allFields[key].options.find((option) => option.value === value);
          if (!!existOption) {
            onSelectedFieldAdd({
              ...allFields[key],
              selectedValue: [{ name: value as string, value: value as string }],
            });
          }
        }
      });
    }
  }, [allFields]);

  // Return --------

  return {
    searchValue,
    handleSearchValueChange,
    filterModel,
    allFields,
    selectedFields,
    notSelectedFields,
    onSelectedFieldAdd,
    onSelectedFieldDelete,
    onSelectedFieldsClear,
    handleFilterValueChange,
    notSelectedOptions,
    filteredItems,
  };
};

export default useFieldFilter;
