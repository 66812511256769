import React, { useEffect } from "react";
import { useDeleteBrand } from "../services/brandService";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { useClickPosition } from "../../../../shared/globals/hooks/useClickPosition";
import { useSnackbar } from "../../../../components/snackbar/hooks/useSnackbar";
import { CircularProgress } from "@material-ui/core";
import CustomButton from "../../../../components/buttons/CustomButton";
import { BrandInterface } from "../types/brandTypes";

export const DeleteBrandForm = ({ item }: { item: BrandInterface }) => {
  const { lang } = useLangLabels();

  const { deleteBrand, status, message } = useDeleteBrand();
  const { position, getClickPosition } = useClickPosition();

  const { setData } = useSnackbar();

  useEffect(() => {
    if (status === "success") {
      setData({
        modal: true,
        errors: message,
        severity: status,
        position,
      });
    }
    if (status === "error") {
      setData({
        modal: true,
        errors: message,
        severity: "warning",
        position,
      });
    }
  }, [status]);

  return status === "loading" ? (
    <CircularProgress size="24px" />
  ) : (
    <CustomButton
      variant="iconButton"
      status={status}
      icon="delete"
      popoverTitle={lang.crudActions.delete + " " + lang.instruments.brands.title.singular}
      action={(e) => getClickPosition(e, () => deleteBrand(item))}
    />
  );
};
