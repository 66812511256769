import { Typography } from "@material-ui/core";
import { FlexContainer } from "../../../../../components/containers";
import { CustomIcon, CustomIconProps } from "../../../../../components/buttons/CustomIcon";

interface PreviewFieldProps {
  iconProps: CustomIconProps;
  title: string;
  label: string | number;
}
export const PreviewField: React.FC<PreviewFieldProps> = ({
  iconProps,
  label,
  title,
}) => {
  const _label = (() => {
    switch (typeof label) {
      case "string":
        return label.toUpperCase();
      default:
        return label.toString();
    }
  })();

  const _key = title?.toUpperCase() || "default";

  return (
    <FlexContainer align={"center"}>
      {iconProps.icon ? <CustomIcon {...iconProps} /> : _key}
      <Typography>{_label}</Typography>
    </FlexContainer>
  );
};
