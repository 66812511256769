import React from 'react';
import SimpleAutocompleteSelect from '../../../components/autocomplete/SimpleAutocompleteSelect';
import { useSuppliesByType } from '../services/service.supplies';
import { SupplyInterface, SupplyType } from '../model/SupplyInterface';
import { useLangLabels } from '../../../shared/lang/services/useLangLabels';


interface Props {
    tagFP:string
    onChange:(item?:any)=>void
    defaultValue?:string
    error?:any
    by?:{
        key:string,
        value:string
    }
    complete?:boolean
    extendInput?:boolean
}

const SelectSupply:React.FC<Props> = ({tagFP,by,onChange,error,defaultValue,complete,extendInput}) => {

    const { lang } = useLangLabels()
    const showTitle = 'supply'
    const supplyType = by?.key === 'type' ? by.value as SupplyType : undefined
    const { data:supplies } = useSuppliesByType(supplyType)
    const label = supplyType || lang.supplies.title.singular

    
    const handleChange = (value:string) => {
        if(complete){
            const resultComplete = supplies?.find((a)=>a[showTitle] === value) as SupplyInterface
            onChange(resultComplete)
        }else{
            const result = value as string 
            onChange(result)
        }
    }

    return (
        <SimpleAutocompleteSelect
            {...{
                showTitle,
                label,
                items:supplies || [],
                onChange:handleChange,
                error:error,
                defaultValue,
                extendInput
            }}
        />
    );
}

export default SelectSupply;
