import { useState } from "react";

type PopoverEvent = React.MouseEvent<HTMLButtonElement | HTMLDivElement>;
type CurrentTarget = HTMLButtonElement | HTMLDivElement | null;

export const usePopoverEvent = () => {
  const [currentTarget, setCurrentTarget] = useState<CurrentTarget>(null);

  const handlePopoverOpen = (event: PopoverEvent) => {
    setCurrentTarget(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setCurrentTarget(null);
  };

  return {
    id: "simple-popover",
    currentTarget,
    handlePopoverOpen,
    handlePopoverClose,
  };
};
