import { Paper } from "@material-ui/core";
import { Suspense, lazy, useMemo } from "react";
import { useRoutesByTagFPAndState } from "../../services/service.routes";
import { useRoutesGridModel } from "../../lang/useRoutesGridModel";
import ReScheduleRoute from "../ReScheduleRoute";

interface KnowledgeOfTheRoutesProps {
  title: string;
}

const KnowledgeOfTheRoutes = ({ title }: KnowledgeOfTheRoutesProps) => {
  const { data: routes } = useRoutesByTagFPAndState();
  const routesGRidModel = useRoutesGridModel();

  const VirtualGrid = useMemo(
    () => lazy(() => import("../../../../shared/react-table/VirtualGrid/VirtualGrid")),
    []
  );

  return (
    <Suspense fallback={"..."}>
        <VirtualGrid
          title={title}
          entityName={title}
          gridModel={routesGRidModel}
          items={routes}
          renderOptionColumn={{
            headerName: "More",
            renderOption: (item: any) => item && <ReScheduleRoute item={item} />,
          }}
          documentExportTitle={`reportes-${title}`}
        />
    </Suspense>
  );
};

export default KnowledgeOfTheRoutes;
