import React from "react";
import { usePlantAreas } from "../../services/service.areas";

import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { AreaInterface } from "../../models/AreaTypes";
import CUAreaForm from "../../CUAreaForm";
import { CrudFormType } from "../../../../shared/form/types/FormTypes";
import { useAreasGridModel } from "../../lang/useAreasGridModel";
import DeleteAreaForm from "../../DeleteAreaForm";
import { CarrousellActionInterface } from "../../../../shared/react-table/VirtualGrid/types/VirtualGridTypes";
import VirtualGrid from "../../../../shared/react-table/VirtualGrid/VirtualGrid";

interface ManageAreaPageProps {
  virtualGridWidth: string;
}

const ManageAreaPage: React.FC<ManageAreaPageProps> = ({ virtualGridWidth }) => {
  const { lang } = useLangLabels();
  const { data: areas, isFetching } = usePlantAreas();

  const carrousellActions: CarrousellActionInterface = [
    {
      icon: "edit",
      popoverTitle: lang.crudActions.update,
      component: (item: AreaInterface) => (
        <CUAreaForm item={item} actionType={CrudFormType.update} isDataUpdated={!isFetching} />
      ),
    },
  ];

  return (
    <>
      <VirtualGrid
        {...{
          title: lang.areas.title.plural,
          entityName: lang.areas.title.plural,
          width: virtualGridWidth,
          items: areas,
          gridModel: useAreasGridModel(),
          carrousellComponents: carrousellActions,
          headerActions: (item: any) => <CUAreaForm actionType={CrudFormType.create} />,
          itemActions: (item) => <DeleteAreaForm item={item} />,
        }}
      ></VirtualGrid>
    </>
  );
};

export default React.memo(ManageAreaPage);
