import React, {
  Children,
  ReactNode,
  isValidElement,
  useCallback,
  useEffect,
} from "react";
import useReactPDFPageState from "../hooks/useReactPDFPageState";
import { PageProps } from "../types/ReactPDFTypes";
import FlexContainer from "../../containers/FlexContainer";

const Page = <T,>({
  children,
  data,
  pageIndex,
  pageData,
  lastDataPosition,
  handleNextDataPosition,
  handlePrevDataPosition,
  handleSkipPage,
  handlePageStateDataPush,
  handlePageStateDataPop,
  otherProps,
}: PageProps<T>) => {
  const {
    variableContentRef,
    remainingSize,
    setInitialContentHeight,
    handleRemainingSizeChange,
    triggerOnResizing,
    handleTriggerOnResizing,
    handleVariableSizeChange,
    handlePageStateOnRemainingSizeChange,
  } = useReactPDFPageState({
    data,
    pageIndex,
    lastDataPosition,
    handleNextDataPosition,
    handlePrevDataPosition,
    handleSkipPage,
    handlePageStateDataPush,
    handlePageStateDataPop,
  });

  const memoizedHandleVariableSizeChange = useCallback(
    handleVariableSizeChange,
    []
  );
  const memoizedHandleTriggerOnResizing = useCallback(
    handleTriggerOnResizing,
    []
  );

  useEffect(() => {
    setInitialContentHeight();
  }, [variableContentRef]);

  useEffect(() => {
    if (remainingSize) {
      handleRemainingSizeChange();
    }
  }, [triggerOnResizing]);

  useEffect(() => {
    handlePageStateOnRemainingSizeChange();
  }, [remainingSize]);

  const stateProps = {
    variableContentRef,
    pageIndex,
    pageData,
    handleVariableSizeChange: memoizedHandleVariableSizeChange,
    handleTriggerOnResizing: memoizedHandleTriggerOnResizing,
    otherProps,
  };

  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return React.cloneElement(child, stateProps);
    }

    return child;
  });

  return (
    <FlexContainer
      padding={"16px"}
      flexDirection="column"
      width={"900px"}
      height={"1272px"}
      style={{ pageBreakAfter: "always" }}
    >
      {typeof children === "function"
        ? children(stateProps)
        : (childrenWithProps as ReactNode)}
    </FlexContainer>
  );
};

export default Page;
