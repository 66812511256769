import { InputComponentTypes } from "../../../../shared/form/types/FormTypes";
import { useForm } from "../../../../shared/form/useForm";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { InstrumentTypeInterface } from "../types/instrumentType";

export const useInstrumentTypeForm = (tagFP: string, item?: InstrumentTypeInterface) => {
  const { lang } = useLangLabels();

  return useForm<InstrumentTypeInterface>({
    id: {
      label: lang.globalentityValues.id,
      placeholder: lang.globalentityValues.id,
      value: item?.id || 0,
      component: InputComponentTypes.none,
    },
    name: {
      label: lang.types.form.name,
      placeholder: lang.types.form.name,
      value: item?.name || "",
      validations: {
        required: { value: true, message: lang.formMessages.required },
      },
      component: InputComponentTypes.text,
    },
    type: {
      label: lang.types.form.type,
      placeholder: lang.types.form.type,
      value: item?.type || "",
      validations: {
        required: { value: true, message: lang.formMessages.required },
      },
      component: item?.type
        ? InputComponentTypes.none
        : InputComponentTypes.instrumentType,
      
    },
    tagFP: {
      label: lang.globalentityValues.tagFP,
      placeholder: lang.globalentityValues.tagFP,
      value: tagFP,
      validations: {
        required: { value: true, message: lang.formMessages.required },
      },
      component: InputComponentTypes.none,
    },
  });
};
