import React from 'react';
import FlexContainer from '../../components/containers/FlexContainer';
import { SOFT_VERSION } from '../../shared/config/appConfig';

const VersionContainer:React.FC<{company:string,justify?:'center'|'left'|'right'}> = ({company,children,justify}) => {

    const soft = SOFT_VERSION?.filter((item)=>item[0] === company)[0] || ['...','0.0.0','...']

    const softName = soft[2]
    const softVersion = soft[1]

    const left = justify === 'center' ? 'calc(50% - 50px)'
    : justify === 'right' ? 'calc(100% - 24px)'
    : justify === 'left' ? '24 px' : ''

    return (
        <FlexContainer flexDirection='column' padding='0' margin={'50px 0 0 0'} style={{position:'relative'}} >
            {children && children}
            <br />
            <div style={{flexGrow:1}}>
                <span style={{position:'absolute',bottom:'24px', left,fontSize:'12px',width:'200px'}} >{softName} - {softVersion}</span>
            </div>
        </FlexContainer>
    );
}

export default VersionContainer;
