
export const phone = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/ 

export const emailFormRegex:RegExp = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

export const FormRegExp = {
    name: /^[ \w]+$/i,
    float:/^\d+(\.\d+)?$/i,
    integer:/^\d+(\.\d+)?$/i,
    number: /^[0-99]+$/i,
    days:/^$|[0-9]+$/i,
    email:emailFormRegex,
    description:/^[ \w]+$/i,
   /*  macAdress:/^([0-9A-Fa-f]{2}){5}([0-9A-Fa-f]{2})$/i */
    macAddress:/^([0-9A-Fa-f]{2}){8}|^([0-9A-Fa-f]{2}){6}$/i
}



export const TypeFormRegex = {
    name: FormRegExp.name,
    float:FormRegExp.float,
    integer:FormRegExp.integer
}

export const LubricantFormRegex = {
    name: FormRegExp.name,
    float:FormRegExp.float,
    integer:FormRegExp.integer
}

export const CriticalityFormRegex = {
    number: FormRegExp.number,
    days:FormRegExp.days
}

export const EquipmentFormRegex = {
    float:FormRegExp.float,
    integer:FormRegExp.integer
}

export const PersonsFormRegex = {
    integer:FormRegExp.integer
}