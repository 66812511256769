import React, { useEffect } from "react";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import VirtualGrid from "../../../../shared/react-table/VirtualGrid/VirtualGrid";
import { useObservationReportGridModel } from "../hooks/useObservationReportGridModel";
import { useGetObservationReportList } from "../services/ObservationReportService";
import { PeriodInterface } from "../../../../shared/reports/model/ReportInterface";
import { ObservationReport } from "../types/ObservationTypes";
import { FieldFilterHookResult } from "../../../../components/filters/FieldFilter/types/FieldFilterTypes";

interface ObservationReportGridProps {
  entityName: string;
  period: PeriodInterface;
  setPeriod: React.Dispatch<React.SetStateAction<any>>;
  fieldFilter: FieldFilterHookResult<ObservationReport>;
  setObservationsItems: (items: ObservationReport[]) => void;
  setSelectedItems: React.Dispatch<React.SetStateAction<any>>;
}

const ObservationReportGrid = ({
  entityName,
  period,
  setPeriod,
  fieldFilter,
  setObservationsItems,
  setSelectedItems,
}: ObservationReportGridProps) => {
  const { lang } = useLangLabels();
  const title = lang.reportsGeneration.generateObservationReport;
  const observationReportGridModel = useObservationReportGridModel();

  const { data: observationReport, isLoading } = useGetObservationReportList(period, {
    filters: fieldFilter.filterModel,
    searchValue: fieldFilter.searchValue,
  });

  useEffect(() => {
    if (!isLoading) {
      setObservationsItems(observationReport ?? []);
    }
  }, [observationReport]);

  return (
    <VirtualGrid
      entityName={entityName}
      gridModel={observationReportGridModel}
      items={observationReport || []}
      title={title}
      period={period}
      periodChange={setPeriod}
      customDynamicFilters={fieldFilter}
      onSelectedDataChange={setSelectedItems}
    />
  );
};

export default ObservationReportGrid;
