import FlexContainer from "../../../../components/containers/FlexContainer"
import PageContainer from "../../../../components/containers/PageContainer"
import { PersonInterface } from "../../../../domains/person/models/PersonInterface"
import { UserType } from "../../../../domains/users/UserInterface"
import Sidebar from "../../../../layout/components/Sidebar"
import { useMenu } from "../../../../layout/menu/useMenu"



export const DrawerWidth = 250

interface props {
    userType:UserType,
    currentPbiGroupName:string,
}

const UserDashboard:React.FC<props> = ({ children, userType, currentPbiGroupName}) => {


    const menu = useMenu({
        name:currentPbiGroupName,
        defaultUser:userType
    })
    
    return(
        <FlexContainer gap={'0px'} padding='24px'>
            <Sidebar menu={menu} open={true} anchor='left' />
            <PageContainer padding='0px' width={`calc(100vw - ${DrawerWidth}px )`}>
                <PageContainer height='84px' >.
                </PageContainer>
                {children}
            </PageContainer>
        </FlexContainer>
    )
} 

export default UserDashboard