import React from "react";
import Container from "./Container";

interface ComponentToPrintProps {
  keys: string[];
  data: Record<string, any>; 
  otherProps?: Record<string, any>;
}

export const ComponentToPrint = React.forwardRef<HTMLDivElement, ComponentToPrintProps>(({ keys, data, otherProps }, ref) => {
  return (
    <div ref={ref}>
      {keys?.map((key, index) => (
        <Container
          key={index}
          data={data?.[key]}
          otherProps={{...otherProps, containerNumber: index, sector: key }}
        />
      ))}
    </div>
  );
});
