import React from "react";
import SimpleAutocompleteSelect from "../../../components/autocomplete/SimpleAutocompleteSelect";
import { CrudFormType } from "../../../shared/form/types/FormTypes";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { useUser } from "../../session/store/sessionStore";
import CUComponentForm from "../CUComponentForm";
import { ComponentInterface } from "../models/ComponentInterface";
import { usePlantComponents } from "../services/service.components";

interface Props {
  tagFP: string;
  error?: string;
  onChange: any;
  defaultValue?: string | undefined;
  complete?: boolean;
}

const SelectComponent: React.FC<Props> = ({ tagFP, error, onChange, defaultValue, complete }) => {
  const showTitle = "component";
  const { data: user } = useUser();
  const { lang } = useLangLabels();
  const { data: components } = usePlantComponents();
  const label = lang.components.title.singular;

  const handleChange = (value: string) => {
    if (complete) {
      const resultComplete = components?.find((a) => a[showTitle] === value) as ComponentInterface;
      onChange(resultComplete);
    } else {
      const result = value as string;
      onChange(result);
    }
  };

  return (
    <SimpleAutocompleteSelect
      {...{
        error,
        label,
        showTitle,
        items: components || [],
        onChange: handleChange,
        defaultValue,
      }}
    >
      {user?.type !== "O" && <CUComponentForm actionType={CrudFormType.create} />}
    </SimpleAutocompleteSelect>
  );
};

export default React.memo(SelectComponent);
