import React from "react";
import theme from "../../../assets/theme";
import { CollectorInterface } from "../models/CollectorInterfaces";
import { CustomIcon } from "../../../components/buttons/CustomIcon";

const CollectorStatus: React.FC<{ collector: CollectorInterface }> = ({ collector }) => {
  return (
    <CustomIcon
      icon={"state"}
      style={{ color: collector.state ? theme.palette.success.main : theme.palette.grey[500] }}
    />
  );
};

export default CollectorStatus;
