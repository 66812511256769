//--------------------------------------
//TGD CONFIG
//--------------------------------------

export const DEMO = false;

export const DEV_MODE = true;

export const ONLINE_MODE = false;

export const INSTRUMENTS_MODE = false;

//--------------------------------------
//Development
//--------------------------------------

// export const URL =  `http://192.168.1.8:5050` //DEVELOPMENT BACKEND

//--------------------------------------
//Testing back
//--------------------------------------

//export const URL = 'https://backtesting.tgdcompany.com' // TESTING BACKEND

export const TESTING = "https://backofficebacktesting.tgdcompany.com"; //TESTING BACKOFFICE BACKEND

//export const URL = 'https://stibacktesting.tgdcompany.com' // STI BACKEND

//--------------------------------------
// SENSORING
//--------------------------------------

export const SENSORING_URL = "https://basso.tgdcompany.com"; //BASSO PRODUCTION NODE RED

//export const SENSORING_URL = 'http://192.168.1.3:1880' //DEVELOPMENT NODE RED

//--------------------------------------
//Production
//--------------------------------------

// export const URL = 'https://java.tgdcompany.com' //PRODUCTION BACKEND

export const URL = DEMO ? TESTING : DEV_MODE ? TESTING : "http://localhost:8000"; // PRODUCTION LOCAL BACKEND

//--------------------------------------
//PowerBI
//--------------------------------------

export const PBI_URL = `https://api.powerbi.com/v1.0/myorg`; //powerBi Api endpoints

export const PBI_TOKEN_URL = ONLINE_MODE
  ? //onlinemode query it self for power bi data
    URL
  : //offlinemode query provisional database on tgd cloud
    "https://backofficebacktesting.tgdcompany.com";

//--------------------------------------
//Vertion config
//--------------------------------------

type company = string;
type version = string;
type serviceDescription = string;

/*
-------------------------------------------------------
------Versioning arrangement:--------------------------
-------------------------------------------------------
*/

/**
 *versionProd.versionTesting.versionDev.versionLocal
 */

export const SOFT_VERSION: [company, version, serviceDescription][] = [
  ["TGD", `3.4.3.1`, "TGD SOFT"],
  ["STI", "1.3.1", "STI SENSORING"],
];
