import { EnumSupplyTypes, LocalStateSupply, SupplyStateDimention } from "../model/SupplyInterface"

export const suppliesToObject = (supplies:string[] | undefined ):LocalStateSupply[] | [] => {
    //from string to object
    if(!supplies) return []
    return(
        (supplies.length > 0) 
            ? supplies.map((item:string)=>{
                const supply = item.split('|') as [ EnumSupplyTypes ,string, SupplyStateDimention ]
                return{
                    type:supply[0],
                    supply:supply[1],
                    state:supply[2]
                }
            })
        : []   
    )
}

