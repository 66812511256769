import React from "react";
import DeletePermitionForm from "../DeletePermitionForm";
import { usePermissionsByTagFP } from "../services/service.permission";
import AssignCancellPermissionForm from "../components/AssignCancelMultiplePermissionForm";
import { PermissionInterface } from "../model/PermissionInterface";
import CUPermissionForm from "../CUPermissionsForm";
import { usePermissionsGridModel } from "../lang/usePermissionsGridModel";
import { useSessionContext } from "../../session/store/sessionContext";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { CrudFormType } from "../../../shared/form/types/FormTypes";
import { CarrousellActionInterface } from "../../../shared/react-table/VirtualGrid/types/VirtualGridTypes";
import VirtualGrid from "../../../shared/react-table/VirtualGrid/VirtualGrid";

interface ManagePermissionsPageProps {
  virtualGridWidth: string;
}

const ManagePermissionsPage: React.FC<ManagePermissionsPageProps> = ({ virtualGridWidth }) => {
  const { lang } = useLangLabels();
  const { tagFP } = useSessionContext();
  const { data: permissions, isFetching } = usePermissionsByTagFP();

  const carrousellComponents: CarrousellActionInterface = [
    {
      icon: "edit",
      popoverTitle: lang.crudActions.update,
      component: (item: PermissionInterface) => (
        <CUPermissionForm {...{ item, actionType: CrudFormType.update, isDataUpdated: !isFetching }} />
      ),
    },
  ];

  return (
    <VirtualGrid
      {...{
        width: virtualGridWidth,
        title: lang.permissions.plural,
        entityName: lang.permissions.plural,
        items: permissions,
        gridModel: usePermissionsGridModel(),
        carrousellComponents,
        headerActions: (item: any) => (
          <>
            <AssignCancellPermissionForm />
            <CUPermissionForm {...{ tagFP, actionType: CrudFormType.create }} />
          </>
        ),
        itemActions: (item) => <DeletePermitionForm item={item} />,
      }}
    />
  );
};

export default ManagePermissionsPage;
