import { GridModelInterface } from "../../../shared/gridModel/gridModelEntity"
import { useLangLabels } from "../../../shared/lang/services/useLangLabels"


export const useAnomaliesGridModel = () => {
    
    const {lang} = useLangLabels()

    const gridModel:GridModelInterface = {
        id:{label:lang.globalentityValues.id}, 
        anomaly:{label:lang.anomalies.anomalyName}, 
        tagFP:{label:lang.globalentityValues.tagFP}, 
        date:{label:lang.globalentityValues.date}, 
    } 
    
    return gridModel
}