import { useEffect, useMemo, useState } from "react"
import { QueryStatus } from "react-query"
import { apiCall, mutateApiCall } from "../../../shared/axios/axios-config"
import { objectMapper } from "../../../shared/globals/utils/objectMapper"
import { PrivateQueryData, PrivateQueryTagFPInterface } from "../../../shared/globals/utilsGlobalTypes"
import { useStatusProcessor } from "../../../shared/queries/StatusProcessor"
import { useMutation, useQuery } from "../../../shared/react-query/react-query-conf"
import { updateDependencies } from "../../../shared/react-query/utils/updateDependencies"
import { useGetAllAuxByPlantData } from "../../allAux/services/allAuxServices"
import { EquipmentInterface, LubricationPointInterface } from "../../lubricationPoints/model/lubricationPointsInterface"
import { useDailyLubricationPointsWithInfo } from "../../lubricationPoints/services/lubricationPointsAdapters"
import { PersonInterface } from "../../person/models/PersonInterface"
import { useSessionContext } from "../../session/store/sessionContext"
import { useToken } from "../../session/store/sessionStore"
import { PermissionInterface, PermissionsEquipments } from "../model/PermissionInterface"



export const PermissionCRUDDependencies = [
    'EquipmentsPermissionsByTagFP',
    'PermissionsByEquipment',
    'PlantAux',
]

//----------------------------
//Axios
//----------------------------

export const CreatePermission = ({data,token}:PrivateQueryData) => {
    return mutateApiCall({
        method:'POST',
        url:'/CreatePermission',
        headers:{
            Authorization: `Bearer ${token}`
        },
        data
    })
}

export const UpdatePermission = ({data,token}:PrivateQueryData) => {
    return mutateApiCall({
        method:'POST',
        url:'/UpdatePermission',
        headers:{
            Authorization: `Bearer ${token}`
        },
        data
    })

}

export const DeletePermission = ({data,token}:PrivateQueryData) => {
    return mutateApiCall({
        method:'POST',
        url:'/DeletePermission',
        headers:{
            Authorization: `Bearer ${token}`
        },
        data
    })
}

export const AssignCancelPermission = ({data,token}:PrivateQueryData) => {
    return mutateApiCall({
        method:'POST',
        url:'/AssignCancelPermission',
        headers:{
            Authorization: `Bearer ${token}`
        },
        data
    })
}

export const EquipmentsPermissionsByTagFP = ({token,tagFP}:PrivateQueryTagFPInterface) => {
    return apiCall({
        method:'GET',
        url:'/EquipmentsPermissionsByTagFP',
        headers:{
            Authorization:`Bearer ${token}`,
            tagFP:tagFP!
        }
    })
}


export const EquipmentsByAssignedPermission = ({data,token}:PrivateQueryData) => {
    return apiCall({
        method:'POST',
        url:'/EquipmentsByAssignedPermission',
        headers:{
            Authorization:`Bearer ${token}`,
        },
        data
    })
    
}

export const PermissionsByEquipment = ({data,token}:PrivateQueryData) => {
    return apiCall({
        method:'POST',
        url:'/PermissionsByEquipment',
        headers:{
            Authorization:`Bearer ${token}`,
        },
        data
    })
}

export const PermissionsForEquipments = ({data,token}:PrivateQueryData) => {
    return apiCall({
        method: "post",
        url:`/PermissionsForEquipments`,
        data,
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}

//----------------------------
//ReactQuery
//----------------------------

export const useEquipmentsByAssignedPermission = (permission:PermissionInterface | any ) =>{
    const token = useToken()
    const data = {
        name:permission?.permissionName,
        tagFP:permission?.tagFP
    }

    return useQuery<LubricationPointInterface[]>({
        queryKey:'EquipmentsByAssignedPermission',
        queryFn:()=>EquipmentsByAssignedPermission({data,token:token!}),
        enabled:!!token && !!permission
    })
}

export const usePermissionsByTagFP = () => {
    return useGetAllAuxByPlantData('permissions')
}

export const useEquipmentsPermissionsByTagFP = () => {
    const {currentPlant,token} = useSessionContext()
    const tagFP = currentPlant?.tagFP!
    return useQuery<PermissionsEquipments[]>({
        queryKey:'EquipmentsPermissionsByTagFP',
        queryFn:()=>EquipmentsPermissionsByTagFP({tagFP,token:token!}),
        enabled:!!tagFP
    })
}

export const usePermisionsByEquipment = (tagTGD?:string) => {
    const {token,currentPlant} = useSessionContext()
    const tagFP = currentPlant?.tagFP
    return useQuery<PermissionInterface[]>({
        queryKey:'PermissionsByEquipment',
        queryFn:()=>PermissionsByEquipment({token:token!,data:{tagFP,tagTGD}})
    })
}



//---------------------------------------- 
//QUERIES
//----------------------------------------

export const usePermissionsForEquipments= (_lubricationPoints?:any[]) => {
    const token = useToken()

    const lubricationPoints = useMemo(()=> _lubricationPoints?.map((point)=>({
        ...point,
        supplies:JSON.stringify(point.supplies),
    })),[_lubricationPoints])

    return useQuery<PermissionInterface[]>({
        queryKey:'PermissionsForEquipments',
        queryFn:()=>PermissionsForEquipments({data:lubricationPoints,token}),
        enabled:!!lubricationPoints && !!(lubricationPoints.length > 0 ) && !!token,
        staleTime:1000
    })
}

export const useEquipmentsPermitionsByTagFP = () => {
    const {token,tagFP} = useSessionContext()
    return useQuery({
        queryKey:'EquipmentsPermissionsByTagFP',
        queryFn:()=>EquipmentsPermissionsByTagFP({tagFP,token}),
        enabled:!!tagFP && !!token,
        staleTime:1
    })
}

export const useDailyPermissions = (lubricationPoints?:LubricationPointInterface[]) => {
/*     const lubricatorData:any = useOperatorDailyData(operator)
 */    //const {data:lubricationPoints} = useDailyLubricationPointsWithInfo(operator) 

    const lubricationPointsPermissions = usePermissionsForEquipments(lubricationPoints)
    const {data:equipmentsPermissions} = useEquipmentsPermitionsByTagFP()
    const equipmentsPermissionsMapp = useMemo(()=>objectMapper(equipmentsPermissions,'permissionName'),[equipmentsPermissions])
    
    const dailyPermissions = {
        ...lubricationPointsPermissions,
        data:lubricationPointsPermissions?.data?.map((permission)=>({
            permission,
            lubricationPoints:lubricationPoints?.filter((point:any)=>point.tagTGD === (equipmentsPermissionsMapp[permission.permissionName]?.equipment))
        }))
    }

    return dailyPermissions

}

//----------------------------
//Mutation
//----------------------------

export const useAssignCancelPermission = () => {
    const token = useToken()
    const query = useMutation(AssignCancelPermission,{
        onSuccess:()=>updateDependencies(PermissionCRUDDependencies)
    })

    const status = useStatusProcessor(query)

    const assignCancelPermission =  (data:PermissionsEquipments | PermissionsEquipments[]) => {
        if(token)
        return query.mutate({
            token,
            data
        })
    }

    return {
        assignCancelPermission,
        ...query,
        ...status
    }
}



export const useCreatePermission = () => {
    const token = useToken()
    const query = useMutation(CreatePermission,{
        onSuccess:()=>updateDependencies(PermissionCRUDDependencies)
    })

    const status = useStatusProcessor(query)

    const createPermision = (data:PermissionInterface) => {
        return query.mutate({
            data,
            token
        })
    }

    return{
        createPermision,
        ...query,
        ...status
    }
}


export const useUpdatePermission = () => {
    const token = useToken()
    const query = useMutation(UpdatePermission,{
        onSuccess:()=>updateDependencies(PermissionCRUDDependencies)
    })

    const status = useStatusProcessor(query)
    const updatePermission = (data:PermissionInterface) => {
        return query.mutate({
            data,
            token
        })
    }

    return{
        updatePermission,
        ...query,
        ...status
    }
}


export const useAssignCancelMultiplePermissions = () => {

    const {assignCancelPermission,status,data,reset} = useAssignCancelPermission()
    const [assignationItems,setAssignationItems] = useState<PermissionsEquipments[]>([])
  
    const handleAssigCancel = (equipments:/* EquipmentInterface[] */LubricationPointInterface[],permissions:PermissionsEquipments[],state:'A'|'C') => {
        setAssignationItems([])
        equipments.forEach(e => {
            permissions.forEach(p => {
                setAssignationItems((assignationItems) =>[
                        ...assignationItems,
                        {
                            id:p.id,
                            permissionName:p.permissionName,
                            equipment:e.tagTGD,
                            state:state,
                            tagFP:e.tagFP
                        }
                ])
            })
        })
    }

    useEffect(() => {
        if(assignationItems.length > 0){
            assignCancelPermission(assignationItems)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[assignationItems])
    
    return{
        handleAssigCancel,
        status,
        objectQuery:assignationItems,
        data,
        reset
    }
}



//----------------------------
//CustomHooks
//----------------------------

//----------------------------
//delete
//---------------------------------------------
interface DeletePermissionErrors {
    equipments?:EquipmentInterface[] | undefined | null
}

export const useDeletePermission = () => {
    return useMutation(DeletePermission,{
        onSuccess:()=>updateDependencies(PermissionCRUDDependencies)
    })
}



export const useDeletePermissionVerification = () => {

    const token = useToken()
    
    const query = useDeletePermission()
    const {mutate:deleteItem,data} =query
    const [status, setStatus] = useState<QueryStatus>('idle')
    const [validationElement,setValidationElement] = useState<PermissionInterface | {} | undefined >()
    const {data:equipmentsByPermissions,status:equipmentsByPermissionsStatus,remove:removeEquipmentsByPermission} = useEquipmentsByAssignedPermission(validationElement)
    const [errors, setErrors] = useState<DeletePermissionErrors>({})

    const handleRemoveValidationData = ()=> {
        removeEquipmentsByPermission()
    }

    const validate = (item:PermissionInterface) => {
        setStatus('loading')
        setErrors({})
        handleRemoveValidationData()
        setValidationElement(item)
    }

    const handleDelete= () =>{
        if(equipmentsByPermissionsStatus === 'success'){
            if((equipmentsByPermissions && equipmentsByPermissions.length > 0)) {
                setErrors({equipments:equipmentsByPermissions})
                setStatus('error')
                setValidationElement(undefined)
            }
            else{
                deleteItem({
                    data:validationElement,
                    token
                })
                setStatus('success')
                setValidationElement(undefined)
            }
        }
        if(equipmentsByPermissionsStatus === 'loading'){
            setStatus('loading')
        }
    }

    
    useEffect(() => {
        validationElement && handleDelete()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[equipmentsByPermissionsStatus])

    return  {
        errors,
        status,
        validate,
        query
    }
}
