import React, { useEffect, useState } from "react";
import SelectSimpleEntity from "../../../../components/autocomplete/old/SelectSimpleEntity";
import { useCategoriesAndSubcategoriesByTagFP } from "../../categories/service.category";
import { SubCategoryInterface } from "../subCategoryInterface";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

interface Props {
  parentCategory: string;
  handleSelect: (value: SubCategoryInterface) => void;
  errors: string;
}

const SelectCategory: React.FC<Props> = ({ handleSelect, errors, parentCategory }) => {
  const { data: categories } = useCategoriesAndSubcategoriesByTagFP();
  const subCategories = categories?.filter(
    (category) => category.subcategories && category.category === parentCategory
  )[0]?.subcategories;
  const [state, setstate] = useState("");
  const selectedSubcategory = subCategories?.filter(
    (subcategory) => subcategory.subcategory === state
  )[0];

  useEffect(() => {
    if (selectedSubcategory) {
      handleSelect(selectedSubcategory);
    }
  }, [selectedSubcategory]);

  const { lang } = useLangLabels();

  return (
    <SelectSimpleEntity
      onChange={setstate}
      label={errors || lang.select.subcategory}
      color={errors ? "primary" : "secondary"}
      entityList={subCategories || []}
      showTitle="subcategory"
    />
  );
};

export default React.memo(SelectCategory);
