import theme from "../../../../assets/theme";

export enum TaskType {
  inspection = "INSPECCION",
  lubrication = "LUBRICACION",
  cleaning = "LIMPIEZA",
  repairment = "REPARACION",
  puntuation = "PUNTUACION",
  intervention = "INTERVENCION",
  control = "CONTROL",
}

export type TaskTypeT =
  | "INSPECCION"
  | "LUBRICACION"
  | "LIMPIEZA"
  | "REPARACION"
  | "PUNTUACION"
  | "INTERVENCION"
  | "CONTROL";

export const SurveyTasksValues = {
  inspection: TaskType.inspection,
  lubrication: TaskType.lubrication,
  cleaning: TaskType.cleaning,
  repairment: TaskType.repairment,
  puntuation: TaskType.puntuation,
  intervention: TaskType.intervention,
  control: TaskType.control,
};

type SurveyTasks = {
  type: TaskType;
  color: string;
};

export const surveyTasks: SurveyTasks[] = [
  {
    type: SurveyTasksValues.intervention,
    color: theme.palette.secondary.main,
  },
  {
    type: SurveyTasksValues.inspection,
    color: theme.palette.secondary.main,
  },
  {
    type: SurveyTasksValues.lubrication,
    color: theme.palette.primary.main,
  },
  {
    type: SurveyTasksValues.cleaning,
    color: theme.palette.info.light,
  },
  {
    type: SurveyTasksValues.repairment,
    color: theme.palette.info.main,
  },
  {
    type: SurveyTasksValues.puntuation,
    color: theme.palette.success.main,
  },
];
