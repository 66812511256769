import React from "react";
import { CrudFormType } from "../../../../shared/form/types/FormTypes";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { useUser } from "../../../../domains/session/store/sessionStore";
import CreateUpdateModelForm from "./CreateUpdateModelForm";
import { useGetAllModels } from "../services/modelService";
import SimpleAutocompleteSelect from "../../../../components/autocomplete/SimpleAutocompleteSelect";

interface SelectModelProps {
  onChange: (value: any) => void;
  defaultValue?: string;
  error?: string;
  disabled?: boolean;
}

const SelectModel: React.FC<SelectModelProps> = ({ onChange, defaultValue, error, disabled }) => {
  const { lang } = useLangLabels();
  const { data: user } = useUser();

  const { data: models } = useGetAllModels();

  const modelOptions = models?.map((model: any) => ({ label: model.model || "" })) || [];

  return (
    <SimpleAutocompleteSelect
      error={error}
      items={modelOptions}
      defaultValue={defaultValue}
      label={lang.instruments.model}
      onChange={onChange}
      showTitle={"label"}
      disabled={disabled}
    >
      {user?.type !== "O" && <CreateUpdateModelForm actionType={CrudFormType.create} />}
    </SimpleAutocompleteSelect>
  );
};

export default SelectModel;
