import React, { useState } from 'react';
import SelectSimpleEntity from '../../components/autocomplete/old/SelectSimpleEntity';
import CustomButton from '../../components/buttons/CustomButton';
import FlexContainer from '../../components/containers/FlexContainer';
import StatusAndMessage from '../../shared/queries/errors/components/StatusAndMessage';
import { PersonInterface, PersonRoleTypes } from '../person/models/PersonInterface';
import { useAssignCancelClusterCompany, useAllClusterCompanies } from './services/clusterEnterpricesService';


interface Props{
    person:PersonInterface
}

const SelectCompanyService:React.FC<Props> = ({person}) => {

    const { assignCompany, cancelCompany, status, error, message } = useAssignCancelClusterCompany()
    const { data:companies } = useAllClusterCompanies()

    const [companyToAssign, setcompanies] = useState<any>();

    const handleSubmit = (type:'A'|'C') =>{ 

        type === 'A' && companyToAssign.map((company:any)=>
        assignCompany({
                email:person.email,
                company:company.company
            })
        )

        type === 'C' && companyToAssign.map((company:any)=>
        cancelCompany({
                email:person.email,
                company:company.company
            })
        )
    }

    return (person.role === PersonRoleTypes.admin  || person.role === PersonRoleTypes.accountMannager || person.role === PersonRoleTypes.superAdmin ? <div>

        <StatusAndMessage {...{status,message,error}} />
        <FlexContainer >
            <SelectSimpleEntity
                width='250px'
                label='Gestionar Servicios'
                entityList={companies || []}
                showTitle='businessName'
                onChange={(value:any)=>setcompanies(value)}
                multiple={true}
                complete
            />
            <CustomButton {...{status,error}} popoverTitle='Asignar' action={() => handleSubmit('A')} icon='check' variant='iconButton' />
            <CustomButton {...{status,error}} popoverTitle='Desasignar' action={() => handleSubmit('C')} icon='close' variant='iconButton' />
        </FlexContainer>
    </div> : null);
}

export default SelectCompanyService;
