import { INSTRUMENTS_MODE } from "../../../shared/config/appConfig";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

export const useSupervisorData = () => {
  const { lang } = useLangLabels();

  if (INSTRUMENTS_MODE) {
    return {
      menu: [
        {
          id: "0",
          type: "index",
          name: lang.instruments.title.plural,
          route: "",
          icon: "home",
          offline: true,
        },
        {
          id: "1",
          type: "config",
          name: lang.instruments.configuration,
          route: "config",
          icon: "config",
          offline: true,
        },
      ],
    };
  }

  return {
    menu: [
      {
        id: "0",
        type: "index",
        name: lang.sidebarMenu.home,
        route: "",
        icon: "home",
        offline: true,
      },
      {
        id: "1",
        type: "explore",
        name: lang.sidebarMenu.explore,
        route: "explorar",
        icon: "search",
        offline: true,
      },
      {
        id: "2",
        type: "reports",
        name: lang.sidebarMenu.reports,
        route: "reportes",
        icon: "assignment",
        offline: true,
      },
      {
        id: "3",
        type: "calendar",
        name: lang.sidebarMenu.calendar,
        route: "calendario",
        icon: "calendar",
        offline: true,
      },
      {
        id: "4",
        type: "knowledge-base",
        name: lang.sidebarMenu.knowledgeBase,
        route: "base-de-conocimiento",
        icon: "storage",
        offline: true,
      },
      {
        id: "5",
        type: "notifications",
        name: lang.sidebarMenu.notifications,
        route: "notificaciones-planta",
        icon: "notifications",
        offline: true,
      },
      {
        id: "6",
        type: "income-log",
        name: lang.sidebarMenu.lubricationSection,
        route: "sala-lubricacion",
        icon: "stock",
        offline: true,
      },
      {
        id: "7",
        type: "plant-config",
        name: lang.sidebarMenu.plantConfig,
        route: "gestionar-planta",
        icon: "config",
        offline: true,
      },
      {
        id: "8",
        type: "client-panel",
        name: lang.sidebarMenu.client,
        route: "visor-de-cliente",
        icon: "view",
        offline: true,
      },
    ],
  };
};
