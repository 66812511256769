import Axios,{ AxiosRequestConfig } from "axios"
import { mutateApiCall } from "../../../shared/axios/axios-config"
import { PrivateQueryData } from "../../../shared/globals/utilsGlobalTypes"
import { useStatusProcessor } from "../../../shared/queries/StatusProcessor"
import { queryClient, useMutation } from "../../../shared/react-query/react-query-conf"
import { useGetAllAuxByPlantData } from "../../allAux/services/allAuxServices"
import { useToken } from "../../session/store/sessionStore"
import { AddedReasonInterface } from "../models/AdedReasonTypes"


export const AddedReasonCRUDDependencies = [
    'PlantAux'
]
//----------------------------
//Axios
//----------------------------


export const CreateAddedReason = ({data,token}:PrivateQueryData) => {
    return mutateApiCall({
        method: "post",
        url:`/CreateAddedReason`,
        headers:{
            Authorization: `Bearer ${token}`
        },
        data
    })
}

export const UpdateAddedReason = ({data,token}:PrivateQueryData) => {
    return mutateApiCall({
        method: "post",
        url:`/UpdateAddedReason`,
        headers:{
            Authorization: `Bearer ${token}`
        },
        data
    })
    
}

export const DeleteAddedReason = ({data,token}:PrivateQueryData) => {
    return mutateApiCall({
        method: "post",
        url:`/DeleteAddedReason`,
        headers:{
            Authorization: `Bearer ${token}`
        },
        data
    })
}


//----------------------------
//ReactQuery
//----------------------------

export const usePlantAddedReasons = (tagFP?:string) => {
    return useGetAllAuxByPlantData('addedReasons')
}


//----------------------------
//Mutation
//----------------------------

export const useCreateAddedReason = () => {
    const token = useToken()
    const query = useMutation(CreateAddedReason,{
        onSuccess:()=> {
            queryClient.invalidateQueries('PlantAux')
        }
    })

    const createAddedReason = (data:AddedReasonInterface) => {
        return query.mutate({
            data,
            token
        })
    }

    const status = useStatusProcessor(query)

    return{
        createAddedReason,
        query:{
            ...query,
            ...status
        }
    }
    
}


export const useUpdateAddedReason = () => {
    const token = useToken()
    const query = useMutation(UpdateAddedReason,{
        onSuccess:()=> {
            queryClient.invalidateQueries('PlantAux')
        }
    })

    const updateAddedReason = (data:AddedReasonInterface) => {
        return query.mutate({
            data,
            token
        })
    }
    const status = useStatusProcessor(query)

    return{
        updateAddedReason,
        query:{
            ...query,
            ...status
        }
    }

}

export const useDeleteAddedReason = () => {
    const token = useToken()
    const query = useMutation(DeleteAddedReason,{
        onSuccess:()=> {
            queryClient.invalidateQueries('PlantAux')
        }
    })

    const deleteAddedReason = (data:AddedReasonInterface) => {
        return query.mutate({
            data,
            token
        })
    }
    const status = useStatusProcessor(query)

    return{
        deleteAddedReason,
        query:{
            ...query,
            ...status
        }
    }

}


