import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useCallback, useState } from "react";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import Portal from "../Portal";

export interface HandleUndefinedProps {
  showError: boolean;
  callback: () => void;
  children?: (children: () => void) => React.ReactNode;
}

const HandleUndefined: React.FC<HandleUndefinedProps> = ({
  showError,
  children,
  callback,
}) => {
  const { lang } = useLangLabels();
  const [snackbar, setSnackbar] = useState<string | undefined>();

  const handleClick = () => {
    showError ? setSnackbar(lang.messages.nothingSelected) : callback();
  };

  const onClose = useCallback(() => {
    setSnackbar(undefined);
  }, []);

  return (
    <>
      {children && children(handleClick)}
      <Portal>
        <Snackbar
          open={!!snackbar}
          onClose={onClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert variant="filled" severity={"error"}>
            {snackbar}
          </Alert>
        </Snackbar>
      </Portal>
    </>
  );
};

export default HandleUndefined;
