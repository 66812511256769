import { Divider, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { QueryStatus } from "react-query";
import CustomButton from "../../../../../../../components/buttons/CustomButton";
import DoNotDoButton from "../../../../../../../components/buttons/DoNotDoButton";
import TgdFab from "../../../../../../../components/buttons/TgdFab";
import FlexContainer from "../../../../../../../components/containers/FlexContainer";
import PageContainer from "../../../../../../../components/containers/PageContainer";
import { useDate } from "../../../../../../../shared/date/useDate";
import { bakupAddSurveyWithObservations } from "../../../../../../../shared/dexie-js/backup/backupRepository";
import { LubricationPointInterface } from "../../../../../../lubricationPoints/model/lubricationPointsInterface";
import AddObservation from "../../../../../../observations/components/AddObservation";
import { ObservationInterface } from "../../../../../../observations/models/ObservationTypes";
import AddSupply from "../../../../../../supply/molecules/AddSupply";
import { suppliesToString } from "../../../../../../supply/utils/supplyToString";
import { useLubricationPointSurveyController } from "../../../../../services/controllers/useLubricationPointSurveyController";
import { TaskType } from "../../../../../tasks/model/TaskInterface";
import { CustomIcon } from "../../../../../../../components/buttons/CustomIcon";
import { useLangLabels } from "../../../../../../../shared/lang/services/useLangLabels";

interface SurveyCleanProps {
  item: LubricationPointInterface;
  taskType: TaskType;
  statusController: (status: QueryStatus) => void;
  outOfRoute: boolean;
}

const SurveyClean: React.FC<SurveyCleanProps> = ({
  item,
  taskType,
  statusController,
  outOfRoute,
}) => {
  const { lang } = useLangLabels();
  const { register, handleSubmit, errors } = useForm();
  const { isToday } = useDate();

  // Survey Data
  const {
    survey,
    setTaskTime,
    nextTaskStep,
    setTaskDone,
    createSurvey,
    createObservation,
    backTaskStep,
    updateEquipmentInfo,
  } = useLubricationPointSurveyController(item, outOfRoute);

  const observations = survey?.observations || [];
  const taskDone = survey?.cleaning.taskDone;
  const taskTime = survey?.cleaning.taskTime;
  const supplies = survey?.neededSupplies || [];

  // Component State
  const [step, setStep] = useState<any>();

  // Components Methods
  useEffect(() => {
    register({ name: "taskDone", value: taskDone });
    register(
      { name: "taskTime", value: taskTime },
      {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
        validate: (value) => value > 0 || lang.validations.messages.required,
      }
    );
  }, [taskTime, taskDone, register]);

  const submit = () => {
    statusController("loading");
    const { surveyDone: lubricationSurveyDone, ...restLubrication } = survey?.lubrication || {};
    const { surveyDone: cleaningSurveyDone, ...restCleaning } = survey?.cleaning || {};

    const newSurvey: any = {
      ...survey?.inspection,
      ...restLubrication,
      ...restCleaning,

      // Task Data
      taskType: taskType,
      taskDone: !!taskDone,
      taskTime: `${taskTime || 0}`,
      endTime: isToday(survey?.inspection.startTime!)
        ? new Date().toISOString()
        : survey?.inspection.startTime!,

      // Lubrication Data
      lubricates: survey?.lubrication.lubricates || false,
      sampleExtraction: false,
      consumables: JSON.stringify(suppliesToString(supplies)),
    };

    const newObservations = (id: number) => {
      return [
        ...observations.map((observation: ObservationInterface) => ({
          ...observation,
          surveyId: id,
        })),
      ];
    };

    bakupAddSurveyWithObservations(newSurvey, newObservations);

    createSurvey(newSurvey)
      .then((surveyData) => {
        if (survey) {
          survey.cleaning.surveyDone = true;
        }

        createObservation(newObservations(surveyData.id)).then((observationData) => {
          if (observationData.error) {
            statusController("error");
          } else {
            updateEquipmentInfo({
              ...item.info,
              lastInspectionDate: survey?.inspection.startTime!,
              lastLubrication: !!survey?.lubrication.lubricates
                ? survey?.inspection.startTime!
                : item?.info?.lastLubrication!,
              updateReview:
                survey?.inspection.updateReview === false
                  ? item.info?.updateReview
                  : survey?.inspection.updateReview!,
              labelReplacement:
                survey?.inspection.labelReplacement === false
                  ? item.info?.labelReplacement
                  : survey?.inspection.labelReplacement!,
              lastSurveyId: survey?.lastSurveyId ?? item.info?.lastSurveyId,
              lubricantQuantity: survey?.lubrication.quantity ?? item.info?.lubricantQuantity,
            } as any).then(async (updateData) => {
              if (updateData.error) {
                statusController("error");
              } else {
                statusController("success");
                nextTaskStep(taskType);
              }
            });
          }
        });
      })
      .catch((err) => {
        statusController("error");
        console.error(err);
      });
  };

  return (
    <>
      {outOfRoute && (
        <div>
          <CustomButton
            action={() => backTaskStep(taskType)}
            icon="before"
            variant="iconButton"
            title={lang.actionTypes.before}
          />
        </div>
      )}

      {taskDone === undefined && (
        <FlexContainer justify="center" align="center" gap="24px" height="100%" width="100%">
          <DoNotDoButton
            action={submit}
            icon="close"
            title={lang.surveys.tasks.cleaning.noCleaning}
          />
          <DoNotDoButton
            action={() => setTaskDone(taskType, true)}
            icon="check"
            title={lang.surveys.tasks.cleaning.title}
          />
        </FlexContainer>
      )}

      {!outOfRoute && taskDone === true && (
        <div>
          <CustomButton
            action={() => setTaskDone(taskType, false)}
            icon="cancelOutlined"
            variant="iconButton"
            popoverTitle={lang.surveys.tasks.cleaning.cancelCleaning}
          />
        </div>
      )}

      {taskDone && (
        <>
          <PageContainer>
            <TextField
              variant="outlined"
              style={{ width: "100%" }}
              type="number"
              name={"taskTime"}
              defaultValue={taskTime}
              onChange={(event) => {
                setTaskTime(taskType, JSON.parse(event.target.value));
              }}
              label={
                `${lang.surveys.taskTime} ${errors?.taskTime?.message || ""}` ||
                lang.surveys.taskTime
              }
              helperText={lang.surveys.inMinutes}
              color={errors?.taskTime?.message ? "primary" : "secondary"}
              inputRef={register({
                required: {
                  value: taskDone,
                  message: lang.validations.messages.required,
                },
              })}
            />
          </PageContainer>
          <br />
          <Divider />
          <br />
          <FlexContainer justify="flex-end">
            {!step?.observation && survey?.observations?.length === 0 && (
              <CustomButton
                icon="close"
                action={() => setStep({ observation: lang.surveys.tasks.noObservations })}
              >
                {lang.surveys.tasks.noObservations}
              </CustomButton>
            )}

            <AddObservation
              outOfRoute={outOfRoute}
              taskType={taskType}
              lubricationPoint={item}
              type="OBSERVACION"
              buttonTitle={lang.observations.plural}
              formTitle={lang.actionTypes.add + " " + lang.observations.singular}
              variant={step?.observation || survey?.observations?.length !== 0 ? "list" : "modal"}
              listTitle={step?.observation || lang.observations.plural}
              buttonType="add"
            />
          </FlexContainer>

          <AddSupply
            outOfRoute={outOfRoute}
            listTitle={lang.supplies.title.plural}
            formTitle={lang.actionTypes.new + " " + lang.supplies.title.singular}
            buttonTitle={lang.supplies.title.plural}
            variant={"list"}
            buttonType={"add"}
            lubricationPoint={item}
          />
          <br />
          {(step?.observation || survey?.observations?.length !== 0) && (
            <TgdFab
              bottom={4}
              right={4}
              color="primary"
              onClick={handleSubmit(submit)}
              children={<CustomIcon icon="next" />}
            />
          )}
        </>
      )}
    </>
  );
};

export default React.memo(SurveyClean);
