import React from "react";
import {
  Button,
  CircularProgress,
  createStyles,
  FormHelperText,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Controller, useForm } from "react-hook-form";
import {
  equipmentCreateSuggestedQuantityFactory,
  EquipmentInterface,
  LubricationPointInterface,
} from "../model/lubricationPointsInterface";
import { useAllAuxByPlant } from "../../allAux/services/allAuxServices";
import { useCreateEquipment } from "../services/lubricationPointsAdapters";
import SelectElement from "../../elements/molecules/SelectElement";
import SelectComponent from "../../components/components/SelectComponent";
import SelectLubricant from "../../lubricant/molecules/SelectLubricant";
import { EquipmentFormRegex } from "../../../shared/regExp/regExp";
import SelectTypeBy from "../../types/components/SelectTypeBy";
import SelectSimpleEntity from "../../../components/autocomplete/old/SelectSimpleEntity";
import { useSessionContext } from "../../session/store/sessionContext";
import { enumTgdTypes } from "../../types/models/types";
import SelectSuggestedQuantity from "./actions/SelectSuggestedQuantity";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

const useStyles = makeStyles((theme) =>
  createStyles({
    formGrid: {
      display: "flex",
      /* gap: '16px',*/
      flexWrap: "wrap",
      flexDirection: "column",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

interface Props {
  equipment: EquipmentInterface | null | undefined;
  defaultState: LubricationPointInterface;
  points: LubricationPointInterface[] | undefined | null;
  handleState: any;
}

const UpdateEquipmentForm: React.FC<Props> = ({ points, equipment, defaultState, handleState }) => {
  const { lang } = useLangLabels();
  const classes = useStyles();
  const { tagFP } = useSessionContext();
  const { data: plantAux } = useAllAuxByPlant();
  const elements = plantAux?.elements;
  const components = plantAux?.components;
  const lubricants = plantAux?.lubricants;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { error, status, data } = useCreateEquipment();
  const { register, handleSubmit, setValue, watch, control, errors } = useForm({
    shouldUnregister: false,
  });

  const selectedLubricant = lubricants?.find((l: any) => l.lubricant === watch().lubricant);

  const pointVerificationArray = (array: any) => {
    var iterators: string[] = [];

    array
      .filter((i: LubricationPointInterface) => i.id !== defaultState.id)
      .forEach((i: any) => {
        iterators.push(i.component);
        iterators.push(i.element);
      });

    if (iterators?.includes(watch("element"))) {
      if (iterators?.includes(watch("component"))) return false;
      else return true;
    }
  };

  const onSubmit = (body: LubricationPointInterface) => {
    if (points) {
      const itemToUpdateIndex = points.findIndex((item) => item.frontId === defaultState.frontId);

      if (itemToUpdateIndex === -1) {
        return;
      }

      const updatedItem = {
        ...defaultState,
        /* Equipment */
        tagTGD: equipment?.tagTGD,
        sector: equipment?.sector,
        equipment: equipment?.equipment,
        plantTag: equipment?.plantTag,
        tagFP: equipment?.tagFP,
        type: equipment?.type,
        criticality: equipment?.criticality,
        /* LubricationPoint */
        brand: body.brand,
        model: body.model,
        capacity: body.capacity,
        component: body.component,
        element: body.element,
        function: body.function,
        location: body.location,
        lubricant: body.lubricant,
        lubricantType: lubricants?.find((l) => l.lubricant === body.lubricant)?.type ?? "",
        measureUnit: lubricants?.find((l) => l.lubricant === body.lubricant)?.measureUnit ?? "",
        observations: body.observations || lang.stringDefaultValues.notSpecified.toUpperCase(),
        oilAnalysis: body.oilAnalysis === "SI",
        /* lowReason: body.lowReason, */
        /* labelId: body.labelId, */
        /* state: true, */
        /* egressDate: '', */
        /* tools: '', */
        /* admissionDate: '', */
      };

      const newLubricationPoints = [
        ...points.slice(0, itemToUpdateIndex),
        updatedItem,
        ...points.slice(itemToUpdateIndex + 1),
      ];

      handleState(newLubricationPoints);
    }
  };

  if (status === "loading") return <CircularProgress size="20px" color="primary" />;
  return (
    <form className={classes.formGrid} onSubmit={handleSubmit(onSubmit)}>
      {error && (
        <Alert severity="error">
          {lang.messages.anErrorHasOccurred}: {error}{" "}
        </Alert>
      )}
      {data && status === "success" && data === "Existe el equipo" && (
        <>
          <Alert severity="warning">{lang.validations.messages.allReadyExist}</Alert>
          <br />
        </>
      )}
      {data && status === "success" && data !== "Ya existe" && (
        <>
          <Alert severity="success">
            {lang.STIEquipments.form.messages.successfullyCreatedEquipment}
          </Alert>
          <br />
        </>
      )}

      <TextField
        inputRef={register({
          required: { value: true, message: lang.validations.messages.required },
        })}
        name="brand"
        placeholder={lang.STIEquipments.form.label.brand}
        defaultValue={defaultState?.brand}
        variant="outlined"
        label={errors?.brand?.message || lang.STIEquipments.form.label.brand}
        color={errors?.brand?.message ? "primary" : "secondary"}
        size="small"
      />
      <br />

      <TextField
        inputRef={register({
          required: { value: true, message: lang.validations.messages.required },
        })}
        name="model"
        placeholder={lang.STIEquipments.form.label.model}
        defaultValue={defaultState?.model}
        variant="outlined"
        label={errors?.model?.message || lang.STIEquipments.form.label.model}
        color={errors?.model?.message ? "primary" : "secondary"}
        size="small"
      />
      <br />

      <TextField
        inputRef={register()}
        name="function"
        placeholder={lang.STIEquipments.form.label.function}
        defaultValue={defaultState?.function}
        variant="outlined"
        label={errors?.function?.message || lang.STIEquipments.form.label.function}
        color={errors?.function?.message ? "primary" : "secondary"}
        size="small"
      />
      <br />

      <TextField
        inputRef={register()}
        name="location"
        placeholder={lang.STIEquipments.form.label.location}
        defaultValue={defaultState?.location}
        variant="outlined"
        label={errors?.location?.message || lang.STIEquipments.form.label.location}
        color={errors?.location?.message ? "primary" : "secondary"}
        size="small"
      />
      <br />

      <TextField
        inputRef={register()}
        name="observations"
        placeholder={lang.STIEquipments.form.label.observations}
        defaultValue={defaultState?.observations}
        variant="outlined"
        label={lang.STIEquipments.form.label.observations}
        color={"secondary"}
        size="small"
      />
      <br />

      <Controller
        as={
          <SelectElement
            error={errors.element?.message}
            tagFP={tagFP}
            onChange={(value) => setValue("element", value)}
            defaultValue={watch("element", defaultState?.element)}
          />
        }
        name="element"
        defaultValue={defaultState?.element}
        rules={{
          required: { value: true, message: lang.validations.messages.required },
        }}
        control={control}
      />
      {errors?.element?.message && (
        <FormHelperText error>{errors?.element?.message}</FormHelperText>
      )}
      <br />

      <Controller
        as={
          <SelectComponent
            error={errors.component?.message}
            tagFP={tagFP}
            onChange={(value: string) => setValue("component", value)}
            defaultValue={watch("component", defaultState?.component)}
          />
        }
        name="component"
        defaultValue={defaultState?.component}
        rules={{
          required: { value: true, message: lang.validations.messages.required },
          validate: { exist: () => pointVerificationArray(points) },
        }}
        control={control}
      />
      {errors?.component?.message && (
        <FormHelperText error>{errors?.component?.message}</FormHelperText>
      )}
      {errors?.component?.type && <FormHelperText error>Ya existe</FormHelperText>}
      <br />

      <Controller
        as={
          <SelectLubricant
            error={errors.lubricant?.message}
            tagFP={tagFP}
            onChange={(value) => setValue("lubricant", value)}
            defaultValue={watch("lubricant", defaultState?.lubricant)}
          />
        }
        name="lubricant"
        defaultValue={defaultState.lubricant}
        rules={{ required: { value: true, message: lang.validations.messages.required } }}
        control={control}
      />
      {errors?.lubricant?.message && (
        <FormHelperText error>{errors?.lubricant?.message}</FormHelperText>
      )}
      <br />

      <TextField
        inputRef={register({
          required: { value: true, message: lang.validations.messages.required },
          pattern: {
            value: EquipmentFormRegex.float,
            message: lang.validations.messages.justNumbers,
          },
        })}
        name="capacity"
        placeholder={lang.lubricationPoints.lubricantCapacity}
        defaultValue={defaultState?.capacity}
        variant="outlined"
        label={errors?.capacity?.message || lang.lubricationPoints.lubricantCapacity}
        color={errors?.capacity?.message ? "primary" : "secondary"}
        size="small"
      />
      <br />

      <Controller
        as={
          <SelectTypeBy
            error={errors?.measureUnit?.message}
            by={{ key: "type", value: enumTgdTypes.unity }}
            tagFP={tagFP}
            onChange={(value) => setValue("measureUnit", value)}
            defaultValue={watch("measureUnit")}
          />
        }
        name="measureUnit"
        defaultValue={defaultState.measureUnit}
        control={control}
      />
      {errors?.measureUnit?.message && (
        <FormHelperText error>{errors?.measureUnit?.message}</FormHelperText>
      )}
      <br />

      <Controller
        as={
          <SelectSimpleEntity
            label={lang.lubricationPoints.oilAnalysis}
            entityList={[
              { label: "SI", value: true },
              { label: "NO", value: false },
            ]}
            showTitle={"label"}
            onChange={setValue}
            defaultValue={watch("oilAnalysis", defaultState?.oilAnalysis)}
          />
        }
        name="oilAnalysis"
        defaultValue={defaultState.oilAnalysis ? "SI" : "NO"}
        rules={{ required: { value: true, message: lang.validations.messages.required } }}
        control={control}
      />
      <br />
      <Controller
        as={
          <SelectSuggestedQuantity
            defaultValue={`0 ${selectedLubricant?.measureUnit ?? ""}`}
            onChange={(value) => setValue("suggestedQuantity", value)}
          />
        }
        name="suggestedQuantity"
        rules={{ required: { value: true, message: lang.validations.messages.required } }}
        control={control}
      />

      {errors?.oilAnalisis?.message && (
        <FormHelperText error>{errors?.oilAnalisis?.message}</FormHelperText>
      )}
      <br />

      <Button type="submit" variant="outlined" color="secondary">
        {" "}
        {lang.crudActions.update}
      </Button>
    </form>
  );
};

export default UpdateEquipmentForm;
