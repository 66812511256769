import React from 'react'
import CustomButton from '../../buttons/CustomButton'
import { Divider } from '../../Divider'
import Title from "../../Typography/Title"
import FlexContainer from "../FlexContainer"
import PageContainer from "../PageContainer"


export interface CarrousellProps{
    current: number;
        handleNext: () => void;
        handlePrev: () => void;
        canPrev: boolean;
        canNext: boolean;
        itemsCount: number;
        title?:string
}

const CarrousellToolbar:React.FC<CarrousellProps> = ({
    children,
    canPrev,
    canNext,
    itemsCount,
    current,
    handlePrev,
    handleNext,
    title
    
}) => {
    return(<>
        <PageContainer padding='8px 16px'>
            <FlexContainer justify='space-between'>
                <FlexContainer align='center'>
                    <CustomButton disabled={!canPrev} variant='iconButton' icon='before' action={handlePrev}/>
                    <Title>{`${current + 1}/${itemsCount}`}</Title>
                    <Title>{title || ''}</Title>
                </FlexContainer>
                <CustomButton disabled={!canNext} variant='iconButton' icon='next' action={handleNext}/>
            </FlexContainer>
        </PageContainer>
        <Divider/>
        <PageContainer padding={'0px 24px'} width='100%'>
            {children}
        </PageContainer>
    </>)
} 

export default CarrousellToolbar