import styled from "@emotion/styled";
import { ModalBackdropProps } from "../types/ModalTypes";

export const ModalBackdrop = styled("div")<ModalBackdropProps>`
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 1101)};
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  left: 0px;
  transition: all 0.17s ease-in;
  :hover {
    cursor: pointer;
  }
`;
