import { Divider, Typography } from "@material-ui/core";
import { FlexContainer } from "../../../../../components/containers";
import { SurveyInterface } from "../../../model/SurveyInterface";
import { surveyTaskColor } from "../../../utils/surveyTaskColor";
import { Lubrication } from "./Lubrication";
import { useLangLabels } from "../../../../../shared/lang/services/useLangLabels";

const taskTypeComponents: { [key: string]: React.FC<{ item: any }> } = {
  CONTROL: () => <></>,
  LUBRICACION: Lubrication,
  LIMPIEZA: () => <></>,
  REPARACION: () => <></>,
};

export const TaskItem: React.FC<{ item?: SurveyInterface }> = ({ item }) => {
  const { lang } = useLangLabels();
  const taskType = item?.taskType;
  const TaskComponent = taskType ? taskTypeComponents[taskType] : () => <></>;

  return (
    <>
      <FlexContainer width="100%" justify={"space-between"} align="center">
        <FlexContainer
          width="100%"
          justify={"space-between"}
          background={surveyTaskColor(item?.taskType)}
          color="#FFF"
          padding="0 16px"
        >
          <Typography variant="h6">{item?.taskType}</Typography>
          <Typography variant={"h6"}>ID: {item?.id}</Typography>
        </FlexContainer>
      </FlexContainer>

      <br />

      <TaskComponent item={item} />

      <br />
      <Divider />
      <br />

      <Typography>
        {lang.surveys.taskTime}: {item?.taskTime} min
      </Typography>

      <br />
      <Divider />
      <br />

      <Typography>
        {lang.surveys.consumablesUsed}: {item?.consumables}
      </Typography>
    </>
  );
};
