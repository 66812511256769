import LossItem from "./LossItem";
import PageBodyContent from "./PageBodyContent";
import {
  Page,
  PageBody,
  PageBodyVariableContent,
  PageFooter,
  PageFooterContent,
  PageHeader,
  PageHeaderContent,
} from "../../../../../components/print-pdf/components";
import useReactPDFState from "../../../../../components/print-pdf/hooks/useReactPDFState";
import { LossReportInterface } from "../types/LossReportTypes";
import TotalLossComponent from "./TotalLossComponent";

interface ContainerProps {
  data: {
    reports: LossReportInterface[];
    totalLeakSector: number | "NaN";
  };
  otherProps?: Record<string, any>;
}

const Container: React.FC<ContainerProps> = ({ data, otherProps }) => {
  const {
    pageState,
    lastDataPosition,
    handleNextDataPosition,
    handlePrevDataPosition,
    handleSkipPage,
    handlePageStateDataPush,
    handlePageStateDataPop,
  } = useReactPDFState<LossReportInterface>();

  const reports = data?.reports || [];
  const numberOfItems = reports.length;
  const totalLeak = data?.totalLeakSector !== "NaN" ? data?.totalLeakSector : 0;

  return (
    <>
      {pageState.map((singlePage, index) => {
        return (
          <Page<LossReportInterface>
            key={index}
            data={reports}
            pageIndex={singlePage.pageIndex}
            pageData={singlePage.pageData}
            lastDataPosition={lastDataPosition}
            handleNextDataPosition={handleNextDataPosition}
            handlePrevDataPosition={handlePrevDataPosition}
            handleSkipPage={handleSkipPage}
            handlePageStateDataPush={handlePageStateDataPush}
            handlePageStateDataPop={handlePageStateDataPop}
            otherProps={otherProps}
          >
            {({
              variableContentRef,
              pageIndex,
              pageData,
              handleVariableSizeChange,
              handleTriggerOnResizing,
              otherProps,
            }) => (
              <>
                <PageHeader>
                  <PageHeaderContent />
                </PageHeader>
                <PageBody>
                  <PageBodyContent
                    pageIndex={pageIndex}
                    otherProps={{ ...otherProps, numberOfItems }}
                  />
                  <PageBodyVariableContent contentRef={variableContentRef}>
                    {pageData.map((pageDataItem, index) => {
                      return (
                        <div key={index}>
                          <LossItem
                            key={index}
                            data={pageDataItem}
                            handleVariableSizeChange={handleVariableSizeChange}
                            handleTriggerOnResizing={handleTriggerOnResizing}
                            style={
                              index !== pageData.length - 1
                                ? {
                                    paddingTop: "20px",
                                    borderBottom: "2px solid #D9D9D9",
                                  }
                                : { paddingTop: "20px" }
                            }
                            onItemRendered={otherProps?.onItemRendered}
                            onItemUnrendered={otherProps?.onItemUnrendered}
                          />
                          <TotalLossComponent
                            isLastitem={
                              pageIndex === pageState.length - 1 && pageData.length - 1 === index
                            }
                            totalLeak={totalLeak}
                            sector={otherProps?.sector}
                          />
                        </div>
                      );
                    })}
                  </PageBodyVariableContent>
                </PageBody>
                <PageFooter>
                  <PageFooterContent />
                </PageFooter>
              </>
            )}
          </Page>
        );
      })}
    </>
  );
};

export default Container;
