import { CrudFormType } from "../../../shared/form/types/FormTypes";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import CUObservationForm from "../CUObservationForm";
import DeleteObservationForm from "../DeleteObservationForm";
import { useObservationsGridModel } from "../lang/useObservationsGridModel";
import { ObservationInterface } from "../models/ObservationTypes";
import {
  InfoSurveyInterface,
  SurveyInterface,
} from "../../surveys/model/SurveyInterface";
import VirtualGrid from "../../../shared/react-table/VirtualGrid/VirtualGrid";

interface SurveyObservationsProps {
  virtualGridWidth?: string;
  virtualGridHeight?: number;
  observations: ObservationInterface[];
  contained?: boolean;
  survey?: SurveyInterface & InfoSurveyInterface;
}

const SurveyObservations: React.FC<SurveyObservationsProps> = ({
  virtualGridWidth,
  virtualGridHeight,
  observations,
  contained,
  survey,
}) => {
  const { lang } = useLangLabels();
  const gridModel = useObservationsGridModel();

  const createObservationData =
    survey !== undefined
      ? {
          type: "OBSERVACION",
          taskType: survey.taskType,
          equipment: survey.equipment,
          tagTGD: survey.tagTGD,
          surveyId: survey.id,
          anomaly: survey.anomalies ?? JSON.stringify([]),
          date: new Date(),
        }
      : undefined;

  return (
    <VirtualGrid
      {...{
        width: virtualGridWidth ?? "100%",
        height: virtualGridHeight ?? undefined,
        contained: contained ?? true,
        title: lang.observations.plural,
        items: observations,
        gridModel,
        itemActions: (item) => [
          <DeleteObservationForm observation={item} />,
          <CUObservationForm
            item={item}
            actionType={CrudFormType.update}
            mode="modal"
          />,
        ],
        headerActions: (item: any) =>
          survey ? (
            <CUObservationForm
              actionType={CrudFormType.create}
              observationData={createObservationData}
            />
          ) : (
            <></>
          ),
      }}
    />
  );
};

export default SurveyObservations;
