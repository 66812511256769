import RouterTabs, {
  TabsInterface,
  useRouteTabProps,
} from "../../../components/containers/tabs/RouterTabs";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { USER_ROUTES } from "../../../shared/routes/routes";
import PendingRevisionChanges from "./components/PendingRevisionChanges";
import SupervisedRevisionChanges from "./components/SupervisedRevisionChanges";

const RevisionChangesPage = () => {
  const { lang } = useLangLabels();

  const tabs: TabsInterface[] = [
    {
      label: lang.revisionChange.pending,
      route: `${USER_ROUTES.revisionChange.pending}/*`,
      component: <PendingRevisionChanges />,
    },
    {
      label: lang.revisionChange.supervised,
      route: `${USER_ROUTES.revisionChange.supervised}/*`,
      component: <SupervisedRevisionChanges />,
    },
  ];

  const tabProps = useRouteTabProps(5, tabs);

  return <RouterTabs {...tabProps} />;
};

export default RevisionChangesPage;
