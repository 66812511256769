import { useCallback } from "react";
import { QueryStatus } from "react-query";
import { useLocalStorage } from "../../../../shared/localStorage/useLocalStorage";
import { CriticalityInterface } from "../../../criticalities/models/criticalityInterface";
import { useUpdateEquipmentInfo } from "../../../infoLubricationPoints/service/infoLubricationPointsService";
import { LubricationPointInterface } from "../../../lubricationPoints/model/lubricationPointsInterface";
import {
  ObservationInterface,
  ObservationType,
} from "../../../observations/models/ObservationTypes";
import { useCreateObservations } from "../../../observations/services/observationServices";
import { useUser } from "../../../session/store/sessionStore";
import { LocalStateSupply } from "../../../supply/model/SupplyInterface";
import { LubricationSurveyControlerInterface } from "../../model/SurveyInterface";
import { SurveyTasksValues, TaskType } from "../../tasks/model/TaskInterface";
import { useCreateSurvey } from "../surveyServices";
import useGetInitialState from "./useGetInitialState";

type TaskResult = "lubrication" | "cleaning" | "repairment" | "puntuation";

const taskMap = {
  [SurveyTasksValues.lubrication]: "lubrication",
  [SurveyTasksValues.cleaning]: "cleaning",
  [SurveyTasksValues.repairment]: "repairment",
  [SurveyTasksValues.puntuation]: "puntuation",
};

const surveyTask = (task: TaskType): TaskResult =>
  (taskMap[task] as TaskResult) || "lubrication";

export const useLubricationPointSurveyController = (
  item?: LubricationPointInterface,
  isOutOfRoute?: boolean
) => {
  const { data: user } = useUser();
  const outOfRoute = isOutOfRoute || false;

  const initialState = useGetInitialState(outOfRoute, item);

  const [survey, setSurvey] =
    useLocalStorage<LubricationSurveyControlerInterface>(
      `${item?.tagTGD}-${outOfRoute}`,
      initialState,
      {
        enabled: !!user?.email && !!item,
      }
    );

  const _loop = survey?.loop || 0;

  const { createSurvey } = useCreateSurvey();
  const { updateEquipmentInfo } = useUpdateEquipmentInfo();
  const { createObservation } = useCreateObservations();

  // Setters

  const setStartTime = useCallback(
    (startTime: string) => {
      setSurvey({
        ...survey,
        inspection: {
          ...survey?.inspection,
          startTime: new Date(startTime).toISOString(),
        },
      });
    },
    [survey, setSurvey]
  );

  const setGlobalStatus = (globalStatus: QueryStatus) => {
    setSurvey({
      ...survey,
      globalStatus,
    });
  };

  const setInaccessible = useCallback(
    (inaccessible: boolean) => {
      setSurvey({
        ...survey,
        inspection: {
          ...survey?.inspection,
          inaccessible: inaccessible,
        },
      });
    },
    [survey, setSurvey]
  );

  const setAssetCriticality = (value: CriticalityInterface) => {
    setSurvey({
      ...survey,
      puntuation: {
        assetCriticality: value.description,
      },
    });
  };

  const setNewComponent = useCallback(
    (value: boolean) => {
      setSurvey({
        ...survey,
        inspection: {
          ...survey?.inspection,
          newComponent: value,
        },
      });
    },
    [survey, setSurvey]
  );

  const setNeedsLubrication = useCallback(
    (value: boolean) => {
      setSurvey({
        ...survey,
        inspection: {
          ...survey?.inspection,
          needsLubrication: value,
        },
      });
    },
    [survey, setSurvey]
  );

  const setLabelReplacement = useCallback(
    (value: boolean) => {
      setSurvey({
        ...survey,
        inspection: {
          ...survey?.inspection,
          labelReplacement: value,
        },
      });
    },
    [survey, setSurvey]
  );

  const setLeak = useCallback(
    (value: boolean) => {
      setSurvey({
        ...survey,
        inspection: {
          ...survey?.inspection,
          leak: value,
        },
      });
    },
    [survey, setSurvey]
  );

  const setTemperature = useCallback(
    (value: string) => {
      setSurvey({
        ...survey,
        inspection: {
          ...survey?.inspection,
          temperature: value,
        },
      });
    },
    [survey, setSurvey]
  );

  const setLubricant = useCallback(
    (value: string) => {
      setSurvey({
        ...survey,
        lubrication: {
          ...survey?.lubrication,
          lubricant: value,
        },
      });
    },
    [survey, setSurvey]
  );

  const setQuantity = (value: number) => {
    setSurvey({
      ...survey,
      lubrication: {
        ...survey?.lubrication,
        quantity: value,
      },
    });
  };

  const setTaskDone = useCallback(
    (task: TaskType, value: boolean) => {
      const taskSelector = surveyTask(task);
      const nextTaskStem =
        value === true ? {} : { taskStep: survey ? survey?.taskStep + 1 : 0 };

      setSurvey({
        ...survey,
        ...nextTaskStem,
        [taskSelector]: {
          ...(survey ? survey[taskSelector] : {}),
          taskDone: value,
        },
      });
    },
    [survey, setSurvey]
  );

  const setLubricate = (lubricates: boolean) => {
    setSurvey({
      ...survey,
      lubrication: {
        ...survey?.lubrication,
        impossibleToLubricate: false,
        taskDone: lubricates,
        taskTime: 0,
        quantity: 0,
        lubricates: lubricates,
      },
      observations: [],
    });
  };

  const setImpossibleToLubricate = () => {
    setSurvey({
      ...survey,
      lubrication: {
        ...survey?.lubrication,
        impossibleToLubricate: true,
        taskDone: true,
        lubricates: false,
      },
      observations: [],
    });
  };

  const setTaskTime = (task: TaskType, taskTime: number) => {
    const taskSelector = surveyTask(task);
    setSurvey({
      ...survey,
      [taskSelector]: {
        ...(survey ? survey[taskSelector] : {}),
        taskTime,
      },
    });
  };

  const setAddedReason = (value: string) => {
    setSurvey({
      ...survey,
      lubrication: {
        ...survey?.lubrication,
        addedReason: value,
      },
    });
  };

  const setNeededSupplies = (value: LocalStateSupply) => {
    setSurvey({
      ...survey,
      neededSupplies: [...(survey ? survey?.neededSupplies : []), value],
    });
  };

  // Steps

  const finishPointOnRoute = useCallback(() => {
    setSurvey({
      ...survey,
      taskStep: 0,
      customStep: 0,
    });
  }, [survey, setSurvey]);

  const handleFinishOutOfRoute = useCallback(() => {
    survey?.inspectionComplete
      ? setSurvey({
          ...survey,
          taskStep: 5,
        })
      : setSurvey({
          ...survey,
          taskStep: 1,
          customStep: 5,
        });
  }, [survey, setSurvey]);

  const nextTaskStep = useCallback(
    (task: TaskType, customStep?: number) => {
      if (outOfRoute) {
        switch (task) {
          case SurveyTasksValues.intervention:
            setSurvey({
              ...survey,
              taskStep: _loop > 0 ? customStep : 1,
              customStep,
            });
            break;

          case SurveyTasksValues.inspection:
            setSurvey({
              ...survey,
              inspection: {
                ...survey?.inspection,
                updateReview: survey?.inspection.newComponent,
              },
              inspectionComplete: true,
              taskStep: survey?.inspection.inaccessible
                ? 0
                : survey?.customStep,
              loop: 1,
            });
            break;

          case SurveyTasksValues.lubrication:
            setSurvey({
              ...survey,
              observations: [],
              neededSupplies: [],
              taskStep: 0,
              loop: 1,
            });
            break;

          case SurveyTasksValues.cleaning:
            setSurvey({
              ...survey,
              observations: [],
              neededSupplies: [],
              taskStep: 0,
              loop: 1,
            });
            break;

          case SurveyTasksValues.repairment:
            setSurvey({
              ...survey,
              observations: [],
              neededSupplies: [],
              taskStep: 0,
              loop: 1,
            });
            break;
        }
      } else {
        switch (task) {
          case SurveyTasksValues.inspection:
            if (survey?.inspection.inaccessible) {
              setSurvey({
                ...survey,
                inspection: {
                  ...survey.inspection,
                  inaccessible: undefined,
                },
                taskStep: 1,
              });
            } else {
              setSurvey({
                ...survey,
                inspection: {
                  ...survey?.inspection,
                  updateReview: survey?.inspection.newComponent,
                },
                inspectionComplete: true,
                taskStep: (survey?.taskStep || 1) + 1,
              });
            }
            break;

          case SurveyTasksValues.lubrication:
            console.log((survey?.taskStep || 1) + 1);
            setSurvey({
              ...survey,
              observations: [],
              neededSupplies: [],
              taskStep: (survey?.taskStep || 1) + 1,
            });
            break;

          case SurveyTasksValues.cleaning:
            setSurvey({
              ...survey,
              observations: [],
              neededSupplies: [],
              taskStep: (survey?.taskStep || 1) + 1,
            });
            break;

          case SurveyTasksValues.repairment:
            setSurvey({
              ...survey,
              observations: [],
              neededSupplies: [],
              taskStep: (survey?.taskStep || 1) + 1,
            });
            break;
        }
      }
    },
    [survey, setSurvey]
  );

  const backTaskStep = useCallback(
    (task?: TaskType) => {
      if (outOfRoute) {
        switch (task) {
          case SurveyTasksValues.inspection:
            const _inaccesible = survey?.inspection.inaccessible;
            setSurvey({
              ...survey,
              inspection: initialState.inspection,
              taskStep: _inaccesible === undefined ? 0 : 1,
            });
            break;

          case SurveyTasksValues.lubrication:
            setSurvey((prevSurvey: LubricationSurveyControlerInterface) => {
              const newSurvey = {
                ...survey,
                taskStep: _loop > 0 ? 0 : 1,
              };

              prevSurvey.lubrication.surveyDone
                ? (newSurvey.lubrication = prevSurvey?.lubrication)
                : (newSurvey.lubrication = initialState.lubrication);

              return newSurvey;
            });
            break;

          case SurveyTasksValues.cleaning:
            setSurvey((prevSurvey: LubricationSurveyControlerInterface) => {
              const newSurvey = {
                ...survey,
                taskStep: _loop > 0 ? 0 : 1,
              };

              prevSurvey.cleaning.surveyDone
                ? (newSurvey.cleaning = prevSurvey?.cleaning)
                : (newSurvey.cleaning = initialState.cleaning);

              return newSurvey;
            });
            break;

          case SurveyTasksValues.repairment:
            setSurvey((prevSurvey: LubricationSurveyControlerInterface) => {
              const newSurvey = {
                ...survey,
                taskStep: _loop > 0 ? 0 : 1,
              };

              prevSurvey.repairment.surveyDone
                ? (newSurvey.repairment = prevSurvey?.repairment)
                : (newSurvey.repairment = initialState.repairment);

              return newSurvey;
            });
            break;

          case SurveyTasksValues.puntuation:
            setSurvey({
              ...survey,
              taskStep: _loop > 0 ? 0 : 1,
            });
            break;
        }
      } else {
        switch (task) {
          case SurveyTasksValues.inspection:
            setSurvey(initialState);
            break;
          case SurveyTasksValues.lubrication:
            setSurvey({
              ...survey,
              taskStep: survey ? survey.taskStep - 1 : 0,
              setInspectionComplete: false,
              lubrication: {
                ...initialState.lubrication,
              },
              observations: [],
            });
            break;
          case SurveyTasksValues.cleaning:
            break;

          case SurveyTasksValues.repairment:
            break;
        }
      }
    },
    [survey, setSurvey]
  );

  // Observations

  const addObservation = useCallback(
    (observation: ObservationInterface) => {
      setSurvey({
        ...survey,
        observations: [...(survey ? survey.observations : []), observation],
      });
    },
    [survey, setSurvey]
  );

  const addAnomaly = useCallback(
    (anomaly: ObservationInterface) => {
      setSurvey({
        ...survey,
        anomalies: [...(survey ? survey.anomalies : []), anomaly],
      });
    },
    [survey, setSurvey]
  );

  const deleteObservation = useCallback(
    ({ type, deleteIndex }: { type: ObservationType; deleteIndex: number }) => {
      const anomalies = survey?.anomalies.filter(
        (item, index) => index !== deleteIndex
      );
      const observations = survey?.observations.filter(
        (item, index) => index !== deleteIndex
      );

      switch (type) {
        case "ANOMALIA":
          setSurvey({
            ...survey,
            anomalies,
          });
          break;
        default:
          setSurvey({
            ...survey,
            observations,
          });
          break;
      }
    },
    [setSurvey, survey]
  );

  // Others

  const deleteSupply = (deleteIndex: number) => {
    setSurvey({
      ...survey,
      neededSupplies: survey?.neededSupplies.filter(
        (item, index) => index !== deleteIndex
      ),
    });
  };

  const resetSurvey = () => {
    if (outOfRoute) {
      setSurvey(initialState);
    }
  };

  return {
    survey,
    setLubricate,
    setAssetCriticality,
    backTaskStep,
    setInaccessible,
    setNewComponent,
    setNeedsLubrication,
    setLabelReplacement,
    setLeak,
    setTemperature,
    addObservation,
    addAnomaly,
    deleteObservation,
    nextTaskStep,
    setTaskDone,
    setLubricant,
    setImpossibleToLubricate,
    setQuantity,
    setTaskTime,
    setAddedReason,
    setNeededSupplies,
    deleteSupply,
    createSurvey,
    updateEquipmentInfo,
    createObservation,
    handleFinishOutOfRoute,
    finishPointOnRoute,
    resetSurvey,
    setGlobalStatus,
    setStartTime,
  };
};
