import { CreateSurveyInterface } from "../../../../domains/surveys/model/SurveyInterface"
import { getViewDateDay } from "../../../date/utils"
import { backupDb } from "../backup-db"

export const bakupAddSurveys = async (survey: CreateSurveyInterface) =>{
    try{
        return backupDb.surveys.add({
            ...survey,
            backupDate:getViewDateDay(new Date().toISOString())
        })
    }catch (err){
        console.log(err)
    }
}