
import { lubricatorState, lubricatorStateOptions } from "./lubricatorEntity"

export const lubricatorStoreFactory = (lubricatorState?:lubricatorStateOptions):lubricatorState => {
    return {
        user:lubricatorState?.user || undefined,
        status:lubricatorState?.status || 'idle',
        message:lubricatorState?.message || '',
        operator: lubricatorState?.operator || undefined ,
        dailyRoutes: lubricatorState?.dailyRoutes || [] ,
        dailyPoints: lubricatorState?.dailyPoints || [],
        todayPendingLubricationPoints: lubricatorState?.todayPendingLubricationPoints || [] ,
        previusPendingLubricationPoints: lubricatorState?.previusPendingLubricationPoints || [] ,
        allDayPoints: lubricatorState?.allDayPoints || [],
        permissions: lubricatorState?.permissions || [],
        selectedpermissions: lubricatorState?.selectedpermissions || [] ,
        lubricants: lubricatorState?.lubricants || [],
        selectedLubricants: lubricatorState?.selectedLubricants || [] ,
        supplies: lubricatorState?.supplies || [],
        allDayPendingPoints:lubricatorState?.allDayPendingPoints || []
    }
}