import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  Grid,
  ListItemText,
  makeStyles,
  Paper,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSessionContext } from "../../session/store/sessionContext";
import { useModal } from "../../../components/containers/modal/Modal/hooks/useModal";
import {
  EquipmentInterface,
  equipmentType,
  LubricationPointInterface,
} from "../model/lubricationPointsInterface";
import { useCreateEquipment } from "../services/lubricationPointsAdapters";
import CustomButton from "../../../components/buttons/CustomButton";
import FullscreenSurface from "../../../components/containers/modal/FullScreenSurface/FullscreenSurface";
import PageContainer from "../../../components/containers/PageContainer";
import { Alert } from "@material-ui/lab";
import TgdAuxList from "../../../components/grids/TgdAuxList";
import UpdateEquipmentForm from "./UpdateEquipmentForm";
import CreateEquipmentForm from "./CreateEquipmentForm";
import { Plant } from "../../plants/PlantInterface";
import EquipmentVerification from "./EquipmentVerification";
import FlexContainer from "../../../components/containers/FlexContainer";
import { CustomIcon } from "../../../components/buttons/CustomIcon";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    root: {
      width: "100%",
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  })
);

interface Props {}

const CreateEquipmentPage: React.FC<Props> = ({}) => {
  const classes = useStyles();
  const { token, currentPlant: plant } = useSessionContext();
  const { isOpen: isModalSurfaceOpen, toggleModal: toggleSurfaceModal } = useModal();
  const [activeStep, setActiveStep] = React.useState(0);
  const [newEquipment, setNewEquipment] = useState<EquipmentInterface | undefined | null>();
  const [lubricationPoints, setLubricationPoints] = useState<
    LubricationPointInterface[] | undefined | null
  >([]);
  const { isOpen, toggleModal: handleLubricationPointModal } = useModal();

  const { mutate: createEquipment, status, data, error, reset } = useCreateEquipment();
  const { lang } = useLangLabels();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleCreateEquipment = () => {
    if (lubricationPoints?.length) {
      createEquipment({
        data: lubricationPoints,
        token,
      });
    } else {
      alert(lang.STIEquipments.form.messages.mustLoadAtLeastOneEquipment);
    }
  };

  const handleRemoveItem = (e: any) => {
    const restLubricationPoints = lubricationPoints?.filter((item) => item.frontId !== e.frontId);

    setLubricationPoints(restLubricationPoints);
  };

  const handleRefresh = () => {
    var action = window.confirm(lang.STIEquipments.form.messages.sureToResetTemplateData);
    if (action) {
      setNewEquipment(undefined);
      setLubricationPoints([]);
      reset();
      handleLubricationPointModal();
    }
  };

  useEffect(() => {
    if (!!newEquipment) {
      handleLubricationPointModal();
    }
  }, [newEquipment]);

  return (
    <>
      <CustomButton
        icon="add"
        action={toggleSurfaceModal}
        popoverTitle={lang.crudActions.create + " " + lang.lubricationPoints.equipment}
      />
      <FullscreenSurface isOpen={isModalSurfaceOpen}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <CustomButton
              icon="close"
              color="inherit"
              action={toggleSurfaceModal}
              variant="iconButton"
            />
            <Typography variant="h6" className={classes.title}>
              {lang.STIEquipments.form.messages.createAndConfigureNewEquipment}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleCreateEquipment}>
              {lang.crudActions.save}
            </Button>
          </Toolbar>
        </AppBar>

        <PageContainer>
          <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            {status === "loading" && (
              <FlexContainer width={"100%"} justify="center" align={"center"}>
                <CircularProgress size="40px" />
              </FlexContainer>
            )}

            {!(status === "loading") && (
              <>
                <Box>
                  {error && (
                    <Alert severity="error" onClose={handleRefresh}>
                      {lang.messages.anErrorHasOccurred}
                    </Alert>
                  )}
                  {status === "success" && data?.length > 0 && (
                    <Alert onClose={handleRefresh} severity={"warning"}>
                      {lang.STIEquipments.form.messages.somePointsCouldNotBeCreated}
                    </Alert>
                  )}
                  {status === "success" && data?.length === 0 && (
                    <Alert onClose={handleRefresh} severity="success">
                      {lang.STIEquipments.form.messages.equipmentLoadedSuccessfully}
                    </Alert>
                  )}
                  <br />
                  <Paper elevation={3} style={{ width: "850px", height: "calc(850px * 1.18)" }}>
                    <PageContainer>
                      <Grid container spacing={3}>
                        <Grid item md={12}>
                          <Typography variant="h6">
                            {newEquipment?.tagFP
                              ? newEquipment?.tagFP.split("|").map((i: string) => `${i} `)
                              : ""}
                          </Typography>
                          <Divider />
                        </Grid>
                        <Grid item md={4}>
                          <Box
                            style={{
                              backgroundColor: "#f6f6f6",
                              maxWidth: "200px",
                              maxHeight: "200px",
                              minHeight: "150px",
                              minWidth: "150px",
                              width: "auto",
                              height: "100%",
                            }}
                          ></Box>
                        </Grid>
                        <Grid item md={8}>
                          <Grid container spacing={3}>
                            <Grid item md={6}>
                              {lang.lubricationPoints.equipmentName}:
                              <Typography variant="body2">
                                <strong>{newEquipment?.equipment}</strong>
                              </Typography>
                              <Divider />
                              <br />
                              {lang.lubricationPoints.equipmentTag}:
                              <Typography variant="body2">
                                <strong>{newEquipment?.tagTGD}</strong>
                              </Typography>
                              <Divider />
                            </Grid>
                            <Grid item md={6}>
                              {lang.lubricationPoints.type}:
                              <Typography variant="body2">
                                <strong>{newEquipment?.type}</strong>
                              </Typography>
                              <Divider />
                              <br />
                              {lang.lubricationPoints.sector}:
                              <Typography variant="body2">
                                <strong>{newEquipment?.sector}</strong>
                              </Typography>
                              <Divider />
                            </Grid>
                            <Grid item md={6}>
                              {lang.lubricationPoints.plantTag}:
                              <Typography variant="body2">
                                <strong>{newEquipment?.plantTag}</strong>{" "}
                              </Typography>
                              <Divider />
                            </Grid>
                            <Grid item md={6}>
                              {lang.lubricationPoints.criticality}:
                              <Typography variant="body2">
                                <strong>{newEquipment?.criticality}</strong>
                              </Typography>
                              <Divider />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <br />
                      <Divider />
                      <TgdAuxList
                        customButtons={(e: LubricationPointInterface) => (
                          <>
                            <CustomIcon
                              onClick={() => handleRemoveItem(e)}
                              icon="delete"
                            ></CustomIcon>
                          </>
                        )}
                        title={lang.components.title.plural}
                        searchBar={false}
                        showLabel={equipmentType.equipment}
                        alternativeColumn={(item: LubricationPointInterface) => (
                          <ListItemText
                            style={{ textAlign: "left" }}
                            primary={`${item.element} ${item.component}`}
                          />
                        )}
                        items={lubricationPoints}
                        status={"idle"}
                        children={(item: LubricationPointInterface) => (
                          <>
                            <UpdateEquipmentForm
                              handleState={setLubricationPoints}
                              defaultState={item}
                              points={lubricationPoints}
                              equipment={newEquipment}
                            />
                          </>
                        )}
                        toggleDisabled={false}
                      />
                    </PageContainer>
                  </Paper>
                </Box>
                <div style={{ padding: "12px", display: "flex", flexDirection: "column" }}>
                  {!lubricationPoints?.length && (
                    <>
                      <Fab color={"primary"} onClick={handleLubricationPointModal}>
                        <CustomIcon icon="edit" />
                      </Fab>
                      <br />
                    </>
                  )}

                  {!!newEquipment && (
                    <>
                      <CreateEquipmentForm
                        plant={plant as Plant}
                        points={lubricationPoints || []}
                        handleLubricationPoint={setLubricationPoints}
                        equipment={newEquipment}
                      />
                      <br />
                    </>
                  )}

                  <CustomButton
                    icon="refresh"
                    variant="fab"
                    color="secondary"
                    popoverTitle={lang.STIEquipments.form.messages.resetData}
                    action={handleRefresh}
                  />
                </div>
              </>
            )}
          </div>
        </PageContainer>

        <Dialog open={!isOpen} onClose={handleLubricationPointModal} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            {lang.STIEquipments.form.messages.initialEquipmentConfiguration}
          </DialogTitle>
          <DialogContent>
            <EquipmentVerification
              handleNext={handleNext}
              defaultState={newEquipment}
              handleState={setNewEquipment}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleLubricationPointModal} color="primary">
              {lang.actionTypes.cancel}
            </Button>
          </DialogActions>
        </Dialog>

        {status !== "loading" && (
          <CreateEquipmentForm
            variant="fab"
            plant={plant as Plant}
            points={lubricationPoints || []}
            handleLubricationPoint={setLubricationPoints}
            equipment={newEquipment}
          />
        )}
      </FullscreenSurface>
    </>
  );
};

export default React.memo(CreateEquipmentPage);
