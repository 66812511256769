import { useEffect, useState } from "react"
import SelectSimpleEntity from "../../../components/autocomplete/old/SelectSimpleEntity"

const SelectStockComplexity:React.FC<{
    handleChange:(item:any)=>void,
    error:string,
    defaultValue?:any
}> = ({handleChange,error,defaultValue}) => {

    const [state,setState] = useState()
    const complexities = [
        {
            label:'COMPLEJO',
            value:true
        },
        {
            label:'SIMPLE',
            value:false
        }
    ]

    const selecteditem = complexities.filter((item) => item.label === state)[0]

    useEffect(()=>{
        if(state){
           handleChange(selecteditem.value) 
        }
    },[state])

    return(
        <SelectSimpleEntity
            onChange={(value:any)=> setState(value)}
            label={error ||'abastecimiento'}
            entityList={complexities}
            showTitle={'label'}
            color={error ? 'primary' : 'secondary'}
            defaultValue={defaultValue}
        />
    )
}

export default SelectStockComplexity