import React, { useState, useMemo } from "react";
import { useStockAreaCategorySubcategory } from "../services/service.stock";
import CreateStockForm from "./CreateStockForm";
import UpdateStockForm from "./UpdateStockForm";
import NewStockQuantityForm from "./NewStockQuantityForm";

import HiddenStockForm from "./HiddenStockForm";
import CustomButton from "../../../components/buttons/CustomButton";
import { useStockGridModel } from "../lang/useStockGridModel";
import { SubCategoryInterface } from "../sub-categories/subCategoryInterface";
import VirtualGrid from "../../../shared/react-table/VirtualGrid/VirtualGrid";
import { capitalize } from "../../../shared/globals/utils/capitalize";
import UpdateSubCategoryForm from "../sub-categories/components/UpdateSubCategoryForm";
import CreatePurchaseRequestForm from "../purchase-requests/components/CreatePurchaseRequestForm";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

interface Props {
  subcategory: SubCategoryInterface;
}
const SubcategoryStockGrid: React.FC<Props> = ({ subcategory }) => {
  const { lang } = useLangLabels();
  const [tab, setTab] = useState(false);

  const { data: categoryStocks } = useStockAreaCategorySubcategory(
    useMemo(
      () => ({
        categoryArea: subcategory.categoryArea,
        category: subcategory.category,
        subcategory: subcategory.subcategory,
        tagFP: subcategory.tagFP,
      }),
      [subcategory]
    )
  );

  const subCategoryStocksNotHidden = useMemo(
    () => categoryStocks?.filter((item) => item.hidden === false) || [],
    [categoryStocks]
  );
  const subCategoryStocksHidden = useMemo(
    () => categoryStocks?.filter((item) => item.hidden === true) || [],
    [categoryStocks]
  );

  const ShowArchived = (
    <CustomButton
      popoverTitle={tab ? lang.lubricationSection.seeItems : lang.lubricationSection.seeArchived}
      icon={tab ? "before" : "delete"}
      action={() => setTab(!tab)}
    />
  );

  const data = useMemo(
    () => (tab ? subCategoryStocksHidden : subCategoryStocksNotHidden),
    [subCategoryStocksHidden, subCategoryStocksNotHidden, tab]
  );

  const gridModel = useStockGridModel();

  return (
    <>
      <VirtualGrid
        width="100%"
        gridModel={gridModel}
        items={data}
        headerActions={() => (
          <>
            <UpdateSubCategoryForm defaultValue={subcategory} mode="modal" />
            <CreateStockForm mode="modal" subcategory={subcategory} />
            {ShowArchived}
          </>
        )}
        bulkActions={(items) => <UpdateStockForm items={items} />}
        renderOptionColumn={{
          headerName: "More",
          renderOption: (item) => (
            <>
              <NewStockQuantityForm key={item.id} item={item} />
              <CreatePurchaseRequestForm mode="popover" variant="iconButton" stock={item} />
              <HiddenStockForm item={item} />
            </>
          ),
        }}
        title={tab ? lang.lubricationSection.archived : `${capitalize(subcategory.subcategory)} en stock`}
      />
    </>
  );
};

export default React.memo(SubcategoryStockGrid);
