import { Autocomplete, AutocompleteRenderOptionState } from "@material-ui/lab";
import { useAutocompleteStyles } from "../../../autocomplete/useAutocompleteStyle";
import { FieldOption, SelectedFieldInputProps } from "../types/FieldFilterTypes";
import { Checkbox, TextField } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CustomButton from "../../../buttons/CustomButton";
import { FlexContainer } from "../../../containers";

const SelectedFieldInput = ({
  fieldKey,
  label,
  options,
  selectedValue,
  component,
  handleFilterValueChange,
  onSelectedFieldDelete,
}: SelectedFieldInputProps) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const classes = useAutocompleteStyles(undefined);

  return (
    <FlexContainer justify="center" align="center">
      <FlexContainer width={"100%"}>
        {component === "autocomplete" && (
          <Autocomplete
            value={selectedValue as FieldOption}
            onChange={(e, value) => handleFilterValueChange(fieldKey, value)}
            options={options ?? []}
            getOptionLabel={(option: FieldOption) => option.name}
            getOptionSelected={(option, value) => option.value === value?.value}
            renderInput={(params) => (
              <TextField {...params} label={label} variant="outlined" size="small" />
            )}
            style={{ width: "100%" }}
            classes={{
              option: classes.option,
            }}
          />
        )}
        {component === "checkboxes" && (
          <Autocomplete
            value={(selectedValue as FieldOption[]) ?? []}
            onChange={(e, values) => handleFilterValueChange(fieldKey, values)}
            options={options ?? []}
            getOptionLabel={(option: FieldOption) => option.name}
            getOptionSelected={(option, value) => option.value === value?.value}
            multiple
            disableCloseOnSelect
            renderOption={(option: FieldOption, state: AutocompleteRenderOptionState) => (
              <li {...state}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={state.selected}
                />
                {option.value}
              </li>
            )}
            renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
            style={{ width: "100%" }}
            classes={{
              option: classes.option,
            }}
          />
        )}
      </FlexContainer>

      <CustomButton
        variant="iconButton"
        icon="delete"
        action={() => onSelectedFieldDelete(fieldKey)}
      />
    </FlexContainer>
  );
};

export default SelectedFieldInput;
