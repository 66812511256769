import React, { useMemo } from "react";
import CustomButton from "../../../components/buttons/CustomButton";
import HandleUndefined from "../../../components/containers/handleUndefined/HandleUndefined";
import { flattenObjAllLevels } from "../../globals/utils/dataFiltering";
import { GridModelInterface } from "../../gridModel/gridModelEntity";
import { useExportXlsx } from "../../xlsx/useExportXlsx";

interface DownloadXLSXProps {
  items: any[] | undefined;
  documentTitle?: string;
  gridModel: GridModelInterface;
  visibleColumns: string[];
}

const filterObjectAttr = (object: any, showColumns: string[]): any => {
  return showColumns.reduce((acc, key) => {
    return {
      ...acc,
      [key]: object[key],
    };
  }, {});
};

const DownloadXlsx: React.FC<DownloadXLSXProps> = ({
  items,
  documentTitle,
  gridModel,
  visibleColumns,
}) => {
  const flatted = useMemo(
    () => (!!items ? items.map((item) => flattenObjAllLevels(item)) : []),
    [items]
  );

  const _items = useMemo(
    () =>
      flatted.map((item) => ({
        ...filterObjectAttr(item, visibleColumns),
      })),
    [flatted, visibleColumns]
  );

  const { downloadFile } = useExportXlsx(_items, documentTitle, gridModel);

  return (
    <>
      <HandleUndefined
        showError={!(items && items.length > 0)}
        callback={downloadFile}
      >
        {(callback) => (
          <CustomButton
            icon="download"
            action={callback}
            variant="iconButton"
            color="secondary"
          />
        )}
      </HandleUndefined>
    </>
  );
};

export default React.memo(DownloadXlsx);
