import React from 'react';
import SelectSimpleEntity from '../../../../components/autocomplete/old/SelectSimpleEntity';
import { useCategoriesAndSubcategoriesByTagFP } from '../service.category';
import { useLangLabels } from '../../../../shared/lang/services/useLangLabels';

interface Props{
    handleSelect:(value:string) => void
    errors:string
}

const SelectSubcategoryByCategory:React.FC<Props> = ({handleSelect,errors}) => {
    
    const {data:categories} = useCategoriesAndSubcategoriesByTagFP()
    const { lang } = useLangLabels();
    
    return (
        <SelectSimpleEntity
        onChange={handleSelect}
        label={errors || lang.select.category}
        color={errors ? 'primary' : 'secondary' }
        entityList={categories || []}
        showTitle='category'
        />
    );
}

export default React.memo(SelectSubcategoryByCategory);
