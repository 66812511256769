import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { InputComponentTypes } from "../../../../shared/form/types/FormTypes";
import { useForm } from "../../../../shared/form/useForm";
import { CollectorInterface } from "../../models/CollectorInterfaces";
import { useSessionContext } from "../../../session/store/sessionContext";

export const useCollectorsForm = (collector?: CollectorInterface) => {
  const { tagFP } = useSessionContext();

  const { lang } = useLangLabels();

  const collectorForm = useForm<CollectorInterface>({
    id: {
      value: collector?.id || undefined,
      component: InputComponentTypes.none,
    },
    tagFP: {
      value: tagFP || "",
      component: InputComponentTypes.none,
    },
    collectorCode: {
      value: collector?.collectorCode || undefined,
      component: InputComponentTypes.none,
    },
    name: {
      label: `${lang.collectors.singular} ${lang.collectors.form.name}`,
      value: collector?.name || "",
      placeholder: `${lang.collectors.singular} ${lang.collectors.form.name}`,
      component: InputComponentTypes.text,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    serialNumber: {
      label: `${lang.collectors.singular} ${lang.collectors.form.serialNumber}`,
      value: collector?.serialNumber || "",
      placeholder: `${lang.collectors.singular} ${lang.collectors.form.serialNumber}`,
      component: InputComponentTypes.text,
    },
    state: {
      label: "state",
      value: collector?.state || undefined,
      component: InputComponentTypes.none,
    },
  });

  return collectorForm;
};
