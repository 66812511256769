import { Divider, ListItem, ListItemAvatar, ListItemText, Typography } from "@material-ui/core";
import {
  CarrousellToolbar,
  FlexContainer,
  HandleUndefined,
  Modal,
  useCarrousell,
  useModal,
} from "../../../../components/containers";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import {
  CriticalityInterface,
  CriticalityTypes,
} from "../../../criticalities/models/criticalityInterface";
import { useCriticalityByTypeByTagFP } from "../../../criticalities/services/service.criticality";
import { NewInterface } from "../../../news/model/NewsInterface";
import { CustomIcon } from "../../../../components/buttons/CustomIcon";
import CriticalityIndicator from "../../../criticalities/components/CriticalityIndicator";

interface NewViewProps {
  items: NewInterface[];
}

const NewView = ({ items }: NewViewProps) => {
  const { lang } = useLangLabels();

  const modalProps = useModal();

  const carrousellProps = useCarrousell(items.length);
  const item = items && items.length > 0 ? items[carrousellProps.current] : undefined;

  const { data: criticalities } = useCriticalityByTypeByTagFP(CriticalityTypes.observation);
  const criticality = criticalities?.find(
    (c: CriticalityInterface) => c.description === item?.criticality
  );

  return (
    <HandleUndefined callback={modalProps.openModal} showError={!(items.length > 0)}>
      {(callback) => (
        <Modal
          buttonIcon="view"
          buttonVariant="iconButton"
          buttonPopoverTitle={lang.actionTypes.view}
          {...modalProps}
          openModal={callback}
        >
          <CarrousellToolbar {...carrousellProps} />
          <ListItem>
            <ListItemAvatar>
              <CustomIcon icon="date" />
            </ListItemAvatar>
            <ListItemText primary={item?.type} secondary={item?.newsDate.slice(0, 10)} />
            <ListItemText secondary={`${lang.globalentityValues.id}: ${item?.id}`} />
            {criticality && <CriticalityIndicator criticality={criticality} />}
          </ListItem>
          <Divider />
          <ListItem>
            <FlexContainer padding="24px 0px">
              <Typography variant={"body1"}>
                <strong>{item?.description.toUpperCase()}</strong>
              </Typography>
            </FlexContainer>
          </ListItem>
          <Divider />
          {item?.equipment && (
            <ListItem>
              <ListItemText primary={item.equipment} secondary={lang.news.equipment} />
            </ListItem>
          )}
          <Divider />
          <FlexContainer>
            <ListItem>
              <ListItemText primary={item?.area} secondary={lang.areas.title.singular} />
            </ListItem>
            <ListItem>
              <ListItemText primary={item?.sector} secondary={lang.sectors.title.singular} />
            </ListItem>
          </FlexContainer>
        </Modal>
      )}
    </HandleUndefined>
  );
};

export default NewView;
