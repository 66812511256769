import { QueryStatus } from "react-query";
import { SVGSyncPanelIconType } from "../InstrumentSyncBoxIcon";

interface SVGHoverGroupProps {
  type: SVGSyncPanelIconType;
  handleClickAction?: () => void;
  handleHoverIn?: (e: any, type: SVGSyncPanelIconType) => void;
  handleHoverOut?: () => void;
  actionStatus: QueryStatus;
}

export const SVGHoverGroup: React.FC<SVGHoverGroupProps> = ({
  children,
  type,
  handleClickAction,
  handleHoverIn,
  handleHoverOut,
  actionStatus,
}) => {
  const handleClick = (e: any) => {
    if (actionStatus === "loading") {
      return;
    }

    handleClickAction && handleClickAction();
    handleHoverOut?.();
  };

  return (
    <>
      <g
        id="SvgHoverGroup"
        onClick={handleClick}
        onMouseEnter={(e) => handleHoverIn?.(e, type)}
        onMouseLeave={handleHoverOut}
      >
        {children}
      </g>
    </>
  );
};
