import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import DeleteElementForm from "../DeleteElementForm";
import { ElementInterface } from "../models/ElementInterface";
import { usePlantElements } from "../services/service.elements";
import CUElementForm from "../CUElementForm";
import { CrudFormType } from "../../../shared/form/types/FormTypes";
import { useElementGridModel } from "../lang/useElementsGridModel";
import { CarrousellActionInterface } from "../../../shared/react-table/VirtualGrid/types/VirtualGridTypes";
import VirtualGrid from "../../../shared/react-table/VirtualGrid/VirtualGrid";

interface ManageElementsPageProps {
  virtualGridWidth: string;
}

const ManageElementsPage: React.FC<ManageElementsPageProps> = ({ virtualGridWidth }) => {
  const { data: elements, isFetching } = usePlantElements();
  const { lang } = useLangLabels();

  const carrousellActions: CarrousellActionInterface = [
    {
      icon: "edit",
      popoverTitle: lang.crudActions.update,
      component: (item: ElementInterface) => (
        <CUElementForm actionType={CrudFormType.update} item={item} isDataUpdated={!isFetching} />
      ),
    },
  ];

  return (
    <VirtualGrid
      {...{
        width: virtualGridWidth,
        title: lang.elements.title.plural,
        entityName: lang.elements.title.plural,
        gridModel: useElementGridModel(),
        carrousellComponents: carrousellActions,
        items: elements,
        headerActions: (item: any) => <CUElementForm actionType={CrudFormType.create} />,
      }}
    >
      {(items: ElementInterface[] | []) =>
        items.length === 1 ? <DeleteElementForm item={items[0]} /> : <></>
      }
    </VirtualGrid>
  );
};

export default ManageElementsPage;
