import { ListItemText, Paper, Typography } from "@material-ui/core";
import React from "react";
import PageContainer from "../../components/containers/PageContainer";
import { ColectorDailyLubricantsInterface } from "../lubricant/model/LubricantInterface";
import { LubricationPointInterface } from "../lubricationPoints/model/lubricationPointsInterface";
import ColectorList from "../surveys/components/ColectorList";

import CheckUncheckItem from "./CheckUncheckItem";
import { useCheckListController } from "./controllers/useCheckListController";
import { CheckListItemsType } from "./interfaces/checkListInterfaces";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";

const CheckList: React.FC<{ checkListType: CheckListItemsType }> = ({ checkListType }) => {
  const { data, list, formModel, action } = useCheckListController();
  const { lang } = useLangLabels();

  const lubricantCuantity = (items: LubricationPointInterface[]) => {
    return items.reduce((a, b: any) => a + (b.leakLevel?.neededQuantity || 0), 0);
  };

  return (
    <>
      <Paper>
        <PageContainer>
          <Typography variant="h6">{lang.supplies[checkListType].title.plural}</Typography>
        </PageContainer>
        <ColectorList
          items={data && data[checkListType].value}
          iterator={list[checkListType].iterator}
          primaryText={list[checkListType].primaryText}
          secondaryText={list[checkListType].secondaryText}
          buttonActions={(item) => (
            <>
              <CheckUncheckItem
                onClick={(value: any) => action(value, checkListType)}
                item={item}
                picked={data ? data[checkListType].picked : []}
                iterator={list[checkListType].iterator}
              />
            </>
          )}
          customItemList={
            checkListType === "LUBRICANTE"
              ? (item: ColectorDailyLubricantsInterface) => (
                  <>
                    <ListItemText
                      primary={item.lubricationPoints.length}
                      secondary={lang.lubricantForm.points.toUpperCase()}
                    ></ListItemText>
                    <ListItemText
                      primary={`${lubricantCuantity(item.lubricationPoints)} ${
                        item.lubricant.measureUnit
                      }`}
                      secondary={lang.lubricantForm.approximateNeeded.toUpperCase()}
                    ></ListItemText>
                  </>
                )
              : checkListType === "PERMISO"
              ? (item: any) => (
                  <>
                    {item.permission.sector && (
                      <ListItemText
                        primary={item.permission.sector}
                        secondary={"Sector"}
                      ></ListItemText>
                    )}
                  </>
                )
              : (item: any) => <></>
          }
        />
      </Paper>
    </>
  );
};
export default React.memo(CheckList);
