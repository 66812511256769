import { Typography } from "@material-ui/core";
import {
  FlexContainer,
  PageContainer,
} from "../../../../../components/containers";
import { getGridModelValue } from "../../../../../shared/gridModel/gridModelEntity";
import { useSurveyGridModel } from "../../../services/lang/useSurveyGridModel";
import { SurveyInterface } from "../../../model/SurveyInterface";
import { lang } from "moment";
import { useLangLabels } from "../../../../../shared/lang/services/useLangLabels";

export const Lubrication = ({ item }: { item: SurveyInterface }) => {
  const gridModel = useSurveyGridModel();

  const { lang } = useLangLabels();

  return (
    <>
      <FlexContainer>
        <PageContainer padding={"0 "} width={"50%"}>
          <Typography>
            {lang.surveys.lubricates}:{" "}
            {getGridModelValue("lubricates", item.lubricates, gridModel)}
          </Typography>
          <Typography>{lang.surveys.reason}: {item.reason}</Typography>
        </PageContainer>
        <PageContainer padding={"0 "} width={"50%"}>
          <Typography>{lang.surveys.lubricant}: {item.lubricant}</Typography>
          <Typography>
          {lang.surveys.quantity}: {item.quantity} {item.measureUnit}
          </Typography>
        </PageContainer>
      </FlexContainer>
    </>
  );
};
