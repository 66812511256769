import React from 'react';
import { ListItem, ListItemSecondaryAction, ListItemText, IconButton} from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import theme from '../../assets/theme';


interface Props{
    title:string,
    value:boolean | undefined, 
    handleValue:(value:any)=>void, 
    validationError?:any
}

const ClickableFormItem:React.FC<Props> = ({title,value,handleValue,validationError}) => {

    const handleResult = () => {
        return (value === undefined)
            ? handleValue(false)
            : (value === false)
                ? handleValue(true) 
                : handleValue(false)
    }

    const handleError = validationError
        ? value !== undefined
            ? 'unset'
            : theme.palette.error.light
        :'unset'
    

    return(
        <ListItem style={{backgroundColor:handleError}}>
            <ListItemText>{title}</ListItemText>
            <ListItemSecondaryAction>
            <IconButton size='small' onClick={handleResult}>
                    
                {(value === true) &&
                    <CheckCircleOutlineIcon fontSize='large' style={{color:'green'}}/>
                }

                {(value === false) &&
                    <HighlightOffIcon fontSize='large' style={{color:'red'}} />
                }
                        
                {(value === undefined) &&
                    <RadioButtonUncheckedIcon fontSize='large'/>
                }
                
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    )
} 
export default React.memo(ClickableFormItem);
