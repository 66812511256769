import React, { useMemo } from "react";
import { Checkbox, Typography } from "@material-ui/core";
import ComponentMode from "../../components/componentMode/ComponentMode";
import { SimpleSelectableObjectInteface } from "./services/interfaces";
import { useSelectedLanguage } from "./services/useSelectedLanguage";
import { useLangLabels } from "./services/useLangLabels";
import FlexContainer from "../../components/containers/FlexContainer";

const SelectLanguageForm: React.FC<{}> = ({}) => {
  const [setLang, browserLang] = useSelectedLanguage();
  const { lang } = useLangLabels();

  const languages: SimpleSelectableObjectInteface[] = useMemo(
    () => [
      {
        label: lang.language.spanish!,
        value: "es",
      },
      {
        label: lang.language.english!,
        value: "en",
      },
    ],
    [browserLang]
  );

  const handleLang = (lang: SimpleSelectableObjectInteface) => {
    setLang(lang.value);
  };

  return (
    <>
      <ComponentMode
        variant="iconButton"
        mode="popover"
        title={lang.language.label!}
        icon="language"
      >
        {languages.map((lang) => (
          <FlexContainer key={lang.value} onClick={() => handleLang(lang)} align="center">
            <Checkbox checked={!!(browserLang?.lang === lang.value)} />
            <Typography>{lang.label}</Typography>
          </FlexContainer>
        ))}
      </ComponentMode>
    </>
  );
};

export default SelectLanguageForm;
