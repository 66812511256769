import React, { useEffect, useRef } from "react";
import { useKeyStatus } from "../../../../hooks/useKeyStatus";
import CustomButton from "../../../buttons/CustomButton";
import { ModalBackdrop } from "./components/ModalBackdrop";
import { ModalDialog } from "./components/ModalDialog";
import { ModalProps } from "./types/ModalTypes";

const Modal: React.FC<ModalProps> = ({
  children,
  isOpen,
  openModal,
  closeModal,
  buttonIcon,
  buttonVariant,
  buttonPopoverTitle,
  buttonColor = "inherit",
  buttonDisabled,
}) => {
  const isKeyPressed = useKeyStatus("Escape");
  const modalRef = useRef<any>();

  const hasCarrousel = children ? children[0]?.props?.current : undefined;

  const scrollToTop = () => {
    modalRef.current?.scrollTo(0, 0);
  };

  const onClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  useEffect(() => {
    closeModal && closeModal();
  }, [isKeyPressed]);

  useEffect(() => {
    scrollToTop();
  }, [isOpen, hasCarrousel]);

  return (
    <>
      <CustomButton
        disabled={!!buttonDisabled}
        color={buttonColor}
        action={openModal}
        icon={buttonIcon}
        variant={buttonVariant}
        popoverTitle={buttonPopoverTitle}
      />

      <div>
        {isOpen ? (
          <ModalBackdrop isOpen={isOpen} onClick={closeModal}>
            <ModalDialog ref={modalRef} isOpen={isOpen} onClick={onClick}>
              {children}
            </ModalDialog>
          </ModalBackdrop>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Modal;
