import React,{ useState, useEffect } from 'react';
//components
import ResourceGridContainer from '../plants/components.ts/ResourceGridContainer';
import { useAddUserInPbiGroup, useDeleteUserInPbiGroup, usePbiGroups } from '../powerBi/services/service.pbi';
import { useSessionContext } from '../session/store/sessionContext';
//services
import {useAssignCancelFactory}from './services/service.factory'

interface Props{
    color:string
    title:string
    email:string
    handleFactory:(object: any)=>void
    onLoad?:React.ReactNode
    action:'assign' | 'cancel'
    filterByEmail?:string[]
    handleRefresh:()=>void
    refresh:boolean | null
}

const FactoryAssigment:React.FC<Props> = ({color,title,email,handleFactory,onLoad,action,filterByEmail,handleRefresh,refresh}) => {
 
    const [loading, setLoading] = useState<any>({
        inCourse1:false,
        inCourse2:false,
        success:true
    });

    const [itemSelected,setItemSelected] = useState<any>()

    const [pbiGroupName,setPbiGroupName] = useState<any>()
    const {pbiToken} = useSessionContext()
    const {data:pbiGroups} = usePbiGroups()
    const {mutateAsync:addUserInPbiGroup} = useAddUserInPbiGroup()
    const {mutateAsync:deleteUserInPbiGroup} = useDeleteUserInPbiGroup()
    const {assignFactory,cancelFactory} = useAssignCancelFactory()

    const pbiAccesUserConfig = {
        emailAddress: email,
        groupUserAccessRight: "Contributor"
    }

    const handleGroups = (rule:'assign' | 'cancel') => {
        if(rule && pbiToken && !!pbiGroupName){
            
            let pbiPlants = pbiGroups.value.filter((g:any) => g.name.toLowerCase().includes(pbiGroupName.toLowerCase()))
            if(action === 'assign'){
                pbiPlants.forEach((item:any) => {
                    
                    addUserInPbiGroup({
                        groupId:item.id,
                        data:pbiAccesUserConfig,
                        token:pbiToken
                    })
                    .then(res =>{
                        alert(`se agrego correctamente ${pbiAccesUserConfig.emailAddress} como contribuidor en el grupo ${item.name}`)
                    })
                    .catch(err => {
                        console.log(err)
                        alert(`no se ha podido asignar ${pbiAccesUserConfig.emailAddress} como Contribuidor en el grupo ${item.name}`)
                    })
                })
            }
            if(rule === 'cancel'){
                pbiPlants.forEach((item:any)=> {
                    deleteUserInPbiGroup({
                        groupId:item.id,
                        user:pbiAccesUserConfig.emailAddress,
                        token:pbiToken
                    })
                    .then(res => {
                        alert(`se eliminó correctamente ${pbiAccesUserConfig.emailAddress} del grupo ${item.name}`)
                    })
                    .catch(err => console.log(err))
                })
            }
        }
    }
    


    //set Data to query
    const setItem = (item:any) =>{
        setItemSelected({
            tagF:item.tagF,
            email:email || '',
            state:action.charAt(0).toUpperCase()
        })
        setPbiGroupName(item.name)
    }

    useEffect(()=>{
        setItemSelected({
            ...itemSelected,
            email:email
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[email])
   
    //make action query  
    useEffect(()=>{
        if(action){

            if((!!itemSelected?.email ) && (itemSelected.tagF) && (action === 'assign') && (!!pbiGroupName) ){
                handleGroups(action)
                assignFactory(itemSelected)
                .then(res => {
                    handleRefresh()
                    handleFactory(itemSelected)
                    setLoading({success:!loading.success})
                })
                .catch(err => console.log(err))
            }

            if((!!itemSelected?.email) && (itemSelected.tagF) && (action === 'cancel')){
                handleGroups(action)
                cancelFactory(itemSelected)
                .then(res => {
                    handleRefresh()
                    handleFactory(itemSelected)
                    setLoading({success:!loading.success})
                })
                .catch(err => console.log(err))
            }
            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[itemSelected])

    if(action){
        if((action === 'assign') ){
            return (
                    <ResourceGridContainer //display all Factory grids (filtered by have plants)
                        action={setItem}
                        color={color}
                        title={title}
                        endpoint={'AllFactoriesWithPlants'}
                        onLoad={onLoad}
                        filterByEmail={filterByEmail}
                        refresh={refresh}
                    />
            );
        }else if((action === 'cancel')){
                return(
                    <ResourceGridContainer //display factories of an account manager
                        action={setItem}
                        color={color}
                        title={title}
                        email={itemSelected?.email}
                        endpoint={'AccountManagerFactories'}
                        onLoad={onLoad}
                        refresh={refresh}
                    />
                )
            }
    }
    return null

}

export default FactoryAssigment;
