import React, {useEffect} from 'react';
import { useForm } from 'react-hook-form';
import { QueryStatus } from 'react-query';
import { AxiosResponse } from 'axios';
import { FactoryInterface } from '../factories/model/FactoryInterface';
import { Plant } from './PlantInterface';
import { useLangLabels } from '../../shared/lang/services/useLangLabels';
import { useUser } from '../session/store/sessionStore';
import { useCreatePlant, usePlantsByFactory } from './plantsService';
import { useCreatePbiGroup } from '../powerBi/services/service.pbi';
import OnlineOfflineMode from '../../shared/config/offline Mode/OnlineOfflineMode';
import DefaultFormLayout from '../../components/form/DefaultFormLayout/DefaultFormLayout';
import TgdTextField from '../../components/fields/TextField/TgdTextField';
import { capitalize } from 'lodash';



interface Props{
    factory:FactoryInterface
    handlePlant?:(value?:Plant)=>void
}

interface TgdMutationInterface {
    status: QueryStatus;
    data: AxiosResponse<any> | undefined;
    error: unknown;
    isIdle: boolean;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    reset: () => void;
}

const CreatePlantForm:React.FC<Props> = ({factory,handlePlant}) => {

    //UI
    const {lang} = useLangLabels()
    
    //DB
    const {data:user} = useUser()
    const {createPlant, status, error, message, reset, data:CreateDataResult} = useCreatePlant()
    const {createPbiGroup} = useCreatePbiGroup()
    const {data:plants} = usePlantsByFactory(factory ? factory.name : undefined)
    

    //Form
    const {watch,register,setValue,handleSubmit,errors} = useForm()


    //Validations
    const required = {
        required:lang.validations.messages.required
    }

    const plantExist = {
        validate:(newPlantName:string)=>{
            const exist = plants?.find((p:Plant)=>p.name.toUpperCase() === newPlantName.toUpperCase())
            return !!exist ? ` ${lang.validations.messages.allReadyExist.toLowerCase()}` : true
        }
    }

    useEffect(()=>{
        register('id',)
        register('name',{...required,...plantExist})
        register('location',required)
        register('factoryId',)
        register('factoryName')
        register('plantId',)
        register('referrer',)
        register('tagFP',)
        register('state',)

        setValue('id',0)
        setValue('name','')
        setValue('location','')
        setValue('factoryId',factory.factoryId)
        setValue('factoryName',factory.name)
        setValue('plantId','')
        setValue('referrer',user?.email)
        setValue('tagFP','')
        setValue('state','')
    },[])


    const submit = (data:Plant) => {
        createPlant(data)
        .then(()=>{
            createPbiGroup({
                name:`${factory.name} ${data.name}`
            }).then(()=>{
                handlePlant && handlePlant(data)
            })
        })
        .catch((err)=>console.log(err))
    }

    const handleReset = () => {
        setValue('name','')
        setValue('location','')
        reset()
    }
   
    const onSuccess = () => {
       handleReset()
    }


    return (
        <OnlineOfflineMode>
            <DefaultFormLayout
                componentMode='content'
                queryStatus={status}
                error={error}
                alertStatusMessage={message}
                buttonAction={handleSubmit(submit)}
                onClose={onSuccess}
                resetForm={onSuccess}
                buttonIcon={'add'}
                buttonText={lang.actionTypes.create}
            >
                
                <TgdTextField
                    error={errors?.name?.message}
                    label={lang.plants.name}
                    value={watch('name')}
                    onChange={(value:string)=>setValue('name',capitalize(value))}
                />        

                <TgdTextField
                    error={errors?.location?.message}
                    label={lang.plants.location}
                    value={watch().location}
                    name='location'
                    onChange={(value)=>setValue('location',value)}
                />  
                
                <TgdTextField
                    readonly={true}
                    label={lang.plants.factoryId}
                    value={watch().factoryId}
                    onChange={()=>console.log('factoryId automatically setted')}
                />
                
                <TgdTextField
                    readonly={true}
                    label={lang.factories.tagF}
                    value={watch().factoryName}
                    onChange={()=>console.log('factoryName automatically setted')}
                />

            </DefaultFormLayout>
         
        </OnlineOfflineMode>
    );
}

export default CreatePlantForm
