import React, { useMemo } from "react";
import HandleUndefined from "../../components/containers/handleUndefined/HandleUndefined";
import { useModal } from "../../components/containers/modal/Modal/hooks/useModal";
import Modal from "../../components/containers/modal/Modal/Modal";
import PageContainer from "../../components/containers/PageContainer";
import CarrousellToolbar from "../../components/containers/toolbar/CarrousellToolbar";
import { useCarrousell } from "../../components/containers/toolbar/hooks/useCarrousell";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";
import VirtualGrid from "../../shared/react-table/VirtualGrid/VirtualGrid";
import SurveyPreview from "../surveys/components/SurveyPreview/SurveyPreview";
import { useObservationsGridModel } from "./lang/useObservationsGridModel";
import { useObservationByIdentifier } from "./services/observationServices";

interface CustomObservationsListProps {
  items?: any[];
  mode?: "content";
  queryType: "lubricationPoint" | "survey";
}

const CustomObservationsList: React.FC<CustomObservationsListProps> = React.memo(
  ({ items, mode, queryType }) => {
    const modalProps = useModal(false);
    const carrouselProps = useCarrousell(items?.length || 0);

    const allItems = useMemo(() => items || [], [items]);
    const currentItem = useMemo(
      () => (allItems.length > 0 ? allItems[carrouselProps.current] : undefined),
      [allItems, carrouselProps]
    )!;

    const { lang } = useLangLabels();
    const gridModel = useObservationsGridModel();

    const observations = useObservationByIdentifier(queryType, currentItem);

    const _observations = useMemo(() => observations.data, [observations]);

    const itemIdentifier = useMemo(
      () =>
        (queryType === "survey" && currentItem?.id) ||
        (queryType === "survey" && currentItem?.plantTag) ||
        (queryType === "lubricationPoint" &&
          `${currentItem?.equipment}>>${currentItem?.element}>>${currentItem?.component}`),
      [
        currentItem?.component,
        currentItem?.element,
        currentItem?.equipment,
        currentItem?.id,
        currentItem?.plantTag,
        queryType,
      ]
    );

    const title = useMemo(
      () =>
        (queryType === "survey" && lang.messages.surveyObservations) ||
        (queryType === "lubricationPoint" &&
          `${lang.messages.lubricationPointObsevetions}: ${itemIdentifier}`) ||
        "",
      [
        itemIdentifier,
        lang.messages.lubricationPointObsevetions,
        lang.messages.surveyObservations,
        queryType,
      ]
    );

    const content = (
      <PageContainer>
        {queryType === "survey" && <SurveyPreview item={currentItem} />}
        <VirtualGrid
          {...{
            key: currentItem?.tagTGD,
            contained: false,
            width: "100%",
            height: 9,
            title: lang.messages.lastObservations,
            documentExportTitle: "Prueba",
            items: _observations || [],
            gridModel: gridModel,
          }}
        />
      </PageContainer>
    );

    const _mode = mode || "modal";

    return (
      <>
        {_mode === "modal" && (
          <HandleUndefined showError={!(items && items.length > 0)} callback={modalProps.openModal}>
            {(callback) => (
              <Modal
                isOpen={modalProps.isOpen}
                openModal={callback}
                closeModal={modalProps.closeModal}
                buttonIcon={"observations"}
                buttonVariant="iconButton"
                buttonPopoverTitle={`${title}`}
              >
                <CarrousellToolbar {...carrouselProps} />
                {content}
              </Modal>
            )}
          </HandleUndefined>
        )}

        {_mode === "content" && content}
      </>
    );
  }
);

export default React.memo(CustomObservationsList);
