import React, { useEffect, useState } from "react";
import { ComponentUIModes } from "../../../../components/componentMode/types/componentModesInterfaces";
import DefaultFormLayout from "../../../../components/form/DefaultFormLayout/DefaultFormLayout";
import FieldSelector from "../../../../shared/form/components/FieldSelector";
import { InputComponentTypes } from "../../../../shared/form/types/FormTypes";
import { useForm } from "../../../../shared/form/useForm";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { useStatusProcessor } from "../../../../shared/queries/StatusProcessor";
import { useAllLubricantsByPlant } from "../../../lubricant/services/service.lubricants";
import { useToken } from "../../../session/store/sessionStore";
import {
  equipmentCreateSuggestedQuantityFactory,
  LubricationPointInterface,
} from "../../model/lubricationPointsInterface";
import { useUpdateLubricationPoint } from "../../services/lubricationPointsAdapters";

interface updateComponentInterface {
  id: number;
  function: string;
  location: string;
  brand: string;
  capacity: number;
  component: string;
  element: string;
  lubricant: string;
  lubricantType: string;
  measureUnit: string;
  model: string;
  observations: string;
  oilAnalysis: string | boolean;
  review: string;
  routes: string;
  supplies: string[];
  tagFP: string;
  tagTGD: string;
  suggestedQuantity: LubricationPointInterface["routeName"];
}

const useUpdateComponentForm = (tagFP: string, item: LubricationPointInterface) => {
  const { lang } = useLangLabels();
  
  return useForm<updateComponentInterface>({
    id: {
      label: lang.globalentityValues.id,
      placeholder: lang.globalentityValues.id,
      value: item?.id || 0,
      component: InputComponentTypes.none,
    },
    function: {
      label: lang.lubricationPoints.function,
      placeholder: lang.lubricationPoints.function,
      value: item?.function || lang.stringDefaultValues.notSpecified,
      component: InputComponentTypes.text,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    location: {
      label: lang.lubricationPoints.location,
      placeholder: lang.lubricationPoints.location,
      value: item?.location || lang.stringDefaultValues.notSpecified,
      component: InputComponentTypes.text,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    review: {
      label: lang.lubricationPoints.review,
      placeholder: lang.lubricationPoints.review,
      value: item.review,
      component: InputComponentTypes.none,
    },
    routes: {
      label: lang.lubricationPoints.routes,
      placeholder: lang.lubricationPoints.routes,
      value: item.routes,
      component: InputComponentTypes.none,
    },
    supplies: {
      label: lang.lubricationPoints.supplies,
      placeholder: lang.lubricationPoints.supplies,
      value: item.supplies,
      component: InputComponentTypes.none,
    },
    brand: {
      label: lang.lubricationPoints.brand,
      placeholder: lang.lubricationPoints.brand,
      component: InputComponentTypes.text,
      value: item?.brand || lang.stringDefaultValues.notSpecified,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    model: {
      label: lang.lubricationPoints.model,
      placeholder: lang.lubricationPoints.model,
      component: InputComponentTypes.text,
      value: item?.model || lang.stringDefaultValues.notSpecified,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    observations: {
      label: lang.lubricationPoints.observations,
      placeholder: lang.lubricationPoints.observations,
      component: InputComponentTypes.text,
      value: item?.observations || lang.stringDefaultValues.notSpecified,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    element: {
      label: lang.lubricationPoints.element,
      placeholder: lang.lubricationPoints.element,
      component: InputComponentTypes.element,
      inputConfig: {
        tagFP: tagFP,
      },
      value: item?.element || null,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    component: {
      label: lang.lubricationPoints.component,
      placeholder: lang.lubricationPoints.component,
      component: InputComponentTypes.component,
      inputConfig: {
        tagFP: tagFP,
      },
      value: item?.component || null,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    lubricant: {
      label: lang.lubricationPoints.lubricant,
      placeholder: lang.lubricationPoints.lubricant,
      component: InputComponentTypes.lubricant,
      inputConfig: {
        tagFP: tagFP,
      },
      value: item?.lubricant || null,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    lubricantType: {
      label: lang.lubricationPoints.lubricantType,
      placeholder: lang.lubricationPoints.lubricantType,
      value: item.lubricantType,
      component: InputComponentTypes.none,
    },
    capacity: {
      label: lang.lubricationPoints.capacity,
      placeholder: lang.lubricationPoints.capacity,
      component: InputComponentTypes.number,
      value: item?.capacity || null,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    measureUnit: {
      label: lang.lubricationPoints.measureUnit,
      placeholder: lang.lubricationPoints.measureUnit,
      component: InputComponentTypes.none,
      value: item?.measureUnit || null,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    oilAnalysis: {
      label: lang.lubricationPoints.oilAnalysis,
      placeholder: lang.lubricationPoints.oilAnalysis,
      component: InputComponentTypes.oilAnalysis,
      value: item?.oilAnalysis !== undefined ? item.oilAnalysis : undefined,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    suggestedQuantity: {
      label: lang.lubricationPoints.suggestedQuantity,
      placeholder: lang.lubricationPoints.suggestedQuantity,
      component: InputComponentTypes.none,
      value: item?.suggestedQuantity || equipmentCreateSuggestedQuantityFactory(item.measureUnit),
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    tagTGD: {
      label: lang.globalentityValues.tagTGD,
      placeholder: lang.globalentityValues.tagTGD,
      value: item?.tagTGD || undefined,
      component: InputComponentTypes.none,
      validations: {
        required: {
          value: true,
          message: lang.globalentityValues.tagTGD,
        },
      },
    },
    tagFP: {
      label: lang.globalentityValues.tagFP,
      placeholder: lang.globalentityValues.tagFP,
      value: tagFP,
      component: InputComponentTypes.none,
    },
  });
};

interface Props {
  mode?: ComponentUIModes;
  item: LubricationPointInterface;
  isDataUpdated?: boolean;
}

const UpdateLubricationPoint: React.FC<Props> = ({ mode, item, isDataUpdated }) => {
  const [defaultItem, setDefaultItem] = useState(item);

  const { lang } = useLangLabels();

  const icon = "edit";
  const title = `${lang.actionTypes.update} - ${item.component} >> ${item.element} >> ${item.equipment}`;

  const { data: lubricants } = useAllLubricantsByPlant();

  const { uiModel, handleSubmit, setValue, formData } = useUpdateComponentForm(
    item.tagFP,
    defaultItem
  );

  const query = useUpdateLubricationPoint();
  const mutationStatus = useStatusProcessor(query);
  const token = useToken();

  const submit = (data: updateComponentInterface) => {
    const isChangeReview = data.lubricant !== item.lubricant; // isLubricantChange
    const newReview = `${parseInt(data?.review || "") + 1}`;

    query.mutate({
      data: {
        ...data,
        review: isChangeReview ? newReview : data.review,
        oilAnalysis: data.oilAnalysis === "SI" ? true : false,
      },
      token,
    });
  };

  const { status, error, message, handleReset } = {
    ...query,
    status: mutationStatus.status,
    error: mutationStatus.error,
    message: mutationStatus.message,
    handleReset: () => {
      mutationStatus.reset();
      query.reset();
    },
  };

  useEffect(() => {
    if (formData.lubricant !== item.lubricant) {
      const newLubricant = lubricants?.find((l) => l.lubricant === formData.lubricant);
      if (newLubricant && newLubricant?.type !== item.lubricantType) {
        setValue("lubricantType", newLubricant.type);
        setValue("measureUnit", newLubricant.measureUnit);
        setDefaultItem({
          ...defaultItem,
          lubricantType: newLubricant.type,
          lubricant: newLubricant.lubricant,
        });
      }
    }
  }, [formData.lubricant, item.lubricant, item.lubricantType, lubricants]);

  return (
    <DefaultFormLayout
      componentMode={mode}
      buttonIcon={icon}
      buttonVariant={"iconButton"}
      buttonText={title}
      popoverTitle={lang.actionTypes.update}
      {...{ queryStatus: status, error, alertStatusMessage: message }}
      buttonAction={handleSubmit(submit)}
      onClose={handleReset}
      resetForm={handleReset}
      isDataUpdated={isDataUpdated}      
    >
      {uiModel.map((props, index) => (
        <>
          <FieldSelector key={props.name} fieldProps={props} />
        </>
      ))}
    </DefaultFormLayout>
  );
};

export default React.memo(UpdateLubricationPoint);
