import { InputComponentTypes } from "../../../shared/form/types/FormTypes";
import { useForm } from "../../../shared/form/useForm";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { FormattedUiModel } from "../../surveys/components/SurveyUpdateForm/models/FormattedUiModel";
import { RouteInterface } from "./RoutesInterface";

interface CreateRouteFormHookInterface {
  tagFP: string;
}

export const useCreateRouteForm = ({ tagFP }: CreateRouteFormHookInterface) => {
  const { lang } = useLangLabels();

  const createRouteForm = useForm<RouteInterface>({
    tagFP: {
      value: tagFP,
      component: InputComponentTypes.none,
    },
    operator: {
      value: "",
      component: InputComponentTypes.operator,
      validations: {
        required: { value: true, message: lang.validations.messages.required },
      },
      inputConfig: {
        tagFP: tagFP,
      },
    },
    periodicity: {
      label: lang.routes.periodicity,
      value: "",
      component: InputComponentTypes.periodicity,
      validations: {
        required: { value: true, message: lang.validations.messages.required },
      },
    },
    route: {
      label: lang.routes.route,
      placeholder: lang.routes.route,
      value: 0,
      component: InputComponentTypes.number,
      validations: {
        required: { value: true, message: lang.validations.messages.required },
        max: { value: 4, message: lang.validations.messages.maxLength },
      },
    },
    startDate: {
      label: lang.routes.startDate,
      value: new Date().toISOString().slice(0, 10),
      component: InputComponentTypes.date,
      validations: {
        required: { value: true, message: lang.validations.messages.required },
      },
    },
  });

  const formattedUiModel: FormattedUiModel<RouteInterface> = createRouteForm.uiModel.reduce(
    (acc, obj) => {
      acc[obj.name] = obj;
      return acc;
    },
    {} as FormattedUiModel<RouteInterface>
  );

  return {
    ...createRouteForm,
    formattedUiModel,
  };
};
