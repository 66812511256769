import { Box, Divider, Grid, Paper, Typography } from "@material-ui/core";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import { useMemo } from "react";
import FlexContainer from "../../../../components/containers/FlexContainer";
import PageContainer from "../../../../components/containers/PageContainer";
import SimpleChip from "../../../../components/SimpleChip";
import { useDate } from "../../../../shared/date/useDate";
import AssignCancelPermissionForm from "../../../permisions/components/AssignCancelPermissionForm";
import AssignCancelRouteForm from "../../../routes/components/AssignCancelRouteForm";
import { EnumSupplyTypes } from "../../../supply/model/SupplyInterface";
import AssignCancelSupplyForm from "../../../supply/molecules/AssignCancelSupplyForm";
import { supplyParser } from "../../../supply/utils/supplyParser";
import { equipmentType, LubricationPointInterface } from "../../model/lubricationPointsInterface";
import { useEquipmentsByTagFP, useEquipmentsTag } from "../../services/lubricationPointsAdapters";
import CreateLubricationPoint from "./CreateLubricationPoint";
import EditEquipmentComponent from "./EditEquipmentComponent";
import ViewLubricationPoint from "./ViewLubricationPoint";
import TgdEquipmentsTable from "../TgdEquipmentsTable";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

interface Props {
  item: any;
  type: equipmentType;
}

const PointProperty: React.FC<{ title: string; value: any }> = ({ title, value }) => (
  <FlexContainer flexDirection="column" padding="24px 0px">
    <Typography>
      {title}:
      <br />
      <strong>{value}</strong>
      <Divider />
    </Typography>
  </FlexContainer>
);

const EquipmentFileInfo: React.FC<{ item: any }> = ({ item }) => {
  const { lang } = useLangLabels();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Typography variant="h6">
            {item?.tagFP ? item?.tagFP.split("|").map((i: string) => `${i} `) : ""}
          </Typography>
          <br />
          <Divider />
        </Grid>
        <Grid item md={4}>
          <Box
            style={{
              backgroundColor: "#f6f6f6",
              maxWidth: "200px",
              maxHeight: "200px",
              minHeight: "150px",
              minWidth: "150px",
              width: "auto",
              height: "100%",
            }}
          ></Box>
        </Grid>
        <Grid item md={8}>
          <Grid container spacing={3}>
            <Grid item md={6}>
              {lang.lubricationPoints.equipmentName}:
              <Typography variant="body2">
                <strong>{item?.equipment}</strong>
              </Typography>
              <Divider />
              <br />
              {lang.lubricationPoints.equipmentTag}:
              <Typography variant="body2">
                <strong>{item?.tagTGD}</strong>
              </Typography>
              <Divider />
            </Grid>
            <Grid item md={6}>
              {lang.lubricationPoints.type}:
              <Typography variant="body2">
                <strong>{item?.type}</strong>
              </Typography>
              <Divider />
              <br />
              {lang.lubricationPoints.sector}:
              <Typography variant="body2">
                <strong>{item?.sector}</strong>
              </Typography>
              <Divider />
            </Grid>
            <Grid item md={6}>
              {lang.lubricationPoints.plantTag}:
              <Typography variant="body2">
                <strong>{item?.plantTag}</strong>{" "}
              </Typography>
              <Divider />
            </Grid>
            <Grid item md={6}>
              {lang.lubricationPoints.equipmentCriticality}:
              <Typography variant="body2">
                <strong>{item?.criticality}</strong>
              </Typography>
              <Divider />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <br />
      <Divider />
      <br />
    </>
  );
};

const LubricationPointFileTemplate: React.FC<Props> = ({ item, type }) => {
  const { getTag } = useEquipmentsTag();
  const { nowYear } = useDate();

	const {lang} = useLangLabels()

  const supplies = supplyParser(item.supplies);
  const permissions = item.permissions;

  const { data: Equipments } = useEquipmentsByTagFP();

  const lubricationPoints = useMemo(() => {
    switch (type) {
      case "equipment":
        return Equipments?.filter((eq) => eq.tagTGD.includes(item.tagTGD));

      case "element":
        return item.lubricationPoints;
    }
  }, [Equipments, item.lubricationPoints, item.tagTGD, type]);

  const elements = useMemo(() => {
    let flag: string[] = [];
    let result: any = {};

    lubricationPoints?.forEach((lp: any) => {
      const element = lp.element;
      if (!!flag.includes(element)) {
        result[element] = {
          ...result[element],
          lubricationPoints: [...result[element].lubricationPoints, lp],
        };
      } else {
        flag.push(element);
        result = {
          ...result,
          [element]: {
            element: element,
            tagFP: lp.tagFP,
            sector: lp.sector,
            plantTag: lp.plantTag,
            type: lp.type,
            criticality: lp.criticality,
            tagTGD: getTag(lp, equipmentType.element),
            equipment: lp.equipment,
            lubricationPoints: [lp],
          },
        };
      }
    });

    return flag.map((key) => result[key]);
  }, [getTag, lubricationPoints]);

  return (
    <Paper
      elevation={3}
      style={{ width: "700px", height: "min-content"}}
    >
      <PageContainer>
        <EquipmentFileInfo item={item} />

        {type === "equipment" && (
          <>
            <TgdEquipmentsTable
              height="auto"
              title={`Elementos: ${elements?.length}`}
              items={elements || []}
              type={equipmentType.element}
              itemActions={(i: any) => (
                <>
                  <EditEquipmentComponent item={i} type={equipmentType.element} />
                </>
              )}
            />
            <br />
            <Divider />
          </>
        )}

        {(type === "equipment" || type === "element") && (
          <>
            <TgdEquipmentsTable
              height="auto"
              title={`Componentes: ${lubricationPoints?.length}`}
              items={lubricationPoints || []}
              type={equipmentType.component}
              headerAction={
                <CreateLubricationPoint
                  item={item}
                  {...(type === "element" ? { element: item.element } : null)}
                />
              }
              itemActions={(i: LubricationPointInterface) => <ViewLubricationPoint item={i} />}
            />
            <br />
            <Divider />
          </>
        )}

        <PageContainer>
          {type === "component" && (
            <>
              <Grid container spacing={3}>
                <Grid item md={3}>
                  {item?.brand && (
                    <>
                      {lang.lubricationPoints.brand}:
                      <div>{item?.brand}</div>
                      <br />
                    </>
                  )}
                </Grid>
                <Grid item md={3}>
                  {item?.model && (
                    <>
                      {lang.lubricationPoints.model}:
                      <div>{item?.model}</div>
                      <br />
                    </>
                  )}
                </Grid>
                <Grid item md={3}>
                  {item?.location && (
                    <>
											{lang.lubricationPoints.location}:
                      <div>{item?.location}</div>
                      <br />
                    </>
                  )}
                </Grid>
                <Grid item md={3}>
									{lang.lubricationPoints.oilAnalysis}:
                  <div>{item?.oilAnalysis ? "SI" : "NO"}</div>
                  <br />
                </Grid>
              </Grid>
              <br />
              <Divider />
              <br />

              <FlexContainer justify="space-between" width="100%">
                <PointProperty
                  title={lang.plants.state}
                  value={
                    <FlexContainer align="center">
                      <FiberManualRecord
                        style={{ color: !!item?.state ? "green" : "gray", marginRight: "16px" }}
                      />
                      {item?.state ? lang.words.active : lang.words.inactive}
                    </FlexContainer>
                  }
                />
                {item?.admissionDate && (
                  <PointProperty
                    title={lang.lubricationPoints.admissionDate}
                    value={item?.admissionDate.slice(0, 10).split("-").reverse().join("-")}
                  />
                )}
                {parseInt(item?.egressDate?.slice(0, 4)) <= nowYear && (
                  <PointProperty
                    title={lang.lubricationPoints.egressDate}
                    value={item?.egressDate.slice(0, 10).split("-").reverse().join("-")}
                  />
                )}
                {item?.review && <PointProperty title={lang.lubricationPoints.review} value={item.review} />}
                {item?.labelId && <PointProperty title={lang.lubricationPoints.labelId} value={item.labelId} />}
              </FlexContainer>

              {item?.lowReason && (
                <PointProperty
                  title={lang.lubricationPoints.lowReason}
                  value={item.lowReason}
                />
              )}

              <Grid container spacing={0}>
                <Grid item md={9}>
                  {!!!item?.function && (
                    <PointProperty title={lang.lubricationPoints.function} value={item.function} />
                  )}
                </Grid>
              </Grid>

              <FlexContainer flexWrap="wrap" width="100%" justify="space-between">
                {item?.element && <PointProperty title={lang.lubricationPoints.element} value={item.element} />}
                {item?.component && <PointProperty title={lang.lubricationPoints.component} value={item?.component} />}
                {item?.lubricant && <PointProperty title={lang.lubricationPoints.lubricant} value={item.lubricant} />}
                {item?.lubricantType && <PointProperty title={lang.lubricationPoints.lubricantType} value={item.lubricantType} />}
                {item?.lubricant && (
                  <PointProperty title={lang.lubricationPoints.capacity} value={`${item.capacity} ${item.measureUnit}`} />
                )}
                {item?.suggestedQuantity && (
                  <PointProperty title={lang.lubricationPoints.suggestedQuantity} value={`${item.suggestedQuantity}`} />
                )}
              </FlexContainer>

              {!!!item?.observations && (
                <PointProperty
                  title={lang.lubricationPoints.observations}
                  value={item.observtions}
                />
              )}

              <br />
              <Divider />
              <br />
              {/* Routes Config */}
              {item?.routes && (
                <>
                  <FlexContainer align="center" flexWrap="wrap">
                    <AssignCancelRouteForm item={item} />
                    {JSON.parse(item?.routes).map((r: string, index: number) => (
                      <SimpleChip key={index} label={r} type={"route"} />
                    ))}
                  </FlexContainer>
                </>
              )}
              <br />
              <Divider />
              <br />
              {item?.supplies && (
                <>
                  {/* Herramientas */}
                  <FlexContainer align="center" flexWrap="wrap">
                    <AssignCancelSupplyForm type={EnumSupplyTypes.tool} item={item} />
                    {supplies.tools.map((t, index) => (
                      <SimpleChip key={index} type="supply" label={t} />
                    ))}
                  </FlexContainer>
                  <br />
                  <Divider />
                  <br />
                  {/* Consumibles */}
                  <FlexContainer align="center" flexWrap="wrap">
                    <AssignCancelSupplyForm type={EnumSupplyTypes.consumable} item={item} />
                    <br />
                    {supplies.consumables.map((t, index) => (
                      <SimpleChip key={index} type="supply" label={t} />
                    ))}
                  </FlexContainer>
                  <br />
                  <Divider />
                  <br />
                  {/* Protecciones */}
                  <FlexContainer align="center" flexWrap="wrap">
                    <AssignCancelSupplyForm type={EnumSupplyTypes.protection} item={item} />
                    <br />
                    {supplies.securityItems.map((t, index) => (
                      <SimpleChip key={index} type="supply" label={t} />
                    ))}
                  </FlexContainer>
                  <br />
                  <Divider />
                  <br />
                </>
              )}
              {item?.permissions && (
                <>
                  <FlexContainer align="center" flexWrap="wrap">
                    <AssignCancelPermissionForm item={item} />
                    {permissions.map((p: string, index: number) => (
                      <SimpleChip key={index} type="supply" label={p} />
                    ))}
                  </FlexContainer>
                  <br />
                  <Divider />
                  <br />
                </>
              )}
            </>
          )}
        </PageContainer>
      </PageContainer>
    </Paper>
  );
};

export default LubricationPointFileTemplate;
