import React from "react";

import { useCreateUpdate } from "../../../../shared/form/hooks/useCreateUpdate";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { useSessionContext } from "../../../../domains/session/store/sessionContext";
import { CrudFormType } from "../../../../shared/form/types/FormTypes";
import { UIFormInterface } from "../../../../shared/form/types/CRUDTypes";
import DefaultFormLayout from "../../../../components/form/DefaultFormLayout/DefaultFormLayout";
import FieldSelector from "../../../../shared/form/components/FieldSelector";
import { PLCInterface, CreateUpdatePLCProps } from "../types/plcTypes";
import { CreatePLC, DeletePLC, UpdatePLC } from "../services/plcService";
import { usePLCForm } from "../hooks/usePLCForm";

const CreateUpdatePLCForm: React.FC<CreateUpdatePLCProps> = ({
  actionType,
  item: plc,
  isDataUpdated,
}) => {
  const {
    action: submitAction,
    resetStatus,
    status,
    error,
    message,
  } = useCreateUpdate(
    {
      create: CreatePLC,
      update: UpdatePLC,
      delete: DeletePLC,
    },
    actionType,
    ["GetAllPLCs"]
  );

  const { lang } = useLangLabels();
  const { tagFP } = useSessionContext();

  const { uiModel, handleSubmit, resetForm } = usePLCForm(plc);

  const ui = (() => {
    const formName = lang.instruments.plcs.title.singular;

    switch (actionType) {
      case CrudFormType.create:
        const create: UIFormInterface = {
          icon: "add",
          title: `${lang.actionTypes.create} ${formName}`,
          variant: "button",
          mode: "modal",
        };

        return create;

      case CrudFormType.update:
        const update: UIFormInterface = {
          icon: "edit",
          title: `${lang.actionTypes.update} ${formName}`,
          variant: "iconButton",
          mode: "content",
        };

        return update;
    }
  })();

  //Methods
  const submitData = (data: PLCInterface) => {
    const plcToSave = {
      ...data,
      tagFP,
      date: new Date(),
    };

    submitAction(plcToSave);
  };

  const resetData = () => {
    resetStatus();
    resetForm();
  };

  return (
    <DefaultFormLayout
      componentMode={ui.mode}
      buttonIcon={ui.icon}
      buttonVariant={ui.variant}
      buttonText={ui.title}
      popoverTitle={ui.title}
      buttonAction={handleSubmit(submitData)}
      {...{ queryStatus: status, error, alertStatusMessage: message }}
      onClose={resetData}
      resetForm={resetData}
      isDataUpdated={isDataUpdated}
    >
      {uiModel.map((props, index) => (
        <FieldSelector key={index} fieldProps={props} />
      ))}
    </DefaultFormLayout>
  );
};

export default React.memo(CreateUpdatePLCForm);
