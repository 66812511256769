import { useTranslateValues } from "../../../components/filters/FieldFilter/hooks/useTranslateValues";
import { apiCall, mutateApiCall } from "../../../shared/axios/axios-config";
import { PrivateQueryData, PrivateQueryTagFPInterface } from "../../../shared/globals/utilsGlobalTypes";
import { useStatusProcessor } from "../../../shared/queries/StatusProcessor";
import { useMutation, useQuery } from "../../../shared/react-query/react-query-conf";
import { updateDependencies } from "../../../shared/react-query/utils/updateDependencies";
import { useSessionContext } from "../../session/store/sessionContext";
import { useToken, useUser } from "../../session/store/sessionStore";
import { SurveyInterface, UpdateSurveyInterface } from "../model/SurveyInterface";
import { SurveyTasksValues } from "../tasks/model/TaskInterface";

// ----------------------------------------------------------------------------------------------------
// Services

export const SurveysByEquipment = ({ data, token }: PrivateQueryData) => {
  return apiCall({
    method: "post",
    url: `/SurveysByLubricationPoint`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const CreateSurvey = ({ data, token }: PrivateQueryData) => {
  return apiCall({
    method: "post",
    url: `/CreateSurvey`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const UpdateSurvey = ({ data, token }: PrivateQueryData<UpdateSurveyInterface>) => {
  return mutateApiCall({
    method: "POST",
    url: "/UpdateSurveys",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const SurveyAttrValues = ({ tagFP, token }: PrivateQueryTagFPInterface) => {
  return apiCall({
    method: "GET",
    url: "/SurveyAttrValues",
    headers: {
      Authorization: `Bearer ${token}`,
      tagFP,
    },
  });
};

// ----------------------------------------------------------------------------------------------------
// Adapters

export const useCreateSurvey = () => {
  const token = useToken();
  const { data: user } = useUser();
  const query = useMutation(CreateSurvey);

  const createSurvey = (data: SurveyInterface) => {
    return query.mutateAsync({
      data: {
        ...data,
        user: user?.email,
        lubricantType:
          data.taskType === SurveyTasksValues.lubrication ? data.lubricantType : undefined,
        lubricant: data.taskType === SurveyTasksValues.lubrication ? data.lubricant : undefined,
        quantity:
          data.taskType === SurveyTasksValues.lubrication
            ? data.lubricates
              ? data.quantity
              : 0
            : undefined,
        addedReason: undefined,
        reason:
          data.taskType === SurveyTasksValues.lubrication
            ? data.lubricates
              ? data.reason
              : ""
            : undefined,
        taskTime:
          data.taskType === SurveyTasksValues.lubrication
            ? data.lubricates
              ? data.taskTime
              : "0"
            : data.taskTime,
      },
      token,
    });
  };

  const status = useStatusProcessor(query);

  return {
    createSurvey,
    query: {
      ...query,
      ...status,
    },
  };
};

export const useSurveysByLubricationPoint = (tagTGD?: string) => {
  const { token } = useSessionContext();
  return useQuery<SurveyInterface[]>({
    queryKey: ["SurveysByLubricationPoint", tagTGD],
    queryFn: () => SurveysByEquipment({ token, data: { tagTGD } }),
    enabled: !!token && !!tagTGD,
    staleTime: 0,
  });
};

export const useUpdateSurvey = () => {
  const { translateValue } = useTranslateValues();
  const token = useToken();

  const updateSurveyQuery = useMutation(UpdateSurvey, {
    onSuccess: () =>
      updateDependencies([
        "LubricationPointsWithInfo",
        "PaginatedSurveyLubricationPointByPeriod",
        "PaginatedDaoSurveyLubricationPointByPeriod",
        "SurveysByLubricationPoint",
        "PaginatedLubricationPointsAndInfo",
      ]),
  });

  const status = useStatusProcessor(updateSurveyQuery);

  const updateSurvey = (data: UpdateSurveyInterface) => {
    return updateSurveyQuery.mutate({
      // Formatting dates to convert them to UTC
      data: {
        ...data,
        startTime: new Date(data?.startTime).toISOString(),
        endTime: new Date(data?.endTime).toISOString(),
        needsLubrication: translateValue(data?.needsLubrication),
        lubricates: translateValue(data?.lubricates),
        impossibleToLubricate: translateValue(data?.impossibleToLubricate),
        taskDone: translateValue(data?.taskDone),
      },
      token,
    });
  };

  return {
    updateSurvey,
    ...updateSurveyQuery,
    ...status,
    resetStatus: status.reset,
  };
};

export const useSurveyAttrValues = () => {
  const { token, tagFP } = useSessionContext();

  return useQuery({
    queryKey: ["SurveyAttrValues"],
    queryFn: () => SurveyAttrValues({ tagFP, token }),
    enabled: !!tagFP,
    staleTime: 3600000,
    cacheTime: Infinity,
  });
};
