import React, { useEffect, useRef, useState } from "react";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import FlexContainer from "../../../../components/containers/FlexContainer";
import { Button, withStyles } from "@material-ui/core";
import { useObservationReportGridModel } from "../hooks/useObservationReportGridModel";
import { usePeriod } from "../../../../shared/date/usePeriod";
import { useReactToPrint } from "react-to-print";
import usePrintState from "../../../../components/print-pdf/hooks/usePrintState";
import { useModal } from "../../../../components/containers/modal/Modal/hooks/useModal";
import { useExportXlsx } from "../../../../shared/xlsx/useExportXlsx";
import { ObservationReport } from "../types/ObservationTypes";
import ObservationReportGrid from "./ObservationReportGrid";
import Report from "./Report";
import { PrintReportModal } from "../../../../components/print-pdf/components";
import useFieldFilter from "../../../../components/filters/FieldFilter/hooks/useFieldFilter";

const styles = (theme: any) => ({
  excel_button: {
    padding: "12px 36px",
    backgroundColor: "#1E8449",
    color: "white",
    "&:hover": {
      backgroundColor: "#176437",
    },
  },
  pdf_button: {
    padding: "12px 36px",
    backgroundColor: "#C0392B",
    color: "white",
    "&:hover": {
      backgroundColor: "#AD2D1F",
    },
  },
});

const ObservationsReport = ({ classes }: { classes: any }) => {
  const { lang } = useLangLabels();
  const entityName = `${lang.reportsGeneration.reportsGeneration
    .toLocaleLowerCase()
    .replace(/\s/g, "-")}-${lang.observations.singular}`.toLocaleLowerCase();
  const observationReportGridModel = useObservationReportGridModel();

  const [observationItems, setObservationsItems] = useState<ObservationReport[]>();
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedItems, setSelectedItems] = useState<ObservationReport[]>([]);

  const { period, setPeriod } = usePeriod(7, entityName);

  const fieldFilter = useFieldFilter<ObservationReport>({
    items: observationItems || [],
    gridModel: observationReportGridModel,
    config: {
      isServerSideFiltering: true,
      fields: {
        criticality: { component: "checkboxes" },
        equipment: { component: "checkboxes" },
        date: { hideField: true },
      },
    },
    entityName,
  });

  const contentRef = useRef(null);

  const { isOpen, openModal, closeModal } = useModal();

  const {
    renderedCount,
    isLoading,
    onItemRendered,
    onItemUnrendered,
    resetRenderedCount,
    handleLoadingChange,
    handleRenderStartedChange,
  } = usePrintState();

  const handleItemRendered = () => {
    onItemRendered();

    const lengthToCompare =
      selectedItems.length > 0 ? selectedItems.length : fieldFilter.filteredItems?.length;

    if (renderedCount.current === lengthToCompare) {
      handleLoadingChange(false);
    }
  };

  const handlePrintClick = () => {
    resetRenderedCount();
    handleLoadingChange(true);
    handleRenderStartedChange(true);
    openModal();
  };

  useEffect(() => {
    if (isLoading === false) {
      handleRenderStartedChange(false);
    }
  }, [isLoading]);

  useEffect(() => {
    setIsDisabled(!(fieldFilter.filteredItems?.length && fieldFilter.filteredItems?.length > 0));
  }, [fieldFilter.filteredItems]);

  const documentTitle = lang.reportsGeneration.observationDocumentTitle;

  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
    documentTitle: `${documentTitle}-${new Date().toISOString().split("T")[0]}`,
  });

  const { downloadFile } = useExportXlsx(
    fieldFilter.filteredItems || [],
    documentTitle,
    observationReportGridModel
  );

  const criticalitiesSet = new Set(
    selectedItems.length > 0 ? selectedItems.map((observation) => observation.criticality) : fieldFilter.filteredItems?.map((observation) => observation.criticality));
  const criticalitiesArray = Array.from(criticalitiesSet);


  const otherProps = {
    ...period,
    criticalities: criticalitiesArray || [],
    onItemRendered: handleItemRendered,
    onItemUnrendered,
  };

  return (
    <FlexContainer flexDirection="column">
      <PrintReportModal
        open={isOpen}
        handleClose={closeModal}
        isLoading={isLoading}
        handlePrint={handlePrint}
        disableEscapeKeyDown={true}
      />
      <ObservationReportGrid
        entityName={entityName}
        period={period}
        setPeriod={setPeriod}
        fieldFilter={fieldFilter}
        setObservationsItems={setObservationsItems}
        setSelectedItems={setSelectedItems}
      />

      <FlexContainer justify="center" padding={"24px"}>
        <Button
          variant="contained"
          className={classes.excel_button}
          onClick={downloadFile}
          disabled={isDisabled}
        >
          {lang.reportsGeneration.generateReport}:{" "}
          <span style={{ fontWeight: "bolder" }}>&nbsp;Excel</span>
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.pdf_button}
          onClick={handlePrintClick}
          disabled={isDisabled}
        >
          {lang.reportsGeneration.generateReport}:{" "}
          <span style={{ fontWeight: "bolder" }}>&nbsp;PDF</span>
        </Button>
      </FlexContainer>

      <Report
        contentRef={contentRef}
        fieldFilter={fieldFilter}
        period={period}
        rendered={isOpen}
        otherProps={otherProps}
        selectedItems={selectedItems}
      />
    </FlexContainer>
  );
};

export default React.memo(withStyles(styles)(ObservationsReport));
