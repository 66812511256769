import { useEffect } from "react";
import { useLangLabels } from "../../../../../shared/lang/services/useLangLabels";
import VirtualGrid from "../../../../../shared/react-table/VirtualGrid/VirtualGrid";
import { useLossReportGridModel } from "../hooks/useLossReportGridModel";
import { useGetLossReportList } from "../services/LossReportService";
import { PeriodInterface } from "../../../../../shared/reports/model/ReportInterface";
import { FieldFilterHookResult } from "../../../../../components/filters/FieldFilter/types/FieldFilterTypes";
import { LossReportInterface } from "../types/LossReportTypes";

interface LossReportGridProps {
  entityName: string;
  period: PeriodInterface;
  setPeriod: React.Dispatch<React.SetStateAction<any>>;
  fieldFilter: FieldFilterHookResult<LossReportInterface>;
  setLossItems: React.Dispatch<React.SetStateAction<any>>;
  setSelectedItems: React.Dispatch<React.SetStateAction<any>>;
}

const LossReportGrid = ({
  entityName,
  period,
  setPeriod,
  fieldFilter,
  setLossItems,
  setSelectedItems,
}: LossReportGridProps) => {
  const { lang } = useLangLabels();
  const title = lang.reportsGeneration.generateLossReport;
  const lossReportGridModel = useLossReportGridModel();

  const { data: lossReport, isLoading } = useGetLossReportList(period, {
    filters: fieldFilter.filterModel,
    searchValue: fieldFilter.searchValue,
  });

  useEffect(() => {
    if (!isLoading) {
      setLossItems(lossReport);
    }
  }, [lossReport]);

  return (
    <VirtualGrid
      entityName={entityName}
      gridModel={lossReportGridModel}
      items={lossReport || []}
      title={title}
      period={period}
      periodChange={setPeriod}
      customDynamicFilters={fieldFilter}
      onSelectedDataChange={setSelectedItems}
    />
  );
};

export default LossReportGrid;
