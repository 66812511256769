import React, { useMemo } from "react";

import {
  ContainerCRUDDependencies,
  CreateContainer,
  DeleteContainer,
  UpdateContainer,
} from "./services/service.containers";
import FieldSelector from "../../shared/form/components/FieldSelector";
import { useContainerForm } from "./services/useContainerForm";
import { CUEntityFormPropsType, UIFormInterface } from "../../shared/form/types/CRUDTypes";
import { ContainersInterface } from "./ContainersInterfaces";
import { useCreateUpdate } from "../../shared/form/hooks/useCreateUpdate";
import { CrudFormType } from "../../shared/form/types/FormTypes";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";
import DefaultFormLayout from "../../components/form/DefaultFormLayout/DefaultFormLayout";
import { useSessionContext } from "../session/store/sessionContext";
import { useAllLubricantsByPlant } from "../lubricant/services/service.lubricants";

type props = CUEntityFormPropsType<ContainersInterface> & { isDataUpdated?: boolean };

const CUContainerForm: React.FC<props> = ({ item, actionType, isDataUpdated }) => {
  const { tagFP } = useSessionContext();

  //DB
  const { action, resetStatus, status, error, message } = useCreateUpdate(
    {
      create: CreateContainer,
      update: UpdateContainer,
      delete: DeleteContainer,
    },
    actionType,
    ContainerCRUDDependencies
  );

  //UI
  const { lang } = useLangLabels();

  const { uiModel, fields, handleSubmit, resetForm } = useContainerForm(tagFP, item);

  const { data: lubricants } = useAllLubricantsByPlant();
  const selectedLubricant = useMemo(
    () => lubricants?.find((lubricant) => lubricant.lubricant === fields.lubricant?.value),
    [fields, lubricants]
  );

  const ui = (() => {
    const formName = lang.containers.title.plural;
    switch (actionType) {
      case CrudFormType.create:
        const create: UIFormInterface = {
          icon: "add",
          title: `${lang.actionTypes.create} ${formName.toLowerCase()}`,
          variant: "button",
          mode: "modal",
        };
        return create;

      case CrudFormType.update:
        const update: UIFormInterface = {
          icon: "edit",
          title: `${lang.actionTypes.update} ${formName.toLowerCase()}`,
          variant: "iconButton",
          mode: "content",
        };
        return update;
    }
  })();

  //Methods
  const submit = (data: ContainersInterface) => {
    switch (actionType) {
      case CrudFormType.create:
        action({
          ...data,
          lubricant: selectedLubricant?.lubricant,
          lubricantType: selectedLubricant?.type,
        });

        break;

      case CrudFormType.update:
        action({
          ...item,
          ...data,
        });
        break;
    }
  };

  const handleReset = () => {
    resetStatus();
    resetForm();
  };

  return (
    <DefaultFormLayout
      componentMode={ui.mode}
      buttonIcon={ui.icon}
      buttonVariant={ui.variant}
      buttonText={ui.title}
      popoverTitle={ui.title}
      {...{ queryStatus: status, error, alertStatusMessage: message }}
      buttonAction={handleSubmit(submit)}
      onClose={handleReset}
      resetForm={handleReset}
      isDataUpdated={isDataUpdated}
    >
      {uiModel.map((props, index) => (
        <FieldSelector key={index} fieldProps={props} />
      ))}
    </DefaultFormLayout>
  );
};

export default CUContainerForm;
