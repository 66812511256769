export enum containersTypes {
    dispenser='DISPENSADOR'
} 

export interface ContainersInterface{
    id?:number
    name:string
    capacity:number
    coefficient:number
    code:string
    lubricantType:string
    lubricant:string
    tagFP:string
    type:containersTypes.dispenser
}