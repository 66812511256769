import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

export const useOperatorData = () => {
  const { lang } = useLangLabels();

  return {
    menu: [
      {
        id: "0",
        type: "index",
        name: lang.sidebarMenu.home,
        route: "",
        icon: "home",
        offline: true,
      },
      {
        id: "1",
        type: "explore",
        name: lang.sidebarMenu.explore,
        route: "explorar",
        icon: "search",
        offline: true,
      },
      {
        id: "2",
        type: "news",
        name: lang.sidebarMenu.news,
        route: "novedades",
        icon: "news",
        offline: true,
      },
    ],
  };
};
