import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import CustomButton from "../../../components/buttons/CustomButton";
import ModalButton from "../../../components/buttons/ModalButton";
import {
  useAssignCancelMultiplePermissions,
  usePermissionsByTagFP,
} from "../services/service.permission";
import { useEquipmentsByTagFP } from "../../lubricationPoints/services/lubricationPointsAdapters";
import { usePermissionsGridModel } from "../lang/usePermissionsGridModel";
import { useLubricationPointsGridModel } from "../../lubricationPoints/lang/useLubricationPointsGridModel";
import VirtualGrid from "../../../shared/react-table/VirtualGrid/VirtualGrid";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

interface Props {}

const AssignCancellPermissionForm: React.FC<Props> = ({}) => {
  const { lang } = useLangLabels();
  const title = lang.actionTypes.assign + " " + lang.permissions.plural.toLowerCase()

  const [selectedEquipments, setSelectedEquipments] = useState<any[]>([]);
  const [selectedPermission, setSelectedPermissions] = useState<any[]>([]);
  const [step, setStep] = useState(1);

  const { data: equipments } = useEquipmentsByTagFP();
  const { data: permissions } = usePermissionsByTagFP();

  const { handleAssigCancel, status, reset } = useAssignCancelMultiplePermissions();

  const equipmentGridModel = useLubricationPointsGridModel();
  const permissionGridModel = usePermissionsGridModel();

  const onSelectedEquipmentsChange = (selectedData: any[]) => {
    setSelectedEquipments(selectedData);
  };

  const onSelectedPermissionsChange = (selectedData: any[]) => {
    setSelectedPermissions(selectedData);
  };

  const handleAssign = () => {
    handleAssigCancel(selectedEquipments, selectedPermission, "A");
  };

  const handleReset = () => {
    reset();
    setSelectedEquipments([]);
    setSelectedPermissions([]);
    setStep(1);
  };

  return (
    <ModalButton
      icon="addList"
      title={title}
      onClose={handleReset}
    >
      {status === "error" && <Alert severity="error">{lang.messages.anErrorHasOccurred}</Alert>}
      {status === "success" && <Alert>{lang.supplies.assignmentsMade}</Alert>}

      {step === 1 && (
        <VirtualGrid
          contained={false}
          gridModel={equipmentGridModel}
          title={lang.supplies.selectEquipmentToAssign}
          items={equipments}
          onSelectedDataChange={onSelectedEquipmentsChange}
          headerActions={(item: any) => (
            <CustomButton
              icon="next"
              popoverTitle={lang.supplies.nextStep}
              {...(selectedEquipments?.length > 0 ? { disabled: false } : { disabled: true })}
              action={() => setStep(2)}
            />
          )}
        />
      )}

      {step === 2 && (
        <VirtualGrid
          contained={false}
          gridModel={permissionGridModel}
          title={title}
          items={permissions}
          onSelectedDataChange={onSelectedPermissionsChange}
          headerActions={(item: any) => (
            <>
              <CustomButton
                icon="before"
                action={handleReset}
                popoverTitle={lang.supplies.backReset}
              />
              <CustomButton
                icon="save"
                popoverTitle={lang.crudActions.save}
                status={status}
                {...(selectedPermission?.length > 0 ? { disabled: false } : { disabled: true })}
                action={handleAssign}
              />
            </>
          )}
        />
      )}
    </ModalButton>
  );
};

export default React.memo(AssignCancellPermissionForm);
