import React from "react";

import FieldSelector from "../../shared/form/components/FieldSelector";

import { SupplyInterface, SupplyType } from "./model/SupplyInterface";
import {
  CreateSupply,
  DeleteSupply,
  UpdateSupply,
  SupplyCRUDDependencies,
} from "./services/service.supplies";
import { useSupplyForm } from "./molecules/useSupplyForm";
import { CUEntityFormPropsType, UIFormInterface } from "../../shared/form/types/CRUDTypes";
import { useCreateUpdate } from "../../shared/form/hooks/useCreateUpdate";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";
import { CrudFormType } from "../../shared/form/types/FormTypes";
import DefaultFormLayout from "../../components/form/DefaultFormLayout/DefaultFormLayout";
import { useSessionContext } from "../session/store/sessionContext";

type CUSupplyFormProps = CUEntityFormPropsType<SupplyInterface> & {
  supplyType?: SupplyType;
  isDataUpdated?: boolean;
};

const CUSupplyForm: React.FC<CUSupplyFormProps> = ({
  item,
  actionType,
  supplyType,
  variant,
  icon,
  mode,
  onSuccess,
  isDataUpdated,
}) => {
  const { tagFP } = useSessionContext();
  //DB
  const { action, resetStatus, status, error, message } = useCreateUpdate(
    {
      create: CreateSupply,
      update: UpdateSupply,
      delete: DeleteSupply,
    },
    actionType,
    SupplyCRUDDependencies
  );

  //UI
  const { lang } = useLangLabels();

  const { uiModel, handleSubmit, resetForm } = useSupplyForm(tagFP, supplyType, item);

  const ui = (() => {
    const formName = supplyType
      ? lang.supplies[supplyType].title.singular
      : lang.supplies.title.singular;
    switch (actionType) {
      case CrudFormType.create:
        const create: UIFormInterface = {
          icon: icon || "add",
          title: `${lang.actionTypes.create} ${formName.toLowerCase()}`,
          variant: variant || "button",
          mode: mode || "modal",
        };
        return create;

      case CrudFormType.update:
        const update: UIFormInterface = {
          icon: icon || "edit",
          title: `${lang.actionTypes.update} ${formName.toLowerCase()}`,
          variant: variant || "iconButton",
          mode: mode || "content",
        };
        return update;
    }
  })();

  //Methods
  const submit = (data: SupplyInterface) => {
    action(data);
  };

  const handleReset = () => {
    resetStatus();
    resetForm();
    onSuccess && onSuccess();
  };

  return (
    <DefaultFormLayout
      componentMode={ui.mode}
      buttonIcon={ui.icon}
      buttonVariant={ui.variant}
      buttonText={ui.title}
      popoverTitle={ui.title}
      {...{ queryStatus: status, error, alertStatusMessage: message }}
      buttonAction={handleSubmit(submit)}
      onClose={handleReset}
      resetForm={handleReset}
      isDataUpdated={isDataUpdated}
    >
      {uiModel.map((props, index) => (
        <FieldSelector key={index} fieldProps={props} />
      ))}
    </DefaultFormLayout>
  );
};

export default CUSupplyForm;
