import { useCallback, useState } from "react";
import VirtualGrid from "../../../shared/react-table/VirtualGrid/VirtualGrid";
import { useLubricationPointsGridModel } from "../lang/useLubricationPointsGridModel";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { CarrousellActionInterface } from "../../../shared/react-table/VirtualGrid/types/VirtualGridTypes";
import { LubricationPointInterface, equipmentType } from "../model/lubricationPointsInterface";
import UpdateLubricationPointR from "../components/actions/UpdateLubricationPointR";
import CustomButton from "../../../components/buttons/CustomButton";
import { FlexContainer } from "../../../components/containers";
import IconStatus from "../../../components/IconStatus";
import UpdateEquipmentSuggestedQuantity from "../components/actions/UpdateEquipmentSuggestedQuantity";
import ChangeRevision from "../components/actions/ChangeRevision";
import ViewLubricationPoint from "../components/actions/ViewLubricationPoint";
import AssignCancelRouteForm from "../../routes/components/AssignCancelRouteForm";
import AssignCancelSupplyForm from "../../supply/molecules/AssignCancelSupplyForm";
import AssignCancelPermissionForm from "../../permisions/components/AssignCancelPermissionForm";
import { EnumSupplyTypes } from "../../supply/model/SupplyInterface";
import DesactivateActivateEquipment from "../components/actions/DesactivateActivateEquipment";
import { useEquipmentsTag } from "../services/lubricationPointsAdapters";
import UpdateEquipmentRevision from "../../plant-notifications/revision-changes/components/UpdateEquipmentRevision";
import useFieldFilter from "../../../components/filters/FieldFilter/hooks/useFieldFilter";

interface ComponentsGridProps {
  virtualGridWidth: string;
  lubricationPoints:
    | (LubricationPointInterface & {
        permissions: string[];
      })[]
    | undefined;
  isFetchinglubricationPoints: boolean;
}

export const ComponentsGrid = ({
  virtualGridWidth,
  lubricationPoints,
  isFetchinglubricationPoints,
}: ComponentsGridProps) => {
  const { lang } = useLangLabels();
  const [draftTab, setDraftTab] = useState<boolean>(false);

  const lubricationPointsGridModel = useLubricationPointsGridModel();
  const { getTag } = useEquipmentsTag();

  const items = lubricationPoints?.filter((item: any) => item.state === true);
  const draftItems = lubricationPoints?.filter((item: any) => item.state === false);

  const entityName = "assets-tab-" + lang.components.title.plural;

  const fieldFilter = useFieldFilter<LubricationPointInterface>({
    items: draftTab ? (draftItems as LubricationPointInterface[]) : items || [],
    gridModel: lubricationPointsGridModel,
    config: {
      fields: {
        state: {
          hideField: true,
        },
      },
    },
    entityName,
  });

  const handleDraftTab = useCallback(() => {
    setDraftTab(!draftTab);
  }, [draftTab]);

  const carrousellComponents: CarrousellActionInterface = [
    {
      icon: "edit",
      popoverTitle: lang.crudActions.update,
      component: (item: LubricationPointInterface) => (
        <UpdateLubricationPointR
          mode="content"
          item={item}
          isDataUpdated={!isFetchinglubricationPoints}
        />
      ),
    },
  ];

  return (
    <VirtualGrid
      {...{
        width: virtualGridWidth,
        gridModel: lubricationPointsGridModel,
        items: draftTab ? draftItems : items,
        documentExportTitle: lang.components.title.plural,
        title: `${
          draftTab ? lang.components.inactiveComponents : lang.components.activeComponents
        }`,
        entityName: entityName,
        customDynamicFilters: fieldFilter,
        carrousellComponents,
        headerActions: (item) => (
          <>
            {!draftTab && (
              <CustomButton
                icon="delete"
                popoverTitle={lang.lubricationPoints.options.disabled}
                action={handleDraftTab}
              />
            )}
            {draftTab && (
              <CustomButton
                icon="off"
                popoverTitle={lang.lubricationPoints.options.active}
                action={handleDraftTab}
              />
            )}
          </>
        ),
        itemActions: (item) => (
          <FlexContainer gap={"16px"} align="center" flexWrap="wrap">
            {item.state && (
              <>
                <IconStatus state={item?.state} />
                <UpdateEquipmentSuggestedQuantity item={item} />
                <UpdateEquipmentRevision item={item} />
                {/* <ChangeRevision item={item} /> */}
                <ViewLubricationPoint item={item} />
                <AssignCancelRouteForm item={item} />
                <AssignCancelSupplyForm type={EnumSupplyTypes.tool} item={item} />
                <AssignCancelSupplyForm type={EnumSupplyTypes.protection} item={item} />
                <AssignCancelSupplyForm type={EnumSupplyTypes.consumable} item={item} />
                <AssignCancelPermissionForm item={item} />
              </>
            )}
            <DesactivateActivateEquipment
              type={equipmentType.component}
              tagTGD={getTag(item, equipmentType.component)}
              item={item}
            />
          </FlexContainer>
        ),
      }}
    />
  );
};
