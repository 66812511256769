import { createFilterOptions } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import SimpleAutocompleteSelect from "../../../../components/autocomplete/SimpleAutocompleteSelect";
import { useEquipmentsByTagFP } from "../../services/lubricationPointsAdapters";
import { LubricationPointInterface } from "../../model/lubricationPointsInterface";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

interface Props {
  onChange: (item?: any) => void;
  complete?: boolean;
  equipmentName?: boolean;
  defaultValue?: any;
  clearable?: boolean;
  children?: React.ReactNode;
  fromItems?: LubricationPointInterface[];
}

const lubricationPointByTagTGD = (items: any[] | undefined, tagTGD: string) => {
  return items?.find((i) => i.tagTGD === tagTGD);
};

const SelectLubricationPoint: React.FC<Props> = ({
  children,
  onChange,
  complete,
  equipmentName,
  defaultValue,
  clearable,
  fromItems,
}) => {
  const { lang } = useLangLabels();
  const { data: LubricationPoints } = useEquipmentsByTagFP();
  const separator = ` >> `;
  const [state, setState] = useState<any>();
  const customName = state?.equipment
    ? `${state?.equipment}${separator}${state?.element}${separator}${state?.component}`
    : undefined;

  const itemsPool = fromItems ? fromItems : LubricationPoints;
  useEffect(() => {
    complete
      ? equipmentName
        ? onChange(customName)
        : onChange({ ...lubricationPointByTagTGD(itemsPool, state), customName })
      : onChange(state);
  }, [state]);

  return (
    <SimpleAutocompleteSelect
      clearable={clearable}
      filterOptions={createFilterOptions({
        matchFrom: "any",
        stringify: (option: any) => option.equipment,
      })}
      items={itemsPool || []}
      label={lang.select.lubricationPoint}
      showTitle={"tagTGD"}
      onChange={(tagTGD: any) => setState(tagTGD)}
      renderOption={(option) => (
        <>
          {option.equipment}
          {separator}
          {option.element}
          {separator}
          {option.component}
        </>
      )}
      getOptionSelected={(option, value) => option["tagTGD"] === value["tagTGD"]}
      defaultValue={state}
      children={children}
    />
  );
};

export default SelectLubricationPoint;
