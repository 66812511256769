import React, { useEffect } from "react";
import { CircularProgress, IconButton } from "@material-ui/core";
import { useDeleteComponentVerification } from "./services/service.components";
import TgdPopover from "../../components/popovers/TgdPopover";

import { useSnackbar } from "../../components/snackbar/hooks/useSnackbar";
import { ComponentInterface } from "./models/ComponentInterface";
import { useStatusProcessor } from "../../shared/queries/StatusProcessor";
import { useClickPosition } from "../../shared/globals/hooks/useClickPosition";
import { CustomIcon } from "../../components/buttons/CustomIcon";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";

interface Props {
  item: ComponentInterface;
}

const DeleteComponent: React.FC<Props> = ({ item }) => {
  const { lang } = useLangLabels();
  const { status: deleteStatus, validate, errors, query } = useDeleteComponentVerification();
  const { status, message } = useStatusProcessor(query);

  const { position, getClickPosition } = useClickPosition();
  const { setData } = useSnackbar();

  useEffect(() => {
    if (deleteStatus === "error") {
      const componentsError = errors?.equipments?.length
        ? errors.equipments.length > 1
          ? `${errors.equipments.length} ${lang.STIEquipments.form.label.equipments}`
          : `${errors.equipments.length} ${lang.STIEquipments.form.label.equipment}`
        : "";
      setData({
        modal: true,
        errors: `${lang.components.componentCannotBeRemoved}: ${componentsError}`,
        severity: "warning",
        position: position,
      });
    }

    if (status === "success") {
      setData({
        modal: true,
        errors: message,
        severity: "success",
        position,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus, status, errors]);

  return status === "loading" ? (
    <CircularProgress size="24px" />
  ) : (
    <TgdPopover mode="hover" title={lang.crudActions.delete + " " + lang.components.title.singular}>
      <IconButton size="small" onClick={(e: any) => getClickPosition(e, () => validate(item))}>
        <CustomIcon icon="delete" />
      </IconButton>
    </TgdPopover>
  );
};

export default React.memo(DeleteComponent);
