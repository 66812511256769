import { PaginationState, PaginationStateResult } from "../types/PaginationTypes";
import { useState } from "react";

export const usePaginationState = (
  initialState: PaginationState = { pageIndex: 0, pageSize: 500 }
): PaginationStateResult => {
  const [{ pageIndex, pageSize }, setPagination] = useState({
    ...initialState,
  });

  const paginationOptions = {
    pageIndex,
    pageSize,
    onPaginationChange: (paginationState: { pageIndex?: number; pageSize?: number }) => {
      setPagination((prevState) => ({
        ...prevState,
        ...paginationState,
      }));
    },
  };

  return { paginationOptions };
};
