import React from "react";
import SimpleAutocompleteSelect from "../../../components/autocomplete/SimpleAutocompleteSelect";
import FlexContainer from "../../../components/containers/FlexContainer";
import { CrudFormType } from "../../../shared/form/types/FormTypes";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { useUser } from "../../session/store/sessionStore";
import CUCriticalityForm from "../CUCriticalityForm";
import { CriticalityInterface } from "../models/criticalityInterface";
import { usePlantCriticality } from "../services/service.criticality";
import { CustomIcon } from "../../../components/buttons/CustomIcon";

interface props {
  onChange: (item: string) => void;
  defaultValue?: string | undefined;
  color?: "primary" | "secondary";
  error?: string;
  by?: {
    key: string;
    value: string;
  };
  disabled?: boolean;
}

const SelectCriticality: React.FC<props> = ({ onChange, defaultValue, error, by, disabled }) => {
  const showTitle = "description";
  const { lang } = useLangLabels();
  const label = lang.criticalities.title.singular;
  const { data: criticalities } = usePlantCriticality();
  const { data: user } = useUser();

  const criticalityFiltered = by
    ? criticalities?.filter(
        (c: CriticalityInterface) => c[by.key as keyof CriticalityInterface] === by.value
      )
    : criticalities;

  return (
    <>
      <SimpleAutocompleteSelect
        {...{
          error,
          label,
          items: criticalityFiltered || [],
          onChange,
          defaultValue,
          showTitle,
          disabled,
          renderOption: (option: CriticalityInterface) => (
            <FlexContainer align="center">
              <CustomIcon icon="state" style={{ color: option.color }} /> {option.description}
            </FlexContainer>
          ),
        }}
      >
        {user?.type !== "O" && <CUCriticalityForm {...{ actionType: CrudFormType.create }} />}
      </SimpleAutocompleteSelect>
    </>
  );
};

export default SelectCriticality;
