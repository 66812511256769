import React from "react";
import CustomButton from "../buttons/CustomButton";
import ModalButton from "../buttons/ModalButton";
import Drawer from "../containers/drawer/Drawer";
import FlexContainer from "../containers/FlexContainer";
import PageContainer from "../containers/PageContainer";
import { usePopoverEvent } from "../popovers/hooks/usePopoverEvent";
import TgdPopover from "../popovers/TgdPopover";
import { ComponentModeInterface } from "./types/componentModesInterfaces";

const ComponentMode: React.FC<ComponentModeInterface> = ({
  modalTitle,
  disabled,
  buttonColor,
  buttonTittle,
  buttonStyle,
  children,
  mode = "modal",
  icon,
  title,
  variant,
  onClose,
  popoverTitle,
  fullwidth,
  width,
  drawerProps,
}) => {
  const { handlePopoverClose, handlePopoverOpen, currentTarget, id } =
    usePopoverEvent();

  return (
    <>
      {mode === "content" && (
        <PageContainer>
          <FlexContainer flexDirection="column">{children}</FlexContainer>
        </PageContainer>
      )}

      {mode === "popover" && (
        <>
          <TgdPopover
            mode="hover"
            title={title || popoverTitle || ""}
            width={width}
          >
            <CustomButton
              title={title || ""}
              color={buttonColor}
              styleProps={{ ...buttonStyle }}
              icon={icon}
              variant={variant}
              aria-describedby={id}
              action={(e) => handlePopoverOpen(e)}
            />
          </TgdPopover>
          <TgdPopover
            width={width}
            handleClose={handlePopoverClose}
            currentTargetEvent={currentTarget}
            title={title || ""}
            children={children}
            typography={{
              variant: "h6",
            }}
          />
        </>
      )}

      {mode === "modal" && (
        <ModalButton
          modalTitle={modalTitle}
          onClose={onClose}
          fullwidth={fullwidth}
          disabled={disabled || false}
          icon={icon || "add"}
          buttonText={buttonTittle}
          title={title}
          popoverTitle={popoverTitle}
          variant={variant}
          color={buttonColor}
          style={buttonStyle}
        >
          <FlexContainer flexDirection="column">{children}</FlexContainer>
        </ModalButton>
      )}

      {mode === "drawer" && (
        <>
          <Drawer
            {...{
              ...drawerProps,
              icon,
              popoverTitle,
              variant,
              color: buttonColor,
            }}
          >
            {children}
          </Drawer>
        </>
      )}
    </>
  );
};

export default React.memo(ComponentMode);
