import { useMemo } from "react";
import { GridModelInterface } from "../../../shared/gridModel/gridModelEntity";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

export const useRoutesGridModel = () => {
  const { lang } = useLangLabels();
  const gridModelRoutes: GridModelInterface = useMemo(
    () => ({
      /* renderOption: {label:'Acciones'}, */
      id: { label: lang.globalentityValues.id },
      routeName: { label: lang.routes.routeName },
      route: { label: lang.routes.route },
      operator: { label: lang.routes.operator },
      periodicity: { label: lang.routes.periodicity },
      startDate: { label: lang.routes.startDate },
      state: { label: lang.routes.state },
      scheduledDate: { label: lang.routes.scheduledDate },
      newScheduledDate: { label: lang.routes.newScheduledDate },
    }),
    [lang]
  );

  return gridModelRoutes;
};
