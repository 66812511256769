import { useMutation, useQuery } from "react-query";
import { apiCall, mutateApiCall } from "../../../../shared/axios/axios-config";
import {
  PrivateQueryData,
  PrivateQueryTagFPInterface,
} from "../../../../shared/globals/utilsGlobalTypes";
import { useSessionContext } from "../../../../domains/session/store/sessionContext";
import { useToken } from "../../../../domains/session/store/sessionStore";
import { queryClient } from "../../../../shared/react-query/react-query-conf";
import { useStatusProcessor } from "../../../../shared/queries/StatusProcessor";
import { PLCInterface } from "../types/plcTypes";

const BASE_URL = "/Plc";
const AUTH_HEADER = (token: string) => ({
  Authorization: `Bearer ${token}`,
});

export const GetAllPLCs = ({ token, tagFP }: PrivateQueryTagFPInterface) => {
  return apiCall({
    method: "GET",
    url: BASE_URL,
    headers: AUTH_HEADER(token),
    params: { tagFP },
  });
};

export const CreatePLC = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "POST",
    headers: AUTH_HEADER(token),
    url: BASE_URL,
    data,
  });
};

export const UpdatePLC = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "PUT",
    headers: AUTH_HEADER(token),
    url: BASE_URL,
    data,
  });
};

export const DeletePLC = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "DELETE",
    headers: AUTH_HEADER(token),
    url: BASE_URL,
    data,
  });
};

// -------------------------------------------------------------------------------------------------

export const useGetAllPLCs = () => {
  const { token, tagFP } = useSessionContext();

  return useQuery({
    queryKey: "GetAllPLCs",
    queryFn: () => GetAllPLCs({ token, tagFP }),
    enabled: !!token,
  });
};

export const useCreatePLC = () => {
  const token = useToken();

  const createPLCQuery = useMutation(CreatePLC, {
    onSuccess: () => {
      queryClient.invalidateQueries("GetAllPLCs");
    },
  });

  const createPLC = (data: PLCInterface) => {
    return createPLCQuery.mutate({
      data,
      token: token!,
    });
  };

  const status = useStatusProcessor(createPLCQuery);

  return {
    createPLC,
    ...createPLCQuery,
    ...status,
    resetStatus: status.reset,
  };
};

export const useUpdatePLC = () => {
  const token = useToken();

  const updatePLCQuery = useMutation(UpdatePLC, {
    onSuccess: () => {
      queryClient.invalidateQueries("GetAllPLCs");
    },
  });

  const updatePLC = (data: PLCInterface) => {
    return updatePLCQuery.mutate({
      data,
      token: token!,
    });
  };

  const status = useStatusProcessor(updatePLCQuery);

  return {
    updatePLC,
    ...updatePLCQuery,
    ...status,
    resetStatus: status.reset,
  };
};

export const useDeletePLC = () => {
  const token = useToken();

  const deletePLCQuery = useMutation(DeletePLC, {
    onSuccess: () => {
      queryClient.invalidateQueries("GetAllPLCs");
    },
  });

  const deletePLC = (data: PLCInterface) => {
    return deletePLCQuery.mutate({
      data,
      token: token!,
    });
  };

  const status = useStatusProcessor(deletePLCQuery);

  return {
    deletePLC,
    ...deletePLCQuery,
    ...status,
    resetStatus: status.reset,
  };
};
