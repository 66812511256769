import { useEffect } from "react"
import { useForm } from "react-hook-form";
import { errorsLog } from "../../../../shared/globals/utils/utils";
import { PeriodInterface } from "../../../../shared/reports/model/ReportInterface";

export type periodFilter = {
    firstDate: string;
    secondDate: string;
}
export type PeriodChange = (period: undefined | periodFilter ) => void

export const usePeriodForm = (defaultPeriod:PeriodInterface | undefined,onPeriodSubmit?:PeriodChange) => {

    //Period
    const today = new Date().getTime()

    const {watch,register,setValue, handleSubmit,errors} = useForm<periodFilter>({
        reValidateMode:'onChange'
    })

    useEffect(()=>{
        register({name:'firstDate'},{
            validate:(value)=>{
                const selectedDate = new Date(value).getTime()
                if(selectedDate > today) return 'La fecha no puede ser futura'
                if(selectedDate > today) return true
            },
            required:!!onPeriodSubmit
        })
        register({name:'secondDate'},{
            validate:(value)=>{
                const selectedDate = new Date(value).getTime()
                if(selectedDate > today) return 'La fecha no puede ser futura'
                if(selectedDate > today) return true
            },
            required:!!onPeriodSubmit
        })
        if(defaultPeriod){
            setValue('firstDate',defaultPeriod.firstDate)
            setValue('secondDate',defaultPeriod.secondDate)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[defaultPeriod])


    const handlePeriod = (e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
       setValue(`${e.target.name}`,e.target.value)
   }

   const periodSubmit = () => {
       return handleSubmit<periodFilter>((data)=>onPeriodSubmit 
        ? onPeriodSubmit(data) 
        : undefined)()
   }


   const handleReset = () =>{
       setValue('firstDate','')
       setValue('secondDate','')
       onPeriodSubmit && onPeriodSubmit(undefined)
   }

   errorsLog(errors)

    return {
       periodValues:watch(),
       handleSubmit,
       errors,
       periodSubmit,
       handlePeriod,
       handleReset,
    }
}