import React, { useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { IncomeInterface } from "../types/Income";
import { useDeleteIncome } from "../services/IncomeService";
import { useSnackbar } from "../../../../components/snackbar/hooks/useSnackbar";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { useClickPosition } from "../../../../shared/globals/hooks/useClickPosition";
import CustomButton from "../../../../components/buttons/CustomButton";

interface DeleteElementFormProps {
  item: IncomeInterface;
}

const DeleteIncomeForm = ({ item }: DeleteElementFormProps) => {
  const { deleteIncome, status, message, resetStatus } = useDeleteIncome();
  const { position, getClickPosition } = useClickPosition();
  const { setData } = useSnackbar();

  useEffect(() => {
    if (status === "error") {
      setData({
        modal: true,
        errors: message,
        severity: "warning",
        position: position,
      });
    }
  
    if (status === "success") {
      setData({
        modal: true,
        errors: message,
        severity: "success",
        position,
      });
    }
  }, [status, message, position]);

  const { lang } = useLangLabels();

  const handleDelete = (e: any) => {
    const alert = window.confirm(`${lang.incomeForm.confirmMessage}`)
      if(alert){   
        getClickPosition(e, () => {
          deleteIncome(item); 
          resetStatus();
        })
      }
  };

  return status === "loading" ? (
    <CircularProgress size="24px" />
  ) : (
    <CustomButton
      variant="iconButton"
      icon="delete"
      status={status}
      popoverTitle={lang.actionTypes.deleteItem}
      action={handleDelete}
    />
  );
};

export default DeleteIncomeForm;
