import { Chip } from '@material-ui/core';
import React from 'react';


interface Props{
    label:string
    type:'supply'| 'route' | 'permission'
    color?:'primary' | 'secondary'

}
const SimpleChip:React.FC<Props> = ({label,color,type}) => {

    const colorType= (type:'supply' | 'route' | 'permission') =>{ 
        switch (type) {
            case 'supply':
                return 'SIN HERRAMIENTAS'
            case 'route':
                return 'SIN RUTAS'
            case 'permission':
                return 'SIN PERMISOS'
        }
    }

    return (
            <Chip 
            style={{margin:'4px'}}
            label={label}
            size='small' 
            color={(label !== colorType(type)) ? color : 'default'} 
            />
    );
}

export default SimpleChip;
