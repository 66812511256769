import React from "react";
import { useLubricantsGridModel } from "../../../../domains/lubricant/lang/useLubricantsGridModel";
import { useAllLubricantsByPlant } from "../../../../domains/lubricant/services/service.lubricants";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import LubricantIncome from "./LubricantIncome";
import { CarrousellActionInterface } from "../../../../shared/react-table/VirtualGrid/types/VirtualGridTypes";
import VirtualGrid from "../../../../shared/react-table/VirtualGrid/VirtualGrid";

interface props {
  virtualGridWidth: string;
}

const LubricantLog: React.FC<props> = ({ virtualGridWidth }) => {
  const { lang } = useLangLabels();
  const { data: lubricants } = useAllLubricantsByPlant();

  const carrousellActions: CarrousellActionInterface = [
    {
      icon: "observations",
      popoverTitle: lang.messages.latestIncome,
      component: (item) => (
        <LubricantIncome
          item={item}
          lubricant={item.lubricant}
        />
      ),
    },
  ];

  return (
    <VirtualGrid
      width={virtualGridWidth}
      gridModel={useLubricantsGridModel()}
      entityName={lang.lubricantForm.plural}
      items={lubricants || []}
      title={lang.lubricantForm.plural}
      carrousellComponents={carrousellActions}
    />
  );
};
export default React.memo(LubricantLog);
