import { useQuery } from "react-query"
import { SurveyInterface } from "../../domains/surveys/model/SurveyInterface"
import { getSurveysByDateAndTagTGD, getSurveysWithObservationsAndAnomalies, SurveysWithObservations, surveysByDateAndTagTGDSearchParams } from "./SurveyExploreService"
import { useToken } from "../../domains/session/store/sessionStore"
import { TaskType } from "../../domains/surveys/tasks/model/TaskInterface";



export const useExploredSurveys = (surveys:SurveyInterface[]) => {

    type group<T> = {[key:string]:T[]}

    const groupSurveysBy = (xs:SurveysWithObservations[]):group<SurveysWithObservations> => {
        // Quitar minutos y segundos para poder agruparlo
        const dayHours = (date: string):string => {
            const datelist = date.split(':').splice(0,2).join(':')
            return datelist
        } 
        return xs.reduce((group, x) => {
            const accesor = dayHours(x.startTime) as string
            (group[accesor] = group[accesor] || []).push(x);
        return group;
        }, {} as group<SurveysWithObservations>) 
    };

    return useQuery({
        queryKey: ['surveyWithObservations', surveys],
        queryFn: ()=>getSurveysWithObservationsAndAnomalies(surveys),
        enabled:!!surveys && surveys.length > 0,
        select:(data)=>{
            // return data
            // procesar relevamientos para evitar duplicados por tarea
            let finalData = [] as SurveysWithObservations[] 
            // Agrupamos por fecha
            let surveysByDate = groupSurveysBy(data)

            // iteramos las fechas
            Object.values(surveysByDate).forEach((dateSurveys:SurveysWithObservations[]) => {     
                            
                // la survey final para el listado
                let uniqueSurvey = {} as SurveysWithObservations
                
                //iteramos las surveys de la misma intervencion
                dateSurveys.forEach((survey)=>{
                    const newObservations = survey.observations.map((observation) => {
                        return {
                            ...observation,
                            observation: observation.observation + " (" + survey.taskType + ")"}
                    })

                    // Si es "lubricacion" mantenemos la cabecera de la lubricacion pero sumamos las observaciones
                    if(uniqueSurvey?.taskType === TaskType.lubrication){
                        uniqueSurvey = {
                            ...uniqueSurvey,
                            observations:[
                                ...( uniqueSurvey.observations ? uniqueSurvey.observations : [] ),
                                ...newObservations,
                            ]
                        }
                    } else{
                        // Si no es lubricacion agregamos la cabecera debida y tambien sumamos las observaciones
                        uniqueSurvey = {
                            ...survey,
                            observations:[
                                ...( uniqueSurvey.observations ? uniqueSurvey.observations : [] ),
                                ...newObservations
                            ]
                        }
                    }
                })

                // Agregamos la survey final al listado final
                finalData.push(uniqueSurvey)
            });

            // Ordenamos finalData por fecha (startTime)
            finalData?.sort((a, b) => {
                const dateA = new Date(a.startTime).getTime();
                const dateB = new Date(b.startTime).getTime();
                
                return dateB - dateA;
            });

            return finalData
        },
    })
}

export const useSurveysByPeriodAndTagTGD = (filters: surveysByDateAndTagTGDSearchParams) => {
    const token = useToken()
    return useQuery({
        queryFn: ()=>getSurveysByDateAndTagTGD(filters, token),
        enabled: !!filters.tagTGD && !!filters.tagFP && !!token,
        queryKey:['surveysByPeriodAndTagTGD', filters],
        
    })
}