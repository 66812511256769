import { useMemo } from "react";
import { TabsInterface } from "../../../../../components/containers/tabs/RouterTabs";
import { Paper } from "@material-ui/core";
import ColectorList from "../../../components/ColectorList";
import VirtualGrid from "../../../../../shared/react-table/VirtualGrid/VirtualGrid";
import { useLubricatorStore } from "../../../../users/lubricator/lubricatorStore";
import { useLubricationPointsGridModel } from "../../../../lubricationPoints/lang/useLubricationPointsGridModel";
import useTabsMenuItems from "./useTabsMenuItems";
import User from "../../../../users/UserInterface";
import { useLangLabels } from "../../../../../shared/lang/services/useLangLabels";

const useTabsMenuConfig = (user: User | undefined) => {
  const { data } = useLubricatorStore();
  
  const gridModel = useLubricationPointsGridModel();
  const { lang } = useLangLabels();

  const tabsMenuItems = useTabsMenuItems(data);

  const tabsMenuConfig: TabsInterface[] = useMemo(
    () =>
      tabsMenuItems.map((tab) => {
        return {
          ...tab,
          component: (
            <Paper style={{ minHeight: "600px" }}>
              {tab.route === "" ? (
                <ColectorList
                  items={tab.data.items}
                  primaryText={tab.data.primaryText}
                  secondaryText={tab.data.secondaryText}
                  buttonActions={tab.data.renderOption}
                />
              ) : (
                <VirtualGrid
                  {...{
                    documentExport: user?.type === "O" ? false : true,
                    documentSendEmail: user?.type === "O" ? false : true,
                    title: tab.data.title!,
                    items: tab.data.items,
                    gridModel,
                    renderOptionColumn: { headerName: lang.surveys.loss, renderOption: tab.data.renderOption, size: 180 },
                    bulkActions: tab.data.bulkActions,
                    height: 10
                  }}
                />
              )}
            </Paper>
          ),
        };
      }),
    [data]
  );

  return tabsMenuConfig;
};

export default useTabsMenuConfig;
