import { useCallback, useMemo } from "react";
import { basicDateFormats, extendedDateFormats } from "./validDateFormats";
import { useLangLabels } from "../lang/services/useLangLabels";

type DateFormatValidationResult = {
  isValidDateFormat: boolean;
  isDateFormat: boolean;
  isFullDateFormat: boolean;
};

export const useDate = () => {
  const currentDate = useMemo(() => new Date(), []);
  const { lang } = useLangLabels();

  const { currentDay, currentMonth, currentYear } = useMemo(() => {
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    return { currentDay, currentMonth, currentYear };
  }, [currentDate]);

  const nowDate = useCallback(() => {
    const formattedMonth = currentMonth < 10 ? `0${currentMonth}` : `${currentMonth}`;
    return `${currentDay}-${formattedMonth}-${currentYear}`;
  }, [currentDay, currentMonth, currentYear]);

  const isValidDateFormat = (dateString: string): DateFormatValidationResult => {
    if (!dateString || dateString === "undefined") {
      return {
        isValidDateFormat: false,
        isDateFormat: false,
        isFullDateFormat: false,
      };
    }

    const isDateFormat = basicDateFormats.some((regex) => regex.test(dateString));
    const isFullDateFormat = extendedDateFormats.some((regex) => regex.test(dateString));

    return {
      isValidDateFormat: isDateFormat || isFullDateFormat,
      isDateFormat,
      isFullDateFormat,
    };
  };

  const formatISOToLocalDateTime = (isoDateTime: string, showSeconds: boolean = true): string => {
    const parsedDate = new Date(isoDateTime);

    const yearOptions: Intl.DateTimeFormatOptions = { year: "numeric" };
    const monthOptions: Intl.DateTimeFormatOptions = { month: "2-digit" };
    const dayOptions: Intl.DateTimeFormatOptions = { day: "2-digit" };
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      second: showSeconds ? "2-digit" : undefined,
    };

    const localYear = parsedDate.toLocaleString(undefined, yearOptions);
    const localMonth = parsedDate.toLocaleString(undefined, monthOptions);
    const localDay = parsedDate.toLocaleString(undefined, dayOptions);
    const localTime = parsedDate.toLocaleTimeString(undefined, timeOptions);

    return `${localYear}-${localMonth}-${localDay}T${localTime}`;
  };

  const formatISODate = (
    isoDate: string,
    showTime: boolean = false,
    showSeconds: boolean = false
  ): string => {
    const hasTime = isoDate.includes("T");

    const parsedDate = hasTime ? new Date(isoDate) : new Date(isoDate + "T12:00:00");

    const dateOptions: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    if (showTime) {
      dateOptions.hour = "2-digit";
      dateOptions.minute = "2-digit";
      dateOptions.second = showSeconds ? "2-digit" : undefined;
    }

    return parsedDate.toLocaleString(undefined, dateOptions);
  };

  const formatISODateIfValid = (value: string) => {
    const { isDateFormat, isFullDateFormat } = isValidDateFormat(value);

    if (isDateFormat) {
      return formatISODate(value);
    }

    if (isFullDateFormat) {
      if (value.includes("T00:00:00.000+00:00")) {
        return getDateWithoutTime(value);
      }
      return formatISODate(value, true);
    }

    return value;
  };

  const isToday = (date: string): boolean => {
    const today = new Date().toISOString().split("T")[0];
    const compareDate = new Date(date).toISOString().split("T")[0];

    return today === compareDate;
  };

  const getDateWithoutTime = (date: string) => {
    const parts = date.split("T")[0].split("-");
    const day = parts[2];
    const month = parts[1];
    const year = parts[0];

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  function getDifferenceInDays(date1: string, date2: string): number {
    const oneDay = 24 * 60 * 60 * 1000;

    const parsedDate1 = new Date(date1);
    const parsedDate2 = new Date(date2);

    const difference = Math.abs(parsedDate1.getTime() - parsedDate2.getTime());

    const days = Math.floor(difference / oneDay);

    return days;
  }

  function differenceInText(date1: string, date2: string): string {
    const days = getDifferenceInDays(date1, date2);

    const years = Math.floor(days / 365);
    const months = Math.floor((days % 365) / 30);
    const remainingDays = days % 30;

    let result = "";

    if (years > 0) {
      result += `${
        years > 1 ? `${years} ${lang.dates.year.plural}` : `${years} ${lang.dates.year.singular}`
      }`;

      if (months > 0) {
        result += `${remainingDays === 0 ? ` ${lang.words.and}` : ","} ${
          months > 1
            ? `${months} ${lang.dates.month.plural}`
            : `${months} ${lang.dates.month.singular}`
        }`;
      }

      if (remainingDays > 0) {
        result += ` ${lang.words.and} ${
          remainingDays > 1
            ? `${remainingDays} ${lang.dates.day.plural}`
            : `${remainingDays} ${lang.dates.day.singular}`
        }`;
      }
    } else if (months > 0) {
      result += `${
        months > 1
          ? `${months} ${lang.dates.month.plural}`
          : `${months} ${lang.dates.month.singular}`
      }`;

      if (remainingDays > 0) {
        result += ` ${lang.words.and} ${
          remainingDays > 1
            ? `${remainingDays} ${lang.dates.day.plural}`
            : `${remainingDays} ${lang.dates.day.singular}`
        }`;
      }
    } else {
      if (remainingDays > 0) {
        result += `${
          remainingDays > 1
            ? `${remainingDays} ${lang.dates.day.plural}`
            : `${remainingDays} ${lang.dates.day.singular}`
        }`;
      }
    }

    return result;
  }

  return {
    nowDate,
    nowDay: currentDay < 10 ? `0${currentDay}` : currentDay,
    nowMonth: currentMonth < 10 ? `0${currentMonth}` : currentMonth,
    nowYear: currentYear,
    formatISODate,
    formatISOToLocalDateTime,
    formatISODateIfValid,
    isValidDateFormat,
    isToday,
    getDateWithoutTime,
    differenceInText,
  };
};
