import GetAppIcon from "@material-ui/icons/GetApp";
import FilterListIcon from "@material-ui/icons/FilterList";
import EventIcon from "@material-ui/icons/Event";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ListAltIcon from "@material-ui/icons/ListAlt";
import SaveIcon from "@material-ui/icons/Save";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import BuildIcon from "@material-ui/icons/Build";
import DeleteIcon from "@material-ui/icons/Delete";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import MapIcon from "@material-ui/icons/Map";
import ViewWeekIcon from "@material-ui/icons/ViewWeek";
import CreateIcon from "@material-ui/icons/Create";
import CropFreeOutlinedIcon from "@material-ui/icons/CropFreeOutlined";
import SpeakerNotesOutlinedIcon from "@material-ui/icons/SpeakerNotesOutlined";
import ToggleOffOutlinedIcon from "@material-ui/icons/ToggleOffOutlined";
import PowerSettingsNewOutlinedIcon from "@material-ui/icons/PowerSettingsNewOutlined";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PowerIcon from "@material-ui/icons/Power";
import VisibilityIcon from "@material-ui/icons/Visibility";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import FlagOutlinedIcon from "@material-ui/icons/FlagOutlined";
import LabelIcon from "@material-ui/icons/Label";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import SendIcon from "@material-ui/icons/Send";
import LanguageIcon from "@material-ui/icons/Language";
import WifiIcon from "@material-ui/icons/Wifi";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Add,
  AddCircle,
  BubbleChart,
  Business,
  Check,
  CodeSharp,
  ErrorOutlineRounded,
  FlagOutlined,
  HomeOutlined,
  LabelImportantOutlined,
  LocationSearching,
  NewReleases,
  Opacity,
  Assignment,
  Storage,
  Schedule,
  LabelOff,
  ChevronLeft,
  ChevronRight,
  Notifications
} from "@material-ui/icons";
import BlurOnIcon from "@material-ui/icons/BlurOn";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CachedIcon from "@material-ui/icons/Cached";
import RefreshIcon from "@material-ui/icons/Refresh";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import WidgetsOutlinedIcon from "@material-ui/icons/WidgetsOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import TodayIcon from "@material-ui/icons/Today";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SyncIcon from "@material-ui/icons/Sync";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import MobileFriendlyIcon from "@material-ui/icons/MobileFriendly";
import PhonelinkEraseIcon from "@material-ui/icons/PhonelinkErase";
import Close from "@material-ui/icons/Close";
import PersonIcon from "@material-ui/icons/Person";
import SearchIcon from "@material-ui/icons/Search";
import InventoryIcon from "../../assets/icons/InventoryIcon";
import HelmetIcon from "../../assets/icons/HelmetIcon";
import PermissionsIcon from "../../assets/icons/PermissionsIcon";
import OilCanIcon from "../../assets/icons/OilCanIcon";
import CleaningIcon from "../../assets/icons/CleaningIcon";
import OutOfRouteIcon from "../../assets/icons/OutOfRouteIcon";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import UpdateIcon from "@material-ui/icons/Update";
import ExitToApp from "@material-ui/icons/ExitToApp";
import LabIcon from "../../assets/icons/LabIcon";
import RoomIcon from "@material-ui/icons/Room";
import QRCode from "react-qr-code";
import { CustomIconType } from "./types/CustomButtonTypes";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

export interface CustomIconProps {
  icon: CustomIconType | undefined;
  style?: CSSProperties;
  onClick?: (e: any) => void;
}

export const CustomIcon: React.FC<CustomIconProps> = ({ style, icon, onClick }) => {
  const componentStyle = {
    margin: "4px",
    ...style,
  };
  const iconProps = {
    onClick,
  };

  return (
    <>
      {icon === "add" && <Add {...iconProps} style={componentStyle} />}
      {icon === "add-circle" && <AddCircle {...iconProps} style={componentStyle} />}
      {icon === "edit" && <CreateIcon {...iconProps} style={componentStyle} />}
      {icon === "delete" && <DeleteIcon {...iconProps} style={componentStyle} />}
      {icon === "close" && (
        <Close {...iconProps} style={componentStyle}>
          {" "}
          close
        </Close>
      )}
      {icon === "config" && <SettingsOutlinedIcon {...iconProps} style={componentStyle} />}
      {icon === "check" && <Check {...iconProps} style={componentStyle} />}
      {icon === "download" && <GetAppIcon {...iconProps} style={componentStyle} />}
      {icon === "filter" && <FilterListIcon {...iconProps} style={componentStyle} />}
      {icon === "calendar" && <EventIcon {...iconProps} style={componentStyle} />}
      {icon === "error" && <ErrorOutlineIcon {...iconProps} style={componentStyle} />}
      {icon === "success" && <CheckCircleOutlineIcon {...iconProps} style={componentStyle} />}
      {icon === "stock" && <WidgetsOutlinedIcon {...iconProps} style={componentStyle} />}
      {icon === "save" && <SaveIcon {...iconProps} style={componentStyle} />}
      {icon === "next" && <NavigateNextIcon {...iconProps} style={componentStyle} />}
      {icon === "before" && <NavigateBeforeIcon {...iconProps} style={componentStyle} />}
      {icon === "addList" && <PlaylistAddIcon {...iconProps} style={componentStyle} />}
      {icon === "map" && <MapIcon {...iconProps} style={componentStyle} />}
      {icon === "tool" && <BuildIcon {...iconProps} style={componentStyle} />}
      {icon === "columns" && <ViewWeekIcon {...iconProps} style={componentStyle} />}
      {icon === "observations" && (
        <SpeakerNotesOutlinedIcon {...iconProps} style={componentStyle} />
      )}
      {icon === "survey" && <CropFreeOutlinedIcon {...iconProps} style={componentStyle} />}
      {icon === "toggleOff" && <ToggleOffOutlinedIcon {...iconProps} style={componentStyle} />}
      {icon === "off" && <PowerSettingsNewOutlinedIcon {...iconProps} style={componentStyle} />}
      {icon === "state" && <FiberManualRecordIcon {...iconProps} style={componentStyle} />}
      {icon === "surveyPlay" && <PlayCircleFilledWhiteIcon {...iconProps} style={componentStyle} />}
      {icon === "more" && <MoreVertIcon {...iconProps} style={componentStyle} />}
      {icon === "checkedCircle" && <CheckCircleOutlineIcon {...iconProps} style={componentStyle} />}
      {icon === "power" && <PowerIcon {...iconProps} style={componentStyle} />}
      {icon === "view" && <VisibilityIcon {...iconProps} style={componentStyle} />}
      {icon === "info" && <InfoOutlinedIcon {...iconProps} style={componentStyle} />}
      {icon === "flag" && <FlagOutlinedIcon {...iconProps} style={componentStyle} />}
      {icon === "label" && <LabelIcon {...iconProps} style={componentStyle} />}
      {icon === "email" && <MailOutlineIcon {...iconProps} style={componentStyle} />}
      {icon === "send" && <SendIcon {...iconProps} style={componentStyle} />}
      {icon === "language" && <LanguageIcon {...iconProps} style={componentStyle} />}
      {icon === "list" && <ListAltIcon {...iconProps} style={componentStyle} />}
      {icon === "wifi" && <WifiIcon {...iconProps} style={componentStyle} />}
      {icon === "inventory" && <InventoryIcon {...iconProps} style={componentStyle} />}
      {icon === "helmet" && <HelmetIcon {...iconProps} style={componentStyle} />}
      {icon === "permission" && <PermissionsIcon {...iconProps} style={componentStyle} />}
      {icon === "oilCan" && <OilCanIcon {...iconProps} style={componentStyle} />}
      {icon === "arrowUp" && <ExpandLessIcon {...iconProps} style={componentStyle} />}
      {icon === "arrowDown" && <ExpandMoreIcon {...iconProps} style={componentStyle} />}
      {icon === "arrowRight" && <ChevronRight {...iconProps} style={componentStyle} />}
      {icon === "arrowLeft" && <ChevronLeft {...iconProps} style={componentStyle} />}
      {icon === "leak" && <Opacity {...iconProps} style={componentStyle} />}
      {icon === "temperature" && <BlurOnIcon {...iconProps} style={componentStyle} />}
      {icon === "pending" && <HourglassEmptyIcon {...iconProps} style={componentStyle} />}

      {icon === "lab" && <LabIcon {...iconProps} style={componentStyle} />}
      {/* {(icon === 'lab') && <BugReport {...iconProps} style={componentStyle} />} */}
      {icon === "cleaning" && <CleaningIcon {...iconProps} style={componentStyle} />}
      {icon === "cleaning" && (
        <OutOfRouteIcon {...iconProps} style={{ ...componentStyle, fill: "#fff" }} />
      )}
      {icon === "resync" && <CachedIcon {...iconProps} style={componentStyle} />}
      {icon === "refresh" && <RefreshIcon {...iconProps} style={componentStyle} />}
      {icon === "cancelOutlined" && <CancelOutlinedIcon {...iconProps} style={componentStyle} />}
      {icon === "outOfRouteWhite" && <OutOfRouteIcon {...iconProps} style={componentStyle} />}
      {icon === "nextState" && <LabelImportantOutlined {...iconProps} style={componentStyle} />}
      {/* {(icon === 'search') && <GridSearchIcon {...iconProps} style={componentStyle} />} */}
      {icon === "capacity" && <ZoomOutMapIcon {...iconProps} style={componentStyle} />}
      {icon === "date" && <TodayIcon {...iconProps} style={componentStyle} />}
      {icon === "user" && <AccountCircleIcon {...iconProps} style={componentStyle} />}
      {icon === "sync" && <SyncIcon {...iconProps} style={componentStyle} />}
      {icon === "smartPhone" && <PhoneIphoneIcon {...iconProps} style={componentStyle} />}
      {icon === "desktop" && <DesktopWindowsIcon {...iconProps} style={componentStyle} />}
      {icon === "readyToSync" && <MobileFriendlyIcon {...iconProps} style={componentStyle} />}
      {icon === "notReadyToSync" && <PhonelinkEraseIcon {...iconProps} style={componentStyle} />}
      {icon === "person" && <PersonIcon {...iconProps} style={componentStyle} />}
      {icon === "home" && <HomeOutlined {...iconProps} style={componentStyle} />}
      {icon === "news" && <ErrorOutlineRounded {...iconProps} style={componentStyle} />}
      {icon === "notifications" && <Notifications {...iconProps} style={componentStyle} />}
      {icon === "search" && <SearchIcon {...iconProps} style={componentStyle} />}
      {icon === "points" && <LocationSearching {...iconProps} style={componentStyle} />}
      {icon === "routes" && <FlagOutlined {...iconProps} style={componentStyle} />}
      {icon === "bubble_chart" && <BubbleChart {...iconProps} style={componentStyle} />}
      {icon === "circleOutlined" && (
        <RadioButtonUncheckedIcon {...iconProps} style={componentStyle} />
      )}
      {icon === "updateSync" && <UpdateIcon {...iconProps} style={componentStyle} />}
      {icon === "code" && <CodeSharp {...iconProps} style={componentStyle} />}
      {icon === "business" && <Business {...iconProps} style={componentStyle} />}
      {icon === "new-release" && <NewReleases {...iconProps} style={componentStyle} />}
      {icon === "logout" && <ExitToApp {...iconProps} style={componentStyle} />}
      {icon === "pin" && <RoomIcon {...iconProps} style={componentStyle} />}
      {icon === "qr" && (
        <QRCode
          size={15}
          value={""}
          style={{
            ...componentStyle,
            marginBottom: "8px",
          }}
        />
      )}
      {icon === "assignment" && <Assignment {...iconProps} style={componentStyle} />}
      {icon === "storage" && <Storage {...iconProps} style={componentStyle} />}
      {icon === "schedule" && <Schedule {...iconProps} style={componentStyle} />}
      {icon === "labelOff" && <LabelOff {...iconProps} style={componentStyle} />}
    </>
  );
};
