import React from "react";
import {
  AppBar,
  createStyles,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { equipmentType } from "../../model/lubricationPointsInterface";
import FullscreenSurface from "../../../../components/containers/modal/FullScreenSurface/FullscreenSurface";
import CustomButton from "../../../../components/buttons/CustomButton";
import { useModal } from "../../../../components/containers/modal/Modal/hooks/useModal";
import { useEquipmentsTag } from "../../services/lubricationPointsAdapters";
import FlexContainer from "../../../../components/containers/FlexContainer";
import EquipmentFileTemplate from "./EquipmentFileTemplate";
import UpdateEquipment from "./UpdateEquipment";
import DesactivateActivateEquipment from "./DesactivateActivateEquipment";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    root: {
      width: "100%",
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  })
);

interface Props {
  item: any;
  type: equipmentType.element | equipmentType.equipment;
}
const EditEquipmentComponent: React.FC<Props> = ({ item, type }) => {
  const { lang } = useLangLabels();

  const classes = useStyles();
  const { isOpen, toggleModal } = useModal();
  const { getTag } = useEquipmentsTag();

  return (
    <>
      <CustomButton action={toggleModal} variant="iconButton" icon="edit" />

      <FullscreenSurface isOpen={isOpen}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <CustomButton icon="close" color="inherit" variant="iconButton" action={toggleModal} />

            <Typography variant="h6" className={classes.title}>
              {lang.crudActions.update} {lang.STIEquipments.form.label.equipment}
            </Typography>
          </Toolbar>
        </AppBar>
        <br />
        <FlexContainer justify="center" gap="16px">
          <EquipmentFileTemplate item={item} type={type} />
          <FlexContainer flexDirection="column">
            <UpdateEquipment item={item} />
            <DesactivateActivateEquipment
              variant="fab"
              type={equipmentType.equipment}
              tagTGD={getTag(item, equipmentType.equipment)}
              item={item}
            />
          </FlexContainer>
        </FlexContainer>
      </FullscreenSurface>
    </>
  );
};

export default EditEquipmentComponent;
