import { CircularProgress, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import theme from "../../../assets/theme";
import { usePbiAccessToken } from "../../../domains/powerBi/services/service.pbi";
import { useSessionContext } from "../../../domains/session/store/sessionContext";
import { LoginPageContainer } from "../../login/components/LoginFormContainer";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { useTodayDelayedAndPendingSetter } from "../session/services/sessionAdapter";

const LoadingNecesaryData = ({ internalRole = "" }: { internalRole?: string }) => {
  const { lang } = useLangLabels();
  const navigate = useNavigate();
  const { data: addToken } = usePbiAccessToken();
  const { person, currentPbigroupName, token, user } = useSessionContext();
  const { isSuccess } = useTodayDelayedAndPendingSetter();

  useEffect(() => {
    if (person && user) {
      const navigateToRole = (role: string) => {
        setTimeout(() => {
          navigate(role);
        }, 1000);
      };

      if (
        internalRole &&
        ["instruments"].includes(internalRole) &&
        ["Operario", "Supervisor"].includes(person.role)
      ) {
        if (token && isSuccess) {
          navigateToRole(internalRole);
          return;
        }
      }

      if (["Cliente"].includes(person.role)) {
        if (addToken && currentPbigroupName) {
          navigateToRole(person.role);
          return;
        }
      }

      if (["Operario", "Supervisor"].includes(person.role)) {
        if (token && isSuccess) {
          navigateToRole(person.role);
          return;
        }
      }

      if (["Super Admin"].includes(person.role)) {
        if (token) {
          navigateToRole(person.role.replace(/ /g, "-"));
        }
      }
    }
  }, [token, isSuccess, person]);

  return (
    <LoginPageContainer color={theme.palette.secondary.main as string}>
      <CircularProgress size={"24px"} style={{ color: "white" }} />
      <br />
      {!!token && (
        <Typography color={"inherit"} style={{ color: "white" }}>
          {lang.client.connectingServices}
        </Typography>
      )}
    </LoginPageContainer>
  );
};

export default LoadingNecesaryData;
