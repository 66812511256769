import React from "react";
import { Typography } from "@material-ui/core";
import { CriticalityInterface } from "../models/criticalityInterface";
import FlexContainer from "../../../components/containers/FlexContainer";

const CriticalityIndicator: React.FC<{ criticality: CriticalityInterface }> = ({ criticality }) => {
  const { color, description, number } = criticality;

  return (
    <FlexContainer
      margin="4px"
      padding="0px 12px 0px 0px"
      height="48px"
      width="150px"
      style={{
        borderLeft: `4px solid ${color}`,
      }}
      align="center"
      justify="center"
      color={color}
    >
      <Typography color="inherit" align="left" style={{ fontWeight: "bold" }}>
        ({number}):
      </Typography>
      <Typography color="inherit" align="left">
        {description!}{" "}
      </Typography>
    </FlexContainer>
  );
};

export default CriticalityIndicator;
