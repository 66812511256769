import { Paper } from "@material-ui/core";
import FlexContainer from "../../../components/containers/FlexContainer";
import { SurveyInterface } from "../../../domains/surveys/model/SurveyInterface";
import {
  LubricationPointInterface,
  lubricationPointName,
} from "../../../domains/lubricationPoints/model/lubricationPointsInterface";
import { useSurveyWithObservationsGridModel } from "../../../domains/surveys/services/lang/useSurveyWithObservationsGridModel";
import { CircularProgress, Table, TableBody, TableHead } from "@mui/material";
import ExploreSurveyRow from "./ExploreSurveyRow";
import { useExploredSurveys } from "../SurveyExploreCache";
import { CustomTableCell } from "./CustomTableCell";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

interface SurveyListProps {
  surveys: SurveyInterface[];
  lubricationPoint: LubricationPointInterface | null | undefined;
}

export const SurveyList: React.FC<SurveyListProps> = ({ lubricationPoint, surveys }) => {
  const { lang } = useLangLabels();
  const listIsEmpty = surveys?.length === 0;
  const { data: surveysObservations, isLoading } = useExploredSurveys(surveys);

  if (isLoading) {
    return (
      <Paper>
        <FlexContainer width={"100%"} height={"200px"} align="center" justify="center">
          <CircularProgress />
        </FlexContainer>
      </Paper>
    );
  }

  if (listIsEmpty) {
    return (
      <Paper>
        <FlexContainer height={"200px"} justify="center" align="center">
          {lang.explore.thereAreNoSurveys}
        </FlexContainer>
      </Paper>
    );
  }

  return (
    <Paper
      style={{
        padding: "24px",
      }}
    >
      <Table>
        <TableHead style={{ backgroundColor: "#e6e6e6" }}>
          <CustomTableCell>{lang.iotDevices.title.singular}</CustomTableCell>
          <CustomTableCell>{lang.routes.startDate}</CustomTableCell>
          <CustomTableCell>{lang.surveys.tasks.lubrication.title}</CustomTableCell>
          <CustomTableCell>{lang.incomeForm.quantity}</CustomTableCell>
          <CustomTableCell>{lang.addedReasons.singular}</CustomTableCell>
          <CustomTableCell>{lang.surveys.outOfRoute}</CustomTableCell>
          <CustomTableCell>{lang.lubricationPoints.observations}</CustomTableCell>
        </TableHead>
        <TableBody>
          {surveysObservations?.map((survey, i) => (
            <ExploreSurveyRow key={i} survey={survey} />
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};
