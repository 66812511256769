export interface RevisionChangeInterface {
  id: number;
  tagTGD: string;
  review: number;
  requestDate: string;
  requestReason: string;
  requestUser: string;
  supervisionDate: string;
  supervisionReason: string;
  supervisionUser: string;
  state: RevisionChangeStateType;
  impactDate: string;
  tagFP: string;
}

export interface RevisionChangeFormInterface extends RevisionChangeInterface {
  isApproved: boolean;
}

export type RevisionChangeStateType = "SOLICITADO" | "APROBADO" | "RECHAZADO";

export enum RevisionChangeState {
  SOLICITADO = "SOLICITADO",
  APROBADO = "APROBADO",
  RECHAZADO = "RECHAZADO",
}
