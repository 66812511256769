import ComponentMode from "../../../../components/componentMode/ComponentMode";
import { Typography } from "@material-ui/core";
import CustomButton from "../../../../components/buttons/CustomButton";
import StatusAndMessage from "../../../../shared/queries/errors/components/StatusAndMessage";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { FlexContainer } from "../../../../components/containers";
import FieldSelector from "../../../../shared/form/components/FieldSelector";
import { useCreateRevisionChange } from "../services/revisionChangeServices";
import useUpdateEquipmentRevisionForm from "../hooks/useUpdateEquipmentRevisionForm";
import { LubricationPointInterface } from "../../../lubricationPoints/model/lubricationPointsInterface";

const UpdateEquipmentRevision = ({ item }: { item: LubricationPointInterface }) => {
  const { lang } = useLangLabels();
  const title = lang.revisionChange.title.singular;

  const { mutate: createRevisionChange, status, error, message, reset } = useCreateRevisionChange();

  const { formattedUiModel, handleSubmit } = useUpdateEquipmentRevisionForm(item);

  const onSubmit = (data: any) => {
    createRevisionChange({
      ...data,
      requestReason: data.supervisionReason,
      requestDate: data.supervisionDate,
      requestUser: data.supervisionUser,
    });
  };

  return (
    <ComponentMode
      {...{
        popoverTitle: title,
        icon: "nextState",
        buttonColor: "primary",
        variant: "iconButton",
        mode: "popover",
      }}
    >
      <Typography variant="h6">{`${title}: ${lang.words.from} ${
        item.review
      } ${lang.words.to.toLowerCase()} ${Number(item.review) + 1}`}</Typography>

      <FlexContainer flexDirection="column" gap={"12px"} width={"500px"}>
        <FieldSelector fieldProps={{ ...formattedUiModel.supervisionReason }} />
        <FieldSelector fieldProps={{ ...formattedUiModel.impactDate }} />
      </FlexContainer>

      <StatusAndMessage {...{ status, error: (error as any)?.message, message, reset }} />

      <CustomButton
        {...{
          status: error ? "error" : status,
          error,
          message,
          action: handleSubmit(onSubmit),
          icon: "save",
          popoverTitle: lang.crudActions.save,
        }}
      />
    </ComponentMode>
  );
};

export default UpdateEquipmentRevision;
