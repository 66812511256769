import React, { useEffect } from "react";
import { useDeleteLubricantVerification } from "./services/service.lubricants";
import CustomButton from "../../components/buttons/CustomButton";
import { useSnackbar } from "../../components/snackbar/hooks/useSnackbar";
import { LubricantInterface } from "./model/LubricantInterface";
import { useClickPosition } from "../../shared/globals/hooks/useClickPosition";
import { CircularProgress } from "@material-ui/core";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";

interface Props {
  item: LubricantInterface;
}
const DeleteLubricantform: React.FC<Props> = ({ item }) => {
  const {
    validate,
    errors,
    status: validationStatus,
    query: { status, message },
  } = useDeleteLubricantVerification();

  const { lang } = useLangLabels();

  const { position, getClickPosition } = useClickPosition();
  const { setData } = useSnackbar();

  useEffect(() => {
    if (validationStatus === "error") {
      const componentsError = errors?.equipments?.length
        ? errors.equipments.length > 1
          ? `${errors.equipments.length} ${lang.STIEquipments.form.label.equipments}`
          : `${errors.equipments.length} ${lang.STIEquipments.form.label.equipment}`
        : "";

      const containersError = errors?.containers?.length
        ? errors.containers.length > 1
          ? `${errors.containers.length} ${lang.containers.title.plural}`
          : `${errors.containers.length} ${lang.containers.title.singular}`
        : "";
      setData({
        modal: true,
        errors: `${lang.lubricantForm.lubricantCannotBeRemoved}: ${
          containersError && containersError
        } ${componentsError && componentsError}`,
        severity: "warning",
        position: position,
      });
    }
    if (validationStatus === "success") {
      setData({
        modal: true,
        errors: message,
        severity: status ? "success" : "warning",
        position: position,
      });
    }
  }, [validationStatus]);

  return validationStatus === "loading" || status === "loading" ? (
    <CircularProgress size="24px" />
  ) : (
    <CustomButton
      popoverTitle={lang.crudActions.delete + " " + lang.lubricantForm.singular}
      action={(e: any) => getClickPosition(e, () => validate(item))}
      status={status}
      variant="iconButton"
      icon="delete"
    />
  );
};

export default React.memo(DeleteLubricantform);
