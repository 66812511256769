import { GridModelInterface } from "../../../shared/gridModel/gridModelEntity"
import { useLangLabels } from "../../../shared/lang/services/useLangLabels"



export const useComponentsGridModel = () => {

    const {lang} = useLangLabels()

    const gridModel:GridModelInterface = {
        id:{label:lang.globalentityValues.id}, 
        code:{label:lang.components.form.label.code}, 
        component:{label:lang.components.form.label.component}, 
        date:{label:lang.globalentityValues.date}, 
        tagFP:{label:lang.globalentityValues.tagFP}, 
    }

    return gridModel
}