import { Button, Chip,  CircularProgress, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import theme from '../../assets/theme';
import ListContainer from '../../components/containers/ListContainer';
import PageContainer from '../../components/containers/PageContainer';
import TgdAuxList from '../../components/grids/TgdAuxList';
import TgdList from '../../components/grids/TgdList';
import { useActivateDemoRequest, useDemoRequests, useDesactivateDemoUser, useHiddenDemoRequests, useShowedDemoRequests, useUpdateDemoRequest } from '../../domains/demo/services/demoServices';
import { htmlDemoApproval } from '../../domains/emails/emailTemplates/htmlClientDemo';
import { useSessionContext } from '../../domains/session/store/sessionContext';
import QueryStatus from '../../domains/tickets/status/QueryStatus';
import OnlineOfflineMode from '../../shared/config/offline Mode/OnlineOfflineMode';
import { calcDate } from '../../shared/date/utils';
import { USER_ROUTES } from '../../shared/routes/routes';

const useStyles = makeStyles((theme) => ({
    active:{
        backgroundColor:theme.palette.background.default,
        margin:'0 8px',
    }
}));


interface Props {
    title:string
    customColor?:string
    themeColor?:"primary" | "secondary" | "default" | undefined
    action?:()=>void
    clickable?:boolean

}
const TgdChip:React.FC<Props> = ({title,customColor,themeColor,action,clickable}) => {

    const [loading,setLoading] = useState(false)

    const handleAction = () => {
        setLoading(true)
        action && action()
    }

    if(loading) return <CircularProgress size='16px'/>
    return(
        <>
            <Chip
            style={{backgroundColor: customColor ? customColor : '',color: customColor ?'white':'white',margin: '8px'}}
            size="small"
            label={title}
            color={themeColor}
            {...clickable && clickable }
            onClick={handleAction}/>
        </>
     )
}


const AddDemoUserPage:React.FC = () => {

    const {token,person} = useSessionContext()
    const classes=useStyles()
    const [showedDemoRequests,{status:showedStatus}] = useShowedDemoRequests()
    const [hiddenDemoRequests,{status:hiddenStatus}] = useHiddenDemoRequests()

    const {refetch} = useDemoRequests()

    //mutations
    const {mutate:AproveDemoUser} = useActivateDemoRequest({onSuccess:refetch})
    const {mutate:ActivateDesactivateDemoUser} = useDesactivateDemoUser({onSuccess:refetch})
    const {archive,unArchive} = useUpdateDemoRequest({onSuccess:refetch})

    const CustomButtons = (item:any) => {

        const handleDemoUserState = (boolean:boolean) => {
            ActivateDesactivateDemoUser({
                data:{
                    applicantsEmail:item.applicantsEmail,
                    demoState:boolean
                },
                token
            })
        }

        const handleActivate = () => {
            if(!item.unsubscribeDate){
                AproveDemoUser({
                    data:{
                        name: item.applicantsName,
                        telephone: item.applicantsTelephone,
                        address: 'No ha ingresado una dirección',
                        email: item.applicantsEmail,
                        file: 0,
                        referrer: person?.id,
                        password: `${item.applicantsTelephone}_${item.applicantsName}`,
                        tagF:'DEMO',
                        tagFP:'',
                        htmlDemoApproval:htmlDemoApproval(item),
                    },
                    token
            })  
            }else if(item.unsubscribeDate){
                handleDemoUserState(true)
            }
            
        }

        
        return(
            <>
                    {item && !item.demoState && !item.unsubscribeDate &&
                       < TgdChip
                        key={1 + item.id}
                        title={'Esperando Revisión'}
                        themeColor='primary'
                       />
                    
                    }

                    {item && item.demoState && !(calcDate(item.unsubscribeDate) < 0) && 
                        < TgdChip
                        key={5 + item.id}
                        title={'Activado'}
                        customColor={'green'}
                       />
                    }

                    {item && !item.demoState && (calcDate(item.unsubscribeDate) < 0) && !!item.unsubscribeDate &&
                        < TgdChip
                        key={7 + item.id}
                        title={'Vencido'}
                        customColor={'black'}
                       />
                    }
    
                    {item && !item.demoState && !item.hidden &&
                       < TgdChip
                       key={4 + item.id}
                       title={item.unsubscribeDate ? 'renovar' : 'Activar'}
                       customColor={`orange`}
                       action={handleActivate}
                       clickable={true}
                      />
                        
                    }
                    
                    

                    {item && item.demoState && !item.hidden &&
                        < TgdChip
                        key={6 + item.id}
                        title={'Desactivar'}
                        themeColor={`primary`}
                        action={()=>handleDemoUserState(false)}//desactivate
                        clickable={true}
                        />
                    }

                    {item && !item.hidden && 
                       < TgdChip
                       key={2 + item.id}
                       title={'Archivar'}
                       themeColor='secondary'
                       action={()=>archive(item.applicantsEmail)}
                       clickable={true}
                      />
                    }
    
                    {item && item.hidden &&
                        < TgdChip
                        key={3 + item.id}
                        title={'Restaurar'}
                        themeColor='secondary'
                        action={()=>unArchive(item.applicantsEmail)}
                        clickable={true}
                       />
                    }
                </> 
        )
    }
  
    return (
        <OnlineOfflineMode>
            <QueryStatus
                status={showedStatus}
                errorMessage={'no se han podido obtener usuarios demo'}
                children={<>
                    <ListContainer>
                        <PageContainer >
                            {/* <TgdListToolbar 
                                searchBar={true}
                                title={'Solicitudes Demo'}
                                actions={<></>}
                            /> */}
                        </PageContainer>
                            <PageContainer>
                                <Button type='button' style={({isActive})=>isActive ? {margin:'0 8px',backgroundColor:theme.palette.background.default} : {margin:'0 8px'}} end component={NavLink} to={''}> Pendientes </Button>
                                <Button type='button' style={({isActive})=>isActive ? {margin:'0 8px',backgroundColor:theme.palette.background.default} : {margin:'0 8px'}} end component={NavLink} to={USER_ROUTES.RejectedDemoUsers}> Archivados </Button>
                            </PageContainer>

                            <Routes>

                                <Route  path={''} 
                                element={
                                    <TgdList
                                        icon={'person'}
                                        status={showedStatus}
                                        editIcon='view'
                                        items={showedDemoRequests}
                                        customButtons={CustomButtons}
                                    />
                                }/>
                                   

                                <Route  path={USER_ROUTES.RejectedDemoUsers} 
                                element={
                                    <TgdList
                                        icon={'person'}
                                        status={hiddenStatus}
                                        editIcon='view'
                                        items={hiddenDemoRequests}
                                        customButtons={CustomButtons}
                                    />
                                } />
                            </Routes>
                    </ListContainer>
                </>}
            />
        </OnlineOfflineMode>
    );
}

export default React.memo(AddDemoUserPage);
