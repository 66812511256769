import React, { useEffect, useState } from "react";
import ModalButton from "../../../components/buttons/ModalButton";
import { useForm, Controller } from "react-hook-form";

import { useCarrousell } from "../../../components/containers/toolbar/hooks/useCarrousell";
import { useSessionContext } from "../../session/store/sessionContext";

import { ComponentUIModes } from "../../../components/componentMode/types/componentModesInterfaces";
import { StockInterface } from "../models/StockInterface";
import { useUpdateStock } from "../services/service.stock";
import { TextField, Typography } from "@material-ui/core";
import StatusAndMessage from "../../../shared/queries/errors/components/StatusAndMessage";
import FlexContainer from "../../../components/containers/FlexContainer";
import SelectArea from "../../areas/components/SelectArea";
import SelectStockComplexity from "./SelectStockComplexity";
import CustomButton from "../../../components/buttons/CustomButton";
import SelectTypeBy from "../../types/components/SelectTypeBy";
import { enumTgdTypes } from "../../types/models/types";
import theme from "../../../assets/theme";
import HandleUndefined from "../../../components/containers/handleUndefined/HandleUndefined";
import Modal from "../../../components/containers/modal/Modal/Modal";
import { isTemplateSpan } from "typescript";
import { useModal } from "../../../components/containers/modal/Modal/hooks/useModal";
import PageContainer from "../../../components/containers/PageContainer";
import CarrousellToolbar from "../../../components/containers/toolbar/CarrousellToolbar";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

interface Props {
  mode?: ComponentUIModes;
  items: StockInterface[];
}

const UpdateStockForm: React.FC<Props> = ({ mode, items }) => {
  const { currentPlant } = useSessionContext();
  const tagFP = currentPlant?.tagFP!;
  const componentMode = mode || "modal";
  const modalProps = useModal();
  const carrousell = useCarrousell(items.length);
  const defaultValue = items[carrousell.current];
  const { updateStock, status, error, message } = useUpdateStock();
  const { register, setValue, errors, watch, handleSubmit, control } = useForm();
  const { lang } = useLangLabels();
  const title = "Editar item de stock";

  useEffect(() => {
    defaultValue &&
      Object.entries(defaultValue).map(([key, value]: [string, any]) => {
        register(key, {
          required: {
            value: !!value,
            message: lang.formMessages.required,
          },
        });
        setValue(key, value);
      });
  }, [defaultValue]);

  const submit = (item: StockInterface) => {
    updateStock({
      ...item,
    });
  };

  const content = (
    <PageContainer>
      <Typography variant="h6">{componentMode === "content" && title}</Typography>
      <StatusAndMessage status={status} message={message} error={error} />
      <FlexContainer padding={`${theme.spacing(3)}px 0px`} flexDirection={"column"}>
        <TextField
          inputRef={register()}
          name="product"
          size="small"
          variant="outlined"
          defaultValue={watch("product")}
          label={errors?.product?.message || "Nombre del producto"}
          color={errors?.product?.message ? "primary" : "secondary"}
        />

        <TextField
          inputRef={register()}
          name="brand"
          size="small"
          variant="outlined"
          label={errors?.brand?.message || "Marca"}
          color={errors?.brand?.message ? "primary" : "secondary"}
          defaultValue={watch("brand")}
        />

        <TextField
          inputRef={register()}
          defaultValue={watch("actualStock")}
          name="actualStock"
          size="small"
          variant="outlined"
          label={errors?.actualStock?.message || "Stock actual"}
          color={errors?.actualStock?.message ? "primary" : "secondary"}
        />

        <TextField
          inputRef={register()}
          name="minimumRequired"
          defaultValue={watch("minimumRequired")}
          size="small"
          variant="outlined"
          label={errors?.minimumRequired?.message || "Cantidad mínima"}
          color={errors?.minimumRequired?.message ? "primary" : "secondary"}
        />

        <Controller
          as={
            <SelectTypeBy
              error={errors?.measureUnit?.message}
              by={{ key: "type", value: enumTgdTypes.unity }}
              tagFP={tagFP}
              onChange={(value: any) => setValue("measureUnit", value)}
              defaultValue={watch("measureUnit")}
            />
          }
          name="measureUnit"
          defaultValue={watch("measureUnit")}
          rules={{ required: { value: true, message: "Campo Obligatorio" } }}
          control={control}
        />

        <Controller
          as={
            <SelectArea
              error={errors?.providerArea?.message}
              onChange={(value: any) => setValue("providerArea", value)}
              defaultValue={watch("providerArea")}
            />
          }
          name="providerArea"
          defaultValue={watch("providerArea")}
          rules={{ required: { value: true, message: "Campo Obligatorio" } }}
          control={control}
        />

        <Controller
          as={
            <SelectStockComplexity
              handleChange={(value: any) => setValue("complex", value)}
              error={errors?.complex?.message}
              defaultValue={watch("complex") ? "COMPLEJO" : "SIMPLE"}
            />
          }
          name="complex"
          control={control}
        />

        <CustomButton status={status} action={handleSubmit(submit)}>
          {lang.crudActions.save}
        </CustomButton>
      </FlexContainer>
    </PageContainer>
  );

  return (
    <>
      {componentMode === "content" && content}

      {componentMode === "modal" && (
        <HandleUndefined callback={modalProps.openModal} showError={!(items.length > 0)}>
          {(callback) => (
            <Modal
              buttonIcon="edit"
              buttonVariant="iconButton"
              {...modalProps}
              openModal={callback}
            >
              <CarrousellToolbar {...carrousell} />
              {content}
            </Modal>
          )}
        </HandleUndefined>
      )}
    </>
  );
};

export default React.memo(UpdateStockForm);
