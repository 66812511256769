import React, { useEffect } from "react";
import { TextField } from "@material-ui/core";
import { ComponentUIModes } from "../../../../components/componentMode/types/componentModesInterfaces";
import { SubCategoryInterface } from "../subCategoryInterface";
import { useCreateSubCategory } from "../service.subcategories";
import { useForm } from "react-hook-form";
import ComponentMode from "../../../../components/componentMode/ComponentMode";
import StatusAndMessage from "../../../../shared/queries/errors/components/StatusAndMessage";
import CustomButton from "../../../../components/buttons/CustomButton";
import { useSessionContext } from "../../../session/store/sessionContext";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

interface Props {
  mode: ComponentUIModes;
  parentCategory: SubCategoryInterface;
}

const CreateSubCategoryForm: React.FC<Props> = ({ mode, parentCategory }) => {
  const { tagFP } = useSessionContext();
  const {
    createSubcategory,
    query: { status, error, message },
  } = useCreateSubCategory();
  const { register, errors, handleSubmit } = useForm();

  const { lang } = useLangLabels();
  const title = lang.lubricationSection.subcategory;

  useEffect(() => {
    register({ name: "id", type: "number", value: 0 });
    register(
      { name: "subcategory", value: "" },
      { required: { value: true, message: lang.formMessages.required } }
    );
    register({ name: "tagFP", value: tagFP });
    register({ name: "categoryArea", type: "string", value: "LUBRICACION" });
    register({ name: "category", value: parentCategory });
  });

  const submit = (item: SubCategoryInterface) => {
    createSubcategory(item);
  };

  return (
    <>
      <ComponentMode mode={mode} title={title}>
        <StatusAndMessage status={status} message={message} error={error} />
        <TextField
          inputRef={register()}
          name="subcategory"
          size="small"
          variant="outlined"
          label={errors?.subcategory?.message || title}
          color={errors?.subcategory?.message ? "primary" : "secondary"}
        />
        <CustomButton status={status} action={handleSubmit(submit)}>
          {lang.crudActions.create}
        </CustomButton>
      </ComponentMode>
    </>
  );
};

export default React.memo(CreateSubCategoryForm);
