import React, { useEffect } from "react";
import { Divider, TextField, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import CustomButton from "../../../../components/buttons/CustomButton";
import FlexContainer from "../../../../components/containers/FlexContainer";
import StatusAndMessage from "../../../../shared/queries/errors/components/StatusAndMessage";
import { PurchaseRequestInterface } from "../models/PurchaseInterface";
import { useUpdatePurchaseRequest } from "../service.purchaseRequests";
import { useCarrousell } from "../../../../components/containers/toolbar/hooks/useCarrousell";
import CarrousellToolbar from "../../../../components/containers/toolbar/CarrousellToolbar";
import Modal from "../../../../components/containers/modal/Modal/Modal";
import HandleUndefined from "../../../../components/containers/handleUndefined/HandleUndefined";
import { useModal } from "../../../../components/containers/modal/Modal/hooks/useModal";
import SetPurchaseRequestStatus from "./SetPurchaseRequestStatus";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

interface Props {
  items: PurchaseRequestInterface[];
}

const initialState = (state: any) => {
  switch (state.state) {
    case "PENDIENTE":
      return {
        ...state,
        stateColor: undefined,
        forms: [
          {
            name: "observations",
            label: "Observacion",
            title: "",
            required: false,
            props: {
              variant: "outlined",
              multiline: true,
              minRows: 8,
              maxRows: 8,
              type: "text",
              size: "small",
            },
          },
        ],
      };
  }
};

const UpdatePurchaseRequest: React.FC<Props> = ({ items }) => {
  const { lang } = useLangLabels();
  const { handleSubmit, register, errors, setValue } = useForm();
  const carrousell = useCarrousell(items.length);
  const modal = useModal();

  const item = items[carrousell.current];

  const { updtePurchaseRequest, status, message, error } = useUpdatePurchaseRequest();
  const state = initialState({
    state: item?.state,
  });

  useEffect(() => {
    Object.entries(([key, value]: [string, any]) => {
      register("key");
      setValue(key, value);
    });
  }, [item]);

  const submit = (data: any) => {
    updtePurchaseRequest({
      ...item,
      ...data,
    });
  };

  return (
    <HandleUndefined showError={!(items.length > 0)} callback={modal.openModal}>
      {(callback) => (
        <Modal
          buttonPopoverTitle="Editar Orden de compra"
          buttonIcon="edit"
          buttonVariant="iconButton"
          {...modal}
          openModal={callback}
        >
          <CarrousellToolbar {...carrousell}>
            <FlexContainer padding={"24px 0px"}>
              <FlexContainer padding={"0px 12px 0px"} width="100%" flexDirection="column">
                <Typography>
                  {item?.id}
                  <br /> {item?.categoryArea}
                  <br /> {item?.category}
                  <br /> {item?.subcategory}
                  <br /> {item?.product}
                  <br /> {item?.dateLoaded}
                </Typography>
              </FlexContainer>
              <FlexContainer padding={"0px 0px 0px 2px"} flexDirection="column">
                <FlexContainer align={"center"} height="50px">
                  {item?.state}
                  <SetPurchaseRequestStatus item={item} />
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
            <Divider />

            {item?.state === "PENDIENTE" ? (
              <>
                <StatusAndMessage status={status} message={message} error={error} />
                <FlexContainer flexDirection="column" padding={"24px 0px"}>
                  {state.forms.map((form: any, index: number) => (
                    <TextField
                      key={index}
                      name={form.name}
                      inputRef={register({
                        required: {
                          value: form.required,
                          message: lang.formMessages.required,
                        },
                      })}
                      {...form.props}
                      label={errors.observation?.message || form.label}
                      color={errors.observation?.message ? "primary" : "secondary"}
                    />
                  ))}
                  <CustomButton status={status} action={handleSubmit(submit)}>
                    {lang.crudActions.save}
                  </CustomButton>
                </FlexContainer>
              </>
            ) : (
              <>
                <FlexContainer padding="24px 12px">Este item ya no puede editarse</FlexContainer>
              </>
            )}
          </CarrousellToolbar>
        </Modal>
      )}
    </HandleUndefined>
  );
};

export default React.memo(UpdatePurchaseRequest);
