import { IconButton } from "@material-ui/core";
import { CheckCircleOutlineOutlined, RadioButtonUnchecked } from "@material-ui/icons";
import React from "react";
import FlexContainer from "../../components/containers/FlexContainer";
import LubricationPointsList from "../lubricationPoints/components/operator/LubricationPointsList";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";

const CheckedButton = ({ condition }: { condition: boolean }) => {
  return (
    <>
      {condition ? (
        <CheckCircleOutlineOutlined style={{ color: "green" }} />
      ) : (
        <RadioButtonUnchecked />
      )}
    </>
  );
};

interface CheckUncheckItemProps {
  onClick?: (item: any) => void;
  item: any;
  picked: any[];
  iterator: string;
}

const CheckeUncheckItem = ({ iterator, picked, onClick, item }: CheckUncheckItemProps) => {
  const { lang } = useLangLabels();

  const handleCheck = (item: any) => {
    onClick && onClick(item);
  };

  const title: string =
    (item.lubricant
      ? `${lang.lubricationPoints.helpers.lubricationPointsThatUse}: ${item.lubricant.type} ${item.lubricant.lubricant}`
      : "") ||
    (item.supply
      ? `${lang.lubricationPoints.helpers.lubricationPointsThatUse}: ${item.supply.supply}`
      : "") ||
    (item.routeName
      ? `${lang.lubricationPoints.helpers.routeLubricationPoint}: ${item.routeName}`
      : "") ||
    (item.permission
      ? `${lang.lubricationPoints.helpers.lubricationPointsThatUseThisPermission}`
      : "");

  return (
    <FlexContainer align="center">
      <LubricationPointsList type="modal" item={item} title={title.toUpperCase()} />
      <IconButton onClick={() => handleCheck(item[iterator].id)} edge="end" aria-label="delete">
        <CheckedButton condition={picked.includes(item[iterator].id)} />
      </IconButton>
    </FlexContainer>
  );
};

export default React.memo(CheckeUncheckItem);
