import { TableRow } from "@material-ui/core";
import React from "react";
import { SurveysWithObservations } from "../SurveyExploreService";
import { ObservationInterface } from "../../../domains/observations/models/ObservationTypes";
import { useDate } from "../../../shared/date/useDate";
import { CustomTableCell } from "./CustomTableCell";
import theme from "../../../assets/theme";
import { CustomIcon } from "../../../components/buttons/CustomIcon";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";

const ExploreSurveyRow: React.FC<{ survey: SurveysWithObservations }> = ({ survey }) => {
  const { lang } = useLangLabels();

  const { formatISODate } = useDate();

  const emptyValue = (value: any) => {
    return !!value ? value : "-";
  };

  const showObservations = (observations: ObservationInterface[]) => {
    if (observations.length === 0) return emptyValue(false);
    return observations?.map((obs) => (
      <>
        - {obs.anomaly !== "[]" ? `${obs.anomaly} | ` : ""} {obs.observation}
        <br />
      </>
    ));
  };

  const showAnomalies = (observations: ObservationInterface[]) => {
    if (observations.length === 0) return emptyValue(false);
    return observations?.map((obs) => (
      <>
        {obs.anomaly} <br />
      </>
    ));
  };

  const lubricationColumnLogic = () => {
    if (survey.needsLubrication && survey.lubricates) return lang.explore.lubricated;
    if (survey.needsLubrication && !survey.lubricates) return lang.explore.failedToLubricate;
    if (!survey.needsLubrication && !survey.lubricates && !survey.inaccessible)
      return lang.explore.checked;
    if (survey.inaccessible && !survey.needsLubrication && !survey.lubricates)
      return lang.explore.uncontrolled;
    if (!survey.needsLubrication && survey.lubricates) return lang.explore.systemInconsistency;
  };

  const outOfRouteColor = (color: string) => (survey.outOfRoute ? "black" : color);
  const phoneIconColor = survey.inaccessible
    ? theme.palette.error.main
    : outOfRouteColor(theme.palette.success.main);
  const desktopIconColor = survey.inaccessible
    ? theme.palette.error.main
    : outOfRouteColor(theme.palette.success.main);

  return (
    <TableRow>
      <CustomTableCell>
        {survey.manualRegistration ? (
          <CustomIcon icon="desktop" style={{ color: desktopIconColor }} />
        ) : (
          <>
            <CustomIcon icon="smartPhone" style={{ color: phoneIconColor }} />
            {!survey.inaccessible && (
              <CustomIcon icon="qr" style={{ color: theme.palette.success.main }} />
            )}
          </>
        )}
      </CustomTableCell>
      <CustomTableCell>{emptyValue(formatISODate(survey.startTime, true))}</CustomTableCell>
      <CustomTableCell>{lubricationColumnLogic()}</CustomTableCell>
      <CustomTableCell>
        {emptyValue(!!survey.quantity && `${survey.quantity} ${survey.measureUnit}`)}
      </CustomTableCell>
      <CustomTableCell>{emptyValue(survey.reason)}</CustomTableCell>
      <CustomTableCell>
        {emptyValue(survey.outOfRoute ? lang.surveys.outOfRoute : lang.surveys.inRoute)}
      </CustomTableCell>
      <CustomTableCell>{showObservations(survey.observations)}</CustomTableCell>
    </TableRow>
  );
};

export default ExploreSurveyRow;
