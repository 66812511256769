import React, { memo, useEffect, useState } from "react";
import FlexContainer from "../../../../../../components/containers/FlexContainer";
import { useLubricationPointSurveyController } from "../../../../services/controllers/useLubricationPointSurveyController";
import TGDModal from "../../../../../../components/containers/modal/TGDModal/TGDModal";
import { SurveyModalProps } from "./types/SurveyModalTypes";
import SurveyModalHeader from "./components/SurveyModalHeader";

const SurveyModal: React.FC<SurveyModalProps> = ({
  children,
  open,
  handleClose,
  tasks,
  currentLubricationPoint,
  outOfRoute,
  canNext,
  canPrev,
  handleNext,
  handlePrev,
}) => {
  const { survey } = useLubricationPointSurveyController(
    currentLubricationPoint,
    outOfRoute
  );
  const backgroundColor = tasks && survey && tasks[survey.taskStep]?.color;
  const currentTask = tasks && survey && tasks[survey.taskStep]?.type;

  const wasInspectedToday = (() => {
    const todayDate = new Date().toISOString().split("T")[0];
    const lastInspectionDate =
      currentLubricationPoint?.info?.lastInspectionDate.split("T")[0];

    return (
      todayDate == lastInspectionDate &&
      currentLubricationPoint?.info?.pending === false
    );
  })();

  const [wasInspectedTodayState, setWasInspectedTodayState] =
    useState<boolean>(wasInspectedToday);

  useEffect(() => {
    setWasInspectedTodayState(wasInspectedToday);
  }, [currentLubricationPoint]);

  return (
    <TGDModal
      open={open}
      fullWidth={true}
      handleClose={handleClose}
      showDialogActions={false}
      header={
        <SurveyModalHeader
          canNext={canNext}
          canPrev={canPrev}
          handleNext={handleNext}
          handlePrev={handlePrev}
          currentLubricationPoint={currentLubricationPoint}
          backgroundColor={backgroundColor}
          currentTask={currentTask}
          wasInspectedTodayState={wasInspectedTodayState}
          setWasInspectedTodayState={setWasInspectedTodayState}
          outOfRoute={outOfRoute}
        />
      }
    >
      {(survey?.taskStep !== 0 || outOfRoute === false) &&
        (!wasInspectedToday || (wasInspectedToday && outOfRoute)) &&
        !wasInspectedTodayState && (
          <FlexContainer height="60vh">{children && children}</FlexContainer>
        )}
    </TGDModal>
  );
};

export default memo(SurveyModal);
