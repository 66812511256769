import { Button, Chip, CircularProgress, IconButton, Fab } from "@material-ui/core";
import React, { CSSProperties } from "react";
import TgdPopover from "../popovers/TgdPopover";
import theme from "../../assets/theme";

import { ButtonVariant, CustomButtonProps } from "./types/CustomButtonTypes";
import { CustomIcon } from "./CustomIcon";

const CustomButton: React.FC<CustomButtonProps> = ({
  action,
  title,
  type,
  children,
  icon,
  status,
  disabled,
  variant,
  color,
  popoverTitle,
  styleProps,
  outlined,
  right,
  bottom,
  top,
  left,
}) => {
  const defaultVariant: ButtonVariant = variant || "button";

  const style: CSSProperties | undefined = status
    ? status === "loading"
      ? { backgroundColor: theme?.palette?.info?.main }
      : status === "success"
      ? { backgroundColor: theme?.palette?.success?.dark }
      : undefined
    : undefined;

  const iconStatus = status ? (status !== "error" && status !== "success" ? icon : status) : icon;

  const buttonContent = (
    <>
      {icon && <CustomIcon icon={iconStatus} />}
      {title && title} {children && children}
    </>
  );

  const buttonStatus = (
    <>
      {status ? (
        status === "loading" ? (
          <CircularProgress color="inherit" size="24px" style={{ padding: "4px" }} />
        ) : (
          buttonContent
        )
      ) : (
        buttonContent
      )}
    </>
  );

  const fabVariant = (
    <>
      {defaultVariant === "fab" && (
        <Fab
          color={color || "primary"}
          onClick={action}
          style={{
            top,
            right,
            bottom,
            left,
          }}
        >
          <CustomIcon icon={icon} />
        </Fab>
      )}
    </>
  );

  const chipVariant = (
    <>
      {defaultVariant === "chip" && (
        <Chip
          disabled={disabled}
          color={color === "inherit" ? "default" : color || "default"}
          size="small"
          icon={<CustomIcon icon={icon} />}
          label={title || children || ""}
          onClick={action}
        />
      )}
    </>
  );

  const IconVariant = (
    <>
      {defaultVariant === "iconButton" && (
        <IconButton
          color={color}
          type={type}
          style={styleProps}
          size="small"
          onClick={action}
          disabled={disabled}
        >
          {status === "loading" ? (
            <CircularProgress color="inherit" size="20px" />
          ) : (
            <CustomIcon icon={icon} />
          )}
        </IconButton>
      )}
    </>
  );

  const ButtonVariant = (
    <>
      {defaultVariant === "button" && (
        <Button
          size="small"
          color={status === "error" ? "primary" : color ? color : "secondary"}
          style={{ ...style, ...styleProps }}
          type={type}
          variant={outlined ? "outlined" : "contained"}
          onClick={action}
          disabled={disabled}
        >
          {buttonStatus}
        </Button>
      )}
    </>
  );

  return (
    <>
      {popoverTitle ? (
        <TgdPopover mode="hover" title={popoverTitle}>
          {IconVariant}
          {ButtonVariant}
          {chipVariant}
          {fabVariant}
        </TgdPopover>
      ) : (
        <>
          {IconVariant}
          {ButtonVariant}
          {chipVariant}
          {fabVariant}
        </>
      )}
    </>
  );
};

export default CustomButton;
