import { useEffect, useState } from "react";
import { QueryStatus, UseMutationResult } from "react-query";
import { apiCall, mutateApiCall } from "../../../shared/axios/axios-config";
import { MutateResponse } from "../../../shared/axios/models/MutateResponse";
import { PrivateQuery, PrivateQueryData } from "../../../shared/globals/utilsGlobalTypes";
import { useStatusProcessor } from "../../../shared/queries/StatusProcessor";
import { queryClient, useMutation, useQuery } from "../../../shared/react-query/react-query-conf";
import { useGetAllAuxByPlantData } from "../../allAux/services/allAuxServices";
import { LubricantInterface } from "../../lubricant/model/LubricantInterface";
import { useLubricantsByType } from "../../lubricant/services/service.lubricants";
import {
  useEquipmentsByEquipmentType,
  useEquipmentsByLubricantType,
  useEquipmentsByMeassureUnit,
} from "../../lubricationPoints/services/lubricationPointsAdapters";
import { EquipmentInterface } from "../../lubricationPoints/model/lubricationPointsInterface";
import { useToken } from "../../session/store/sessionStore";
import { TypeInterface } from "../models/TypeInterface";

import { enumTgdTypes } from "../models/types";
import { useSessionContext } from "../../session/store/sessionContext";

//---------------------------
//interfaces
//---------------------------

/* export const entityTypes:{name:string}[] = [
    {
        name:"LUBRICANTE"
    },
    {
        name:"EQUIPO"
    },
    {
        name:"UNIDAD"
    },
    {
        name:"OBSERVACION"
    }
] */

//---------------------------
//Repo
//---------------------------

export const GetTypes = ({ token }: PrivateQuery) => {
  return apiCall({
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: "/AllTypes",
  });
};

export const CreateType = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: "/CreateType",
    data,
  });
};

export const UpdateType = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: "/UpdateType",
    data,
  });
};

export const deleteType = ({ data, token }: PrivateQueryData) => {
  return mutateApiCall({
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: "/DeleteType",
    data,
  });
};

//----------------------------
//ReactQuery
//---------------------------------------------

export const useTypes = (type: enumTgdTypes, tagFP?: string) => {
  const { token } = useSessionContext();
  return useQuery<TypeInterface[] | []>({
    queryKey: "AllTypes",
    queryFn: () => GetTypes({ token }),
    enabled: !!token,
    select: (types) => {
      const firstFilter = types.filter((item: { type: string }) => item.type === type);
      const secondFilter = tagFP
        ? firstFilter.filter((item: { tagFP: string }) => item.tagFP === tagFP)
        : firstFilter;
      return secondFilter;
    },
  });
  // next line last useQery Vertion has not selector
  /* return types?.filter((item:{type:string}) => item.type === type) */
};

export const useTypesByPlant = (tagFP?: string) => {
  return useGetAllAuxByPlantData("types");
};

export const usePlantType = (tagFP?: string, type?: string) => {
  const query = useTypesByPlant(tagFP);
  return {
    ...query,
    data: type
      ? query.data?.filter((item: { type: string }) => item.type === type?.toUpperCase())
      : undefined,
  };
};

export const useLubricantTypesByTagFP = (tagFP?: string) => {
  return useTypes(enumTgdTypes.lubricant, tagFP);
};

export const useMeasureUnitByTagFP = (tagFP?: string) => {
  return useTypes(enumTgdTypes.unity, tagFP);
};

export const useObservationTypesByTagFP = (tagFP?: string) => {
  return useTypes(enumTgdTypes.observation, tagFP);
};
export const useEquipmentTypesByTagFP = (tagFP?: string) => {
  return useTypes(enumTgdTypes.equipment, tagFP);
};

//----------------------------
//MUTATIONS
//---------------------------------------------

export const useCreateType = () => {
  const token = useToken();
  const query = useMutation(CreateType, {
    onSuccess: () => {
      queryClient.invalidateQueries("AllTypes");
      queryClient.invalidateQueries("PlantAux");
      queryClient.invalidateQueries("EquipmentsByLubricantType");
      queryClient.invalidateQueries("LubricantByType");
    },
  });

  const createType = (data: any) => {
    return query.mutate({
      data,
      token: token!,
    });
  };

  const status = useStatusProcessor(query);

  return {
    createType,
    ...query,
    ...status,
  };
};

export const useUpdateType = () => {
  const token = useToken();

  const query = useMutation(UpdateType, {
    onSuccess: () => {
      queryClient.invalidateQueries("AllTypes");
      queryClient.invalidateQueries("PlantAux");
    },
  });

  const updateType = (data: TypeInterface) => {
    return query.mutate({
      data,
      token: token!,
    });
  };

  const status = useStatusProcessor(query);

  return {
    updateType,
    ...query,
    ...status,
  };
};

export const useDeleteType = () => {
  return useMutation(deleteType, {
    onSuccess: () => {
      queryClient.invalidateQueries("AllTypes");
      queryClient.invalidateQueries("PlantAux");
    },
  });
};

interface DeleteTypeErrors {
  lubricants?: LubricantInterface[] | undefined;
  equipments?: EquipmentInterface[] | undefined;
}

export const useDeleteTypeVerification = (item: TypeInterface) => {
  const token = useToken();
  const query = useDeleteType();
  const { mutate: deleteItem, data } = query;
  const [status, setStatus] = useState<QueryStatus>("idle");

  const [validationElement, setValidationElement] = useState<TypeInterface | undefined>();

  const {
    data: lubricantsByType,
    status: lubricantsByTypeStatus,
    remove: lubricantsByTypeRemove,
  } = useLubricantsByType(validationElement);
  const {
    data: equipmentsByLubricant,
    status: equipmentsByLubricantStatus,
    remove: equipmentsByLubricantRemove,
  } = useEquipmentsByLubricantType(validationElement);
  const {
    data: equipmentsByEquipment,
    status: equipmentsByEquipmentStatus,
    remove: equipmentsByEquipmentRemove,
  } = useEquipmentsByEquipmentType(validationElement);
  const {
    data: equipmentsByMeasureUnit,
    status: equipmentsByMeasureUnitStatus,
    remove: equipmentsByMeasureUnitRemove,
  } = useEquipmentsByMeassureUnit(validationElement);

  const [errors, setErrors] = useState<DeleteTypeErrors>({});

  const removeQueries = () => {
    lubricantsByTypeRemove();
    equipmentsByLubricantRemove();
    equipmentsByEquipmentRemove();
    equipmentsByMeasureUnitRemove();
  };

  const validate = () => {
    setErrors({});
    removeQueries();
    setStatus("loading");
    setValidationElement(item);
  };

  const handleDelete = () => {
    if (
      lubricantsByTypeStatus === "success" &&
      equipmentsByLubricantStatus === "success" &&
      equipmentsByEquipmentStatus === "success" &&
      equipmentsByMeasureUnitStatus === "success"
    ) {
      if (
        lubricantsByType?.length > 0 ||
        equipmentsByLubricant?.length > 0 ||
        equipmentsByEquipment?.length > 0 ||
        equipmentsByMeasureUnit?.length > 0
      ) {
        if (lubricantsByType.length > 0 && equipmentsByLubricant.length > 0) {
          setErrors({ ...errors, lubricants: lubricantsByType, equipments: equipmentsByLubricant });
          setStatus("error");
        } else if (lubricantsByType.length > 0) {
          setErrors({ ...errors, lubricants: lubricantsByType });
          setStatus("error");
        } else if (equipmentsByLubricant.length > 0) {
          setErrors({ ...errors, equipments: equipmentsByLubricant });
          setStatus("error");
        } else if (equipmentsByEquipment.length > 0 && equipmentsByMeasureUnit.length > 0) {
          setErrors({ ...errors, equipments: equipmentsByEquipment + equipmentsByMeasureUnit });
          setStatus("error");
        } else if (equipmentsByEquipment.length > 0) {
          setErrors({ ...errors, equipments: equipmentsByEquipment });
          setStatus("error");
        } else if (equipmentsByMeasureUnit.length > 0) {
          setErrors({ ...errors, equipments: equipmentsByMeasureUnit });
          setStatus("error");
        }
        setValidationElement(undefined);
      } else {
        deleteItem(
          {
            data: validationElement,
            token: token!,
          },
          {
            onSuccess: () => {
              setStatus("success");
            },
            onError: (err) => {
              console.log(err);
              setStatus("error");
            },
          }
        );
      }
    }
    if (
      lubricantsByTypeStatus === "loading" ||
      equipmentsByLubricantStatus === "loading" ||
      equipmentsByEquipmentStatus === "loading" ||
      equipmentsByMeasureUnitStatus === "loading"
    ) {
      setStatus("loading");
    }
  };

  useEffect(() => {
    validationElement && handleDelete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    validationElement,
    lubricantsByType,
    equipmentsByLubricant,
    equipmentsByEquipment,
    equipmentsByMeasureUnit,
  ]);

  return {
    errors,
    status,
    validate,
    query,
  };
};
