import FlexContainer from "../../../../components/containers/FlexContainer";
import RouterTabs, { TabsInterface, useRouteTabProps } from "../../../../components/containers/tabs/RouterTabs";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { USER_ROUTES } from "../../../../shared/routes/routes";
import LubricantIncome from "./LubricantIncome";
import LubricantIncomeLog from "./LubricantLog";

const IncomeLogPage = () => {
  const { lang } = useLangLabels();

  const tabs: TabsInterface[] = [
    {
      label: lang.lubricationSection.lubricants,
      route: `${USER_ROUTES.plantManagement.lubricants}/*`,
      component: <LubricantIncomeLog virtualGridWidth="100%"/>
    },
    {
      label: lang.lubricationSection.allIncome,
      route: `all-incomes`,
      component: <LubricantIncome item={{} as any}/>
    }
  ];

  const tabProps = useRouteTabProps(5, tabs);

  return (
    <FlexContainer padding={"0px 0px 0px 16px"} flexDirection="column">
      <RouterTabs {...tabProps} />
    </FlexContainer>
  );
};

export default IncomeLogPage;
