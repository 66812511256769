import theme from "../../../../../assets/theme";
import ComponentMode from "../../../../../components/componentMode/ComponentMode";
import { FlexContainer } from "../../../../../components/containers";
import { useLabelReplacementGridModel } from "../../../../../domains/lubricationPoints/lang/useLabelReplacementGridModel";
import { useLabelReplacementByRoute } from "../../../../../domains/lubricationPoints/services/lubricationPointsAdapters";
import { useLangLabels } from "../../../../../shared/lang/services/useLangLabels";
import VirtualGrid from "../../../../../shared/react-table/VirtualGrid/VirtualGrid";

interface LabelReplacementByRouteProps {
  route: string;
}

export const LabelReplacementByRoute = ({ route }: LabelReplacementByRouteProps) => {
  const { data: lubricationPoints } = useLabelReplacementByRoute(route);
  const gridModel = useLabelReplacementGridModel();
  const { lang } = useLangLabels();

  if (!lubricationPoints?.length) {
    return <></>;
  }

  return (
    <ComponentMode
      mode={"modal"}
      variant="iconButton"
      icon="labelOff"
      buttonStyle={{
        color: theme.palette.info.main,
        padding: "0px 6px",
        margin: "-4px",
      }}
      title={lang.routes.labelsToReplace}
      modalTitle={false}
    >
      <FlexContainer flexDirection="column" height={"60vh"}>
        <VirtualGrid
          gridModel={gridModel}
          items={lubricationPoints || []}
          title={lang.lubricationPoints.helpers.pointsThatNeedLabelChange}
          contained={false}
          height={10}
        />
      </FlexContainer>
    </ComponentMode>
  );
};
