import React, { useEffect } from "react";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";
import { useClickPosition } from "../../shared/globals/hooks/useClickPosition";
import { useSnackbar } from "../../components/snackbar/hooks/useSnackbar";
import { CircularProgress } from "@material-ui/core";
import CustomButton from "../../components/buttons/CustomButton";
import { useDeleteArea } from "./services/service.areas";
import { AreaInterface } from "./models/AreaTypes";

const DeleteAreaForm = ({ item }: { item: AreaInterface }) => {
  const { lang } = useLangLabels();

  const { deleteArea, status, message } = useDeleteArea();
  const { position, getClickPosition } = useClickPosition();

  const { setData } = useSnackbar();

  useEffect(() => {
    if (status === "success") {
      setData({
        modal: true,
        errors: message,
        severity: status,
        position,
      });
    }
    if (status === "error") {
      setData({
        modal: true,
        errors: message,
        severity: "warning",
        position,
      });
    }
  }, [status]);

  return status === "loading" ? (
    <CircularProgress size="24px" />
  ) : (
    <CustomButton
      variant="iconButton"
      status={status}
      icon="delete"
      popoverTitle={lang.crudActions.delete + " "}
      action={(e) => getClickPosition(e, () => deleteArea(item))}
    />
  );
};

export default DeleteAreaForm;
