import React from "react";
import SimpleAutocompleteSelect from "../../../../components/autocomplete/SimpleAutocompleteSelect";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";

interface Props {
  error?: string;
  onChange: (item?: any) => void;
  defaultValue?: any;
  disabled?: boolean | undefined;
  placeholder?: string;
}

type Periodicity = { number: string };

const periodicities: Periodicity[] = [
  { number: "1" },
  { number: "7" },
  { number: "14" },
  { number: "30" },
  { number: "60" },
  { number: "90" },
];

const Selectperiodicity: React.FC<Props> = ({ onChange, defaultValue, disabled, error }) => {
  const { lang } = useLangLabels();
  const showTitle = "number";
  const label = `${lang.routes.periodicity} ${!!defaultValue ? ":" + defaultValue : ""}`;

  return (
    <SimpleAutocompleteSelect
      {...{
        error,
        label,
        showTitle,
        defaultValue,
        items: periodicities,
        onChange,
        disabled,
      }}
    />
  );
};

export default Selectperiodicity;
