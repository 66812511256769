import React, { Fragment, useCallback, useMemo } from "react";

import {
  Badge,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { LubricationPointInterface } from "../lubricationPoints/model/lubricationPointsInterface";
import { ComponentUIModes } from "../../components/componentMode/types/componentModesInterfaces";
import { useLangLabels } from "../../shared/lang/services/useLangLabels";
import { suppliesToObject } from "./utils/supplyToObject";
import FlexContainer from "../../components/containers/FlexContainer";
import CustomButton from "../../components/buttons/CustomButton";
import CUSupplyForm from "./CUSupplyForm";
import { CrudFormType } from "../../shared/form/types/FormTypes";
import { useUser } from "../session/store/sessionStore";
import ComponentMode from "../../components/componentMode/ComponentMode";
import StatusAndMessage from "../../shared/queries/errors/components/StatusAndMessage";
import { LocalStateSupply } from "./model/SupplyInterface";
import { useAssignCanceInfoEquipmentSupplies } from "./services/service.supplies";

interface Props {
  items?: LubricationPointInterface[];
  lubricationPoint?: LubricationPointInterface;
  mode?: ComponentUIModes;
}

const AssignCancelSupply: React.FC<{
  lubricationPoint: LubricationPointInterface;
  supply: string;
}> = ({ lubricationPoint, supply }) => {
  const { lang } = useLangLabels();
  const {
    assignSupply,
    deleteSupply,
    queryAssign: dataAssign,
    queryCancel: dataCancel,
    cancelStatus,
    assignStatus,
  } = useAssignCanceInfoEquipmentSupplies(lubricationPoint);

  const isError = useMemo(() => {
    if (assignStatus.status === "error" && assignStatus.message) return assignStatus.message;
    if (cancelStatus.status === "error" && cancelStatus.message) return cancelStatus.message;
  }, [assignStatus, cancelStatus]);

  const isSuccess = useMemo(() => {
    if (assignStatus.status === "success" && assignStatus.message) return assignStatus.message;
    if (cancelStatus.status === "success" && cancelStatus.message) return cancelStatus.message;
  }, [assignStatus, cancelStatus]);

  const data: any = cancelStatus.dataStatus || assignStatus.dataStatus;
  const reset =
    (assignStatus.status === "error" && assignStatus.reset) ||
    (cancelStatus.status === "error" && assignStatus.reset) ||
    (() => console.log("Supply created"));
  const _supply = suppliesToObject(data)[0];
  const type = _supply?.type;

  const handleAssign = () => {
    assignSupply(supply);
  };

  const handleDelete = () => {
    deleteSupply(supply);
  };

  return (
    <>
      <FlexContainer>
        {isError && (
          <>
            <CustomButton
              icon="error"
              color="primary"
              variant="iconButton"
              popoverTitle={isError || ""}
            />
            <CUSupplyForm
              onSuccess={reset}
              variant="iconButton"
              supplyType={type}
              actionType={CrudFormType.create}
            />
          </>
        )}

        {isSuccess && (
          <CustomButton
            icon="check"
            color="secondary"
            variant="iconButton"
            popoverTitle={isSuccess || ""}
          />
        )}

        {!isError && !isSuccess && (
          <>
            {dataAssign.isLoading ? (
              <CircularProgress />
            ) : (
              <CustomButton
                action={handleAssign}
                {...assignStatus}
                color="secondary"
                variant="iconButton"
                icon={"check"}
                popoverTitle={lang.actionTypes.assign}
              />
            )}
            {dataCancel.isLoading ? (
              <CircularProgress />
            ) : (
              <CustomButton
                action={handleDelete}
                {...cancelStatus}
                color="primary"
                variant="iconButton"
                icon={"close"}
                popoverTitle={`${
                  lang.actionTypes.deleteItem
                } ${lang.words.request.toLocaleLowerCase()}`}
              />
            )}
          </>
        )}
      </FlexContainer>
    </>
  );
};

const PendingSuppliesToAssign: React.FC<Props> = ({ lubricationPoint, mode, items }) => {
  const { lang } = useLangLabels();
  const { data: user } = useUser();

  const isMultiEquipmentMode = useMemo(() => !!(items && items.length > 1), [items]);
  const multiBAdgeContent = useMemo(
    () =>
      (isMultiEquipmentMode &&
        items?.reduce((acc: number, item: LubricationPointInterface) => {
          const supplies =
            item.info?.supplies.filter((s) => s !== "SIN INSUMOS" && s !== "").length || 0;
          return acc + supplies;
        }, 0)) ||
      0,
    [isMultiEquipmentMode, items]
  );

  const lubPointList = useMemo(
    () =>
      (isMultiEquipmentMode &&
        items?.filter((i) => {
          const havePendingSupplies =
            i.info?.supplies.filter((s) => s !== "SIN INSUMOS" && s !== "").length || 0;
          if (havePendingSupplies > 0) return true;
          return false;
        })) ||
      [],
    [isMultiEquipmentMode, items]
  );

  const info = lubricationPoint?.info;
  const supplies = useMemo(() => info?.supplies || [], [info?.supplies]);
  const suppliesQuantity = useMemo(
    () => supplies?.filter((s) => s !== "SIN INSUMOS" && s !== "").length,
    [supplies]
  );
  const parsedSupplies: any = useMemo(() => suppliesToObject(supplies), [supplies]);

  const { assignStatus, assignAll } = useAssignCanceInfoEquipmentSupplies(lubricationPoint);

  const handleAll = useCallback(() => {
    supplies && assignAll(supplies);
  }, [assignAll, supplies]);

  const handleReset = useCallback(() => {
    assignStatus.reset();
  }, [assignStatus]);

  const simpleEquipmentMode = useMemo(
    () => (
      <Badge color="primary" badgeContent={/* suppliesQuantity */ 0}>
        <ComponentMode
          width="350px"
          mode={mode}
          icon="tool"
          variant="iconButton"
          title={lang.infoLubricationPoints.requiredSupplies}
        >
          {suppliesQuantity === 0 ? (
            lang.infoLubricationPoints.thereAreNoSuppliesToAssign
          ) : (
            <Fragment>
              <List>
                <StatusAndMessage {...{ ...assignStatus, reset: handleReset }} />
                {parsedSupplies.map((item: LocalStateSupply, index: number) => (
                  <Fragment key={`${item.supply}-${item.type}-${index}`}>
                    <ListItem>
                      <ListItemText primary={item.supply} secondary={item.type}></ListItemText>
                      {user?.type !== "O" && (
                        <ListItemSecondaryAction>
                          {lubricationPoint && (
                            <AssignCancelSupply
                              lubricationPoint={lubricationPoint}
                              supply={supplies[index]}
                            />
                          )}
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                    <Divider />
                  </Fragment>
                ))}
              </List>
              <CustomButton
                disabled={true}
                {...assignStatus}
                action={handleAll}
                variant="chip"
                color="secondary"
                title="Asigar todos"
              />
            </Fragment>
          )}
        </ComponentMode>
      </Badge>
    ),
    [
      assignStatus,
      handleAll,
      handleReset,
      lubricationPoint,
      mode,
      parsedSupplies,
      supplies,
      suppliesQuantity,
      user?.type,
    ]
  );

  const multiEquipmentMode = useMemo(
    () => (
      <Badge color="primary" badgeContent={multiBAdgeContent}>
        <ComponentMode
          width="450px"
          mode="popover"
          icon="tool"
          variant="iconButton"
          popoverTitle={lang.infoLubricationPoints.requiredSupplies}
        >
          <Typography variant="h6">
            {" "}
            {lang.infoLubricationPoints.equipmentsWithRequests}{" "}
          </Typography>
          {lubPointList?.map((i) => (
            <React.Fragment>
              <FlexContainer align="center" justify="space-between" key={i.tagTGD}>
                <Typography>
                  {i.component} &gt;&gt; {i.element} &gt;&gt; {i.equipment}{" "}
                </Typography>
                <PendingSuppliesToAssign lubricationPoint={i} mode="popover" />
              </FlexContainer>
              <Divider />
            </React.Fragment>
          ))}
        </ComponentMode>
      </Badge>
    ),
    [lubPointList, multiBAdgeContent]
  );

  return <>{isMultiEquipmentMode ? multiEquipmentMode : simpleEquipmentMode}</>;
};

export default React.memo(PendingSuppliesToAssign);
