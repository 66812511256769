import { useEffect, useMemo, useState } from "react";

import { dynamicFilter } from "../domain/dynamicfilterEntity";

import { PeriodChange, usePeriodForm } from "./usePeriodForm";
import { resetFilter } from "./../domain/dynamicFilterModel";
import { PeriodInterface } from "../../../../shared/reports/model/ReportInterface";
import { getValueFromFlatObjectKey } from "../../../../shared/globals/utils/valueFromFlatObjectkey";
import { groupByKeyOptions } from "../../../../shared/globals/utils/dataFiltering";
import { GridModelInterface } from "../../../../shared/gridModel/gridModelEntity";
import useBasicLocalStorage from "../../../../shared/localStorage/useBasicLocalStorage";
import { DynamicFiltersProps, FilterLabel, FilterType } from "../types/DynamicFilterTypes";
import { useTranslateValues } from "../../FieldFilter/hooks/useTranslateValues";

const translateBoolean = (value: any) => {
  if (typeof value === "boolean") {
    if (value === true) return 1;
    else return 0;
  }

  return value;
};

export const useDynamicFilter = (
  items: any[],
  gridModel?: GridModelInterface,
  defaultPeriodValues?: PeriodInterface,
  onPeriodSubmit?: PeriodChange,
  filterOptions?: any,
  entityName?: string
): DynamicFiltersProps => {
  const { translateValue } = useTranslateValues();

  const {
    periodValues,
    errors,
    handlePeriod,
    periodSubmit,
    handleReset: handleResetPeriod,
  } = usePeriodForm(defaultPeriodValues, onPeriodSubmit);
  const [filters, setFilters] = useState<dynamicFilter | undefined>(); // Probablemente se pueda prescindir de este estado en un futuro
  const [andFilters, setAndFilters] = useState<dynamicFilter | undefined>();
  const { onFiltersChange, onDeleteFilter, onResetFilters, onSearchValueChange, isReport } =
    filterOptions || {};

  const existAndFilter = useMemo(() => {
    return !!andFilters && Object.keys(andFilters).length > 0;
  }, [andFilters]);

  const [searchValue, setSearchValue] = useState<string>("");

  const storeValue = entityName ? true : false;

  const [selectedEntitiestoFilter, setSelectedEntitiestoFilter] = useBasicLocalStorage<
    FilterLabel[] | []
  >({
    key: `${entityName?.toLowerCase()}-row-filters`,
    initialValue: [],
    storeLocal: storeValue,
  });

  const periodFilterEnabled = !!onPeriodSubmit;

  useEffect(() => {
    if (selectedEntitiestoFilter) {
      selectedEntitiestoFilter.forEach((entity) => {
        onFiltersChange?.(entity.key, entity.selectedValue);
      });
    }
  }, []);

  const filtered = useMemo(() => {
    const result = items.filter((item) => {
      let matchesFilters = true;
      for (const entity of selectedEntitiestoFilter) {
        const filterKey = entity.key;
        const filterValue = entity.selectedValue;
        const translatedValue = translateValue(filterValue);
        const itemValue = getValueFromFlatObjectKey(item, filterKey);

        if (translatedValue !== undefined) {
          matchesFilters =
            translatedValue.toString().toUpperCase() === itemValue?.toString().toUpperCase();
          if (!matchesFilters) break; // Importante: Si un filtro no coincide, sale del bucle
        }
      }
      return matchesFilters;
    });

    if (searchValue) {
      const keys = Object.keys(result[0] || []);

      const searchBoxResults = result.filter((item: any) => {
        let exist = false as boolean | undefined;

        keys.forEach((key) => {
          if (exist === true) return;

          if (typeof item[key] === "number") {
            exist = item[key].toString().indexOf(searchValue.toUpperCase()) === -1 ? false : true;
          }

          if (typeof item[key] === "string") {
            exist =
              item[key].toUpperCase().indexOf(searchValue.toUpperCase()) === -1 ? false : true;
          }
        });

        return exist;
      });

      return searchBoxResults;
    } else {
      return result;
    }
  }, [filters, items, searchValue, andFilters]);

  //Methods
  const handleResetFilters = (isLastFilter = false) => {
    setSelectedEntitiestoFilter([]);
    setFilters(resetFilter());
    setAndFilters(resetFilter());
    onResetFilters?.();

    if (!isLastFilter) {
      setSearchValue("");
      onSearchValueChange?.("");
    }
  };

  const handleFiltersChange = (field: string, value: any, index: number, type: FilterType) => {
    const translatedValue = translateBoolean(translateValue(value));

    setFilters({
      ...filters,
      [field]: [value],
    });
    setAndFilters({
      ...andFilters,
      [field]: [value],
    });
    setSelectedEntitiestoFilter(
      selectedEntitiestoFilter.map((item) => {
        if (item.key === field) {
          return {
            ...item,
            selectedValue: value,
          };
        } else {
          return item;
        }
      })
    );

    onFiltersChange?.(field, translatedValue);
  };

  const handleDeleteFilter = (filter: FilterLabel, filterValue: any) => {
    const fieldToDelete = filter.key;
    const isLastFilter = selectedEntitiestoFilter.length <= 1;

    if (isLastFilter) {
      handleResetFilters(isLastFilter);
    } else {
      const validItems = { ...andFilters };
      delete validItems[fieldToDelete];

      setAndFilters(validItems);
      setFilters(validItems);
      onDeleteFilter?.(fieldToDelete);

      setSelectedEntitiestoFilter(() => {
        return selectedEntitiestoFilter.filter((item) => {
          return item.id !== filter.id;
        });
      });
    }
  };

  const addEntityToFilters = (item: any, callback: () => void) => {
    setSelectedEntitiestoFilter([...selectedEntitiestoFilter, item]);

    callback();
  };

  const columnLabels = useMemo(() => {
    if (gridModel) {
      return (
        Object.entries<any[]>(groupByKeyOptions(items))
          //filter gridModel inexistance of attributes
          .filter(([key]) => !!gridModel[key]?.label)
          .map(([key, values], index) => {
            const nestedKeys = key.split(".");
            const lastIndexKey = nestedKeys.length - 1;
            const lastKey = nestedKeys[lastIndexKey];

            return {
              key: lastKey,
              id: index,
              label: gridModel[key]?.label || key,
              options:
                gridModel[key]?.options ||
                values.map((value) => ({
                  name: value?.toString(),
                  value: value?.toString(),
                })),
            };
          })
      );
    } else {
      return Object.entries<any[]>(groupByKeyOptions(items))
        .map(([key, values], index) => {
          const nestedKeys = key.split(".");
          const lastIndexKey = nestedKeys.length - 1;
          const lastKey = nestedKeys[lastIndexKey];

          return {
            key: lastKey,
            id: index,
            label: key,
            options: values.map((value) => ({
              name: value?.toString(),
              value: value?.toString(),
            })),
          };
        })
        .filter((item) => !(item.label === ""));
    }
  }, [gridModel, items]);

  const notSelectedColumnLabels = useMemo(() => {
    const selectedEntitiesKeys = selectedEntitiestoFilter?.map((entity) => entity.key);

    return columnLabels.filter((entity) => {
      const field = entity.key;
      return !selectedEntitiesKeys.includes(field);
    });
  }, [columnLabels, filters, selectedEntitiestoFilter]);

  return {
    filters,
    existAndFilter,
    filtered,
    periodFilterEnabled,
    errors,
    handlePeriod,
    periodSubmit,
    handleResetPeriod,
    defaultPeriodValues: periodValues,
    setSearchValue,
    selectedEntitiestoFilter,
    handleFiltersChange,
    handleResetFilters,
    addEntityToFilters,
    columnLabels,
    handleDeleteFilter,
    notSelectedColumnLabels,
    searchValue,
    onSearchValueChange,
    isReport,
  };
};
