import React from 'react'
import { Paper } from "@material-ui/core"
import EndpointAutomation from "../EndpointAutomation"
import { BrowserBackups } from './BrowserBackups'
import TgdTabs from '../../../components/tabs/TgdTabs'
import PostManProcedure from '../InsertSurveyObs'



const devtoolsTabs = [
    {label:'Endpoints Automation',component:<EndpointAutomation/>},
    {label:'Browser backups',component:<BrowserBackups/>},
    {label:'Api procedure',component:<PostManProcedure/>}
]

const DevTools:React.FC<{}> = ({}) => {
    return <>
        <Paper style={{width:'100%'}}>
            <TgdTabs
                labelIterator='label'
                items={devtoolsTabs}
                content={({component})=>component}
            />
        </Paper>
    </>
}

export default DevTools