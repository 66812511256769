import { ObservationInterface } from "../../../../domains/observations/models/ObservationTypes"
import { getViewDateDay } from "../../../date/utils"
import { backupDb } from "../backup-db"

export const backupAddMultipleObservations = async (observations:ObservationInterface[]) => {
    try{
        return backupDb.observations.bulkAdd(observations.map((obs)=>({
            ...obs,
            backupDate:getViewDateDay(new Date().toISOString())
        })))
    }catch(err) {
        console.log(err)
    }
}