import { queryByTestId } from "@testing-library/react"
import { useEffect } from "react"
import { MutateOptions, QueryClient, useQuery } from "react-query"
import { apiCall, mutateApiCall } from "../../../shared/axios/axios-config"
import { MutateResponse } from "../../../shared/axios/models/MutateResponse"
import { getViewDateDay } from "../../../shared/date/utils"
import { PrivateQueryData, PrivateQueryTagFPInterface } from "../../../shared/globals/utilsGlobalTypes"
import { useStatusProcessor } from "../../../shared/queries/StatusProcessor"
import { queryClient, useMutation } from "../../../shared/react-query/react-query-conf"
import { PeriodInterface } from "../../../shared/reports/model/ReportInterface"
import { useSessionContext } from "../../session/store/sessionContext"
import { useToken } from "../../session/store/sessionStore"
import { ObservationInterface } from "../models/ObservationTypes"


export const ObservationCRUDDependencies = [
    'ObservationsByPeriodAndTagFP',
    'ObservationsByTagFP',
    'ObservationsByLubricationPoint',
    'ObservationsBysurveyId',
    'PaginatedObservationsLubricationPointByPeriod'
]




//----------------------------
//Axios
//---------------------------------------------

export const ObservationsByTagFP = ({tagFP,token}:PrivateQueryTagFPInterface) => {

    return apiCall({
        method: "GET",
        url:`/ObservationsByTagFP`,
        headers:{
            Authorization:`Bearer ${token}`,
            tagFP
        }
    })

}


/**
 * todas las observaciones de un equipo
 * 
 *  
 */

export const ObservationsByEquipment = ({data,token}:PrivateQueryData) => {
    return apiCall({
        method: "POST",
        url:`/ObservationsByLubricationPoint`,
        headers:{
            Authorization:`Bearer ${token}`,
        },
        data
    })
}

export const ObservationsBySurveyId = ({data,token}:PrivateQueryData) => {
    return apiCall({
        method: "POST",
        url:`/ObservationsBySurveyId`,
        headers:{
            Authorization:`Bearer ${token}`,
        },
        data
    })
}

export const UpdateObservation = ({data,token}:PrivateQueryData) => {
    return mutateApiCall({
        method: "POST",
        url:`/UpdateObservation`,
        headers:{
            Authorization:`Bearer ${token}`,
        },
        data
    })  
}

export const DeleteObservation = ({data,token}:PrivateQueryData) => {
    return mutateApiCall({
        method: "POST",
        url:`/DeleteObservation`,
        headers:{
            Authorization:`Bearer ${token}`,
        },
        data
    })
}
export const CreateObservation = ({data,token}:PrivateQueryData) => {
    return mutateApiCall({
        method: "POST",
        url:`/CreateObservation`,
        headers:{
            Authorization:`Bearer ${token}`,
        },
        data
    })
}

export const ObservationsByPeriodAndTagFP = ({data,token}:PrivateQueryData) => {
    return apiCall({
        method: "POST",
        url:`/ObservationsLubricationPointByPeriodAndTagFP`,
        headers:{
            Authorization:`Bearer ${token}`,
        },
        data
    })
}

export const ObservationAttrValues = ({ tagFP, token }: PrivateQueryTagFPInterface) => {
    return apiCall({
        method: "GET",
        url:"/ObservationAttrValues",
        headers:{
            Authorization:`Bearer ${token}`,
            tagFP
        },
    })
}



//----------------------------
//QUERY
//---------------------------------------------

export const useObservationsByPeriodAndTagFP = (period:PeriodInterface | undefined) => {

    const {token,tagFP} = useSessionContext()
    
    const canFetch = [!!period?.firstDate,!!period?.secondDate,!!tagFP,!!token,token != ''].includes(false) ? false : true

    const data ={
        firstDate:period?.firstDate,
        secondDate:period?.secondDate,
        tagFP
    }
    const query= useQuery<ObservationInterface[]>({
        queryKey:['ObservationsByPeriodAndTagFP',period],
        queryFn:()=>ObservationsByPeriodAndTagFP({data,token}),
        enabled:canFetch,
        staleTime:0,
        select:(data)=>{
            return data?.map((i)=>({ 
                ...i,
                modifiedDate:getViewDateDay(i.modifiedDate)
            }))
        }
    })

    return query
}

export const useObservationsByTagFP = () => {
    const {token,tagFP} = useSessionContext()
    return useQuery({
        queryKey:'ObservationsByTagFP',
        queryFn:()=>ObservationsByTagFP({tagFP,token}),
        enabled:!!tagFP && !!token 
    })
}



export const useObservationByIdentifier = (entity:'lubricationPoint' | 'survey', item?:any) =>{

    const token = useToken()

    const byTagTGD = useQuery({
        queryKey:'ObservationsByLubricationPoint',
        queryFn:()=>ObservationsByEquipment({data:{tagTGD:item?.tagTGD},token}),
        enabled:(entity === 'lubricationPoint') && !!item && !!token,
        staleTime:1
    })


    const bySurveyId = useQuery({
        queryKey:'ObservationsBysurveyId',
        queryFn:()=>ObservationsBySurveyId({data:{surveyId:item?.id,tagFP:item?.tagFP},token}),
        enabled:(entity === 'survey') && !!item && !!token,
        staleTime:1,
    })


    
    const query = (()=>{
        switch (entity) {
            case 'survey':
                return bySurveyId
                
            case 'lubricationPoint':
                return byTagTGD
        
            }
        })()
        
    useEffect(()=>{
        query.refetch()         
    },[item])

    return query

}

export const useObservationAttrValues = () => {
    const { token, tagFP } = useSessionContext();

    return useQuery({
        queryKey: ["ObservationAttrValues"],
        queryFn: () => ObservationAttrValues({ tagFP, token }),
        enabled: !!tagFP,
        staleTime: 3600000,
        cacheTime: Infinity,
    });
};

//----------------------------
//MUTATIONS
//---------------------------------------------


export const useCreateObservations = () => {

    const token = useToken()
    const query = useMutation(CreateObservation)
    
    const createObservation = (data:any) => {
        return query.mutateAsync({
            data,
            token
        })
    }

    const status = useStatusProcessor(query)
    return {
        createObservation,
        query:{
            ...query,
            ...status
        }
    }
} 

export const useUpdateObservation = () => {
    const token = useToken()
    const query = useMutation(UpdateObservation,{
        onSuccess:()=>{
            queryClient.invalidateQueries('ObservationsByTagFP')
            queryClient.invalidateQueries('ObservationsByPeriodAndTagFP')
            queryClient.invalidateQueries('PaginatedObservationsLubricationPointByPeriod')
        }
    })

    const status = useStatusProcessor(query)
    const updatupdateObservation = (data:any) => {
        return query.mutate({
            token,
            data
        })
    } 

    return {
        updatupdateObservation,
        query:{
            ...query,
            ...status
        }
    }
}

export const useDeleteObservation = () => {
    const token = useToken()
    const query = useMutation(DeleteObservation,{
        onSuccess:()=>{
            queryClient.invalidateQueries('ObservationsByTagFP')
            queryClient.invalidateQueries('ObservationsByPeriodAndTagFP')
            queryClient.invalidateQueries('ObservationsByLubricationPoint')
            queryClient.invalidateQueries('ObservationsBysurveyId')
            queryClient.invalidateQueries('PaginatedObservationsLubricationPointByPeriod')
        }
    })

    const status = useStatusProcessor(query)


    const deleteObs = (data:any) => {
        return query.mutate({
            token,
            data
        })
    } 

    return {
        deleteObs, 
        query:{
            ...query,
            ...status
        }
    }
} 