import { InputComponentTypes } from "../../../shared/form/types/FormTypes";
import { useForm } from "../../../shared/form/useForm";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { CriticalityTypes } from "../../criticalities/models/criticalityInterface";
import { ObservationInterface } from "../models/ObservationTypes";

export const useObservationForm = (
  tagFP: string,
  item?: ObservationInterface
) => {
  const { lang } = useLangLabels();

  return useForm<ObservationInterface>({
    id: {
      label: lang.globalentityValues.id,
      placeholder: lang.globalentityValues.id,
      value: item?.id || 0,
      component: InputComponentTypes.none,
    },
    anomaly: {
      label: lang.anomalies.singular,
      placeholder: lang.anomalies.singular,
      value: item?.anomaly || "",
      component: InputComponentTypes.none,
    },
    observation: {
      label: lang.observations.singular,
      placeholder: lang.observations.singular,
      value: item?.observation || "",
      component: InputComponentTypes.observation,
      validations: {
        required: { value: true, message: lang.validations.messages.required },
      },
      inputConfig: {
        tagFP: tagFP,
      },
    },
    criticality: {
      label: lang.criticalities.title.singular,
      placeholder: lang.criticalities.title.singular,
      value: item?.criticality || "",
      component: InputComponentTypes.criticality,
      validations: {
        required: { value: true, message: lang.validations.messages.required },
      },
      inputConfig: {
        tagFP: tagFP,
        by: {
          key: "criticalityType",
          value: CriticalityTypes.observation,
        },
      },
    },
    area: {
      label: lang.areas.title.singular,
      placeholder: lang.areas.title.singular,
      value: item?.area || "",
      component: InputComponentTypes.area,
      inputConfig: {
        tagFP: tagFP,
      },
    },
    surveyId: {
      label: "not set",
      placeholder: "not set",
      value: item?.surveyId || 0,
      component: InputComponentTypes.none,
    },
    equipment: {
      label: "not set",
      placeholder: "not set",
      value: item?.equipment || "",
      component: InputComponentTypes.none,
    },
    tagTGD: {
      label: lang.globalentityValues.tagTGD,
      placeholder: lang.globalentityValues.tagTGD,
      value: item?.tagTGD || "",
      component: InputComponentTypes.none,
    },
    modified: {
      label: "not set",
      placeholder: "not set",
      value: item?.modified || "",
      component: InputComponentTypes.none,
    },
    modifiedDate: {
      label: "not set",
      placeholder: "not set",
      value: item?.modifiedDate || "",
      component: InputComponentTypes.none,
    },
    observationDate: {
      label: "not set",
      placeholder: "not set",
      value: item?.observationDate || "",
      component: InputComponentTypes.none,
    },
    OTnumber: {
      label: "not set",
      placeholder: "not set",
      value: item?.OTnumber || "",
      component: InputComponentTypes.none,
    },
    state: {
      label: "not set",
      placeholder: "not set",
      value: item?.state || undefined,
      component: InputComponentTypes.none,
    },
    taskId: {
      label: "not set",
      placeholder: "not set",
      value: item?.taskId || "",
      component: InputComponentTypes.none,
    },
    taskRequestId: {
      label: "not set",
      placeholder: "not set",
      value: item?.taskRequestId || "",
      component: InputComponentTypes.none,
    },
    taskType: {
      label: "not set",
      placeholder: "not set",
      value: item?.taskType || "",
      component: InputComponentTypes.none,
    },
    type: {
      label: "not set",
      placeholder: "not set",
      value: item?.type || "",
      component: InputComponentTypes.none,
    },
    tagFP: {
      label: lang.globalentityValues.tagFP,
      placeholder: lang.globalentityValues.tagFP,
      value: tagFP,
      component: InputComponentTypes.none,
    },
  });
};
