const  EndpointsAutomationScripts = [
    {
        name:'default',
        script:`return data`
    },
    {
        name:"CreateSurvey Automation (recursivo)",
        script:`
            return data.reduce((acc,item)=>{
                const type = (item.res.id > 0) ? 'success' : 'error'
                const label = (item.res.id > 0) ? 'res' : 'req'
                return{
                    ...acc,
                    [type]:{
                        count:acc[type].count + 1,
                        points:[...acc[type].points,item[label].tagTGD]
                    }
                }
            },{
                error:{
                    count:0,
                    points:[]
                },
                success:{
                    count:0,
                    points:[]
                }
            })
        `
    }
]

export const ApiProcedure = [
    {
        name:'default',
        script:`/*(params:{current,next})=>{ obtiene dos objetos de tipo PostmanAction */\nconsole.log(params)\n/*}*/`
    },
    {
        name:'Recibir Survey Crear Nueva Observacion',
        script:`/*(params:{current,next})=>{ obtiene dos objetos de tipo PostmanAction */

        const {current,next} = params 
      
        let nextReqData = []
      
        current.res.forEach((item)=>{
            const newId = item.res.id
            const oldId = item.req.data.id
            const itemObservations = next.req.data
            .filter((obs)=>{
                return obs.surveyId === oldId
            })
            .map((old)=>({
                ...old,surveyId:newId
            }))
      
            nextReqData = [
                ...nextReqData,
                ...itemObservations
            ]
        })
      
        current.setNextRequestData(nextReqData)
        
        
      /*}*/`
    }
]


export default EndpointsAutomationScripts