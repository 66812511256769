import styled from "@emotion/styled";
import React from "react";
import { scrollbarWidth } from "./utils/scrollbarWidth";
import theme from "../../../assets/theme";

interface TableContainerProps {
  width?: string;
  height?: number;
}

export const CELL_HEIGHT = 35;
export const SCROLLBAR_SIZE = scrollbarWidth();
export const CELL_WIDTH = 150;
export const ROWS_CUANTITY = 16;

export const TABLE_LINES_COLOR = "#E6E6E6";
const HOVER_COLOR = theme.palette.background.default;
const SELECTED_ROWS_COLOR = "#E8E8E8";

export const TableContainer: React.FC<TableContainerProps> = React.memo(styled(
  "div"
)<TableContainerProps>`
  .container {
    border: 1px solid ${TABLE_LINES_COLOR};
  }

  .table-container {
    overflow-x: scroll;
    position: relative;
  }

  .table {
    display: grid;
    border-spacing: 0;

    .th,
    .td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid ${TABLE_LINES_COLOR};
      border-right: 1px solid ${TABLE_LINES_COLOR};
      overflow: hidden;
      white-space: nowrap;
    }

    .thead {
      display: grid;
      position: sticky !important;
      top: 0;
      z-index: 1;

      .tr {
        width: 100%;
        display: flex;

        .th {
          background: ${HOVER_COLOR};
          position: relative;
          text-align: left;
          cursor: default;

          .sort {
            cursor: pointer;
          }
        }
      }

      .th {
        display: flex;
      }
    }

    .tbody {
      display: grid;
      position: relative;

      .tr,
      .tr-selected {
        width: 100%;
        display: flex;
        position: absolute;
        cursor: pointer;
        background: white;
        :hover {
          background: ${HOVER_COLOR};
        }

        .td {
          display: flex;
        }
      }

      .tr-selected {
        background: ${SELECTED_ROWS_COLOR};
      }
    }

    .resizer {
      position: absolute;
      width: 6px;
      height: 100%;
      top: 0;
      background: rgba(0, 0, 0, 0);
      cursor: col-resize;
      user-select: none;
      touch-action: none;
    }

    .resizer.ltr {
      right: 0;
    }

    .resizer.rtl {
      left: 0;
    }

    .resizer.isResizing {
      opacity: 1;
    }

    @media (hover: hover) {
      .resizer {
        opacity: 0;
      }

      *:hover > .resizer {
        opacity: 1;
      }
    }
  }
`);
