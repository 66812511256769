import CustomButton from "../../../../../../../components/buttons/CustomButton";
import { FlexContainer } from "../../../../../../../components/containers";
import EquipmentInfo from "../../../../../../infoLubricationPoints/components/EquipmentInfo";
import { TasHeaderProps } from "../types/SurveyModalTypes";

export const TaskHeader: React.FC<TasHeaderProps> = ({
  children,
  canNext,
  canPrev,
  handleNext,
  handlePrev,
  currentItem,
  backgroundColor,
}) => {
  return (
    <FlexContainer
      background={backgroundColor}
      color="white"
      align="center"
      justify="space-between"
      padding="8px 24px"
    >
      {handlePrev && canPrev !== undefined && (
        <CustomButton
          variant="iconButton"
          styleProps={{
            color: !canPrev ? "#FFFFFF50" : "#fff",
            borderColor: !canPrev ? "#FFFFFF50" : "#FFF",
          }}
          disabled={!canPrev}
          icon="before"
          action={handlePrev}
        />
      )}
      <FlexContainer width="100%" align="center">
        <FlexContainer width="100%" justify="space-between">
          {children && children}
        </FlexContainer>
        <EquipmentInfo variant="resume" items={currentItem && [currentItem]} />
      </FlexContainer>
      {handleNext && canNext !== undefined && (
        <CustomButton /* outlined */
          variant="iconButton"
          styleProps={{
            color: !canNext ? "#FFFFFF50" : "#fff",
            borderColor: !canNext ? "#FFFFFF50" : "#FFF",
          }}
          disabled={!canNext}
          icon="next"
          action={handleNext}
        />
      )}
    </FlexContainer>
  );
};


