import React, { Suspense, useMemo } from "react";
import FlexContainer from "../../../components/containers/FlexContainer";
import Popover from "../../../components/containers/Popover";
import EntityProperties from "../../../components/EntityProperties";
import Title from "../../../components/Typography/Title";
import { useLangLabels } from "../../../shared/lang/services/useLangLabels";
import { useAllAuxByPlant } from "../../allAux/services/allAuxServices";
import { useCriticalitiesGridModel } from "../../criticalities/lang/useCriticalitiesGridModel";
import { CriticalityInterface } from "../../criticalities/models/criticalityInterface";
import { LubricationPointInterface } from "../../lubricationPoints/model/lubricationPointsInterface";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { CustomIcon } from "../../../components/buttons/CustomIcon";

interface criticalityFlagsProps {
  lubricationPoint?: LubricationPointInterface;
  fontSize?: number;
  mode?: "icons";
}

const CriticalityFlags: React.FC<criticalityFlagsProps> = React.memo(
  ({ fontSize, lubricationPoint, mode }) => {
    const { lang } = useLangLabels();
    const { data: allAux } = useAllAuxByPlant();
    const criticalityGridModel = useCriticalitiesGridModel();
    const criticalities = allAux?.criticalities;

    const assetImportance = useMemo(
      () =>
        lubricationPoint
          ? criticalities?.filter(
              (criticality: CriticalityInterface) =>
                criticality.description === lubricationPoint.criticality
            )[0]
          : undefined,
      [criticalities, lubricationPoint]
    );
    const assetCriticality = useMemo(
      () =>
        lubricationPoint
          ? criticalities?.filter(
              (criticality: CriticalityInterface) =>
                criticality.description === lubricationPoint.info?.assetCriticality
            )[0]
          : undefined,
      [criticalities, lubricationPoint]
    );

    if (!!mode && mode === "icons") {
      return (
        <FlexContainer>
          <ListItem>
            <ListItemIcon>
              <CustomIcon icon="state" style={{ color: assetCriticality?.color }} />
            </ListItemIcon>
            <ListItemText primary={assetCriticality?.description} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CustomIcon icon="state" style={{ color: assetImportance?.color }} />
            </ListItemIcon>
            <ListItemText primary={assetImportance?.description} />
          </ListItem>
        </FlexContainer>
      );
    }

    return (
      <FlexContainer width={"100%"} justify="space-between">
        <Popover
          popoverContent={
            <EntityProperties
              title={lang.infoLubricationPoints.assetGeneralScore.toUpperCase()}
              entity={assetCriticality}
              gridModel={criticalityGridModel}
            />
          }
          justify="center"
          width={"45%"}
          height={"50px"}
          align="center"
          background={assetCriticality?.color || "gray"}
          borderRadius={"8px"}
        >
          <FlexContainer color="white" justify="center">
            <Title fontSize={fontSize}>
              {assetCriticality?.description} - {assetCriticality?.number}
            </Title>
          </FlexContainer>
        </Popover>

        <Popover
          popoverContent={
            <Suspense fallback={""}>
              <EntityProperties
                title={lang.infoLubricationPoints.assetPriority.toUpperCase()}
                entity={assetImportance}
                gridModel={criticalityGridModel}
              />
            </Suspense>
          }
          width={"45%"}
          height={"50px"}
          justify="center"
          align="center"
          background={assetCriticality?.color || "gray"}
          borderRadius={"8px"}
        >
          <FlexContainer color="white" justify="center">
            <Title fontSize={fontSize}>
              {assetImportance?.description} - {assetImportance?.number}
            </Title>
          </FlexContainer>
        </Popover>
      </FlexContainer>
    );
  }
);

export default CriticalityFlags;
