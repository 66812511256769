import React from 'react';

interface Props {
    children?: React.ReactNode;
    index: any;
    value: any;
    }
    
    const TabPanel:React.FC<Props> = ({ children, value, index, ...other })=> {
    
        return (
            <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
            >
            {value === index && (
                children
            )}
            </div>
        );
    }

export default React.memo(TabPanel);
