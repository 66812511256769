import React, { useEffect } from "react";
import FlexContainer from "../../../components/containers/FlexContainer";
import { CircularProgress } from "@material-ui/core";
import { LubricationPointInterface } from "../../../domains/lubricationPoints/model/lubricationPointsInterface";
import { SurveyInterface } from "../../../domains/surveys/model/SurveyInterface";
import { usePeriod } from "../../../shared/date/usePeriod";
import { useSurveysByPeriodAndTagTGD } from "../SurveyExploreCache";
import { PeriodFields } from "../../../components/fields/PeriodFields/PeriodFields";

interface SurveyFiltersProps {
  lubricationPoint: LubricationPointInterface | null | undefined;
  handleChange: (surveys: SurveyInterface[]) => void;
}

export const SurveyFilters: React.FC<SurveyFiltersProps> = ({ lubricationPoint, handleChange }) => {
  const { period, setPeriod } = usePeriod(180);

  const { data: surveys, isLoading } = useSurveysByPeriodAndTagTGD({
    tagFP: lubricationPoint?.tagFP,
    tagTGD: lubricationPoint?.tagTGD,
    firstDate: period.firstDate,
    secondDate: period.secondDate,
  });

  useEffect(() => {
    if (!!surveys) {
      handleChange(surveys);
    }
  }, [surveys]);
 
  if (isLoading) {
    return (
      <FlexContainer height={"100px"} justify="center">
        <CircularProgress />
      </FlexContainer>
    );
  }

  return (
    <FlexContainer height={"100px"} width={"100%"} justify="center" align="center">
      {<PeriodFields defaultPeriodValues={period} onChange={setPeriod} />}
    </FlexContainer>
  );
};
