import React from "react";
import { Button } from "@material-ui/core";
import { CustomIcon } from "./CustomIcon";

interface Props {
  icon: "check" | "close";
  title?: string;
  action: () => void;
  height?: string;
  width?: string;
}

const DoNotDoButton: React.FC<Props> = ({ icon, title, action, height, width }) => {
  const config = {
    backgroundColor: icon === "check" ? "green" : "red",
    height: height || "100px",
    width: width || "150px",
    color: "#fff",
  };

  return (
    <Button onClick={action} style={config}>
      <CustomIcon icon={icon} />
      {title && title}
    </Button>
  );
};

export default DoNotDoButton;
