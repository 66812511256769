import { HTMLProps, useEffect, useRef } from "react";
import { CELL_HEIGHT } from "../../TableContainer/TableContainer";

export function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className}
      {...rest}
      style={{
        margin: "auto",
        width: `${CELL_HEIGHT / 2}px`,
        height: `${CELL_HEIGHT / 2}px`,
        cursor: "pointer",
      }}
    />
  );
}
