import { MutateOptions } from "react-query"
import { apiCall } from "../../../shared/axios/axios-config"
import { PrivateQueryData, PrivateQueryTagFPInterface } from "../../../shared/globals/utilsGlobalTypes"
import { useStatusProcessor } from "../../../shared/queries/StatusProcessor"
import { queryClient, useMutation, useQuery } from "../../../shared/react-query/react-query-conf"
import { LubricationPointInterface } from "../../lubricationPoints/model/lubricationPointsInterface"
import { useSessionContext } from "../../session/store/sessionContext"
import { useToken } from "../../session/store/sessionStore"
import { LUBRICATOR_DATA_KEY } from "../../users/lubricator/lubricatorStore"
import { InfoLubricationPointInterface } from "../model/InfoLubricationPointInterface"

/*---------------
Axios
-----------------*/

 

export const UpdateLubricationPointInfo = ({data,token}:PrivateQueryData) => {
    return apiCall({
        method: "post",
        url:`/UpdateEquipmentInfo`,
        data,
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}

export const UpdateReviewState = ({data,token}:PrivateQueryData) => {
    return apiCall({
        method: "post",
        url:`/UpdateReviewState`,
        data,
        headers:{
            Authorization:`Bearer ${token}`
        }
    })
}


export const EquipmentInfoByTagFP = ({token,tagFP}:PrivateQueryTagFPInterface) => {
    return apiCall({
        method:'GET',
        url:`/EquipmentsInfoByTagFP`,
        headers:{
            authorization:`Bearer ${token}`,
            tagFP
        }
    })
}

export const useEquipmentsInfoByTagFP =  ()  => {
    const {token,tagFP} = useSessionContext()
    return useQuery<InfoLubricationPointInterface[]>({
        queryKey:['EquipmentsInfoByTagFP'],
        queryFn:()=>EquipmentInfoByTagFP({token,tagFP})
    })
}

export const getAllInfoLubricationPoints = () => {
    return queryClient.getQueryData<InfoLubricationPointInterface[]>('EquipmentsInfoByTagFP')
}



/*---------------
MUTATIONS
-----------------*/ 

export const useUpdateEquipmentInfo = () => {

    const token = useToken()
    const query = useMutation(UpdateLubricationPointInfo,{
        onSuccess:(res)=>{
            queryClient.invalidateQueries('pendingPoints')
            queryClient.invalidateQueries('DailyOperatorLubricationPoints')
            queryClient.invalidateQueries('LubricationPointsWithInfo')
            queryClient.invalidateQueries('AllLubricationPointsAndInfoByTagFP')
            queryClient.invalidateQueries('DailyOperatorLubricationPoints')
            queryClient.invalidateQueries('PaginatedLubricationPointsAndInfo')
            queryClient.invalidateQueries('PaginatedSurveyLubricationPointByPeriod')
            queryClient.invalidateQueries('PaginatedDaoSurveyLubricationPointByPeriod')
            return res
        }
    })
    
    const updateEquipmentInfo = (data:InfoLubricationPointInterface) => {
        return query.mutateAsync({
            data:{
                ...data,
                supplies:JSON.stringify(data.supplies),
                updatedToday:true
            },
            token
        })
    }

    const status = useStatusProcessor(query)
    return {
        updateEquipmentInfo,
        query:{
            ...query,
            ...status
        }
    }
}

export const useUpdateInfoEquipmentReview = () =>{
    
    const {token} = useSessionContext()
    const queryData = useMutation(UpdateReviewState,{
        onSuccess:()=>{
            queryClient.invalidateQueries('AllLubricationPointsAndInfoByTagFP')
            queryClient.invalidateQueries('EquipmentsInfoByTagFP')
            queryClient.invalidateQueries('LubricationPointsWithInfo')
            queryClient.invalidateQueries('PaginatedLubricationPointsAndInfo')
        }
    })
    const status = useStatusProcessor(queryData)


    const cancelReviewState = ({tagTGD,tagFP}:LubricationPointInterface) => {
        queryData.mutate({
            token,
            data:{
                tagTGD,
                tagFP,
                updateReview:false
            }
        })
    }

    const activateReviewState = ({tagTGD,tagFP}:LubricationPointInterface) => {
        queryData.mutate({
            token,
            data:{
                tagTGD,
                tagFP,
                updateReview:true
            }
        })
    }

    return {
        cancelReviewState,
        ...queryData,
        ...status
    }
}

