import React from "react";
import { CrudFormType } from "../../../../shared/form/types/FormTypes";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import VirtualGrid from "../../../../shared/react-table/VirtualGrid/VirtualGrid";
import { useSessionContext } from "../../../session/store/sessionContext";
import { useColectorGridModel } from "../../lang/useColectorGridModel";
import { useCollectorsByTagFP } from "../../services/collectorsServices";
import { CarrousellActionInterface } from "../../../../shared/react-table/VirtualGrid/types/VirtualGridTypes";
import { CollectorInterface } from "../../models/CollectorInterfaces";
import CollectorStatus from "../CollectorStatus";
import ViewQrCode from "../ViewQrCode";
import DesactivateActivateCollector from "../components/DesactivateActivateCollector";
import CreateUpdateCollectorForm from "../components/CreateUpdateCollectorForm";

interface Props {
  virtualGridWidth: string;
}

const ManageCollectorsPage: React.FC<Props> = ({ virtualGridWidth }) => {
  const { tagFP } = useSessionContext();
  const { lang } = useLangLabels();
  const { data: collectors } = useCollectorsByTagFP(tagFP);

  const carrousellActions: CarrousellActionInterface = [
    {
      icon: "edit",
      component: (item: CollectorInterface) => {
        return (
          <CreateUpdateCollectorForm
            item={item}
            actionType={CrudFormType.update}
          />
        );
      },
    },
  ];

  return (
    <VirtualGrid
      carrousellComponents={carrousellActions}
      width={virtualGridWidth}
      title={lang.collectors.plural}
      entityName={lang.collectors.plural}
      gridModel={useColectorGridModel()}
      items={collectors}
      headerActions={() => (
        <CreateUpdateCollectorForm actionType={CrudFormType.create} />
      )}
      itemActions={(item: any) => <DesactivateActivateCollector item={item} />}
      renderOptionColumn={{
        headerName: "More",
        renderOption: (item) => (
          <>
            <CollectorStatus collector={item} />
            <ViewQrCode {...{ item }} />
          </>
        ),
      }}
    ></VirtualGrid>
  );
};

export default ManageCollectorsPage;
