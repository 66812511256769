import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { InputComponentTypes } from "../../../../shared/form/types/FormTypes";
import { useForm } from "../../../../shared/form/useForm";
import { FormattedUiModel } from "../../../../domains/surveys/components/SurveyUpdateForm/models/FormattedUiModel";
import { InstrumentInterface, SaveInstrumentInterface } from "../types/instrumentTypes";
import { enumTgdTypes } from "../../../../domains/types/models/types";
import { getTodayDay, useDate } from "../../../../shared/date";

export const useInstrumentForm = (tagFP: string, instrument?: InstrumentInterface) => {
  const { lang } = useLangLabels();

  const textToFormValues = (rangeString: string) => {
    const regex = /^(\d+) - (\d+) (.+)$/;
    const match = rangeString.match(regex);

    if (!match) {
      return null;
    }

    const [, rangeFromStr, rangeToStr, measureUnit] = match;
    const rangeFrom = parseInt(rangeFromStr, 10);
    const rangeTo = parseInt(rangeToStr, 10);

    return { rangeFrom, rangeTo, measureUnit };
  };

  const rangeValues = textToFormValues(instrument?.range || "");

  const instrumentForm = useForm<SaveInstrumentInterface>({
    id: {
      value: instrument?.id || "",
      component: InputComponentTypes.none,
    },
    tag: {
      label: lang.instruments.tag,
      value: instrument?.tag || "",
      placeholder: lang.instruments.tag,
      component: InputComponentTypes.text,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    name: {
      label: lang.instruments.name,
      value: instrument?.name || "",
      placeholder: lang.instruments.name,
      component: InputComponentTypes.text,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    sciNumber: {
      label: lang.instruments.sciNumber,
      value: instrument?.sciNumber || "",
      placeholder: lang.instruments.sciNumber,
      component: InputComponentTypes.text,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    type: {
      label: lang.instruments.type,
      value: instrument?.type || "",
      placeholder: lang.instruments.type,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
      component: InputComponentTypes.typeBy,
      inputConfig: {
        tagFP: tagFP,
        by: {
          key: "type",
          value: enumTgdTypes.instrument,
        },
      },
    },
    brand: {
      label: lang.instruments.brand,
      value: instrument?.brand || "",
      placeholder: lang.instruments.brand,
      component: InputComponentTypes.instrumentBrand,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
      inputConfig: {
        tagFP: tagFP,
      },
    },
    model: {
      label: lang.instruments.model,
      value: instrument?.model || "",
      placeholder: lang.instruments.model,
      component: InputComponentTypes.instrumentModel,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
      inputConfig: {
        tagFP: tagFP,
      },
    },
    rangeFrom: {
      label: lang.instruments.rangeFrom,
      value: rangeValues?.rangeFrom || 0,
      placeholder: lang.instruments.rangeFrom,
      component: InputComponentTypes.number,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    rangeTo: {
      label: lang.instruments.rangeTo,
      value: rangeValues?.rangeTo || 0,
      placeholder: lang.instruments.rangeTo,
      component: InputComponentTypes.number,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    plc: {
      label: lang.instruments.plc,
      value: instrument?.plc || "",
      placeholder: lang.instruments.plc,
      component: InputComponentTypes.instrumentPLC,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
      inputConfig: {
        tagFP: tagFP,
      },
    },
    assignation: {
      label: lang.instruments.assignment,
      value: instrument?.assignation || "",
      placeholder: lang.instruments.assignment,
      component: InputComponentTypes.text,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    calibrationDate: {
      label: lang.instruments.calibrationDate,
      value: instrument?.calibrationDate.split("T")[0] || getTodayDay(),
      placeholder: lang.instruments.calibrationDate,
      component: InputComponentTypes.date,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    certificate: {
      label: lang.instruments.certificate,
      value: instrument?.certificate || "",
      placeholder: lang.instruments.certificate,
      component: InputComponentTypes.text,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    patronCertificate: {
      label: lang.instruments.patronCertificate,
      value: instrument?.patronCertificate || "",
      placeholder: lang.instruments.patronCertificate,
      component: InputComponentTypes.text,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    patronDate: {
      label: lang.instruments.patronDate,
      value: instrument?.patronDate.split("T")[0] || getTodayDay(),
      placeholder: lang.instruments.patronDate,
      component: InputComponentTypes.date,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
    },
    measureUnit: {
      label: lang.instruments.measureUnit,
      value: rangeValues?.measureUnit || "",
      placeholder: lang.instruments.measureUnit,
      component: InputComponentTypes.typeBy,
      validations: {
        required: {
          value: true,
          message: lang.validations.messages.required,
        },
      },
      inputConfig: {
        tagFP: tagFP,
        by: {
          key: "type",
          value: enumTgdTypes.unity,
        },
      },
    },
  });

  const { uiModel } = instrumentForm;

  const formattedUiModel: FormattedUiModel<SaveInstrumentInterface> = uiModel.reduce((acc, obj) => {
    acc[obj.name] = obj;
    return acc;
  }, {} as FormattedUiModel<SaveInstrumentInterface>);

  return {
    ...instrumentForm,
    formattedUiModel,
  };
};
