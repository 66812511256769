import React from "react";
import { CrudFormType } from "../../../../shared/form/types/FormTypes";
import { useLangLabels } from "../../../../shared/lang/services/useLangLabels";
import { useUser } from "../../../../domains/session/store/sessionStore";
import CreateUpdateBrandForm from "./CreateUpdateBrandForm";
import { useGetAllBrands } from "../services/brandService";
import SimpleAutocompleteSelect from "../../../../components/autocomplete/SimpleAutocompleteSelect";

interface SelectBrandProps {
  onChange: (value: any) => void;
  defaultValue?: string;
  error?: string;
  disabled?: boolean;
}

const SelectBrand: React.FC<SelectBrandProps> = ({ onChange, defaultValue, error, disabled }) => {
  const { lang } = useLangLabels();
  const { data: user } = useUser();

  const { data: brands } = useGetAllBrands();

  const brandOptions = brands?.map((brand: any) => ({ label: brand.brand || "" })) || [];

  return (
    <SimpleAutocompleteSelect
      error={error}
      items={brandOptions}
      defaultValue={defaultValue}
      label={lang.instruments.brand}
      onChange={onChange}
      showTitle={"label"}
      disabled={disabled}
    >
      {user?.type !== "O" && <CreateUpdateBrandForm actionType={CrudFormType.create} />}
    </SimpleAutocompleteSelect>
  );
};

export default SelectBrand;
