import { GridModelInterface } from "../../../../../shared/gridModel/gridModelEntity";
import { useLangLabels } from "../../../../../shared/lang/services/useLangLabels";

export const useLossReportGridModel = () => {
  const { lang } = useLangLabels();

  const gridModel: GridModelInterface = {
    sector: { label: lang.sectors.title.singular },
    equipment: { label: lang.STIEquipments.title.singular },
    element: { label: lang.observations.element },
    component: { label: lang.observations.component },
    tagPlanta: { label: lang.reportsGeneration.tagPlanta },
    date: { label: lang.observations.observationDate },
    observation: { label: lang.observations.singular },
    user: { label: lang.observations.user },
    lubricant: { label: lang.containers.form.label.lubricant},
    quantity: { label: lang.surveys.quantity},
    capacitiy: { label: lang.containers.form.label.capacity},
    lossPercentage: { label: lang.surveys.loss },
    addedReason: { label: lang.surveys.reason },
  };

  return gridModel;
};
