import { LubricationPointInterface } from "../../../lubricationPoints/model/lubricationPointsInterface";
import { LubricationSurveyControlerInterface } from "../../model/SurveyInterface";
import { SurveyTasksValues } from "../../tasks/model/TaskInterface";

const useGetInitialState = (
  outOfRoute: boolean,
  item?: LubricationPointInterface
) => {
  const initialState: LubricationSurveyControlerInterface = {
    lastSurveyId: undefined,
    taskStep: outOfRoute ? 0 : 1,
    customStep: 0,
    loop: 0,
    fetchStatus: "idle",
    point: item,
    inspectionComplete: false,
    inspection: {
      updateReview: false,
      tagTGD: item?.tagTGD! || "",
      tagFP: item?.tagFP! || "",
      sector: item?.sector!,
      equipment: item?.equipment!,
      measureUnit: item?.measureUnit!,
      needsLubrication: undefined,
      inaccessible: undefined,
      manualRegistration: true,
      user: "",
      startTime: new Date().toISOString(),
      review: item?.review!,
      routeDate: new Date().toISOString(),
      outOfRoute: outOfRoute,
      route: item?.routeName!,
      temperature: 0,
      leak: false,
      labelReplacement: false,
      newComponent: false,
    },
    lubrication: {
      addedReason: "",
      sampleExtraction: false,
      lubricantType: item?.lubricantType!,
      impossibleToLubricate: undefined,
      lubricates: undefined,
      lubricant: item?.lubricant!,
      taskType: SurveyTasksValues.lubrication,
      taskTime: 0,
      taskDone: undefined,
      quantity: 0,
      surveyDone: false,
    },
    cleaning: {
      taskType: SurveyTasksValues.cleaning,
      taskTime: 0,
      taskDone: undefined,
      surveyDone: false,
    },
    repairment: {
      taskType: SurveyTasksValues.repairment,
      taskTime: 0,
      taskDone: undefined,
      surveyDone: false,
    },
    puntuation: {
      assetCriticality: "",
    },
    neededSupplies: [],
    observations: [],
    anomalies: [],
    pending: item?.info?.pending,
    globalStatus: "idle",
    modal: false,
  };

  return initialState;
};

export default useGetInitialState;
