import { TaskHeader } from "./TaskHeader";
import { Typography } from "@material-ui/core";
import { FlexContainer } from "../../../../../../../components/containers";
import { Alert } from "@material-ui/lab";
import CustomButton from "../../../../../../../components/buttons/CustomButton";
import SurveyTasks from "../../../../SurveyTasks/SurveyTasks";
import { SurveyModalHeaderProps } from "../types/SurveyModalTypes";
import { useLangLabels } from "../../../../../../../shared/lang/services/useLangLabels";
import { TaskType } from "../../../../../tasks/model/TaskInterface";

const SurveyModalHeader = ({
  canNext,
  canPrev,
  handleNext,
  handlePrev,
  currentLubricationPoint,
  backgroundColor,
  currentTask,
  wasInspectedTodayState,
  setWasInspectedTodayState,
  outOfRoute,
}: SurveyModalHeaderProps) => {
  const { lang } = useLangLabels();

  const getTaskTitle = (currentTask: TaskType) => {
    switch (currentTask) {
      case TaskType.inspection:
        return lang.surveys.tasks.inspection.title;
      case TaskType.lubrication:
        return lang.surveys.tasks.lubrication.title;
      case TaskType.cleaning:
        return lang.surveys.tasks.cleaning.title;
      case TaskType.repairment:
        return lang.surveys.tasks.repairment.title;
      case TaskType.puntuation:
        return lang.surveys.tasks.score;
      case TaskType.intervention:
        return lang.surveys.tasks.intervention;
      case TaskType.control:
        return lang.surveys.tasks.control;
    }
  };

  const taskTitle = getTaskTitle(currentTask as TaskType)?.toUpperCase();

  return (
    <>
      <TaskHeader
        canNext={canNext}
        canPrev={canPrev}
        handleNext={handleNext}
        handlePrev={handlePrev}
        currentItem={currentLubricationPoint}
        backgroundColor={backgroundColor}
      >
        <Typography color="inherit" variant="h6">
          {taskTitle}
        </Typography>
      </TaskHeader>

      {wasInspectedTodayState ? (
        <FlexContainer height={"60vh"} justify="center" align={"center"} padding="24px">
          <Alert
            severity="warning"
            icon={
              <CustomButton
                variant="iconButton"
                icon="surveyPlay"
                color="secondary"
                action={() => setWasInspectedTodayState(false)}
                popoverTitle={lang.routes.survey.outOfRoute}
              />
            }
          >
            <FlexContainer justify="center" width="100%">
              {lang.surveys.pointAlreadySurveyedToday}
              <br />
              {lang.surveys.surveyOffRouteAgain}
            </FlexContainer>
          </Alert>
        </FlexContainer>
      ) : (
        <>
          {outOfRoute && (
            <FlexContainer
              align="center"
              background={"black"}
              color="white"
              padding="4px 24px"
              height="3vh"
            >
              <Typography variant="body2">({lang.surveys.outOfRoute.toUpperCase()})</Typography>
            </FlexContainer>
          )}

          <SurveyTasks outOfRoute={outOfRoute} lubricationPoint={currentLubricationPoint} />
        </>
      )}
    </>
  );
};

export default SurveyModalHeader;
