import FlexContainer from "../../containers/FlexContainer";

interface PageHeaderProps {
  children: React.ReactNode;
}

const PageHeader = ({ children }: PageHeaderProps) => {
  return (
    <FlexContainer
      width={"95%"}
      margin={"auto"}
      height={"56px"}
      align="center"
      justify="space-between"
      gap={"16px"}
    >
      {children}
    </FlexContainer>
  );
};

export default PageHeader;
