/* eslint-disable no-new-func */
import React, { useEffect, useMemo, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { AxiosRequestConfig } from "axios";
import SimpleAutocompleteSelect from "../../components/autocomplete/SimpleAutocompleteSelect";
import CustomButton from "../../components/buttons/CustomButton";
import { useGenericAPICall } from "./hooks/useGenericAPICall";
import { executeRecursivePromise } from "./utils/executeRecursivePromise";

import EndpointsAutomationScripts from "./scripts/EndpointsAutomationScripts.js";
import PageContainer from "../../components/containers/PageContainer";
import TgdTextField from "../../components/fields/TextField/TgdTextField";
import FlexContainer from "../../components/containers/FlexContainer";
import { usePlants } from "../session/store/sessionStore";

const initialReq: AxiosRequestConfig = {
  headers: undefined,
  method: "POST",
  data: [],
  url: "",
};

const EndpointAutomation: React.FC<{}> = ({}) => {
  const apiCall = useGenericAPICall();
  const [isLoading, setIsLoading] = useState(false);
  const [recursive, setRecursive] = useState(false);

  const [req, setReq] = useState(initialReq);

  const [res, setRes] = useState<any[]>([]);

  const handleReq = (key: keyof AxiosRequestConfig, value: any) => {
    setReq({
      ...req,
      [key]: value,
    });
  };

  const handleJSON = (string: string) => {
    handleReq("data", JSON.parse(string));
  };

  const submit = async () => {
    setIsLoading(true);
    setRes([]);
    if (recursive) {
      await executeRecursivePromise(req.data, (item) => {
        setIsLoading(() => true);
        return apiCall({
          ...req,
          data: item,
        })
          .then((response) => {
            setRes((old: any[]) => [
              ...old,
              {
                res: response,
                req: item,
              },
            ]);
            setIsLoading(() => false);
          })
          .catch((error) => {
            setRes((old: any[]) => [...old, error]);
            setIsLoading(() => false);
          });
      });
    } else {
      await apiCall(req)
        .then((response: any) => {
          setRes([response]);
          setIsLoading(() => false);
        })
        .catch((error) => {
          setRes([error]);
          setIsLoading(() => false);
        });
    }
  };

  const [makeCalculation, setMakeCalculation] = useState(false);
  const [fn, setfn] = useState(EndpointsAutomationScripts[0].script);
  const [fnResult, setfnResult] = useState<string>();

  const adder = useMemo(() => new Function("data", `${fn}`), [makeCalculation]);

  useEffect(() => {
    if (makeCalculation) {
      const result = adder(res, fn);
      setfnResult(JSON.stringify(result, null, "\t"));
      setMakeCalculation(false);
    }
  }, [makeCalculation, fn, res]);

  const plants = usePlants();

  return (
    <PageContainer>
      <FlexContainer>
        <FlexContainer gap="24px" flexWrap="wrap" width="50%">
          <FlexContainer>
            <input type="checkbox" onChange={() => handleReq("method", "GET")} />
            <label>GET</label>
          </FlexContainer>
          <FlexContainer>
            <input type="checkbox" onChange={() => handleReq("method", "POST")} />
            <label>POST</label>
          </FlexContainer>
          <SimpleAutocompleteSelect
            defaultValue={req.headers?.tagFP}
            label="Planta"
            showTitle="tagFP"
            items={plants}
            onChange={(value) => handleReq("headers", { tagFP: value })}
          />
          <TgdTextField
            label="endpoint"
            value={req.url}
            onChange={(value) => handleReq("url", value)}
          />
          <TgdTextField rows={25} label="Body" onChange={(value) => handleJSON(value)} />
          <FlexContainer>
            <input type="checkbox" onChange={() => setRecursive(!recursive)} />
            <label>recursivo</label>
          </FlexContainer>
          <CustomButton icon="tool" action={submit}>
            Cobrar Asado
          </CustomButton>
        </FlexContainer>
        <FlexContainer flexWrap="wrap" width="50%">
          {isLoading ? (
            <CircularProgress size="20px" />
          ) : (
            <>
              <TgdTextField
                value={JSON.stringify(res, null, "\t")}
                readonly
                rows={8}
                label="respuesta"
                onChange={() => console.log()}
              />
              <FlexContainer flexWrap="wrap" width="100%">
                <FlexContainer flexWrap="wrap" align="center" width="100%">
                  <SimpleAutocompleteSelect
                    defaultValue={
                      EndpointsAutomationScripts.find((item) => item.script === fn)?.name
                    }
                    label="Seleccionar Scripts"
                    items={EndpointsAutomationScripts}
                    showTitle="name"
                    onChange={(value) => {
                      const selectedScript = EndpointsAutomationScripts.find(
                        (item) => item.name === value
                      );
                      setfn(selectedScript?.script!);
                    }}
                  />
                  <div>
                    <CustomButton
                      icon="lab"
                      color="inherit"
                      variant="iconButton"
                      action={() => setMakeCalculation(true)}
                    />
                  </div>
                  <TgdTextField
                    value={fn}
                    rows={8}
                    label="descubrir"
                    onChange={(value) => setfn(value.toString())}
                  />
                </FlexContainer>
              </FlexContainer>
              <TgdTextField
                value={fnResult}
                readonly
                rows={8}
                label="resultado"
                onChange={() => console.log()}
              />
              <br />
            </>
          )}
        </FlexContainer>
      </FlexContainer>
    </PageContainer>
  );
};

export default EndpointAutomation;
